

import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { Label } from '../../components/ui/label';
import React, { useState } from 'react';
import { toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OrderTrack = () => {
  const [orderId, setOrderId] = useState('');
  const [email, setEmail] = useState('');

  const handleTrackOrder = (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Implement the order tracking logic here
    console.log('Order ID:', orderId);
    console.log('Email:', email);

    // Assume order tracking logic sends an email and triggers a toast notification
    toast.success('Your order status will be sent to your email!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    // Reset form fields after submission
    setOrderId('');
    setEmail('');
  };

  return (
    <div className="bg-white">
      <div className="wrapper flex flex-col gap-6 p-8">
        <h2 className="text-xl font-bold  text-primary">
          Track Your Order
        </h2>
        <div className=" text-lg text-gray-800 space-y-4 bg-gray-50 w-full">
          <p className="">
            Enter your Order ID, Email Address given to Place Order to track the status of your order.
          </p>
          <form className="flex gap-4 w-full justify-between shadow-lg p-5 items-center flex-wrap " onSubmit={handleTrackOrder}>
            <div className='flex gap-2 items-center'>
              <Label >
                Order ID*
              </Label>
              <Input
                type="text"
                placeholder="Enter your Order ID"
                  className='input-field'
                value={orderId}
                onChange={(e) => setOrderId(e.target.value)}
                required
              />
            </div>
            <div className='flex gap-2 items-center'>
              <Label >
                Email Address*
              </Label>
              <Input
                type="email"
                placeholder="Enter your Email Address"
                className='input-field'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <Button
              type="submit"
              className="headermenu max-w-full "
            >
              Track Order
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OrderTrack;
