import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import React from "react";
import { useGetBannersQuery } from "../../../redux/slice/admin/ABannerImage";
import { IMGURL } from "../../../constants/appConfig";


const CarouselCom = () => {
  const { data: bannerIamges, isLoading, isSuccess, isError, error } = useGetBannersQuery("bannerList", { pollingInterval: 60000, refetchOnFocus: true, refetchOnMountOrArgChange: true })



  let content
  if (isLoading) content = <section className="wrapper" >
    <div className="flex justify-center h-full items-center">
      <div className="animate-spin rounded-full h-12 w-12  border-t-2 border-b-2 border-blue-800 "></div>
    </div>
  </section>;
  if (isError) content = <p>{bannerIamges?.result?.message}</p>
  if (!bannerIamges?.result?.length) {
    content = <section className="bgcolorgold" >
      {/* <p className="p-4 text-center text-lg font-semibold">
        No SubCategories Available Under This Category
      </p> */}</section>
  }
  if (isSuccess && bannerIamges?.result?.length > 0) {
    content = <Carousel
      autoPlay={true}
      infiniteLoop={true}
      interval={2500}
      showThumbs={false} showStatus={false} className="wrapper"
    >

           { bannerIamges?.result?.map((Image: any) => <div key={Image.id}>
              <img src={`${IMGURL}${Image.imageUrl}`} alt={"new"} className="md:h-[350px] h-[150px]" />
              </div>)}
    </Carousel>
  }
  return <>
    {content}
  </>
}

export default CarouselCom