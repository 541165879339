import { apiSlice } from "../../apiSlice";


export const AAttributeApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getProductAttributes:builder.query({
            query:(id)=>({url:`/common/getProductAttributes/${id}`}),
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                  return [
                    { type: "Attribute", id: "LIST" },
                    ...result.ids.map((id: any) => ({ type: "Attribute", id })),
                  ];
                } else return [{ type: "Attribute", id: "LIST" }];
              },
        }),
        getProductAttributesById:builder.query({
            query:(id)=>({url:`/common/getProductAttributesById/${id}`}),
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                  return [
                    { type: "Attribute", id: "LIST" },
                    ...result.ids.map((id: any) => ({ type: "Attribute", id })),
                  ];
                } else return [{ type: "Attribute", id: "LIST" }];
              },
        }),
        saveProductAttributeColor: builder.mutation({
            query: initialProdData => ({
                url: '/admin/saveProductAttributeColor',
                method: 'POST',
                body: {
                    ...initialProdData,
                }
            }),
            invalidatesTags: [
                { type: 'Attribute', id: "LIST" }
            ]
        }),
        saveProductAttributeSides: builder.mutation({
            query: initialProdData => ({
                url: '/admin/saveProductAttributeSides',
                method: 'POST',
                body: {
                    ...initialProdData,
                }
            }),
            invalidatesTags: [
                { type: 'Attribute', id: "LIST" }
            ]
        }),
        UpdateProductAttribute: builder.mutation({
            query: initialProdData => ({
                url: '/admin/UpdateProductAttribute',
                method: 'POST',
                body: {
                    ...initialProdData,
                }
            }),
            invalidatesTags: [
                { type: 'Attribute', id: "LIST" }
            ]
        }),
        getProductAttributesByAttName: builder.mutation({
            query: initialProdData => ({
                url: '/common/getProductAttributesByAttName',
                method: 'POST',
                body: {
                    ...initialProdData,
                }
            }),
            invalidatesTags: [
                { type: 'Attribute', id: "LIST" }
            ]
        }),
        getProductAttributesByCatSubCat: builder.mutation({
            query: initialProdData => ({
                url: '/common/getProductAttributesByCatSubCat',
                method: 'POST',
                body: {
                    ...initialProdData,
                }
            }),
            invalidatesTags: [
                { type: 'Attribute', id: "LIST" }
            ]
        }),
        deleteProductAttribute: builder.mutation({
            query: ( id ) => ({
                url: `/admin/deleteProductAttribute/${id}`,
                method: 'DELETE',
                }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Attribute', id: arg.id }
            ]
        }),
    }),
})

export const {
    useDeleteProductAttributeMutation,
    useSaveProductAttributeColorMutation,
    useUpdateProductAttributeMutation,
    useSaveProductAttributeSidesMutation,
    useGetProductAttributesByAttNameMutation,
    useGetProductAttributesByCatSubCatMutation,
    useGetProductAttributesQuery,
    useGetProductAttributesByIdQuery
} = AAttributeApiSlice

