import { useEffect, useState } from "react";
import { Button } from "../../components/ui/button";
import { useGetAllProductsMutation } from "../../redux/slice/ProductsApiSlice";
import {  useNavigate, useSearchParams } from "react-router-dom";
import { DataTable } from "../../components/shared/admin/components/Table/data-table";
import { columns } from "../../components/shared/admin/components/Table/columns";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { useGetAllCategoryQuery, useGetSubcategoryQuery } from "../../redux/slice/CategoryApiSlice";


const ProductsAdmin = () => {
  const [productByCategory, { isSuccess, isError, error, data: products }] = useGetAllProductsMutation()
  const [searchParams] = useSearchParams();
  const catId = searchParams.get("catId") ||1;
  const subCatId = searchParams.get("subCatId") ||"All";
  const navigate=useNavigate()

  useEffect(()=>{
    if(subCatId==="All"){
      productByCategory({catId:catId})
    }else{
      productByCategory({catId:catId,subcatId:subCatId})
    }
  },[catId,productByCategory,subCatId])

  

  return (<section className="max-w-full md:ml-52">
    <main className=" border-2  bg-red-50 border-gray-500 md:mx-8 md:my-12 md:p-4">
       <div className="flex justify-between p-4">
        <h5 className='h5-bold'>Products</h5>
        <div className="flex gap-4">
        <FilterByCategoryProducts catId={catId.toString()} />
        <FilterBySubCategoryProducts catId={catId.toString()} subCatId={subCatId}/></div>
        <Button className="headermenu" onClick={()=>navigate("/admin/dashboard/newproduct")}>Add New Product</Button>
      </div>
     {products?.result?.length>0 && <div className=" p-4 rounded-md border-2 md:overflow-hidden shadow-lg bg-green-50">
        {products?.result?.length>0 && <DataTable data={products?.result} columns={columns}/>}
      </div>}
      {products?.result?.length <= 0 && <h2 className="my-3 w-full p-2 font-bold text-lg">Selected Filters There Is No Products</h2> }

    </main>
    </section>)
}

export default ProductsAdmin

export const FilterByCategoryProducts = ({ catId}: {catId?:string}) => {
  const{data:categories}=useGetAllCategoryQuery("categoriesList",{pollingInterval:60000,refetchOnFocus:true,refetchOnMountOrArgChange:true})
  const [searchParams, setSearchParams] = useSearchParams();
  const handleValueChange = (selectedValue: string) => {
    setSearchParams({"catId":selectedValue,"subCatId":"All"});
  };
  
  return (<div className="flex gap-1 items-center">
    <p className="font-bold">Category:</p>
    <Select onValueChange={handleValueChange} value={catId} >
      <SelectTrigger className="bg-blue-50 text-md font-semibold px-0">
      
      <SelectValue placeholder="T Shirts" />
      </SelectTrigger>
      <SelectContent className='border-gray-500 bg-gray-100  hover:text-black '>
        {categories?.result?.map((category:any) => (
          <SelectItem
            key={category.id}
            value={category.id.toString()}
            className="select-item p-regular-14"
          >
            {category.categoryName}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
    </div>);
};

export const FilterBySubCategoryProducts = ({ catId,subCatId}: {catId?:string,subCatId?:string}) => {
  const{data:subCategories}=useGetSubcategoryQuery(catId,{pollingInterval:60000,refetchOnFocus:true,refetchOnMountOrArgChange:true})
  const [searchParams, setSearchParams] = useSearchParams();
  const ScatId = searchParams.get("catId") ||1;

  const handleValueChange = (selectedValue: string) => {
    setSearchParams({"catId":ScatId.toString(),"subCatId":selectedValue});
  };
  
  return (<div className="flex gap-1 items-center">
    <p className="font-bold">SubCategory:</p>
    <Select onValueChange={handleValueChange} value={subCatId} >
      <SelectTrigger className="bg-blue-50 text-md font-semibold px-0">
      <SelectValue placeholder="All" />
      </SelectTrigger>
      <SelectContent className='border-none bg-gray-100  hover:text-black '>
      <SelectItem
            key={"All"}
            value={"All"}
            className="select-item p-regular-14"
          >
           All
          </SelectItem>
        {subCategories?.result?.map((subCategory:any) => (
          <SelectItem
            key={subCategory.id}
            value={subCategory.id.toString()}
            className="select-item p-regular-14"
          >
            {subCategory.subcategoryName}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  </div>);
};