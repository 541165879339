import { apiSlice } from "../../apiSlice";


export const ABannerImage = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getBanners:builder.query({
            query:()=>({url:`/common/getBanners`}),
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                  return [
                    { type: "Banner", id: "LIST" },
                    ...result.ids.map((id: any) => ({ type: "Banner", id })),
                  ];
                } else return [{ type: "Banner", id: "LIST" }];
              },
        }),
        addBanner: builder.mutation({
            query: initialProdData => ({
                url: '/admin/addBanner',
                method: 'POST',
                body: {
                    ...initialProdData,
                }
            }),
            invalidatesTags: [
                { type: 'Banner', id: "LIST" }
            ]
        }),
        updateBannerOrder: builder.mutation({
            query: initialProdData => ({
                url: '/admin/updateBannerOrder',
                method: 'POST',
                body: {
                    ...initialProdData,
                }
            }),
            invalidatesTags: [
                { type: 'Banner', id: "LIST" }
            ]
        }),
        deleteBanner: builder.mutation({
            query: ( id ) => ({
                url: `/admin/deleteBanner/${id}`,
                method: 'DELETE',
                }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Banner', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetBannersQuery,
    useAddBannerMutation,
    useUpdateBannerOrderMutation,
    useDeleteBannerMutation
} = ABannerImage

