
import { Button } from "../../components/ui/button";
import { PaperQualityT, PaperSizeT, PaperTypeItem } from "@/react-app-env";
import { useEffect, useState } from "react";
import { useGetProductAttributesByAttNameMutation, useGetProductAttributesByIdQuery, useSaveProductAttributeSidesMutation, useUpdateProductAttributeMutation } from "../../redux/slice/admin/AAttributeApiSlice";
//import { PaperQuality, PaperSizes, PaperTypeFixed } from "../../constants";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Label } from "../../components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select"
import { Card } from "../../components/ui/card";
import { Switch } from "../../components/ui/switch";
import { useGetSubcategoryQuery } from "../../redux/slice/CategoryApiSlice";
import { IoMdAdd, IoMdClose } from "react-icons/io";
import { FilterBySubCategory } from "./StationaryAttributes";
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../components/ui/dialog"
import { Input } from "../../components/ui/input"
import { RadioGroup, RadioGroupItem } from "../../components/ui/radio-group";
import { Separator } from "../../components/ui/separator";

const EditAttributeOffice = () => {
    const{id}=useParams()
    const  { data: productAttributes,isSuccess } = useGetProductAttributesByIdQuery(id)
    const [paperSizesSet,setPaperSizesSet] =useState([])
    const [paperQualitySet,setPaperQualitySet] =useState([])
    const [paperTypeSet,setPaperTypeSet] =useState([])


    useEffect(()=>{
        const AttValue = productAttributes?.result?.attributeValue
        const AttValueObj = AttValue ? JSON.parse(AttValue) : null;
        const paperSizesSet = AttValueObj ? AttValueObj["paperSizes"] : null;
        setPaperSizesSet(paperSizesSet)
        const paperQualitySet = AttValueObj ? AttValueObj["paperQua"] : null;
        setPaperQualitySet(paperQualitySet)
        const paperTypeSet = AttValueObj ? AttValueObj["paperType"] : null;
        setPaperTypeSet(paperTypeSet)
    },[productAttributes?.result])
  
    console.log((isSuccess&&productAttributes?.result?.attributeValue) );
    
    return (
        <div className="max-w-full md:mx-20 md:my-10 border-2 border-gray-300 md:p-4">
            <div className="flex justify-between gap-4">
                <h5 className="h5-bold mb-4">Edit Fields Stationary</h5>
               
            </div>
           {(isSuccess&&productAttributes?.result?.attributeValue) && <EditOfficeToCategory result={productAttributes?.result} paperTypeSetM={paperTypeSet}
           paperQualitySetM={paperQualitySet} paperSizesSetM={paperSizesSet} />} 
        </div>
    )
}


const EditOfficeToCategory = ({result,paperSizesSetM,paperQualitySetM,paperTypeSetM}:any) => {
    //const [categoryId, setCategoryId] = useState<number>(11)
    const categoryId = 11;
    const [subcategoryId, setSubcategoryId] = useState<number>(result.subcategoryId)
    const [EditProductAttribute] = useUpdateProductAttributeMutation()
   const [paperSIzeEn, setPaperSIzeEn] = useState<boolean>(paperSizesSetM ? true : false)
  const [paperSizes, setPaperSizes] = useState<PaperSizeT[]>(paperSizesSetM ? [...paperSizesSetM] : [])
  const [paperQuaEn, setPaperQuaEn] = useState<boolean>(paperQualitySetM ? true : false)
  const [paperQua, setPaperQua] = useState<PaperQualityT[]>(paperQualitySetM ? [...paperQualitySetM] : [])
  const [paperTypeEn, setPaperTypeEn] = useState<boolean>(paperTypeSetM ? true : false)
    const [paperType, setPaperType] = useState<PaperTypeItem[]>([])
    const navigate = useNavigate()
    const [productAttByAttName, { data: productAttributes }] = useGetProductAttributesByAttNameMutation()
    const attributeName = 'Office Stationary Fixed'
    const [paperSizesSet, setPaperSizesSet] = useState([])
    const [paperQualitySet, setPaperQualitySet] = useState([])
    const [paperTypeSet, setpaperTypeSet] = useState([])



    useEffect(() => {
        productAttByAttName({ catId: 11, subcatId: 0, attributeName })
    }, [productAttByAttName, attributeName])

    useEffect(() => {
        setPaperSIzeEn(paperSizesSetM ? true : false)
        setPaperSizes(paperSizesSetM ? [...paperSizesSetM] : [])
     setPaperQuaEn(paperQualitySetM ? true : false)
    setPaperQua(paperQualitySetM ? [...paperQualitySetM] : [])
   setPaperTypeEn(paperTypeSetM ? true : false)
    setPaperType(paperTypeSetM ?[...paperTypeSetM] :[])
    }, [paperSizesSetM, paperQualitySetM, paperTypeSetM])

    useEffect(() => {
        const AttValue = productAttributes?.result[0]?.attributeValue
        const AttValueObj = AttValue ? JSON.parse(AttValue) : null;
        const paperSizesSet = AttValueObj ? AttValueObj["PaperSizes"] : null;
        setPaperSizesSet(paperSizesSet)
        const paperQualitySet = AttValueObj ? AttValueObj["PaperQuality"] : null;
        setPaperQualitySet(paperQualitySet)
        const paperTypeSet = AttValueObj ? AttValueObj["PaperType"] : null;
        setpaperTypeSet(paperTypeSet)
        setPaperType(paperTypeSet)
        setPaperType(paperTypeSetM ?[...paperTypeSetM] :paperTypeSet)
    }, [paperTypeSetM, productAttributes])

    const handleSubmit = async () => {
        if (!paperSIzeEn && !paperQuaEn && !paperTypeEn) {
            toast.error("Select atleast one property")
        }
        const valuesAtt = JSON.stringify({
            ...(paperSIzeEn ? { paperSizes } : {}),
            ...(paperQuaEn ? { paperQua } : {}),
            ...(paperTypeEn ? { paperType } : {})
        });
        await EditProductAttribute({id:result.id, categoryId, subcategoryId, attributeName: "Office Stationary", attributeValue: valuesAtt })
        navigate(`/admin/dashboard/attributes/stationary?catId=${categoryId}&subCatId=${subcategoryId}`)
    }

    const handleChangeSizes = (selectedValue: any) => {
        setPaperSizes(prev => {
            const exists = prev.find(val => val.id === selectedValue.id);
            if (exists) {
                return prev.map(val =>
                    val.id === selectedValue.id ? selectedValue : val
                );
            } else {
                return [...prev, selectedValue];
            }
        });
    };

    const handleChangeQuality = (selectedValue: any) => {
        setPaperQua(prev => {
            const exists = prev.find(val => val.id === selectedValue.id);
            if (exists) {
                return prev.map(val =>
                    val.id === selectedValue.id ? selectedValue : val
                );
            } else {
                return [...prev, selectedValue];
            }
        });
    };

    const handlePropertyToggle = (id: string, property: keyof PaperTypeItem) => {
        setPaperType(prevState =>
            prevState.map(item =>
                item.id === id
                    ? {
                        ...item, "enabled": !item.enabled
                        /*  [property]: Array.isArray(item[property]) // Type Guard
                           ? (item[property] as Array<any>).map((subItem: any) => ({ ...subItem, enabled: !subItem.enabled }))
                           : item.type === '' ? 'Default Value' : '' // Assume it's a string property */
                    }
                    : item
            )
        );
    };

    const handleSubPropertyToggle = (id: string, property: keyof PaperTypeItem, subPropertyId: number) => {
        setPaperType(prevState =>
            prevState.map(item =>
                item.id === id && Array.isArray(item[property]) // Type Guard
                    ? {
                        ...item,
                        [property]: (item[property] as Array<any>).map((subItem: any) =>
                            subItem.id === subPropertyId || subItem.id === subPropertyId
                                ? { ...subItem, enabled: !subItem.enabled }
                                : subItem
                        )
                    }
                    : item
            )
        );
    };

    return (<>
        <div className=" py-4">

            <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-4">
                <Label htmlFor="Category" className="font-bold text-lg" >
                    Category: Office Stationary
                </Label>
                <div className="flex items-center gap-4 w-full">
                    <Label htmlFor="subCategory">
                        SubCategory:
                    </Label>
                    <FilterBySubCategory onChangeHandler={setSubcategoryId} subcategoryId={subcategoryId} categoryId={categoryId} />
                </div>

            </div>

            <div className="grid md:grid-cols-2  grid-cols-1 items-center gap-6 my-4">
                <Card className="w-full p-1 py-2 border-gray-500">
                    <div className="flex justify-between">
                        <div className="flex items-center space-x-2">
                            <Switch id="size" checked={paperSIzeEn}
                                onCheckedChange={(value) => setPaperSIzeEn(value)} />
                            <Label htmlFor="size">PAPER SIZE:</Label>
                        </div>
                        <div className="bg-gray-500">

                        </div>
                    </div>
                    {paperSIzeEn && <div className="p-2 space-y-2">
                        {paperSizes?.length > 0 && <div className="flex gap-2 flex-wrap">
                            {paperSizes.map(pap => <p key={pap.id} style={{ fontSize: "12px" }} className="p-2 shadow-md bg-gray-200 rounded-full px-3 flex items-center gap-1 text-sm">{pap.paper}
                                <button onClick={() => setPaperSizes(pre => ([...pre.filter(pr => pr.id !== pap.id)]))}><IoMdClose /></button>
                            </p>)}
                        </div>}
                        <Select onValueChange={handleChangeSizes}   >
                            <SelectTrigger className="bg-blue-50 text-md font-semibold px-0  w-full">
                                <SelectValue placeholder="----Select----" />
                            </SelectTrigger>
                            <SelectContent className='border-gray-500 bg-gray-100  hover:text-black '>
                                {paperSizesSet?.map((sizes: any) => (
                                    <SelectItem
                                        key={sizes.id}
                                        value={sizes}
                                        className="select-item p-regular-14"
                                    >
                                        {sizes.paper}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>}
                </Card>
                <Card className="w-full p-1 py-2 border-gray-500">
                    <div className="flex items-center space-x-2">
                        <Switch id="size" checked={paperQuaEn}
                            onCheckedChange={(value) => setPaperQuaEn(value)} />
                        <Label htmlFor="size">PAPER QUALITY:</Label>
                    </div>
                    {paperQuaEn && <div className="p-2 space-y-2">
                        {paperQua.length > 0 && <div className="flex gap-2 flex-wrap">
                            {paperQua.map(pap => <p key={pap.id} style={{ fontSize: "12px" }} className="p-2 shadow-md bg-gray-200 rounded-full px-3 flex items-center gap-1 text-sm">{pap.quality}
                                <button onClick={() => setPaperQua(pre => ([...pre.filter(pr => pr.id !== pap.id)]))}><IoMdClose /></button>
                            </p>)}
                        </div>}
                        <Select onValueChange={handleChangeQuality}   >
                            <SelectTrigger className="bg-blue-50 text-md h-12 font-semibold p-2  w-full">
                                <SelectValue placeholder="----Select Quality----" />
                            </SelectTrigger>
                            <SelectContent className='border-gray-500 bg-gray-100  hover:text-black '>
                                {paperQualitySet?.map((qual: any) => (
                                    <SelectItem
                                        key={qual.id}
                                        value={qual}
                                        className="select-item p-regular-14"
                                    >
                                        {qual.quality}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>}
                </Card>
                <Card className="w-full p-1 py-2 col-span-2 border-gray-500">
                    <div className="flex items-center space-x-2">
                        <Switch id="size" checked={paperTypeEn}
                            onCheckedChange={(value) => setPaperTypeEn(value)} />
                        <Label htmlFor="size">PAPER CONDITIONS:</Label>
                    </div>
                    {paperTypeEn && <div className="p-2 space-y-2">
                        {paperTypeSet?.length > 0 && <div className="flex gap-2 flex-wrap">
                            {paperType?.map((item:PaperTypeItem) => (
                                <div key={item.id} style={{ marginBottom: '10px', border: '1px solid #ccc', padding: '10px' }}>
                                    <div>
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={item.enabled}
                                                onChange={() => handlePropertyToggle(item.id, 'type')}
                                            />
                                            Type: {item.type}
                                        </label>
                                    </div>

                                    <div>
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={item.contains.some((c: any) => c.enabled)}
                                                onChange={() => handlePropertyToggle(item.id, 'contains')}
                                            />
                                            Contains:
                                            {item.contains.map((c: any) => (
                                                <div key={c.id}>
                                                    <input
                                                        type="checkbox"
                                                        checked={c.enabled}
                                                        onChange={() => handleSubPropertyToggle(item.id, 'contains', c.id)}
                                                    />
                                                    {c.contain}
                                                </div>
                                            ))}
                                        </label>
                                    </div>

                                    <div>
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={item.colors.some((c: any) => c.enabled)}
                                                onChange={() => handlePropertyToggle(item.id, 'colors')}
                                            />
                                            Colors:
                                            {item.colors.map((c: any) => (
                                                <div key={c.id}>
                                                    <input
                                                        type="checkbox"
                                                        checked={c.enabled}
                                                        onChange={() => handleSubPropertyToggle(item.id, 'colors', c.id)}
                                                    />
                                                    {c.color}
                                                </div>
                                            ))}
                                        </label>
                                    </div>
                                </div>
                            ))}
                        </div>}
                    </div>}
                </Card>
            </div>

        </div>
        <div className="flex justify-between gap-4">
            <Button onClick={handleSubmit} className="headermenu">Edit Stationary Fields</Button>
            <Button onClick={() => navigate(-1)}>Cancel</Button>
        </div>

    </>
    )
}


export default EditAttributeOffice