import {
  Form,
  FormControl,
  FormItem,
  FormField,
  FormLabel,
  FormMessage,
} from "../../../ui/form";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "../../../../components/ui/sheet"
import { Input } from "../../../ui/input";
import { useForm } from "react-hook-form";
import { Button } from "../../../ui/button";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { IMGURL } from "../../../../constants/appConfig";
import { ProductComTemplateT, ProductWithImages, TemplateT } from "@/react-app-env";
import { productDefaultValues } from "../../../../constants/index";
import Dropdown from "./Dropdown";
import productFormSchema from "./Validator";
import { useAddNewProductMutation } from "../../../../redux/slice/admin/AProductsApiSlice";
import ProductDescriptionNew from "./ProductDescriptionRQ";
import { Label } from "../../../../components/ui/label";
import { useGetProductTemplateMutation } from "../../../../redux/slice/admin/ATemplateApiSlice";
import { toast } from "react-toastify";
import { MdErrorOutline } from "react-icons/md";


type EventFormProps = {
  userId?: string;
  type: "Create" | "Update";
  product?: ProductComTemplateT;
  eventId?: string;
};

const ProductForm = ({type, product}: EventFormProps) => {
  //const [imageBase64, setImageBase64] = useState<string | undefined>();
//  const [imageBase64Resize, setImageBase64Resize] = useState<string | undefined>();
  const[addEditProduct,{isError,isSuccess,data}]= useAddNewProductMutation()
  const [catId,setCatId]=useState<number|undefined>(type==="Create"?1:product?.categoryId)
  const [openSheetTemplate,setOpenSheetTemplate]=useState<boolean>(false)
  const [selectedTemplate,setSelectedTemplate]=useState<{ sampleImageUrl?: string,id?:number}>({});
  const handleCatId=(id:number)=>{setCatId(id)}
  const navigate=useNavigate()


  
  useEffect(()=>{
    if(isSuccess&& data?.success){
      navigate(`/admin/dashboard/products?catId=${catId}`)
    }else if(isError){
      if(type==="Create"){
        alert("can't add new product")
      }else{
        alert("can't edit/update product")
      }
    }
  },[isError,isSuccess,navigate,type,data,catId])

  const initialValues = product && type === "Update" ? { ...product } : productDefaultValues;


  const form = useForm<z.infer<typeof productFormSchema>>({
    resolver: zodResolver(productFormSchema),
    defaultValues: initialValues
  });

  const {watch}=form;
  const subcategoryId=watch("subcategoryId")
  const categoryId=watch("categoryId")

  async function onSubmit(values: z.infer<typeof productFormSchema>) {
    if(!selectedTemplate?.id && type==="Create"){toast.error("Select the template");return}

     if(type === "Create"){
      try {
      await addEditProduct( {...values,templateId:selectedTemplate.id});
        
      } catch (error) {
        console.log(error);
      }
    }
    if(type === "Update"){
      try {
       await addEditProduct({...values,id:product?.productId,templateId:selectedTemplate.id??product?.templateId})
        
      } catch (error) {
        console.log(error);
      }
    } 

  } 
  
  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-5"
      >
        <div className="flex flex-col gap-5 items-center md:flex-row">
          <FormField
            control={form.control}
            name="productName"
            render={({ field }) => (
              <FormItem className="w-full ">
                <FormLabel>Product Name</FormLabel>
                <FormControl>
                  <Input placeholder="Enter Product Name" className="input-field" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
         
        </div>
        <div className="flex flex-col gap-5 items-center md:flex-row">
          <FormField
            control={form.control}
            name="categoryId"
            render={({ field }) => (
              <FormItem className="w-full ">
                 <FormLabel htmlFor="categoryIdDropdown">Category</FormLabel>
                <FormControl>
                  <Dropdown
                   id="categoryIdDropdown"
                    onChangeHandler={field.onChange}
                    value={field.value.toString()}
                    type="categoryId"
                    onChangeCID={handleCatId}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="subcategoryId"
            render={({ field }) => (
              <FormItem className="w-full ">
                <FormLabel htmlFor="subCategoryIdDropdown">SubCategory</FormLabel>
                <FormControl>
                  <Dropdown
                   id="subCategoryIdDropdown"
                    onChangeHandler={field.onChange}
                    value={field.value.toString()}
                    type="subCategoryId"
                    catId={catId}
                    formType={type==="Update"?"Edit":""}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
           <div className="w-full">
            <Label>Select Template</Label>
            <div className="flex gap-4 items-center">
            <Button type="button" variant={"outline"} onClick={()=>setOpenSheetTemplate(pre=>!pre)}>Select/Change Template</Button>
           {selectedTemplate?.sampleImageUrl?<img className="w-20" src={`${IMGURL}${selectedTemplate?.sampleImageUrl}`} alt={"Template"}/>:
           (product?.sampleImageUrl?<img className="w-24" src={`${IMGURL}${product?.sampleImageUrl}`} alt={"No Template"}/>:<p className="flex"><MdErrorOutline color="red" size={24} className="mx-2"/>No Image</p>)}
            </div>
            {openSheetTemplate&&<GetTemplates setSelectedTemplate={setSelectedTemplate} categoryId={categoryId} subcategoryId={subcategoryId} setOpenSheetTemplate={setOpenSheetTemplate} />}
          </div>
          
        </div>
        <div className="flex flex-col gap-5 items-center md:flex-row">
        <FormField
            control={form.control}
            name="priceINR"
            render={({ field }) => (
              <FormItem className="w-full ">
                <FormLabel>Price in Rupess (INR)</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter priceINR"
                    {...field}
                    className="input-field"
                    min={1}
                    onChange={event => field.onChange(+event.target.value)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex flex-col gap-5 items-center md:flex-row">
          <FormField
            control={form.control}
            name="productDescription"
            render={({ field }) => (
              <FormItem className="w-full ">
                <FormLabel>Description </FormLabel>
                <FormControl>
                  {/* <Textarea
                    placeholder="Product Description"
                    {...field}
                    className="textarea rounded-2xl"
                  /> */}
                   <ProductDescriptionNew onChangeHandler={field.onChange}
                    value={field.value.toString()} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex md:justify-between my-10 items-center md:flex-row flex-col"> 
        <Button
          type="submit"
          size="lg"
          className="button col-span-1 headermenu"
        >
          { `${type} Product`}
        </Button>
        <Button
        onClick={()=>{navigate(-1)}}
          size="lg"
          type="button"
          className="button col-span-1 headermenu"
        >
         cancel
        </Button>
        </div>
      </form>
    </Form>
  );
};

type getTemplatesProps={
  subcategoryId:number,
  categoryId:number,
  setOpenSheetTemplate:React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedTemplate:any
}

export function GetTemplates({subcategoryId,categoryId,setOpenSheetTemplate,setSelectedTemplate}:getTemplatesProps) {
  const [getProductTemplate,{data:templates}]=useGetProductTemplateMutation()
  const [open, setOpen] = useState(true)
    const handleClose = () => {
      setOpen(false)
      setOpenSheetTemplate(false)
    }

  useEffect(()=>{
    if(subcategoryId&&categoryId){
      getProductTemplate({subcategoryId,categoryId})
    }
  },[subcategoryId,categoryId,getProductTemplate])
  
  
  return (
    <Sheet open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }}}>
      <SheetContent className="overflow-y-scroll max-h-screen max-w-[350px] " >
        <SheetHeader>
          <SheetTitle className="">Select Template</SheetTitle>
        </SheetHeader>
        <div className="flex gap-4 my-4">
          {templates?.result?.length>0&&templates?.result?.map((template:TemplateT)=><div onClick={()=>{setSelectedTemplate(template);setOpenSheetTemplate(false)}}  key={template.id} className="rounded-md w-24 shadow-lg border-2">
            <img src={`${IMGURL}${template.sampleImageUrl}`} alt={template.sampleImageUrl} />
          </div>)}
        </div>
    
      </SheetContent>
    </Sheet>
  )
}


export default ProductForm;
