import  { useRef } from 'react'
import { /* Canvas, */ useFrame, useThree } from '@react-three/fiber'
import { useGLTF } from '@react-three/drei'
import * as THREE from 'three'

interface CustomGLTF {
  materials:any,
  nodes: {
    Exterior?: THREE.Mesh,
    Cylinder003_1?: THREE.Mesh,
    Cylinder003?: THREE.Mesh,
    Impression?: THREE.Mesh,
    Interior?: THREE.Mesh,
    // Add other nodes as needed
  }
}

function MugModel(props:any) {
  const group = useRef<THREE.Group>(null); // Add the correct type for the ref
  const { nodes, materials } = useGLTF('/assets/mugs/MugModel.gltf') as CustomGLTF
  const { gl } = useThree()

  /* CANVAS POUR DESSINER TEXTURE*/

  let canvas = Array.from(document.getElementsByTagName('canvas'))[1],
  ctx = canvas.getContext('2d')
 
  let texture: THREE.CanvasTexture;
  if (ctx) {
    texture = new THREE.CanvasTexture(ctx.canvas);
  } else {
    console.error('Failed to get 2D context for canvas');
    texture = new THREE.Texture() as THREE.CanvasTexture;
  }


  texture.flipY = false // Vous pouvez essayer true si nécessaire.
  texture.anisotropy = gl.capabilities.getMaxAnisotropy()
  texture.needsUpdate = true

/*   useFrame((state, delta) => {
    if (props.animation && group.current) {
      group.current.rotation.y += props.animationSpeed * 0.001 // Vitesse de rotation
    }
    texture.needsUpdate = true
  }) */

  useFrame((state, delta) => {
    if (props.animation&& group.current) {
      group.current.rotation.y += props.animationSpeed * 0.001 // Vitesse de rotation
    }
    texture.needsUpdate = true
  })
  return (
    // DESCRIPTION DU MODELE

    <group scale={10} rotation={[0, 1.812, 0]} position={[0, -0.5, 0]} ref={group} {...props} dispose={null}>

      <group rotation={[0, -0.412, 0]}>
      <mesh castShadow receiveShadow geometry={nodes.Exterior?.geometry} material={materials['white-material']} />
    {/*   <mesh castShadow receiveShadow geometry={nodes.Cylinder003_1?.geometry} material={materials['color-material']} /> */}
    <mesh  castShadow receiveShadow geometry={nodes.Cylinder003_1?.geometry}>
      <meshStandardMaterial attach="material" color={props.mugColor} />
    </mesh>
      </group>

      <mesh castShadow receiveShadow geometry={nodes.Exterior?.geometry} material={materials['white-material']} rotation={[0, -0.412, 0]} />

      <mesh
        rotation={[0, -0.412, 0]}
        castShadow
        receiveShadow
        geometry={nodes.Impression?.geometry}
        material={materials['map-material']}
        /* flipY={true} */
      >
        <meshStandardMaterial attach="material" map={texture}>
          <canvasTexture attach="map" image={canvas} />
        </meshStandardMaterial>
      </mesh>
     
{/*       <mesh castShadow receiveShadow geometry={nodes.Interior?.geometry} material={materials['color-material']} rotation={[0, -0.412, 0]} />
 */}    <mesh  castShadow receiveShadow geometry={nodes.Interior?.geometry} rotation={[0, -0.412, 0]}>
      <meshStandardMaterial attach="material"  color={props.mugColor}  />
    </mesh>
  </group>
  )
}

useGLTF.preload('/assets/mugs/MugModel.gltf')


export default MugModel;




// import { useRef } from 'react';
// import { useFrame, useThree } from '@react-three/fiber';
// import { useGLTF } from '@react-three/drei';
// import * as THREE from 'three';

// // Define the type for the nodes object in your GLTF model
// interface CustomGLTF {
//   nodes: {
//     Exterior?: THREE.Mesh,
//     Cylinder003_1?: THREE.Mesh,
//     Impression?: THREE.Mesh,
//     Interior?: THREE.Mesh,
//     // Add other nodes as needed
//   }
// }

// function MugModel(props: any) {
//   const group = useRef<THREE.Group>(null);
//   const { nodes } = useGLTF('/assets/mugs/MugModel.gltf') as CustomGLTF; // Cast to CustomGLTF
//   const { gl } = useThree();
  
//   // Accessing canvas and its 2D context
//   const canvas = document.getElementsByTagName('canvas')[1];
//   const ctx = canvas?.getContext('2d');

//   // Creating texture
//   let texture: THREE.CanvasTexture;
//   if (ctx) {
//     texture = new THREE.CanvasTexture(ctx.canvas);
//   } else {
//     console.error('Failed to get 2D context for canvas');
//     texture = new THREE.Texture() as THREE.CanvasTexture;
//   }

//   // Texture settings
//   texture.flipY = false; // You can try true if necessary
//   texture.anisotropy = gl.capabilities.getMaxAnisotropy();
//   texture.needsUpdate = true;

//   // Animation
//   useFrame((state, delta) => {
//     if (props.animation && group.current) {
//       group.current.rotation.y += props.animationSpeed * 0.001; // Rotation speed
//     }
//     texture.needsUpdate = true; // Update texture
//   });

//   return (
//     <group scale={10} rotation={[0, 1.812, 0]} position={[0, -0.5, 0]} ref={group} {...props} dispose={null}>
//       {/* Exterior */}
//       {nodes.Exterior && nodes.Exterior.geometry && (
//         <mesh castShadow receiveShadow geometry={nodes.Exterior.geometry} material={(nodes.Exterior as THREE.Mesh).material} />
//       )}

//       {/* Cylinder */}
//       {nodes.Cylinder003_1 && nodes.Cylinder003_1.geometry && (
//         <mesh castShadow receiveShadow geometry={nodes.Cylinder003_1.geometry} material={(nodes.Cylinder003_1 as THREE.Mesh).material} />
//       )}

//       {/* Exterior with rotation */}
//       {nodes.Exterior && nodes.Exterior.geometry && (
//         <mesh castShadow receiveShadow geometry={nodes.Exterior.geometry} material={(nodes.Exterior as THREE.Mesh).material} rotation={[0, -0.412, 0]} />
//       )}

//       {/* Impression */}
//       <mesh
//         rotation={[0, -0.412, 0]}
//         castShadow
//         receiveShadow
//         geometry={nodes.Impression?.geometry} // Optional chaining to handle possible null values
//         material={(nodes.Impression as THREE.Mesh).material}
//       >
//         <meshStandardMaterial attach="material" map={texture}>
//           <canvasTexture attach="map" image={canvas} />
//         </meshStandardMaterial>
//       </mesh>
     
//       {/* Interior */}
//       <mesh
//         castShadow
//         receiveShadow
//         geometry={nodes.Interior?.geometry} // Optional chaining to handle possible null values
//         material={(nodes.Interior as THREE.Mesh).material}
//         rotation={[0, -0.412, 0]}
//       />
//     </group>
//   );
// }

// useGLTF.preload('/assets/mugs/MugModel.gltf');

// export default MugModel;

// type MugModelProps = {
//   animation?: boolean;
//   mugColor?: string;
// };

// function MugModel(props:any) {
//   const group = useRef<THREE.Group>(null);
//   const { nodes, materials } = useGLTF('/assets/mugs/scene.gltf') as any;
//  // const canvas = document.createElement('canvas');
//  const { gl } = useThree()
//   let canvas = Array.from(document.getElementsByTagName('canvas'))[1],
//     ctx = canvas.getContext('2d')
   
//     let texture: THREE.CanvasTexture;
//     if (ctx) {
//       texture = new THREE.CanvasTexture(ctx.canvas);
//     } else {
//       console.error('Failed to get 2D context for canvas');
//       texture = new THREE.Texture() as THREE.CanvasTexture;
//     }
  
  
//     texture.flipY = false // Vous pouvez essayer true si nécessaire.
//     texture.anisotropy = gl.capabilities.getMaxAnisotropy()
//     texture.needsUpdate = true
  
//   /*   useFrame((state, delta) => {
//       if (props.animation && group.current) {
//         group.current.rotation.y += props.animationSpeed * 0.001 // Vitesse de rotation
//       }
//       texture.needsUpdate = true
//     }) */
  
//     useFrame((state, delta) => {
//       if (props.animation&& group.current) {
//         group.current.rotation.y += props.animationSpeed * 0.001 // Vitesse de rotation
//       }
//       texture.needsUpdate = true
//     })


//   return (
//     <group
//       scale={10}
//       rotation={[0, 1.812, 0]}
//       position={[-.8, -0.5, 0]}
//       dispose={null}
//     >
//       <group rotation={[0, -0.412, 0]}>
//         <mesh
//           castShadow
//           receiveShadow
//           geometry={nodes.Object_2.geometry}
//           material={materials['Material.001']}
//         />
//         <mesh castShadow receiveShadow geometry={nodes.Object_2.geometry}>
//           <meshStandardMaterial attach="material" color={props.mugColor} />
//         </mesh>
//       </group>
//     </group>
  
//   );
// }

// export default MugModel;
