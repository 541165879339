import { useLocation, Navigate, Outlet } from "react-router-dom"



const RequireAuth = () => {
    const location = useLocation()
    const currentUser=JSON.parse(localStorage.getItem("pfuser")!);
    const content = (
     currentUser?.token ? <Outlet />: <Navigate to="/signin" state={{ from: location }} replace />
    )
   
    return content
}
export default RequireAuth

export const RequireAuthAdmin = () => {
    const location = useLocation()
    const currentUser=JSON.parse(localStorage.getItem("pfuser")!);

    const content = (
        ( currentUser?.token && currentUser.userRoleId===1 ) ? <Outlet />: <Navigate to="/signin" state={{ from: location }} replace />
    )
   
    return content
}
 