//import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../apiSlice";

/* 
const productsAdapter = createEntityAdapter({})

const initialState = productsAdapter.getInitialState() */

export const productsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getRecentProducts: builder.query({
            query: () =>({url:"/common/getRecentProducts",}),
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                  return [
                    { type: 'Product', id: 'LIST' },
                    ...result.ids.map((id:any) => ({ type: 'Product', id })),
                  ];
                } else return [{ type: 'Product', id: 'LIST' }];
              },
        }),
        getAllProducts: builder.mutation({
            query: initialProductDataT => ({
                url: '/common/getAllProducts',
                method: 'POST',
                body: {
                    ...initialProductDataT,
                }
            }),
            invalidatesTags: [
                { type: 'Product', id: "LIST" }
            ]
        }),
        getProductsByDesign: builder.mutation({
            query: initialProductDataT => ({
                url: '/common/getProductsByDesign',
                method: 'POST',
                body: {
                    ...initialProductDataT,
                }
            }),
            invalidatesTags: [
                { type: 'Product', id: "LIST" }
            ]
        }),
        getProductsByPrice: builder.mutation({
            query: initialProductDataT => ({
                url: '/common/getProductsByPrice',
                method: 'POST',
                body: {
                    ...initialProductDataT,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Product', id: arg.id }
            ]
        }),
         getProductById: builder.query({
            query: (id) =>({url:`/common/getProduct/${id}`}),
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                  return [
                    { type: 'Product', id: 'LIST' },
                    ...result.ids.map((id:any) => ({ type: 'Product', id })),
                  ];
                } else return [{ type: 'Product', id: 'LIST' }];
              },
        }),
      /*   deleteUser: builder.mutation({
            query: ({ id }) => ({
                url: `/users`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.id }
            ]
        }), */
    }),
})

export const {
    useGetRecentProductsQuery,
    useGetAllProductsMutation,
    useGetProductsByPriceMutation,
    useGetProductByIdQuery,useGetProductsByDesignMutation,
} = productsApiSlice

