import { Card } from "../../../components/ui/card"
import { Input } from "../../../components/ui/input"
import { Label } from "../../../components/ui/label"
import { Sheet, SheetContent } from "../../../components/ui/sheet"
import { IMGURL } from "../../../constants/appConfig"
import { ChangeEvent, useEffect, useState } from "react"
import { fabric } from 'fabric';
import { useGetLogodesignsMutation } from "../../../redux/slice/admin/ALogoApiSlice"
export const SelectImage = ({ editor,setModalOpen,groupSelect,fixedTopLayerImg }: any) => {

    const [getLogoDesigns, {data: logos} ] = useGetLogodesignsMutation()
    const [open, setOpen] = useState(true)
    const handleClose = () => {
      setOpen(false)
      setModalOpen(false)
    }

    const handleImageUpload = (url: string) => {
      setModalOpen(false);
      if (!editor || !editor.canvas || !groupSelect) return;
    
      // Check if url is a valid URL by checking if it starts with 'http' or 'https'
      if (url.startsWith('http://') || url.startsWith('https://')) {
        fetch(url, { mode: 'cors' })
          .then(response => response.blob())
          .then(blob => {
            const reader = new FileReader();
            reader.onload = () => {
              fabric.Image.fromURL(reader.result as string, (img: fabric.Image) => {
                img.set({
                  crossOrigin: 'anonymous',
                  left: groupSelect.left,
                  top: groupSelect.top,
                  scaleX: (groupSelect.width! * (groupSelect.scaleX || 1)) / img.width!,
                  scaleY: (groupSelect.height! * (groupSelect.scaleY || 1)) / img.height!,
                  selectable: true,
                  hasControls: true,
                  hasBorders: true,
                });
                (img as any).id = 'groupSelectImage';
                editor.canvas.remove(groupSelect);
                editor.canvas.add(img);
                if(fixedTopLayerImg)editor.canvas.sendToBack(img);
                editor.canvas.renderAll();
              }, { crossOrigin: 'anonymous' });
            };
            reader.readAsDataURL(blob);
          })
          .catch(error => {
            console.error('Error fetching image:', error);
          });
      }
    };
    /* const handleImageUpload = (url: string) => {
      
      setModalOpen(false);
      if (!editor || !editor.canvas || !groupSelect) return;
    
      const imgObj = new Image();
      imgObj.src = url;
    
      imgObj.onload = () => {
        if (imgObj.width && imgObj.height) {
          fabric.Image.fromURL(url, (img) => {
            const widthFix = (groupSelect.scaleX && groupSelect.width) ? groupSelect.width * groupSelect.scaleX : groupSelect.width!;
            const heightFix = (groupSelect.scaleY && groupSelect.height) ? groupSelect.height * groupSelect.scaleY : groupSelect.height!;
    
            img.set({
              left: groupSelect.left,
              top: groupSelect.top,
              scaleX: widthFix / imgObj.width,
              scaleY: heightFix / imgObj.height,
              selectable: true,
              hasControls: true,
              hasBorders: true,
            });
    
            editor.canvas.remove(groupSelect);
            editor.canvas.add(img);
            editor.canvas.renderAll();
          });
        } else {
          console.error("Image dimensions are not defined.");
        }
      };
    
      imgObj.onerror = () => {
        console.error("Failed to load image.");
      };
    };
     */
    
    const handleLocalImageChange = (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      setModalOpen(false);
      if (files && editor && editor.canvas && groupSelect) {
        const reader = new FileReader();
        reader.onload = () => {
          fabric.Image.fromURL(reader.result as string, (img) => {
            if (img.width && img.height) {
              const widthFix = (groupSelect.scaleX && groupSelect.width) ? groupSelect.width * groupSelect.scaleX : groupSelect.width!;
              const heightFix = (groupSelect.scaleY && groupSelect.height) ? groupSelect.height * groupSelect.scaleY : groupSelect.height!;
 
              img.set({
                left: groupSelect.left,
                top: groupSelect.top,
                scaleX: widthFix / img.width,
                scaleY: heightFix / img.height,
                selectable: true,
                hasControls: true,
                hasBorders: true,
              });
              (img as any).id = 'groupSelectImage';
              editor.canvas.remove(groupSelect);
              editor.canvas.add(img);
              if(fixedTopLayerImg)editor.canvas.sendToBack(img);
              editor.canvas.renderAll();
            } else {
              console.error("Image dimensions are not defined.");
            }
          });
        };
        reader.readAsDataURL(files[0]);
      }
    };
    
  
    useEffect(()=>{getLogoDesigns({})},[])
  
    return <Sheet open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      {/* <SheetTrigger asChild >
        <Button className='headermenu w-[150px]' size="sm">Select From Gallery</Button>
      </SheetTrigger> */}
      <SheetContent side="left" className="overflow-y-scroll max-h-screen w-[350px] " >
      <div>
              <Label htmlFor="local-image" className='headermenu p-2 rounded-lg text-white'>Upload Your Image</Label>
              <Input id="local-image" type="file" onChange={handleLocalImageChange} className='hidden' />
            </div>
            {logos?.result?.length > 0 &&<p className="mb-3 mt-4 font-semibold text-sm">Select image from Down:</p>}
        <div className='flex gap-3 flex-wrap my-3'>
          {logos?.result?.length > 0 && logos?.result?.map((logo: any) => <Card key={logo.id} className="w-[80px]" onClick={() => { setOpen(false); handleImageUpload(`${IMGURL}${logo.designUrl}`) }}>
            <img src={`${IMGURL}${logo.designUrl}`} alt="template" className="w-full h-[80px]" />
          </Card>)}
        </div>
      </SheetContent>
    </Sheet>
  }
export const SelectImagePrintable = ({ editor,setSheetOpen,printableAreas }: any) => {

    const [getLogoDesigns, {data: logos} ] = useGetLogodesignsMutation()
    const [open, setOpen] = useState(true)
    const handleClose = () => {
      setOpen(false)
      setSheetOpen(false)
    }

    const handleImageUpload = (url: string) => {
      
      setSheetOpen(false);
      if (!editor || !editor.canvas ) return;

      // Check if url is a valid URL by checking if it starts with 'http' or 'https'
      if (url.startsWith('http://') || url.startsWith('https://')) {
        fetch(url, { mode: 'cors' })
          .then(response => response.blob())
          .then(blob => {
            const reader = new FileReader();
            reader.onload = () => {
              fabric.Image.fromURL(reader.result as string, (img: fabric.Image) => {
                const area = printableAreas.length>0 && printableAreas[0].getBoundingRect();
                img.scaleToWidth(100);
                img.scaleToHeight(100);
                img.set({
                  left: area?.left+5 || 0,
                  top: area?.top+5 || 0,
                });
                editor.canvas.add(img);
               // editor.canvas.sendToBack(img);
                editor.canvas.renderAll();
              });
            };
            reader.readAsDataURL(blob);
          })
          .catch(error => {
            console.error('Error fetching image:', error);
          });
      }
    };
   /*  const handleImageUpload = (url: string) => {      
      setSheetOpen(false);
      if (!editor || !editor.canvas ) return;
     // if (!editor || !editor.canvas || !printableArea) return;
    
      const imgObj = new Image();
      imgObj.src = url;
    
      imgObj.onload = () => {
      fabric.Image.fromURL(url, (img) => {
            const area =printableAreas&& printableAreas[0]?.getBoundingRect();
            img.scaleToWidth(100);
            img.scaleToHeight(100);
            img.set({
              left: area?.left+5 || 0,
                top: area?.top+5 || 0,
            });
            
            editor.canvas.add(img);
            editor.canvas.renderAll();
          });
      };
    
      imgObj.onerror = () => {
        console.error("Failed to load image.");
      };
    }; */
    
    const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      setSheetOpen(false);
      if (files && files.length > 0 /* && printableArea */ && editor) {
        Array.from(files).forEach(file => {
          const reader = new FileReader();
          reader.onload = (event) => {
            fabric.Image.fromURL(event.target?.result as string, (oImg) => {
              const area =printableAreas.length>0&& printableAreas[0]?.getBoundingRect();
              
              oImg.scaleToWidth(100);
              oImg.scaleToHeight(100);
              oImg.set({
                left: area?.left+5 || 0,
                top: area?.top+5 || 0,
              });
              editor.canvas.add(oImg);
              //editor.canvas.sendToBack(oImg);
              editor.canvas.renderAll()
            });
          };
          reader.readAsDataURL(file);
        });
      }
    };
    
    useEffect(()=>{getLogoDesigns({})},[])
  
    return <Sheet open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      {/* <SheetTrigger asChild >
        <Button className='headermenu w-[150px]' size="sm">Select From Gallery</Button>
      </SheetTrigger> */}
      <SheetContent side="left" className="overflow-y-scroll max-h-screen w-[350px] " >
      <div>
              <Label htmlFor="local-image" className='headermenu p-2 rounded-lg text-white'>Upload Your Image</Label>
              <Input id="local-image" type="file" onChange={handleImageChange} className='hidden' />
            </div>
            {logos?.result?.length > 0 &&<p className="mb-3 mt-4 font-semibold text-sm">Select image from Down:</p>}
        <div className='flex gap-3 flex-wrap my-3'>
          {logos?.result?.length > 0 && logos?.result?.map((logo: any) => <Card key={logo.id} className="w-[80px]" onClick={() => { setOpen(false); handleImageUpload(`${IMGURL}${logo.designUrl}`) }}>
            <img src={`${IMGURL}${logo.designUrl}`} alt="template" className="w-full h-[80px]" />
          </Card>)}
        </div>
      </SheetContent>
    </Sheet>
  }

  export const SelectImageMug = ({ editor,setModalOpen,groupSelect,fixedTopLayerImg }: any) => {

    const [getLogoDesigns, {data: logos} ] = useGetLogodesignsMutation()
    const [open, setOpen] = useState(true)
    const handleClose = () => {
      setOpen(false)
      setModalOpen(false)
    }
     const handleImageUpload = (url: string) => {
      setModalOpen(false);
      if (!editor || !editor.canvas || !groupSelect) return;
    
      // Check if url is a valid URL by checking if it starts with 'http' or 'https'
      if (url.startsWith('http://') || url.startsWith('https://')) {
        fetch(url, { mode: 'cors' })
          .then(response => response.blob())
          .then(blob => {
            const reader = new FileReader();
            reader.onload = () => {
              fabric.Image.fromURL(reader.result as string, (img: fabric.Image) => {
                img.set({
                  crossOrigin: 'anonymous',
                  left: groupSelect.left,
                  top: groupSelect.top,
                  scaleX: (groupSelect.width! * (groupSelect.scaleX || 1)) / img.width!,
                  scaleY: (groupSelect.height! * (groupSelect.scaleY || 1)) / img.height!,
                  selectable: true,
                  hasControls: true,
                  hasBorders: true,
                });
                (img as any).id = 'groupSelectImage';
                editor.canvas.remove(groupSelect);
                editor.canvas.add(img);
                if(fixedTopLayerImg)editor.canvas.sendToBack(img);
                editor.canvas.renderAll();
              }, { crossOrigin: 'anonymous' });
            };
            reader.readAsDataURL(blob);
          })
          .catch(error => {
            console.error('Error fetching image:', error);
          });
      }
    };
    
    const handleLocalImageChange = (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      setModalOpen(false);
      if (files && editor && editor.canvas && groupSelect) {
        const reader = new FileReader();
        reader.onload = () => {
          fabric.Image.fromURL(reader.result as string, (img) => {
            if (img.width && img.height) {
              const widthFix = (groupSelect.scaleX && groupSelect.width) ? groupSelect.width * groupSelect.scaleX : groupSelect.width!;
              const heightFix = (groupSelect.scaleY && groupSelect.height) ? groupSelect.height * groupSelect.scaleY : groupSelect.height!;
    
              img.set({
                left: groupSelect.left,
                top: groupSelect.top,
                scaleX: widthFix / img.width,
                scaleY: heightFix / img.height,
                selectable: true,
                hasControls: true,
                hasBorders: true,
              });
              (img as any).id = 'groupSelectImage';
              editor.canvas.remove(groupSelect);
              editor.canvas.add(img);
              if(fixedTopLayerImg)editor.canvas.sendToBack(img);
              editor.canvas.renderAll();
            } else {
              console.error("Image dimensions are not defined.");
            }
          });
        };
        reader.readAsDataURL(files[0]);
      }
    };

    useEffect(()=>{getLogoDesigns({})},[])
  
    return <Sheet open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      {/* <SheetTrigger asChild >
        <Button className='headermenu w-[150px]' size="sm">Select From Gallery</Button>
      </SheetTrigger> */}
      <SheetContent side="left" className="overflow-y-scroll max-h-screen w-[350px] " >
      <div>
              <Label htmlFor="local-image" className='headermenu p-2 rounded-lg text-white'>Upload Your Image</Label>
              <Input id="local-image" type="file" onChange={handleLocalImageChange} className='hidden' />
            </div>
            {logos?.result?.length > 0 &&<p className="mb-3 mt-4 font-semibold text-sm">Select image from Down:</p>}
        <div className='flex gap-3 flex-wrap my-3'>
          {logos?.result?.length > 0 && logos?.result?.map((logo: any) => <Card key={logo.id} className="w-[80px]" onClick={() => { setOpen(false); handleImageUpload(`${IMGURL}${logo.designUrl}`) }}>
            <img src={`${IMGURL}${logo.designUrl}`} alt="template" className="w-full h-[80px]" />
          </Card>)}
        </div>
      </SheetContent>
    </Sheet>
  }
export const SelectImagePrintableMug = ({ editor,setSheetOpen,printableAreas }: any) => {

    const [getLogoDesigns, {data: logos} ] = useGetLogodesignsMutation()
    const [open, setOpen] = useState(true)
    const handleClose = () => {
      setOpen(false)
      setSheetOpen(false)
    }

    const handleImageUpload = (url: string) => {
      setSheetOpen(false);
      if (!editor || !editor.canvas ) return;

      // Check if url is a valid URL by checking if it starts with 'http' or 'https'
      if (url.startsWith('http://') || url.startsWith('https://')) {
        fetch(url, { mode: 'cors' })
          .then(response => response.blob())
          .then(blob => {
            const reader = new FileReader();
            reader.onload = () => {
              fabric.Image.fromURL(reader.result as string, (img: fabric.Image) => {
                const area =printableAreas.length>0&& printableAreas[0].getBoundingRect();
                img.scaleToWidth(100);
                img.scaleToHeight(100);
                img.set({
                  left: area?.left+5 || 0,
                  top: area?.top+5 || 0,
                });
                editor.canvas.add(img);
               // editor.canvas.sendToBack(img);
                editor.canvas.renderAll();
              });
            };
            reader.readAsDataURL(blob);
          })
          .catch(error => {
            console.error('Error fetching image:', error);
          });
      }
    };
    
    const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      setSheetOpen(false);
      if (files && files.length > 0 /* && printableArea  */&& editor) {
        Array.from(files).forEach(file => {
          const reader = new FileReader();
          reader.onload = (event) => {
            fabric.Image.fromURL(event.target?.result as string, (oImg) => {
              const area =printableAreas.length>0&& printableAreas[0]?.getBoundingRect();
              oImg.scaleToWidth(100);
              oImg.scaleToHeight(100);
              oImg.set({
                left: area?.left+5 || 0,
                top: area?.top+5 || 0,
              });
              editor.canvas.add(oImg);
             // editor.canvas.sendToBack(oImg);
             editor.canvas.renderAll();
            });
          };
          reader.readAsDataURL(file);
        });
      }
    };
    
    useEffect(()=>{getLogoDesigns({})},[])
  
    return <Sheet open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      {/* <SheetTrigger asChild >
        <Button className='headermenu w-[150px]' size="sm">Select From Gallery</Button>
      </SheetTrigger> */}
      <SheetContent side="left" className="overflow-y-scroll max-h-screen w-[350px] " >
      <div>
              <Label htmlFor="local-image" className='headermenu p-2 rounded-lg text-white'>Upload Your Image</Label>
              <Input id="local-image" type="file" onChange={handleImageChange} className='hidden' />
            </div>
            {logos?.result?.length > 0 &&<p className="mb-3 mt-4 font-semibold text-sm">Select image from Down:</p>}
        <div className='flex gap-3 flex-wrap my-3'>
          {logos?.result?.length > 0 && logos?.result?.map((logo: any) => <Card key={logo.id} className="w-[80px]" onClick={() => { setOpen(false); handleImageUpload(`${IMGURL}${logo.designUrl}`) }}>
            <img src={`${IMGURL}${logo.designUrl}`} alt="template" className="w-full h-[80px]" />
          </Card>)}
        </div>
      </SheetContent>
    </Sheet>
  }