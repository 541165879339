import TemplateForm from "../../components/shared/admin/components/canvas/TemplateForm"

const AddTemplate = () => {
    return (
        <div className="max-w-full md:mx-20 md:my-10 border-2 border-gray-300 md:p-4">
          <h5 className="h5-bold mb-4">Add New Template</h5>
       <TemplateForm type="Create" />
    </div>
    )
}

export default AddTemplate