import { Label } from "../../components/ui/label";
import { Separator } from "../../components/ui/separator"
import { Input } from "../../components/ui/input";
import { Textarea } from "../../components/ui/textarea";
import { Button } from "../../components/ui/button";

const ProductDataT=[
    {id:1,name:"Products:",data:[{qst:"Where to buy sarees:",ans:"Visit the chennapatnam website and filter the prodducts depending on category/price range, add to cart and proceed to buy"}]},
    {id:1,name:"Order:",data:[{qst:"Order Status:",ans:"Once the order placed it takes 7-8 working days to deliver the order"}]},
    {id:1,name:"Payment:",data:[{qst:"How to make a payment:",ans:"The payment can be done through the razorpay"},{qst:"Payment Method:",ans:"It accepts the all online payment methods"}]},
]

const FAQ = () => {
  return (
    <div className="bgcolorgold">
        <div className="wrapper">
            <div className="wrapper-weaves">
                <h5 className="h5-bold">FREQUENTLY ASKED QUESTIONS</h5>
            </div>
            <Separator />
            <div className="flex flex-col gap-2">
               {ProductDataT.map(question=>(
                <div className="flex flex-col gap-2">
                    <h6 className="text-lg font-semibold">{question.name}</h6>
                    {question.data.map(dat=>(
                        <div className="wrapper-weaves">
                        <p className="text-md font-medium">{dat.qst}</p>
                        <p>{dat.ans}</p>
                        </div>
                    ))}
                </div>
               ))}
            </div>
        </div>
    </div>
  )
}

export const ContactUs = () => {
    return (
      <div className="bg-white">
        <div className="wrapper flex flex-col gap-6 p-8">
          <h2 className="text-xl font-bold text-primary">
            Contact Us
          </h2>
          <div className="flex flex-col gap-6 text-lg text-gray-800">
            <p className="text-center">
              We’re here to help! Whether you have a question about our services, need assistance with an order feel free to get in touch with us.
            </p>
            <div className="flex flex-col md:flex-row justify-between gap-10">
              {/* Contact Information */}
              <div className="flex flex-col gap-4 text-sm">
                <h3 className="text-xl font-semibold text-primary">Our Contact Information</h3>
                <p>
                  <strong>Email:</strong> support@printferry.com
                </p>
                <p>
                  <strong>Phone:</strong> +91 (123) 456-7890
                </p>
                <p>
                  <strong>Address:</strong> 123 PrintFerry Lane, PrintCity, PC 45678
                </p>
                <p>
                  <strong>Business Hours:</strong> Monday - Friday, 9:00 AM - 6:00 PM
                </p>
              </div>
              {/* Contact Form */}
              <div className="flex flex-col gap-4 w-full shadow-lg bg-gray-100 p-3 rounded-lg ">
                <h3 className="text-xl font-semibold text-primary">Send Us a Message</h3>
                <form className="flex flex-col gap-4">
                  <div className="flex flex-col gap-2">
                    <Label className="block text-md font-medium text-gray-700">
                      Name*
                    </Label>
                    <Input
                      type="text"
                      required
                      placeholder="Your Name"
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <Label className="block text-md font-medium text-gray-700">
                      Email*
                    </Label>
                    <Input
                      type="email"
                      required
                      placeholder="Your Email"
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <Label className="block text-md font-medium text-gray-700">
                      Message*
                    </Label>
                    <Textarea
                    required
                      placeholder="Your Message"
                    ></Textarea>
                  </div>
                  <Button
                    type="submit"
                    className="headermenu hover:bg-blue-600 max-w-[200px]"
                  >
                    Send Message
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  
export default FAQ
