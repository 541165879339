import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { Button } from "../../components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog"
import { Label } from "../../components/ui/label"
import { useDeleteProductAttributeMutation, useGetProductAttributesByAttNameMutation, useGetProductAttributesQuery, useSaveProductAttributeSidesMutation, useUpdateProductAttributeMutation } from "../../redux/slice/admin/AAttributeApiSlice"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select"
import { useGetAllCategoryQuery, useGetSubcategoryQuery } from "../../redux/slice/CategoryApiSlice"
import { Checkbox } from "../../components/ui/checkbox"
import { toast } from "react-toastify"
import { Input } from "../../components/ui/input"
import { MdDeleteOutline } from "react-icons/md"
import { IoMdClose } from "react-icons/io"
import { useNavigate, useSearchParams } from "react-router-dom"
import { FilterByCategoryTemplate, FilterBySubCategoryTemplate } from "./Templates"
import { ProductAttribute } from "@/react-app-env"

const PrintSides = () => {
  const [productAttByAttName, { data: productAttributes }] = useGetProductAttributesByAttNameMutation()
  let attributeName = "Sides"
  const [searchParams] = useSearchParams()
  const catId = searchParams.get("catId") || 1;
  const subcatId = searchParams.get("subCatId") || "All";
  const [condEdDe, setCondEdDe] = useState<boolean>(false)
  const [editSides, setEditSides] = useState<boolean>(false)
  const [deleteSides, setDeleteSides] = useState<boolean>(false)

  useEffect(() => {
    if (subcatId === "All") {
      productAttByAttName({ catId, attributeName, subcatId: 0 })
    } else {
      productAttByAttName({ catId, subcatId, attributeName })
    }
  }, [productAttByAttName, attributeName, catId, subcatId, condEdDe])

  const sidesFixed = productAttributes?.result[0];
  const attributeValueSides = sidesFixed && JSON.parse(sidesFixed?.attributeValue)

  return (
    <div className="wrapper-dashboard">
      <div className="flex justify-between gap-4 flex-wrap">
        <h5 className="text-xl font-bold">Print Sides Category</h5>
        <div className="flex gap-6 flex-wrap">
          <FilterByCategoryTemplate catId={catId.toString()} />
          <FilterBySubCategoryTemplate catId={catId.toString()} subCatId={subcatId} />
        </div>
        <AddSidesToCategory  setCondEdDe={setCondEdDe}/>
      </div>
      <div className="my-4 p-4shadow-md">
        <p className="font-semibold text-lg text-gray-700">Attribute Name: {attributeName}</p>
        <div className="flex gap-4 items-center flex-wrap my-4">
          {sidesFixed ? (
            <div className="flex gap-6 flex-wrap bg-white p-4 rounded-md shadow-inner">
              {attributeValueSides.front ? (
                <p className="text-green-700 font-semibold">
                  <span className="mx-1 font-bold">Front:</span> TRUE
                </p>
              ) : (
                <p className="text-red-600 font-semibold">
                  <span className="mx-1 font-bold">Front:</span> FALSE
                </p>
              )}
              {attributeValueSides.back ? (
                <p className="text-green-700 font-semibold">
                  <span className="mx-1 font-bold">Back:</span> TRUE
                </p>
              ) : (
                <p className="text-red-600 font-semibold">
                  <span className="mx-1 font-bold">Back:</span> FALSE
                </p>
              )}
              {attributeValueSides.back && (
                <p className="font-semibold">
                  <span className="mx-1 font-bold">Extra Price Back:</span>{attributeValueSides.ePriceBack }
                </p>
              )}
              {attributeValueSides.sides ? (
                <p className="text-green-700 font-semibold">
                  <span className="mx-1 font-bold">Sides (Left, Right):</span> TRUE
                </p>
              ) : (
                <p className="text-red-600 font-semibold">
                  <span className="mx-1 font-bold">Sides (Left, Right):</span> FALSE
                </p>
              )}
              {attributeValueSides.sides && (
                <p className=" font-semibold">
                  <span className="mx-1 font-bold">Extra Price Sides:</span>{attributeValueSides.ePriceSides }
                </p>
              )}
              <div className="flex gap-4">
              <Button onClick={()=>setEditSides(true)} variant="default" className="headermenu">Edit Sides</Button>
              <Button onClick={()=>setDeleteSides(true)}   className="bg-red-800" >Delete Sides</Button>
              </div>
             {editSides&& <EditSides side={sidesFixed} setCondEdDe={setCondEdDe} setEditSides={setEditSides} />}
             {deleteSides&& <DeleteSides id={sidesFixed.id} setCondEdDe={setCondEdDe} setDeleteSides={setDeleteSides}/>}
            </div>
          ) : (
            <p className="text-lg font-semibold text-gray-800 p-3 rounded-md shadow-sm">
              Selected Filters: There Is No Sides (By Default Only Front Side)
            </p>
          )}
        </div>
      </div>

    </div>
  )
}


const AddSidesToCategory = ({setCondEdDe}:any) => {
  const [categoryId, setCategoryId] = useState<number>(1)
  const [subcategoryId, setSubcategoryId] = useState<number>(0)
  const [sides, setSides] = useState({ front: true, back: false, sides: false, ePriceBack: 0, ePriceSides: 0 })
  const [saveProductAttribute] = useSaveProductAttributeSidesMutation()
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const handleSubmit = async () => {
    if (!sides.back && !sides.sides) {
      toast.error("Add This Property only when other then the front side")
      return;
    }
    await saveProductAttribute({ categoryId, subcategoryId, attributeName: "Sides", attributeValue: JSON.stringify(sides) })
    setOpen(false)
    setCondEdDe((p:any)=>!p)
    setSides({ front: true, back: false, sides: false, ePriceBack: 0, ePriceSides: 0 })
    navigate(`/admin/dashboard/attributes/sides?catId=${categoryId}&subCatId=${subcategoryId}`)
  }

  const handleClose = () => {
    setOpen(false)
    setSides({ front: true, back: false, sides: false, ePriceBack: 0, ePriceSides: 0 })
  }

  return (
    <Dialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      <DialogTrigger asChild>
        <Button variant="default" className="headermenu">Add Sides</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Add Sides To SubCategory</DialogTitle>
          <p className="text-sm my-1 text-red-500"></p>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="flex items-center gap-4">
            <Label htmlFor="Category" className="text-right">
              Category:
            </Label>
            <FilterByCategory onChangeHandler={setCategoryId} categoryId={categoryId} />
          </div>
          <div className="flex items-center gap-4">
            <Label htmlFor="subCategory" className="text-right">
              SubCategory:
            </Label>
            <FilterBySubCategory onChangeHandler={setSubcategoryId} subcategoryId={subcategoryId} categoryId={categoryId} />
          </div>
          <div className="flex items-center gap-4">
            <Label htmlFor="color" className="text-right">
              Selected Sides:
            </Label>
            <div className="flex items-center space-x-2">
              <Checkbox id="front" disabled checked={sides.front} onCheckedChange={(value) => setSides(pre => ({ ...pre, front: Boolean(value) }))} />
              <label
                htmlFor="front"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Front
              </label>
            </div>
            <div className="flex items-center space-x-2">
              <Checkbox id="back" checked={sides.back} onCheckedChange={(value) => setSides(pre => ({ ...pre, back: Boolean(value) }))} />
              <label
                htmlFor="back"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Back
              </label>
            </div>
            <div className="flex items-center space-x-2">
              <Checkbox id="terms" checked={sides.sides} onCheckedChange={(value) => setSides(pre => ({ ...pre, sides: Boolean(value) }))} />
              <label
                htmlFor="terms"
                className="text-sm flex items-center font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Sides <span className="mx-1 text-gray-500">(Left,Right)</span>
              </label>
            </div>
          </div>

          {sides.back && <div className="flex items-center space-x-2">
            <Label htmlFor="ePriceBack" className="text-right">
              ExtraPrice Back:
            </Label>
            <Input id="ePriceBack" className="input-field" value={sides.ePriceBack} onChange={(e) => setSides(pre => ({ ...pre, ePriceBack: +e.target.value }))} />
          </div>}
          {sides.sides && <div className="flex items-center space-x-2">
            <Label htmlFor="ePriceSides" className="text-right">
              ExtraPrice Sides:
            </Label>
            <Input id="ePriceSides" className="input-field" value={sides.ePriceSides} onChange={(e) => setSides(pre => ({ ...pre, ePriceSides: +e.target.value }))} />

          </div>}


        </div>
        <DialogFooter>
          <Button onClick={handleSubmit}>Add Sides</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>)
}

export function DeleteSides({ id, setCondEdDe ,setDeleteSides}: { id: number, setCondEdDe: Dispatch<SetStateAction<boolean>>,setDeleteSides:Dispatch<SetStateAction<boolean>> }) {
  const [open, setOpen] = useState(true)
  const [deleteProductAttribute] = useDeleteProductAttributeMutation()
  const handleClose = () => {
    setOpen(false)
    setDeleteSides(false)
  }
  return (
    <AlertDialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      {/* <AlertDialogTrigger asChild>
        <Button className="bg-red-800" >Delete Sides</Button>
      </AlertDialogTrigger> */}
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete Sides related to Category/SubCategory.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={() => { deleteProductAttribute(id); setCondEdDe(p => !p);setDeleteSides(false) }} className="bg-red-500" >Delete</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

const EditSides = ({ side, setCondEdDe,setEditSides }: { side: ProductAttribute, setCondEdDe: Dispatch<SetStateAction<boolean>>,setEditSides:Dispatch<SetStateAction<boolean>> }) => {
  const [categoryId, setCategoryId] = useState<number>(1)
  const [subcategoryId, setSubcategoryId] = useState<number>(0)
  const [sides, setSides] = useState({ front: true, back: false, sides: false, ePriceBack: 0, ePriceSides: 0 })
  const [updateProductAttribute] = useUpdateProductAttributeMutation()
  const [open, setOpen] = useState(true)

  useEffect(() => {
    setCategoryId(side.categoryId)
    setSubcategoryId(side.subcategoryId)
    setSides(JSON.parse(side.attributeValue))
  }, [side])

  const handleSubmit = async () => {
    await updateProductAttribute({ id: side?.id, categoryId, subcategoryId, attributeName: "Sides", attributeValue: JSON.stringify(sides) })
    setOpen(false)
    setEditSides(false)
    setCondEdDe(p => !p)
  }


  const handleClose = () => {
    setOpen(false)
    setEditSides(false)
  }


  return (
    <Dialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      {/* <DialogTrigger asChild>
        <Button variant="default" className="headermenu">Edit Sides</Button>
      </DialogTrigger> */}
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Edit Sides To SubCategory</DialogTitle>
          <p className="text-sm my-1 text-red-500"></p>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="flex items-center gap-4">
            <Label htmlFor="Category" className="text-right">
              Category:
            </Label>
            <FilterByCategory onChangeHandler={setCategoryId} categoryId={categoryId} />
          </div>
          <div className="flex items-center gap-4">
            <Label htmlFor="subCategory" className="text-right">
              SubCategory:
            </Label>
            <FilterBySubCategory onChangeHandler={setSubcategoryId} subcategoryId={subcategoryId} categoryId={categoryId} />
          </div>
          <div className="flex items-center gap-4">
            <Label htmlFor="color" className="text-right">
              Selected Sides:
            </Label>
            <div className="flex items-center space-x-2">
              <Checkbox id="front" disabled checked={sides.front} onCheckedChange={(value) => setSides(pre => ({ ...pre, front: Boolean(value) }))} />
              <label
                htmlFor="front"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Front
              </label>
            </div>
            <div className="flex items-center space-x-2">
              <Checkbox id="back" checked={sides.back} onCheckedChange={(value) => setSides(pre => ({ ...pre, back: Boolean(value) }))} />
              <label
                htmlFor="back"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Back
              </label>
            </div>
            <div className="flex items-center space-x-2">
              <Checkbox id="terms" checked={sides.sides} onCheckedChange={(value) => setSides(pre => ({ ...pre, sides: Boolean(value) }))} />
              <label
                htmlFor="terms"
                className="text-sm flex items-center font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Sides <span className="mx-1 text-gray-500">(Left,Right)</span>
              </label>
            </div>
          </div>
          {sides.back && <div className="flex items-center space-x-2">
            <Label htmlFor="ePriceBack" className="text-right">
              ExtraPrice Back:
            </Label>
            <Input id="ePriceBack" className="input-field" value={sides.ePriceBack} onChange={(e) => setSides(pre => ({ ...pre, ePriceBack: +e.target.value }))} />
          </div>}
          {sides.sides && <div className="flex items-center space-x-2">
            <Label htmlFor="ePriceSides" className="text-right">
              ExtraPrice Sides:
            </Label>
            <Input id="ePriceSides" className="input-field" value={sides.ePriceSides} onChange={(e) => setSides(pre => ({ ...pre, ePriceSides: +e.target.value }))} />

          </div>}
        </div>
        <DialogFooter>
          <Button onClick={handleSubmit}>Edit Sides</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>)
}

export const FilterByCategory = ({ onChangeHandler, categoryId }: any) => {
  const { data: categories } = useGetAllCategoryQuery("categoriesList", { pollingInterval: 60000, refetchOnFocus: true, refetchOnMountOrArgChange: true })

  const handleValueChange = (selectedValue: string) => {
    onChangeHandler(selectedValue);
  };

  return (<div className="flex gap-1 items-center">
    <Select onValueChange={handleValueChange} value={categoryId.toString()} >
      <SelectTrigger className="bg-blue-50 text-md font-semibold px-0">
        <SelectValue placeholder="T Shirts" />
      </SelectTrigger>
      <SelectContent className='border-gray-500 bg-gray-100  hover:text-black '>
        {categories?.result?.map((category: any) => (
          <SelectItem
            key={category.id}
            value={category.id.toString()}
            className="select-item p-regular-14"
          >
            {category.categoryName}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  </div>);
};
export const FilterBySubCategory = ({ onChangeHandler, subcategoryId, categoryId }: any) => {
  const { data: subCategories } = useGetSubcategoryQuery(categoryId, { pollingInterval: 60000, refetchOnFocus: true, refetchOnMountOrArgChange: true })

  const handleValueChange = (selectedValue: string) => {
    onChangeHandler(Number(selectedValue));
  };

  return (<div className="flex gap-1 items-center">
    <Select onValueChange={handleValueChange} value={subcategoryId.toString()} >
      <SelectTrigger className="bg-blue-50 text-md font-semibold px-0">
        <SelectValue placeholder="All" />
      </SelectTrigger>
      <SelectContent className='border-none bg-gray-100  hover:text-black '>
        <SelectItem
          key={"All"}
          value={"0"}
          className="select-item p-regular-14"
        >
          All
        </SelectItem>
        {subCategories?.result?.map((subCategory: any) => (
          <SelectItem
            key={subCategory.id}
            value={subCategory.id.toString()}
            className="select-item p-regular-14"
          >
            {subCategory.subcategoryName}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  </div>);
};


export default PrintSides