import { Link, useNavigate, useParams } from 'react-router-dom'
import { useGetAllCategoryQuery, useGetSubcategoryQuery } from '../redux/slice/CategoryApiSlice'
import { SlashIcon } from "@radix-ui/react-icons"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../components/ui/breadcrumb"
import { TCategory, TSubCategory } from '@/react-app-env'
import { Card } from '../components/ui/card'
import { IMGURL } from '../constants/appConfig'


const AllCategoryProducts = () => {
  const { data: categories, isLoading, isSuccess, isError, } = useGetAllCategoryQuery("categoriesList", { pollingInterval: 6000, refetchOnFocus: true, refetchOnMountOrArgChange: true, });


  let content
  if (isLoading) content = <section className="bgcolorgold" ><p className="p-4">Loading...</p></section>;
  if (isError) content = <p>{categories?.result?.message}</p>
  if (!categories?.result?.length) {
    content = <section className="bgcolorgold" >
      <p className="p-4 text-center text-lg font-semibold">
        No SubCategories Available Under This Category
      </p></section>
  }
  if (isSuccess && categories?.result?.length > 0) {
    content = <div className="wrapper flex flex-wrap max-sm:p-4 md:gap-6 gap-3">
      {categories?.result?.map((subCategory: TCategory) => <Card key={subCategory.id} className="relative w-[260px] border-2 flex flex-col items-center border-gray-500">
        <Link to={`/category/${subCategory.id}`} className='w-full h-full'>
          <img src={`${IMGURL}${subCategory.imageUrl}`} alt={subCategory.categoryName} className='w-full  h-[280px] rounded-t-md object-cover' />
{/*           <p className='absolute bottom-4 text-white headermenu  p-2 px-3 rounded-lg text-sm font-semibold'>{subCategory.categoryName}</p>
 */}          <p className='bottom-4  text-white headermenu h-16 flex items-center justify-center  p-2 px-3 rounded-b-md text-xl font-semibold'>{subCategory.categoryName}</p>

        </Link></Card>)}

    </div>
  }
  return (<section className="bgcolorgold" >
    <div>
      {content}
    </div>
  </section>);
};

export function BreadcrumbWithCustomSeparator({ data }: { data: TSubCategory }) {
  return (
    <Breadcrumb className='h5-bold  sm:m-2 md:my-4 md:px-72'>
      <BreadcrumbList>
        <BreadcrumbItem>
          {/* <BreadcrumbLink href="/">Home</BreadcrumbLink> */}
          <Link to="/">Home</Link>
        </BreadcrumbItem>
        <BreadcrumbSeparator>
          <SlashIcon />
        </BreadcrumbSeparator>
        <BreadcrumbItem>
          <BreadcrumbPage className='font-bold text-lg'>{data.subcategoryName}</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  )
}
export default AllCategoryProducts;

