import Footer from "../components/shared/SubLayout/Footer";
import Header from "../components/shared/SubLayout/Header";
import { Outlet } from "react-router-dom";


const PageNavigation = () => {
  return (
    <div className="flex flex-col font-display">
      <Header />
      <div className="min-h-[700px]">
      <Outlet />
      </div>
      <Footer/>
    </div>
  );
};

export default PageNavigation;
