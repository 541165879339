import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { Button } from "../../components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog"
import { Input } from "../../components/ui/input"
import { Label } from "../../components/ui/label"
import { useDeleteProductAttributeMutation, useGetProductAttributesByAttNameMutation,  useSaveProductAttributeColorMutation, useUpdateProductAttributeMutation } from "../../redux/slice/admin/AAttributeApiSlice"
import { IoMdClose } from "react-icons/io"
import { MdDeleteOutline } from "react-icons/md"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select"
import { useGetAllCategoryQuery, useGetSubcategoryQuery } from "../../redux/slice/CategoryApiSlice"
import { useSearchParams } from "react-router-dom"
import { FilterByCategoryTemplate, FilterBySubCategoryTemplate } from "./Templates"
import { ProductAttribute } from "@/react-app-env"


const ColorByCategory = () => {
  const [productAttByAttName,{ data: productAttributes }] = useGetProductAttributesByAttNameMutation()
  let attributeName = "Color"
  const [searchParams]=useSearchParams()
  const catId=searchParams.get("catId")||1;
  const subcatId=searchParams.get("subCatId")||"All";
  const [condEdDe,setCondEdDe]=useState<boolean>(false)
  const [handleChange,setHandleChange]=useState<boolean>(false)

  useEffect(()=>{
    if(subcatId==="All"){
      productAttByAttName({catId,attributeName,subcatId:0})
    }else{
      productAttByAttName({catId,subcatId,attributeName})
    }
  },[productAttByAttName,attributeName,catId,subcatId,condEdDe])

  
  const attr = productAttributes?.result[0]
  const colors = attr?.attributeValue && JSON.parse(attr?.attributeValue)

  return (
    <div className="wrapper-dashboard">
      <div className="flex justify-between gap-4 flex-wrap">
        <h5 className="text-xl font-bold">Color By Categories</h5>
        <div className="flex gap-6 flex-wrap">
        <FilterByCategoryTemplate catId={catId.toString()} />
        <FilterBySubCategoryTemplate catId={catId.toString()} subCatId={subcatId}/>
        </div>
        <AddNewColor setCondEdDe={setCondEdDe}/>
      </div>
      <div className="my-4">
        <p className="font-medium">Attribute Name: {attributeName}</p>
        <div className="flex gap-2 items-center flex-wrap my-4">
          {productAttributes?.result?.length > 0 ? colors.map((color: string) => <div key={color} style={{ backgroundColor: `${color}` }} className="rounded-full border border-gray-500 shadow-md h-10 w-10"></div>):
            <p className="text-lg font-semibold text-gray-800 p-3 rounded-md shadow-sm">
        Selected Filters: There Is No Colors
      </p>}
      <Button className="headermenu mx-3" onClick={()=>{setHandleChange(true)}} variant="default">Edit/Delete</Button>
          {(productAttributes?.result?.length > 0 && handleChange)  && <EditColor setHandleChange={setHandleChange} colors={productAttributes?.result[0]} setCondEdDe={setCondEdDe}/>}
           {/*  {colors?.length>0 && <DeleteAllColors  id={attr.id} setCondEdDe={setCondEdDe}/>} */}
        </div>
      </div>
    </div>
  )
}

export function DeleteAllColors({ id,setCondEdDe }: { id: number,setCondEdDe:Dispatch<SetStateAction<boolean>> }) {
  const [open, setOpen] = useState(false)
  const [deleteProductAttribute] = useDeleteProductAttributeMutation()
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <AlertDialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      <AlertDialogTrigger asChild>
        <Button className="bg-red-700">Delete Colors Row<MdDeleteOutline className="ml-2" size={20} /></Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete all colors related to category.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={() =>{ deleteProductAttribute(id);setCondEdDe(p=>!p)}} className="bg-red-500" >Delete</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

const EditColor = ({ colors ,setCondEdDe,setHandleChange}: { colors: ProductAttribute ,setCondEdDe:Dispatch<SetStateAction<boolean>>,setHandleChange:Dispatch<SetStateAction<boolean>>}) => {
  const [colorsEdit, setColorsEdit] = useState<string[]>([])
  const [updateProductAttribute] = useUpdateProductAttributeMutation()
  const [open, setOpen] = useState(true)
  const colorref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setColorsEdit(JSON.parse(colors.attributeValue))
  }, [colors])

  const handleSubmit = async () => {
    await updateProductAttribute({ id:colors.id, categoryId:colors.categoryId, attributeName: "Color", attributeValue: JSON.stringify(colorsEdit) })
    setOpen(false)
    setCondEdDe(p=>!p)
    setHandleChange(false)
  }

  const handleClose = () => {
    setOpen(false)
    setHandleChange(false)
  }
  const handleDeleteColor = (indexToRemove: number) => {
    const filteredArray = colorsEdit.filter((_, index) => index !== indexToRemove);
    setColorsEdit(filteredArray)
  }
  const handleColor = () => {
    if (colorref.current?.value) { // Use ! operator to assert non-null
      setColorsEdit(prevColors => [...prevColors, colorref.current!.value]);
    }
  }

  return (
    <Dialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      {/* <DialogTrigger asChild>
        <Button className="headermenu mx-3" variant="default">Edit/Delete</Button>
      </DialogTrigger> */}
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Edit Color</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="flex items-center gap-4">
            <Label htmlFor="color" className="text-right">
              Select Color:
            </Label>
            <Input id="color" type="color" className="col-span-3 input-field w-20" ref={colorref} />
            <Button className="headermenu" size="sm"  onClick={handleColor}>Add New Color</Button>
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-sm text-red-400">Click on color to delete</p>
            <div className="flex items-center flex-wrap gap-2">
              <p className="font-semibold">Preview Colors:</p>
              {colorsEdit.map((color, idx) => <button className="rounded-full w-[40px] h-[40px] relative border border-black" onClick={() => handleDeleteColor(idx)} key={color} style={{ backgroundColor: `${color}` }}><IoMdClose className="absolute left-3 top-3" /></button>)}
            </div>
          </div>
        </div>
        <DialogFooter className="felx justify-between gap-4">
          <Button onClick={handleSubmit} className="bg-green-800">Save</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>)
}

const AddNewColor = ({ setCondEdDe }: { setCondEdDe:Dispatch<SetStateAction<boolean>> }) => {
  const [categoryId, setCategoryId] = useState<number>(1)
  const [subcategoryId, setSubcategoryId] = useState<number>(0)
  const [colors, setColors] = useState<string[]>([])
  const [saveProductAttribute, { data }] = useSaveProductAttributeColorMutation()
  const [open, setOpen] = useState(false)
  const colorref = useRef<HTMLInputElement>(null)

  const handleSubmit = async () => {
    await saveProductAttribute({ categoryId,subcategoryId, attributeName: "Color", attributeValue: JSON.stringify(colors) })
    setOpen(false)
    setColors([])
    setCondEdDe(p=>!p)
  }

  const handleClose = () => {
    setOpen(false)
    setColors([])
  }
  const handleColor = () => {
    if (colorref.current?.value) { // Use ! operator to assert non-null
      setColors(prevColors => [...prevColors, colorref.current!.value]);
    }
  }

  return (
    <Dialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      <DialogTrigger asChild>
        <Button variant="default" className="headermenu">Add New Color</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Add New Color</DialogTitle>

        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="flex items-center gap-4">
            <Label htmlFor="Category" className="text-right">
              Category:
            </Label>
            <FilterByCategory onChangeHandler={setCategoryId} categoryId={categoryId} />
          </div>
          <div className="flex items-center gap-4">
            <Label htmlFor="subCategory" className="text-right">
              SubCategory:
            </Label>
            <FilterBySubCategory onChangeHandler={setSubcategoryId} subcategoryId={subcategoryId} categoryId={categoryId} />
          </div>
          <div className="flex items-center gap-4">
            <Label htmlFor="color" className="text-right">
              Select Color:
            </Label>
            <Input id="color" type="color" className="col-span-3 input-field w-20" ref={colorref} />
            <Button variant="outline" size="sm" onClick={handleColor}>Add To Color</Button>
          </div>
          <div className="flex items-center flex-wrap gap-2">
            <p className="font-semibold">Preview Colors:</p>
            {colors.map(color => <div className="rounded-full w-[40px] h-[40px]" key={color} style={{ backgroundColor: `${color}` }}></div>)}
          </div>
        </div>
        <DialogFooter>
          <Button onClick={handleSubmit}>Add New Colors</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>)
}

export const FilterByCategory = ({ onChangeHandler, categoryId }: any) => {
  const { data: categories } = useGetAllCategoryQuery("categoriesList", { pollingInterval: 60000, refetchOnFocus: true, refetchOnMountOrArgChange: true })

  const handleValueChange = (selectedValue: string) => {
    onChangeHandler(selectedValue);
  };

  return (<div className="flex gap-1 items-center">
    <Select onValueChange={handleValueChange} value={categoryId.toString()} >
      <SelectTrigger className="bg-blue-50 text-md font-semibold px-0">
        <SelectValue placeholder="T Shirts" />
      </SelectTrigger>
      <SelectContent className='border-gray-500 bg-gray-100  hover:text-black '>
        {categories?.result?.map((category: any) => (
          <SelectItem
            key={category.id}
            value={category.id.toString()}
            className="select-item p-regular-14"
          >
            {category.categoryName}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  </div>);
};
export const FilterBySubCategory = ({ onChangeHandler, subcategoryId, categoryId }: any) => {
  const { data: subCategories } = useGetSubcategoryQuery(categoryId, { pollingInterval: 60000, refetchOnFocus: true, refetchOnMountOrArgChange: true })

  const handleValueChange = (selectedValue: string) => {
    onChangeHandler(Number(selectedValue));
  };

  return (<div className="flex gap-1 items-center">
    <Select onValueChange={handleValueChange} value={subcategoryId.toString()} >
      <SelectTrigger className="bg-blue-50 text-md font-semibold px-0">
        <SelectValue placeholder="All" />
      </SelectTrigger>
      <SelectContent className='border-none bg-gray-100  hover:text-black '>
        <SelectItem
          key={"All"}
          value={"0"}
          className="select-item p-regular-14"
        >
          All
        </SelectItem>
        {subCategories?.result?.map((subCategory: any) => (
          <SelectItem
            key={subCategory.id}
            value={subCategory.id.toString()}
            className="select-item p-regular-14"
          >
            {subCategory.subcategoryName}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  </div>);
};


export default ColorByCategory