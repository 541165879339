import { useEffect } from "react";
import { Outlet, ScrollRestoration, useLocation } from "react-router-dom";

const ScrollToTop = () => {
    const location = useLocation();
    useEffect(() => {
      window.scrollTo({top: 0, left: 0/* , behavior: 'smooth' */ });
    }, [location]);

    return null
  };
  
/* export const ScrollTop = () => {
   
    return <>
    <ScrollToTop/>
    <Outlet/>
    </>
  }; */
export const ScrollTop = () => {
   
    return <>
    <ScrollRestoration />
    <Outlet/>
    </>
  };

export default ScrollToTop;