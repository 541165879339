import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { Button } from "../../components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog"
import { Label } from "../../components/ui/label"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select"
import { useGetAllCategoryQuery, useGetSubcategoryQuery } from "../../redux/slice/CategoryApiSlice"
import { toast } from "react-toastify"
import { Input } from "../../components/ui/input"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip"
import { IMGURL } from "../../constants/appConfig"
import { MdDelete, MdEdit, MdOutlineCancel } from "react-icons/md"
import { GrUpdate } from "react-icons/gr";
import { useAddBannerMutation, useDeleteBannerMutation, useGetBannersQuery, useUpdateBannerOrderMutation } from "../../redux/slice/admin/ABannerImage"
import { IoSave } from "react-icons/io5"

const BannerImages = () => {
  const { data: bannerIamges } = useGetBannersQuery("bannerList", { pollingInterval: 60000, refetchOnFocus: true, refetchOnMountOrArgChange: true })

  return (
    <div className="wrapper-dashboard">
      <div className="flex justify-between gap-4 flex-wrap">
        <h5 className="text-xl font-bold">Banner Images</h5>
        {bannerIamges?.result?.length >=5?<h5 className="text-xl font-bold">Max Limit is 5 Images</h5>:<AddImageToCategory />}
      </div>
      <div className="my-4">
        <div className="flex gap-2 items-center flex-wrap my-4">
          {bannerIamges?.result?.length > 0 ?
            bannerIamges?.result?.map((Image: any) => <div key={Image.id} className="flex flex-col relative  shadow-lg rounded-lg border border-gray-500">
              <img src={`${IMGURL}${Image.imageUrl}`} alt={"new"} className="w-96 h-40 bg-red-500 rounded-t-lg" />
            
            <EditBannerImage Image={Image}/>
            </div>
            ) :

            <p className="text-xl font-bold">There Is No Banner Images</p>}
        </div>
      </div>
    </div>
  )
}


const AddImageToCategory = () => {
  const [displayOrder, setDisplayOrder] = useState<number>(1)
  const [imgBanner, setImgBanner] = useState<string>("")
  const [addBannerImage] = useAddBannerMutation()
  const [open, setOpen] = useState(false)

  const handleSubmit = async () => {
    if (!imgBanner) {
      toast.error("Select the Banner Image")
      return;
    }
    if (displayOrder<=0 || displayOrder>5) {
      toast.error("Select the DisplayOrder No in between 1 and 5")
      return;
    }
    await addBannerImage({ displayOrder, imgBanner })
    setOpen(false)
    setImgBanner("")
    setDisplayOrder(1)
  }

  const handleClose = () => {
    setOpen(false)
    setImgBanner("")
    setDisplayOrder(1)
  }

  return (
    <Dialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      <DialogTrigger asChild>
        <Button variant="default" className="headermenu">Add Banner Image</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Add Banner Images</DialogTitle>
          <p className="text-sm my-1 text-red-500"></p>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="flex items-center gap-4">
            <Label htmlFor="subCategory" className="text-right">
              Display Order Number:
            </Label>
            <Input type="number" min={1} max={5} value={displayOrder} onChange={e => setDisplayOrder(+e.target.value)} />
          </div>
          <div className="">
            <div className="flex items-center space-x-2">
              <Label htmlFor="template" className="text-right">
                Choose Banner Image
              </Label>
              <Input id="template" type="file" className="col-span-3 input-field" accept="image/*, application/pdf"
                onChange={(event) => {
                  const file = event.target.files && event.target.files[0];
                  if (file) {
                    const reader = new FileReader();
                    reader.onload = () => {
                      const base64String = reader.result as string;
                      setImgBanner(base64String);
                    };
                    reader.readAsDataURL(file); // Convert the selected file to Base64
                  }
                }} />
            </div>
            {imgBanner && <img src={imgBanner} alt="preview" className="w-52 h-28 bg-gray-500" />}
          </div>

        </div>
        <DialogFooter>
          <Button onClick={handleSubmit}>Add Banner Image</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>)
}



const EditBannerImage = ({ Image }: { Image: any }) => {

  const [updateBannerOrder] = useUpdateBannerOrderMutation()
  const [editOrder, setEditOrder] = useState<boolean>(false)

  const [displayOrder, setDisplayOrder] = useState<number>(1)

  const handleCondition = async(id:number)=>{
    setEditOrder(true)
    setDisplayOrder(Image.displayOrder)
  }

  const handleEditOrder = async()=>{
    if (displayOrder<=0 || displayOrder>5) {
      toast.error("Select the DisplayOrder No in between 1 and 5")
      return;
    }
    await updateBannerOrder({banners:[{...Image,displayOrder}]})
    setEditOrder(false)
  }
  
  return (
    <div className="flex gap-1 justify-between  bg-gray-200 w-full rounded-b-lg px-1">
    {editOrder?<Label className="flex">Update DisplayOrder No: <Input type="number" className="border-2 h-10 my-1 focus-visible:ring-0 focus-visible:ring-offset-transparent focus-visible:ring-offset-0 border-black max-w-20" min={1} max={5} value={displayOrder} onChange={e => setDisplayOrder(+e.target.value)} />
    </Label>:
    <p className="text-sm  flex font-semibold items-center gap-2 pl-1 text-gray-500">DisplayOrder NO: 
      <span className="text-black">{Image.displayOrder}</span>
      </p>}
    <div className="gap-2 flex my-1">
      {!editOrder?<Button variant={"ghost"} onClick={()=>{handleCondition(Image.id)}}> <MdEdit size={20}/></Button>:<>
      <TooltipProvider delayDuration={100}>
      <Tooltip>
          <TooltipTrigger asChild>
          <Button variant={"ghost"} onClick={handleEditOrder}> <IoSave  size={20}/></Button> 
            </TooltipTrigger>
          <TooltipContent>
              <p>Update DisplayOrder No</p>
          </TooltipContent>
      </Tooltip>
      </TooltipProvider>
      <TooltipProvider delayDuration={100}>
      <Tooltip>
          <TooltipTrigger asChild>
          <Button variant={"ghost"} onClick={()=>setEditOrder(false)}> <MdOutlineCancel  size={20}/></Button> 
            </TooltipTrigger>
          <TooltipContent>
              <p>Cancel Edit</p>
          </TooltipContent>
      </Tooltip>
      </TooltipProvider>
      </>
     }
     {!editOrder&& <DeleteBannerImage id={Image.id}  />}
    </div>

  </div>)
}

export function DeleteBannerImage({ id }: { id: number }) {
  const [open, setOpen] = useState(false)
  const [deleteBanner] = useDeleteBannerMutation()
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <AlertDialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      <AlertDialogTrigger asChild>
        <Button variant={"ghost"} ><MdDelete size={"20"} color="red" /></Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete Banner Image.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={() => { deleteBanner(id);handleClose()}} className="bg-red-500" >Delete</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}


export default BannerImages