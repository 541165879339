"use client"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { Button } from "../../../../../components/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../../components/ui/form"
import { Input } from "../../../../../components/ui/input"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../../components/ui/dialog"
import { Label } from "../../../../../components/ui/label"
import { templateDefaultValues } from "../../../../../constants"
import { templateFormSchema } from "../Validator"
import Dropdown from "../Dropdown"
import { useEffect,  useState } from "react"
import CreateTemplate from "./CreateTemplate"
import { useSaveProductTemplateMutation, useUpdateProductTemplateMutation } from "../../../../../redux/slice/admin/ATemplateApiSlice"
import { useNavigate } from "react-router-dom"
import { TemplateT, TSubCategory } from "@/react-app-env"
import { toast } from "react-toastify"
import { useGetSubcategoryByIdQuery } from "../../../../../redux/slice/CategoryApiSlice"
import {  useUpdateSubcategoryAMutation } from "../../../../../redux/slice/admin/ACategoryApiSlice"
import { IMGURL } from "../../../../../constants/appConfig"


type templateFormProps = {
  type: string,
  data?: TemplateT
}


const TemplateForm = ({ type, data }: templateFormProps) => {
  const handleCatId = (id: number) => { setCatId(id) }
  const [catId, setCatId] = useState<number | undefined>(type==="Create"?1:data?.categoryId)
  const [canvasDimensions, setCanvasDimensions] = useState({ width: 0, height: 0 })
  const [templatePreviewUrl, setTemplatePreviewUrl] = useState("")
  const [templateEditJsonData, setTemplateEditJsonData] = useState({front:{canvasUrl:"",previewUrl:""},back:{canvasUrl:"",previewUrl:""},left:{canvasUrl:"",previewUrl:""},right:{canvasUrl:"",previewUrl:""}})
  //const [templateEditJsonData, setTemplateEditJsonData] = useState({front:"",back:"",left:"",right:"",previewFront:"",previewBack:"",previewLeft:"",previewRight:""})
  const [saveTemplate] = useSaveProductTemplateMutation()
  const [updateTemplate] = useUpdateProductTemplateMutation()
  const [loadCanvas,setLoadCanvas]=useState(false)
  const navigate = useNavigate()
  const [imageBase64, setImageBase64] = useState<string | undefined>();
  const [canvasSides,setCanvasSides]=useState({templateBackUrl:"",templateLeftSideUrl:"",
    templateRightSideUrl:"",previewBackUrl:"",previewLeftSideUrl:"",previewRightSideUrl:""})

  useEffect(()=>{
    if(type==="Edit"&&data){
      setTemplateEditJsonData({front:{canvasUrl:data?.templateUrl,previewUrl:data?.templatePreviewUrl}
        ,back:{canvasUrl:data?.templateBackUrl,previewUrl:data?.previewBackUrl},
        left:{canvasUrl:data?.templateLeftSideUrl,previewUrl:data?.previewLeftSideUrl},
        right:{canvasUrl:data?.templateRightSideUrl,previewUrl:data?.previewRightSideUrl}
      })
    /*   setTemplateEditJsonData({front:data?.templateUrl,back:data?.templateBackUrl,left:data?.templateLeftSideUrl,right:data?.templateRightSideUrl,
        previewFront:data?.templatePreviewUrl,previewBack:data?.previewBackUrl,previewLeft:data?.previewLeftSideUrl,previewRight:data?.previewRightSideUrl,
      }) */
    }
  },[type,data])

  const initialValues = (type === "Edit" && data) ? { ...data,templateSample:data?.sampleImageUrl } : templateDefaultValues;

  const form = useForm<z.infer<typeof templateFormSchema>>({
    resolver: zodResolver(templateFormSchema),
    defaultValues: initialValues
  })

  const { watch,setValue } = form;
  const subCategoryId = watch("subcategoryId")
  const categoryId = watch("categoryId")
  const { data: subCategoryDetails } = useGetSubcategoryByIdQuery(subCategoryId)

useEffect(()=>{
  if(subCategoryDetails?.result?.canvasHeight&&subCategoryDetails?.result?.canvasWidth ){
    setCanvasDimensions({ width:subCategoryDetails?.result?.canvasWidth, height:subCategoryDetails?.result?.canvasHeight })
  }else{
    setCanvasDimensions({ width:0, height:0 })
  }
  setLoadCanvas(false)
},[subCategoryDetails])

  // 2. Define a submit handler.
  async function onSubmit(values: z.infer<typeof templateFormSchema>) {

    let res
   if (values) {
      if (type === "Edit") {
        res = await updateTemplate({categoryId:values.categoryId,templateSample:imageBase64?.length?(imageBase64):(data?.sampleImageUrl),isSampleImgCahnged:imageBase64?.length&&true,subcategoryId:values.subcategoryId, templateData: values?.templateUrl, templatePreview: templatePreviewUrl, templateId: data?.id,
          ...canvasSides})

      } else if (type === "Create") {
        res = await saveTemplate({categoryId:values.categoryId,subcategoryId:values.subcategoryId,templateSample:imageBase64, templateData: values?.templateUrl, templatePreview:templatePreviewUrl,
          ...canvasSides })
       /*  res = await saveTemplate({categoryId:values.categoryId,subcategoryId:values.subcategoryId,templateSample:imageBase64, templateData: values?.templateUrl, templatePreview:templatePreviewUrl,
          templateBackUrl:canvasSides?.templateBackUrl,templateLeftSideUrl:canvasSides?.templateLeftSideUrl,templateRightSideUrl:canvasSides?.templateRightSideUrl }) */
      }

      if (res && 'data' in res) {
        if (res.data.success) {
          toast.success("Template Added Successfully")
          navigate(`/admin/dashboard/templates?catId=${values?.categoryId}&subCatId=${values.subcategoryId}`)
        }else{
          toast.error("Internal Server error")
        }
      } else {
        toast.error("Internal Server error")
        console.error('Error:', res?.error);
      }

    } 
  }


  return (
    <Form {...form} >
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <div className="flex flex-col gap-5 items-center md:flex-row">
          <FormField
            control={form.control}
            name="categoryId"
            render={({ field }) => (
              <FormItem className="w-full ">
                <FormLabel htmlFor="categoryIdDropdown">Category</FormLabel>
                <FormControl>
                  <Dropdown
                    id="categoryIdDropdown"
                    onChangeHandler={field.onChange}
                    value={field.value.toString()}
                    type="categoryId"
                    onChangeCID={handleCatId}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="subcategoryId"
            render={({ field }) => (
              <FormItem className="w-full ">
                <FormLabel htmlFor="subCategoryIdDropdown">SubCategory</FormLabel>
                <FormControl>
                  <Dropdown
                    id="subCategoryIdDropdown"
                    onChangeHandler={field.onChange}
                    value={field.value.toString()}
                    type="subCategoryId"
                    catId={catId}
                    formType={type}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {/* {categoryId!==1&& */}{/* <div className="space-y-2">
            <p className="font-semibold">Canvas Dimensions:</p>
            {subCategoryId  && <CanvasDimensions subCategoryDetails={subCategoryDetails?.result} setCanvasDimensions={setCanvasDimensions} />}
          </div> */}{/* } */}
        </div>
        <div className="flex flex-col gap-5 items-start md:flex-row">
      
        <div className="flex flex-row gap-4">
            <FormField
              control={form.control}
              name="templateSample"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Sample Image Final</FormLabel>
                  <FormControl>
                    <Input
                      type="file"
                      accept="image/*"
                      className="input-field"
                      onChange={(event) => {
                        const file = event.target.files && event.target.files[0];
                        if (file) {
                          const reader = new FileReader();
                          reader.onload = () => {
                            const base64String = reader.result as string;
                            setImageBase64(base64String);
                            setValue("templateSample", base64String)
                          };
                          reader.readAsDataURL(file); // Convert the selected file to Base64
                        }
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {imageBase64 ? (<img src={imageBase64} alt="imgphot" width={100} height={100} />) : (
              data?.sampleImageUrl !== undefined && (<img src={`${IMGURL}${data?.sampleImageUrl}`} alt="imgphot" width={100} height={100} />)
            )
            }
          </div>
        </div>
        <div>
          {loadCanvas?<FormField
            control={form.control}
            name="templateUrl"
            render={({ field }) => (
              <FormItem className="w-full ">
                <FormLabel htmlFor="templateUrl">Template</FormLabel>
                <FormControl>
                  <CreateTemplate
                    canvasDimensions={canvasDimensions}
                    onChangeHandler={field.onChange}
                    templateEditJsonData={templateEditJsonData}
                    onSubmit={onSubmit}
                    type={type}
                    categoryId={categoryId}
                    subCategoryId={subCategoryId}
                    setCanvasSides={setCanvasSides}
                    setTemplatePreviewUrl={setTemplatePreviewUrl}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />:
          <div className="flex flex-col gap-3">
           { (canvasDimensions.width<=0||canvasDimensions.height<=0) ?<p className="text-sm font-semibold text-red-600">*Set the template dimensions for the selected SubCategory</p>:
            <p className="text-sm font-semibold text-red-600">*Select above categories before Create/Edit Design</p>}
            <Button onClick={()=>setLoadCanvas(pre=>!pre)} className="headermenu w-40" disabled={(canvasDimensions.width<=0||canvasDimensions.height<=0)}>Create/Edit Design</Button>
            </div>}
        </div>
        {/*  <Button type="submit" className="headermenu">Save Template</Button> */}
      </form>
    </Form>
  )
}



export function CanvasDimensions({ subCategoryDetails, setCanvasDimensions }: { subCategoryDetails: TSubCategory, setCanvasDimensions: any }) {
  const [canvasWidth, setCanvasWidth] = useState<number>(450)
  const [canvasHeight, setCanvasHeight] = useState<number>(600)
  const [saveSubCatDime, { data }] = useUpdateSubcategoryAMutation()
  const [open,setOpen]=useState(false)
  const handleClose=()=>{
    setOpen(false)
  }

  
  useEffect(() => {
    if(data?.result){
      setCanvasDimensions({width: canvasWidth, height: canvasHeight})
    }
  }, [data?.result,canvasWidth,canvasHeight,setCanvasDimensions])

  useEffect(() => {
    if (subCategoryDetails?.canvasWidth && subCategoryDetails?.canvasHeight) {
      setCanvasWidth(subCategoryDetails?.canvasWidth)
      setCanvasHeight(subCategoryDetails?.canvasHeight)
      setCanvasDimensions({ width: subCategoryDetails?.canvasWidth, height: subCategoryDetails?.canvasHeight })
    }else{
      setCanvasDimensions({width: 450, height: 600})
    }
  }, [subCategoryDetails?.canvasWidth, subCategoryDetails?.canvasHeight, setCanvasDimensions])

  const onSubmit = async () => {
    await saveSubCatDime({ ...subCategoryDetails, canvasHeight, canvasWidth })
    setOpen(false)
  }
  return (<div className="flex gap-1 items-center"> 
  <p className="font-medium">width*height:{canvasWidth}*{canvasHeight}</p>
    <Dialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }}}>
      <DialogTrigger asChild>
        <Button variant="outline">Add/Edit Canvas Dimensions</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Canvas Dimensions</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="width" className="text-right">
              Width
            </Label>
            <Input
              id="width"
              className="col-span-3"
              value={canvasWidth}
              onChange={event => setCanvasWidth(+event.target.value)}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="height" className="text-right">
              Height
            </Label>
            <Input
              id="height"
              className="col-span-3"
              value={canvasHeight}
              onChange={event => setCanvasHeight(+event.target.value)}
            />
          </div>
        </div>
        <DialogFooter>
          <Button type="button" onClick={onSubmit}>Save changes</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
    </div>)
}


export default TemplateForm