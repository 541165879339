import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from "../../ui/sheet";
import { Separator } from "../../ui/separator";
/*   import NavItems from "./NavItems" */
import { FaBars } from "react-icons/fa";
import { MobileNavigationLinks } from "../SubLayout/NavigationLinks";
import { useState } from "react";
import { NavLinksH } from "../../../constants";
import { Button } from "../../../components/ui/button";
import { useNavigate } from "react-router-dom";

const MobileNav = () => {
  const [open,setOpen]=useState(false)
  const navigate =useNavigate()
  const handleClose = ()=>{
    setOpen(false)
  }
  return (
    <nav className="md:hidden sticky top-0 ">
    <Sheet open={open} onOpenChange={()=>{
       if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      <SheetTrigger className="align-middle">
        <FaBars size="26px" color="black"/>
      </SheetTrigger>
      <SheetContent className="flex w-[250px] sm:w-[300px] flex-col gap-6 bg-white lg:hidden" side={"left"}>
        <img 
           src="/assets/logo/logo1.png"
          alt="logo"
          width={128}
          height={38}
        />
        <Separator className="border border-gray-100" />
        <div className="flex  gap-2  flex-col text-lg font-semibold justify-start items-start">
        {NavLinksH.map(nav=> <Button  key={nav.id} onClick={()=>{navigate(`/category/${nav.id}`);handleClose()}} variant={"link"}
         className="hover:text-red-700 font-medium text-lg ">{nav.categoryName}</Button>
        )}
    </div>
        {/* <div>
          <MobileNavigationLinks />
        </div> */}
      </SheetContent>
    </Sheet>
  </nav>
  );
};

export default MobileNav;
