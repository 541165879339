import { apiSlice } from "../../apiSlice";


export const ALogoApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        addLogodesign: builder.mutation({
            query: initialProdData => ({
                url: '/admin/addLogodesign',
                method: 'POST',
                body: {
                    ...initialProdData,
                }
            }),
            invalidatesTags: [
                { type: 'Logo', id: "LIST" }
            ]
        }),
        updateLogodesign: builder.mutation({
            query: initialProdData => ({
                url: '/admin/updateLogodesign',
                method: 'POST',
                body: {
                    ...initialProdData,
                }
            }),
            invalidatesTags: [
                { type: 'Logo', id: "LIST" }
            ]
        }),
        getLogodesigns: builder.mutation({
            query: initialProdData => ({
                url: '/common/getLogodesigns',
                method: 'POST',
                body: {
                    ...initialProdData,
                }
            }),
            invalidatesTags: [
                { type: 'Logo', id: "LIST" }
            ]
        }),
        getTransparentImagesA: builder.mutation({
            query: initialProdData => ({
                url: '/admin/getTransparentImages',
                method: 'POST',
                body: {
                    ...initialProdData,
                }
            }),
            invalidatesTags: [
                { type: 'Transparent', id: "LIST" }
            ]
        }),
        updateTransparentImageA: builder.mutation({
            query: initialProdData => ({
                url: '/admin/updateTransparentImage',
                method: 'POST',
                body: {
                    ...initialProdData,
                }
            }),
            invalidatesTags: [
                { type: 'Transparent', id: "LIST" }
            ]
        }),
        addTransparentImageA: builder.mutation({
            query: initialProdData => ({
                url: '/admin/addTransparentImage',
                method: 'POST',
                body: {
                    ...initialProdData,
                }
            }),
            invalidatesTags: [
                { type: 'Transparent', id: "LIST" }
            ]
        }),
        deleteLogodesign: builder.mutation({
            query: ( id ) => ({
                url: `/admin/deleteLogodesign/${id}`,
                method: 'DELETE',
                }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Logo', id: arg.id }
            ]
        }),
        deleteTransparentImageA: builder.mutation({
            query: ( id ) => ({
                url: `/admin/deleteTransparentImage/${id}`,
                method: 'DELETE',
                }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Transparent', id: arg.id }
            ]
        }),
    }),
})

export const {
    useAddLogodesignMutation,
    useDeleteLogodesignMutation,
    useUpdateLogodesignMutation,
    useGetLogodesignsMutation,
    useGetTransparentImagesAMutation,
    useAddTransparentImageAMutation,
    useUpdateTransparentImageAMutation,
    useDeleteTransparentImageAMutation
} = ALogoApiSlice

