  // Function to attach event listeners to text objects
  /* const attachEventListeners = () => {
    if (!editor || !editor.canvas) return;

    editor.canvas.forEachObject((obj: fabric.Object) => {
      // Make sure all objects are selectable and have controls
      obj.set({
        selectable: true,
        hasControls: true,
        hasBorders: true,
      });

      if ((obj as any).id === 'printableArea') {
        obj.set({
          selectable: false,
          evented: false,
          hasControls: false,
          hasBorders: false,
        });
      }

      if (obj.type === "textbox" || obj.type === "text" || obj.type === "i-text") {
        const textObj = obj as fabric.IText;
        textObj.editable = true;
        textObj.evented = true;

        // Enable editing on double click
        textObj.on("mousedown", (e) => {
          if (e.e.detail === 2) {  // Double click
            textObj.enterEditing();
            editor.canvas.setActiveObject(textObj);
            editor.canvas.renderAll();
          }
        });

        // Log editing events
        textObj.on("editing:entered", () => {
          console.log("Entered editing mode for text:", textObj);
        });

        textObj.on("editing:exited", () => {
          console.log("Exited editing mode for text:", textObj);
        });

        // Allow movement
        textObj.set({
          lockMovementX: false,
          lockMovementY: false,
        });

        if (printableArea) {
          textObj.set('clipPath', printableArea);
        }
      }

      // Attach image upload listener for group objects
      if (obj.type === "group") {
        const group = obj as fabric.Group;
        group.on("mousedown", () => {
          const imageInput = document.createElement('input');
          imageInput.type = 'file';
          imageInput.accept = 'image/*';
          imageInput.style.display = 'none';
          imageInput.addEventListener('change', function (e) {
            const file = (e.target as HTMLInputElement).files?.[0];
            const reader = new FileReader();

            reader.onload = function (event) {
              const imgObj = new Image();
              imgObj.src = event.target?.result as string;

              imgObj.onload = function () {
                if (editor.canvas && imgObj.width && imgObj.height && group) {
                  const widthFix = (group?.scaleX && group?.width) ? group?.width * group?.scaleX : group?.width!;
                  const heightFix = (group?.scaleY && group?.height) ? group?.height * group?.scaleY : group?.height!;

                  const img = new fabric.Image(imgObj);
                  img.set({
                    left: group.left,
                    top: group.top,
                    scaleX: widthFix / imgObj.width,
                    scaleY: heightFix / imgObj.height,
                  });

                  editor.canvas.remove(group);
                  editor.canvas.add(img);
                  editor.canvas.renderAll();
                }
              };
            };

            if (file) {
              reader.readAsDataURL(file);
            }
          });

          imageInput.click();
        });

        group.on("scaling", () => {
          console.log("Group scaling event");
        });

        group.forEachObject((subObj: fabric.Object) => {
          if (subObj.type === "text" && (subObj as fabric.Text).text && ((subObj as fabric.Text).text as string).includes("click to select image")) {
            attachImageUploadListener(subObj);
          }
        });
      }
    });

    editor.canvas.on('object:moving', (e) => {
      const obj = e.target;
      if (obj && printableArea) {
        const clipRect = printableArea.getBoundingRect();
        const objRect = obj.getBoundingRect();
        
        if (objRect.left < clipRect.left) obj.set('left', clipRect.left);
        if (objRect.top < clipRect.top) obj.set('top', clipRect.top);
        if (objRect.left + objRect.width > clipRect.left + clipRect.width) obj.set('left', clipRect.left + clipRect.width - objRect.width);
        if (objRect.top + objRect.height > clipRect.top + clipRect.height) obj.set('top', clipRect.top + clipRect.height - objRect.height);
      }
      editor.canvas.renderAll();
    });

    editor.canvas.on('object:scaling', (e) => {
      const obj = e.target;
      if (obj && printableArea) {
        const clipRect = printableArea.getBoundingRect();
        const objRect = obj.getBoundingRect();
        
        if (objRect.left < clipRect.left) obj.set('left', clipRect.left);
        if (objRect.top < clipRect.top) obj.set('top', clipRect.top);
        if (objRect.left + objRect.width > clipRect.left + clipRect.width) obj.set('left', clipRect.left + clipRect.width - objRect.width);
        if (objRect.top + objRect.height > clipRect.top + clipRect.height) obj.set('top', clipRect.top + clipRect.height - objRect.height);
      }
      editor.canvas.renderAll();
    });

    editor.canvas.on('object:selected', (e) => {
      const obj = e.target;
      //  console.log('Object selected:', obj);
    });

    editor.canvas.renderAll();
  }; */

  /// funtions if we have single PRintableArea

  // const getPrintableArea = () => {
  //   if (!editor || !editor.canvas) return null;
  //   const objects = editor.canvas.getObjects();
  //   const area = objects.find(obj => (obj as any).id === 'printableArea') as fabric.Rect | fabric.Path;
  //   return area;
  // };


  // function getClippingRect() {
  //   const area = getPrintableArea();
  //   let clipdime
  //   if (area) {
  //     if(area.type==="rect"){
  //     const dime = area.getBoundingRect();
  //     clipdime = new fabric.Rect({
  //       left: dime.left,
  //       top: dime.top,
  //       width: dime.width,
  //       height: dime.height,
  //       absolutePositioned: true,
  //     })}else if(area.type === "path"){
  //       const pathData = (area as fabric.Path).path;
  //       clipdime = new fabric.Path(pathData, {
  //         left: area.left,
  //         top: area.top,
  //         angle:area.angle,
  //         scaleX:area.scaleX,
  //         scaleY:area.scaleY,
  //         absolutePositioned: true,
  //       });
  //     }
  //     return clipdime;
  //   }
  //   return null;
  // }

  // const handleGroupClick = (group: fabric.Group) => {
  //   setGroupSelect(group);
  //   setModalOpen(true);
  // };

  // const attachEventListeners = () => {
  //   if (!editor || !editor.canvas) return;

  //   editor.canvas.forEachObject((obj: fabric.Object) => {
  //     // Make sure all objects are selectable and have controls
  //     obj.set({
  //       selectable: true,
  //       hasControls: true,
  //       hasBorders: true,
  //     });

  //     if ((obj as any).id === 'printableArea') {
  //       obj.set({
  //         selectable: false,
  //         evented: false,
  //         hasControls: false,
  //         hasBorders: false,
  //       });
  //     }

  //     if (obj.type === "textbox" || obj.type === "text" || obj.type === "i-text") {
  //       const textObj = obj as fabric.IText;
  //       textObj.editable = true;
  //       textObj.evented = true;

  //       // Enable editing on double click
  //       textObj.on("mousedown", (e) => {
  //         if (e.e.detail === 2) {  // Double click
  //           textObj.enterEditing();
  //           editor.canvas.setActiveObject(textObj);
  //           editor.canvas.renderAll();
  //         }
  //       });

  //       // Log editing events
  //       textObj.on("editing:entered", () => {
  //         console.log("Entered editing mode for text:", textObj);
  //       });

  //       textObj.on("editing:exited", () => {
  //         console.log("Exited editing mode for text:", textObj);
  //       });

  //       // Allow movement
  //       textObj.set({
  //         lockMovementX: false,
  //         lockMovementY: false,
  //       });

  //       if (printableArea) {
  //         textObj.set('clipPath', printableArea);
  //       }
  //     }

  //     // Attach image upload listener for group objects
  //     if (obj.type === "group") {
  //       const group = obj as fabric.Group;

  //       group.on('mousedown', () => handleGroupClick(group));

  //       group.on("scaling", () => {
  //         console.log("Group scaling event");
  //       });

  //       group.forEachObject((subObj: fabric.Object) => {
  //         if (subObj.type === "text" && (subObj as fabric.Text).text && ((subObj as fabric.Text).text as string).includes("click to select image")) {
  //           attachImageUploadListener(subObj);
  //         }
  //       });
  //     }
  //   });
  //   editor.canvas.on('object:moving', (e) => {
  //     const obj = e.target;
  //     const clipRect = getClippingRect();

  //     if (obj && clipRect) {
  //       obj.clipPath = clipRect;
  //       editor.canvas.renderAll();
  //     }
  //   });

  //   editor.canvas.on('object:scaling', (e) => {
  //     const obj = e.target;
  //     const clipRect = getClippingRect();

  //     if (obj && clipRect) {
  //       obj.clipPath = clipRect;
  //       editor.canvas.renderAll();
  //     }
  //   });

  //   /*  editor.canvas.on('object:moving', (e) => {
  
  //      const obj = e.target;
  //      const area = getPrintableArea()
 
  //      if (obj && area) {
  //        const clipRect = area.getBoundingRect();
  //        const objRect = obj.getBoundingRect();
 
  //        if (objRect.left < clipRect.left) obj.set('left', clipRect.left);
  //        if (objRect.top < clipRect.top) obj.set('top', clipRect.top);
  //        if (objRect.left + objRect.width > clipRect.left + clipRect.width) obj.set('left', clipRect.left + clipRect.width - objRect.width);
  //        if (objRect.top + objRect.height > clipRect.top + clipRect.height) obj.set('top', clipRect.top + clipRect.height - objRect.height);
  //      }
  //      editor.canvas.renderAll();
  //    });
 
  //    editor.canvas.on('object:scaling', (e) => {
  //      const obj = e.target;
  //      const area = getPrintableArea()
  //      if (obj && area) {
  //        const clipRect = area.getBoundingRect();
  //        const objRect = obj.getBoundingRect();
         
  //        if (objRect.left < clipRect.left) obj.set('left', clipRect.left);
  //        if (objRect.top < clipRect.top) obj.set('top', clipRect.top);
  //        if (objRect.left + objRect.width > clipRect.left + clipRect.width) obj.set('left', clipRect.left + clipRect.width - objRect.width);
  //        if (objRect.top + objRect.height > clipRect.top + clipRect.height) obj.set('top', clipRect.top + clipRect.height - objRect.height);
  //      }
  //      editor.canvas.renderAll();
  //    });
 
  //    editor.canvas.on('object:selected', (e) => {
  //      const obj = e.target;
  //      //  console.log('Object selected:', obj);
  //    }); */

  //   editor.canvas.renderAll();
  // };
  // const addText = async () => {
  //   if (!editor) return;
  //   const area = printableArea?.getBoundingRect();
    
  //   // if (!area) return;
  //   const textProps: fabric.ITextboxOptions = {
  //     left: area?.left ? area?.left + 1 : 0,
  //     top: area?.top ? area?.top + 1 : 0,
  //     width: 120,
  //     fontSize: textOptions.fontSize,
  //     fontFamily: textOptions.fontFamily,
  //     fill: textOptions.textColor,
  //     fontStyle: textOptions.fontStyle,
  //     fontWeight: textOptions.fontWeight,
  //   };

  //   const text = new fabric.Textbox('Double click to edit', textProps);
  //   editor.canvas.add(text);

  //   text.on('mousedblclick', () => {
  //     text.enterEditing();
  //     text.selectAll();
  //   });

  //   editor.canvas.setActiveObject(text);
  //   editor.canvas.renderAll();
  // };

  /* import React, { useEffect, useRef, useState } from 'react';
  import { fabric } from 'fabric';
  
  const CanvasFixedPosition: React.FC = () => {
    const canvasRef = useRef<fabric.Canvas | null>(null);
    const [isDrawing, setIsDrawing] = useState(false);
  
    useEffect(() => {
      const canvas = new fabric.Canvas('drawingCanvas', {
        height: 600,
        width: 800,
        backgroundColor: 'white',
      });
      canvasRef.current = canvas;
  
      // Set up the brush for smoother drawing
      if (canvas.freeDrawingBrush) {
        canvas.freeDrawingBrush.color = 'black';  // Set the brush color
        canvas.freeDrawingBrush.width = 5;  // Set the brush width
        canvas.freeDrawingBrush.shadow = new fabric.Shadow({
          blur: 10,  // Adjust blur for smoother lines
          offsetX: 0,
          offsetY: 0,
          affectStroke: true,
          color: 'rgba(0, 0, 0, 0.3)',  // Adjust shadow color for smoother lines
        });
      }
  
      return () => {
        canvas.dispose();
      };
    }, []);
  
    const startDrawing = () => {
      if (canvasRef.current) {
        canvasRef.current.isDrawingMode = true;
        setIsDrawing(true);
      }
    };
  
    const stopDrawing = () => {
      console.log(canvasRef);
      
      if (canvasRef.current) {
        canvasRef.current.isDrawingMode = false;
        setIsDrawing(false);
      }
    };
  
    return (
      <div>
        <button onClick={startDrawing} disabled={isDrawing}>
          Start Drawing
        </button>
        <button onClick={stopDrawing} disabled={!isDrawing}>
          Stop Drawing
        </button>
        <canvas id="drawingCanvas" />
      </div>
    );
  };
  
  export default CanvasFixedPosition; */
/* 

  import React, { useEffect, useState } from "react";
import { fabric } from "fabric";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";

export default function CanvasFixedPosition() {
  const { editor, onReady } = useFabricJSEditor();
  const [color, setColor] = useState("#35363a");
  const [isDrawing, setIsDrawing] = useState(false);

  useEffect(() => {
    if (!editor || !fabric) {
      return;
    }

    if (isDrawing) {
      editor.canvas.isDrawingMode = true;
      editor.canvas.freeDrawingBrush.color = color;
    } else {
      editor.canvas.isDrawingMode = false;
    }

    editor.canvas.freeDrawingBrush.width = isDrawing ? 12 : 5;
    editor.canvas.renderAll();
  }, [isDrawing, color]);

  const toggleDraw = () => {
    setIsDrawing(!isDrawing);
    console.log(editor?.canvas);
    
  };

  return (
    <div className="App">
      <h1>FabricJS React Sample</h1>
      <button onClick={toggleDraw}>
        {isDrawing ? "Stop Drawing" : "Start Drawing"}
      </button>
      <label>
        <input
          type="color"
          value={color}
          onChange={(e) => setColor(e.target.value)}
        />
      </label>
      <div
        style={{
          border: "1px solid Green",
          width: "750px",
          height: "400px"
        }}
      >
        <FabricJSCanvas className="sample-canvas w-[700px] h-[600px] border-2 border-gray-900"  onReady={onReady} />
      </div>
    </div>
  );
} */

  
  import React, { useEffect, useState } from "react";
import { fabric } from "fabric";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";

export default function CanvasFixedPosition() {
  const { editor, onReady } = useFabricJSEditor();
  const [color, setColor] = useState("#35363a");
  const [isDrawing, setIsDrawing] = useState(false);

  useEffect(() => {
    if (!editor || !fabric) {
      return;
    }

    if (isDrawing) {
      editor.canvas.isDrawingMode = true;
      editor.canvas.freeDrawingBrush.color = color;
    } else {
      editor.canvas.isDrawingMode = false;
      convertFreeDrawingToPath();
    }

    editor.canvas.freeDrawingBrush.width = isDrawing ? 12 : 5;
    editor.canvas.renderAll();
  }, [isDrawing, color]);

  const toggleDraw = () => {
    setIsDrawing(!isDrawing);
  };

  const convertFreeDrawingToPath = () => {
    if(!editor) return;
    const objects = editor.canvas.getObjects('path');
    const freeDrawingPaths = editor.canvas.getObjects('freeDrawing');

    freeDrawingPaths.forEach((freeDrawing) => {
      const pathData = freeDrawing.toSVG();
      fabric.loadSVGFromString(pathData, (objects) => {
        objects.forEach((obj) => {
          editor.canvas.add(obj);
        });
        editor.canvas.remove(freeDrawing);
        editor.canvas.renderAll();
      });
    });
    
  };

  return (
    <div className="App">
      <h1>FabricJS React Sample</h1>
      <button onClick={toggleDraw}>
        {isDrawing ? "Stop Drawing" : "Start Drawing"}
      </button>
      <label>
        <input
          type="color"
          value={color}
          onChange={(e) => setColor(e.target.value)}
        />
      </label>
      <div
        style={{
          border: "1px solid Green",
          width: "750px",
          height: "400px"
        }}
      >
        <FabricJSCanvas className="sample-canvas w-[700px] h-[600px] border-2 border-gray-900" onReady={onReady} />
      </div>
    </div>
  );
}


// import {  ChangeEvent, useEffect, useRef, useState } from 'react';
// import { fabric } from 'fabric';
// import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
// import { Label } from '../../../components/ui/label';
// import { Input } from '../../../components/ui/input';
// import { fontFamiliesDefault } from '../../../constants';
// import { Button } from '../../../components/ui/button';
// import { IMGURL } from '../../../constants/appConfig';

// type canvasDesignCustomerprops={
//   canvasHeight:number,
//   canvasWidth:number,
//   designUrl:string
// }

// const CanvasDesignCustomer = ({canvasHeight,canvasWidth,designUrl}:canvasDesignCustomerprops) => {
//   const { editor, onReady } = useFabricJSEditor();
//   const [textOptions, setTextOptions] = useState({
//     fontSize: 20,
//     fontFamily: 'Arial',
//     textColor: '#000000',
//     fontStyle: 'normal' as 'normal' | 'italic' | 'oblique' | undefined,
//     fontWeight: 'normal' as 'normal' | 'bold' | 'bolder' | 'lighter' | number | undefined,
//     underline: false
//   });

//   const getPrintableArea = () => {
//     if (!editor || !editor.canvas) return null;
//     const objects = editor.canvas.getObjects();
//     const printableArea = objects.find(obj => (obj as any).id === 'printableArea');
//     return printableArea as fabric.Rect;
//   };

//   // Function to attach event listeners to text objects
//   const attachEventListeners = () => {
//       if (!editor || !editor.canvas) return;

//       editor.canvas.forEachObject((obj: fabric.Object) => {
//           // Make sure all objects are selectable and have controls
//           obj.set({
//               selectable: true,
//               hasControls: true,
//               hasBorders: true,
//           });

//           if ((obj as any).id === 'printableArea') {
//             obj.set({
//               selectable: false,
//               evented: false,
//               hasControls: false,
//               hasBorders: false,
//             });
//           }

//           if (obj.type === "textbox" || obj.type === "text" || obj.type === "i-text") {
//               const textObj = obj as fabric.IText;
//               textObj.editable = true;
//               textObj.evented = true;

//               // Enable editing on double click
//               textObj.on("mousedown", (e) => {
//                   if (e.e.detail === 2) {  // Double click
//                       textObj.enterEditing();
//                       editor.canvas.setActiveObject(textObj);
//                       editor.canvas.renderAll();
//                   }
//               });

//               // Log editing events
//               textObj.on("editing:entered", () => {
//                   console.log("Entered editing mode for text:", textObj);
//               });

//               textObj.on("editing:exited", () => {
//                   console.log("Exited editing mode for text:", textObj);
//               });

//               // Allow movement
//               textObj.set({
//                   lockMovementX: false,
//                   lockMovementY: false,
//               });
//           }

//           // Attach image upload listener for group objects
//           if (obj.type === "group") {
//               const group = obj as fabric.Group;
//               group.on("mousedown", () => {
//                   const imageInput = document.createElement('input');
//                   imageInput.type = 'file';
//                   imageInput.accept = 'image/*';
//                   imageInput.style.display = 'none';
//                   imageInput.addEventListener('change', function (e) {
//                       const file = (e.target as HTMLInputElement).files?.[0];
//                       const reader = new FileReader();

//                       reader.onload = function (event) {
//                           const imgObj = new Image();
//                           imgObj.src = event.target?.result as string;

//                           imgObj.onload = function () {
//                               if (editor.canvas && imgObj.width && imgObj.height && group) {
//                                   const widthFix = (group?.scaleX && group?.width) ? group?.width * group?.scaleX : group?.width!;
//                                   const heightFix = (group?.scaleY && group?.height) ? group?.height * group?.scaleY : group?.height!;

//                                   const img = new fabric.Image(imgObj);
//                                   img.set({
//                                       left: group.left,
//                                       top: group.top,
//                                       scaleX: widthFix / imgObj.width,
//                                       scaleY: heightFix / imgObj.height,
//                                   });

//                                   editor.canvas.remove(group);
//                                   editor.canvas.add(img);
//                                   editor.canvas.renderAll();
//                               }
//                           };
//                       };

//                       if (file) {
//                           reader.readAsDataURL(file);
//                       }
//                   });

//                   imageInput.click();
//               });

//               group.on("scaling", () => {
//                   console.log("Group scaling event");
//               });

//               group.forEachObject((subObj: fabric.Object) => {
//                   if (subObj.type === "text" && (subObj as fabric.Text).text && ((subObj as fabric.Text).text as string).includes("click to select image")) {
//                       attachImageUploadListener(subObj);
//                   }
//               });
//           }

//      });

//       editor.canvas.on('object:moving', (e) => {
//           const obj = e.target;
//         //  console.log('Object moving:', obj);
//       });

//       editor.canvas.on('object:scaling', (e) => {
//           const obj = e.target;
//         //  console.log('Object scaling:', obj);
//       });

//       editor.canvas.on('object:selected', (e) => {
//           const obj = e.target;
//         //  console.log('Object selected:', obj);
//       });

//       editor.canvas.renderAll();
//   };

//   useEffect(() => {
//       if (editor) {
//           attachEventListeners();
//       }
//   }, [editor]);

//   useEffect(() => {
//     if (editor && editor.canvas) {
//       const fetchDesign = async () => {
//         try {
//           const response = await fetch(`${IMGURL}${designUrl}`);
//           if (!response.ok) {
//             throw new Error('Network response was not ok');
//           }
//           const json = await response.json();
//           handleJSONImport(json);
//         } catch (error) {
//           console.error('Error fetching design JSON:', error);
//         }
//       };

//       fetchDesign();
//     }
//   }, [designUrl, editor]);

//   const handleJSONImport = (jsonData: any) => {
//     if (!editor || !editor.canvas) return;

//     try {
//       editor.canvas.clear();

//       editor.canvas.loadFromJSON(jsonData, () => {
//         editor.canvas.forEachObject((obj: fabric.Object) => {
//           obj.set({
//             selectable: true,
//             hasControls: true,
//             hasBorders: true,
//           });
//           if ((obj as any).id === 'printableArea') {
//             obj.set({
//               selectable: false,
//               evented: false,
//               hasControls: false,
//               hasBorders: false,
//             });
//           }
//           obj.off("mousedown");
//           obj.off("scaling");
//         });

//         attachEventListeners();
//         editor.canvas.renderAll();
//       });
//     } catch (error) {
//       console.error("Error parsing JSON:", error);
//     }
//   };

//   const attachImageUploadListener = (obj: fabric.Object) => {
//       const fileInput = document.createElement("input");
//       fileInput.type = "file";
//       fileInput.accept = "image/*";
//       fileInput.style.display = "none";
//       fileInput.onchange = (event) => handleImageUpload(event, obj);
//       document.body.appendChild(fileInput);

//       obj.on("mousedown", () => {
//           fileInput.click(); // Trigger file input click when object is clicked
//       });
//   };

//   const handleImageUpload = (event: Event, obj: fabric.Object) => {
//       if (!editor || !editor.canvas) return;
//       const input = event.target as HTMLInputElement;
//       if (input.files && input.files[0]) {
//           const file = input.files[0];
//           const reader = new FileReader();
//           reader.onload = (e) => {
//               const dataUrl = e.target?.result as string;
//               fabric.Image.fromURL(dataUrl, (img) => {
//                   img.set({
//                       left: obj.left || 0,
//                       top: obj.top || 0,
//                       scaleX: obj.scaleX || 1,
//                       scaleY: obj.scaleY || 1,
//                       angle: obj.angle || 0,
//                       opacity: obj.opacity || 1,
//                       selectable: true,
//                       hasControls: true,
//                       hasBorders: true,
//                   });

//                   editor.canvas.remove(obj); // Remove the original text object
//                   editor.canvas.add(img); // Add the uploaded image to the canvas
//                   editor.canvas.renderAll(); // Render the canvas to reflect changes
//               });
//           };
//           reader.readAsDataURL(file);
//       }
//   };

//   const handleImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
//     const files = event.target.files;
//     if (files && editor && editor.canvas) {
//       const printableArea = await getPrintableArea();
//       Array.from(files).forEach(file => {
//         const reader = new FileReader();
//         reader.onload = () => {
//           fabric.Image.fromURL(reader.result as string, (oImg: any) => {
//             oImg.scaleToWidth(100);
//             oImg.scaleToHeight(100);
//             if (printableArea) {
//               oImg.set({
//                 left: printableArea.left || 0,
//                 top: printableArea.top || 0
//               });
//             } else {
//               oImg.set({
//                 left: 20,
//                 top: 20
//               });
//             }
//             editor.canvas.add(oImg);
//           });
//         };
//         reader.readAsDataURL(file);
//       });
//     }
//   };

//   const addText = async() => {
//     if (!editor) return;
//     const printableArea = await getPrintableArea();
//     const textProps: fabric.ITextboxOptions = {
//       left: 20,
//       top: 20,
//       width: 150,
//       fontSize: textOptions.fontSize,
//       fontFamily: textOptions.fontFamily,
//       fill: textOptions.textColor,
//       fontStyle: textOptions.fontStyle,
//       fontWeight: textOptions.fontWeight,
//     };
//     if (printableArea) {
//       textProps.left = printableArea.left? printableArea.left+1 : 0;
//       textProps.top = printableArea.top? printableArea.top+1 : 0;
//     }

//     const text = new fabric.Textbox('Double click to edit', textProps);
//     editor.canvas.add(text);

//     text.on('mousedblclick', () => {
//       text.enterEditing();
//       text.selectAll();
//     });

//     editor.canvas.setActiveObject(text);
//     editor.canvas.renderAll();
//   };

//   const handleFontSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setTextOptions({ ...textOptions, fontSize: parseInt(e.target.value) });
//     updateActiveText({ fontSize: parseInt(e.target.value) });
//   };

//   const handleFontFamilyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
//     setTextOptions({ ...textOptions, fontFamily: e.target.value });
//     updateActiveText({ fontFamily: e.target.value });
//   };

//   const handleTextColorChange = (color: string) => {
//     setTextOptions({ ...textOptions, textColor: color });
//     updateActiveText({ fill: color });
//   };

//   const handleFontStyleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
//     const fontStyle = e.target.value as 'normal' | 'italic' | 'oblique';
//     setTextOptions({ ...textOptions, fontStyle });
//     updateActiveText({ fontStyle });
//   };

//   const handleFontWeightChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
//     const fontWeight = e.target.value as 'normal' | 'bold' | 'bolder' | 'lighter' | number;
//     setTextOptions({ ...textOptions, fontWeight });
//     updateActiveText({ fontWeight });
//   };

//   const handleUnderlineChange = () => {
//     setTextOptions({ ...textOptions, underline: !textOptions.underline });
//     updateActiveText({ underline: !textOptions.underline });
//   };

//   const updateActiveText = (options: Partial<fabric.ITextboxOptions>) => {
//     if (!editor) return;
//     const activeObject = editor.canvas.getActiveObject() as fabric.Textbox;
//     if (activeObject && activeObject.type === 'textbox') {
//       activeObject.set(options);
//       editor.canvas.renderAll();
//     }
//   };

//   return (
//     <div className='flex gap-10'>
//      {(canvasHeight&&canvasWidth) && <FabricJSCanvas className={`border-2 border-gray-800 w-[${canvasWidth}px] h-[${canvasHeight}px]`} onReady={onReady} />}
//       <div className="flex flex-col w-[300px] gap-4">
//      <div className='flex flex-col gap-3 w-full my-2'>
//           <p className="font-bold text-lg">Text Field:</p>
//           <div className="flex flex-wrap gap-6 items-center">
//             <Button type='button' onClick={addText} size={"sm"} className="bg-blue-500 hover:bg-blue-700">
//               Add Text
//             </Button>
//             <Label className='flex  items-center'>
//               Font Size:
//               <Input type="range" min="10" max="50" className='w-32' value={textOptions.fontSize} onChange={handleFontSizeChange} />
//               {textOptions.fontSize}
//             </Label>
//             <Label>
//               Font:
//               <select value={textOptions.fontFamily} onChange={handleFontFamilyChange} className='h-8 w-32 rounded-lg'>
//                 {fontFamiliesDefault.map((family:string) => <option value={family} key={family}>{family}</option>)}
//               </select>
//             </Label>
//             <Label className='flex gap-1 items-center'>
//               Text Color:
//               <Input type="color" value={textOptions.textColor} onChange={(e) => handleTextColorChange(e.target.value)} />
//             </Label>
//             <Label className='flex gap-2 items-center'>
//               Font Style:
//               <select value={textOptions.fontStyle} onChange={handleFontStyleChange} className='h-8 w-28 rounded-lg'>
//                 <option value="normal">Normal</option>
//                 <option value="italic">Italic</option>
//                 <option value="oblique">Oblique</option>
//               </select>
//             </Label>
//             <Label>
//               Font Weight:
//               <select value={textOptions.fontWeight} onChange={handleFontWeightChange} className='h-8 w-28 rounded-lg'>
//                 <option value="normal">Normal</option>
//                 <option value="bold">Bold</option>
//                 <option value="bolder">Bolder</option>
//                 <option value="lighter">Lighter</option>
//               </select>
//             </Label>
//             <Label className='flex items-center gap-2'>
//               <Input type="checkbox" checked={textOptions.underline} onChange={handleUnderlineChange} />
//               Underline
//             </Label>
//           </div>
//       </div>
//       <div>
//             <Label htmlFor="picture">Upload Custom Image</Label>
//             <Input id="picture" type="file" placeholder='Select own image' onChange={handleImageChange} />
//        </div>
//       </div>
//     </div>
//   );
// };

// export default CanvasDesignCustomer;











//////////////Main
// const CanvasDesignCustomer = ({ canvasHeight, canvasWidth, designTemplate }: CanvasDesignCustomerProps) => {
//   const { editor, onReady } = useFabricJSEditor();
//   const [textOptions, setTextOptions] = useState({
//     fontSize: 20,
//     fontFamily: 'Arial',
//     textColor: '#000000',
//     fontStyle: 'normal' as 'normal' | 'italic' | 'oblique' | undefined,
//     fontWeight: 'normal' as 'normal' | 'bold' | 'bolder' | 'lighter' | number | undefined,
//     textAlign: 'left',
//     underline: false,
//     text: "Edit text You Added"
//   });
//   const [sizesCount, setSizesCount] = useState({ S: 0, M: 0, L: 0, XL: 0, XXL: 0 })
//   const { data: productAttributes } = useGetProductAttributesQuery(designTemplate.categoryId)
//   const attr = productAttributes?.result[0]
//   const hexColors = attr?.attributeValue && JSON.parse(attr?.attributeValue)
//   const [modalOpen, setModalOpen] = useState(false);
//   const [sheetOpen, setSheetOpen] = useState(false);
//   const [groupSelect, setGroupSelect] = useState({});
//   const [printableAreas, setPrintableAreas] = useState<(fabric.Rect | fabric.Path)[]>([]);
//   const [sideSelected, setSideSelected] = useState<Side>("front");
//   const [selectedColor, setSelectedColor] = useState<string>("");
//   const [loadNextJson, setLoadNextJson] = useState<boolean>(false);
//   const [triggerAddCart, setTriggerAddCart] = useState<boolean>(false);
//   const [activateSide, setActivateSide] = useState<Record<SidesActive, boolean>>({ front: true, back: false, sides: false });
//   const [canvasSides, setCanvasSides] = useState<Record<Side, SideState>>({
//     front: { canvasUrl: designTemplate.templateUrl, previewUrl: designTemplate.templatePreviewUrl },
//     back: { canvasUrl: designTemplate.templateBackUrl, previewUrl: "" },
//     left: { canvasUrl: designTemplate.templateLeftSideUrl, previewUrl: "" },
//     right: { canvasUrl: designTemplate.templateRightSideUrl, previewUrl: "" },
//   })
//   const [cartCanvasSides, setCartCanvasSides] = useState<Record<Side, SideState>>({
//     front: { canvasUrl: "", previewUrl: "" },
//     back: { canvasUrl: "", previewUrl: "" },
//     left: { canvasUrl: "", previewUrl: "" },
//     right: { canvasUrl: "", previewUrl: "" },
//   })
//   const [textDialogOpen, setTextDialogOpen] = useState<boolean>(false)
//   const [loadingPreview, setLoadingPreview] = useState<boolean>(false)
//   const [quantity, setQuantity] = useState(1)
//   const currentUser = JSON.parse(localStorage.getItem("pfuser")!);
//   const [AddItemCart] = useAddItemCartMutation()
//   const dispatch = useDispatch()
//   const [textFieldObjects, setTextFieldObjects] = useState<any[]>([]);


//   const getPrintableAreas = (): (fabric.Rect | fabric.Path)[] => {
//     if (!editor || !editor.canvas) return [];
//     const objects = editor.canvas.getObjects();
//     return objects.filter(obj => (obj as any).id === 'printableArea') as (fabric.Rect | fabric.Path)[];
//   };

//   const getClippingRects = () => {
//     const areas = getPrintableAreas();
//     return areas.map(area => {
//       if (area.type === 'rect') {
//         const dime = area.getBoundingRect();
//         return new fabric.Rect({
//           left: dime.left,
//           top: dime.top,
//           width: dime.width,
//           height: dime.height,
//           absolutePositioned: true,
//         });
//       } else if (area.type === 'path') {
//         const pathData = (area as fabric.Path).path;
//         return new fabric.Path(pathData, {
//           left: area.left,
//           top: area.top,
//           angle: area.angle,
//           scaleX: area.scaleX,
//           scaleY: area.scaleY,
//           absolutePositioned: true,
//         });
//       }
//       return null;
//     }).filter(clip => clip !== null) as (fabric.Rect | fabric.Path)[];
//   };


//   const handleGroupClick = (group: fabric.Group) => {
//     setGroupSelect(group);
//     setModalOpen(true);
//   };

//   const openTextEditDialog = (textObject: any) => {
//     setTextOptions(pre => ({ ...pre, text: textObject.text }))
//     setTextDialogOpen(true);
//   };

//   const attachEventListeners = () => {
//     if (!editor || !editor.canvas) return;
//     const objectsWithTextField = editor.canvas.getObjects()
//       .filter(obj => obj.type === 'textbox' && (obj as any).textField) as fabric.Textbox[];
//     setTextFieldObjects(objectsWithTextField);

//     editor.canvas.forEachObject((obj: fabric.Object) => {
//       obj.set({
//         selectable: true,
//         hasControls: true,
//         hasBorders: true,
//       });

//       if ((obj as any).id === 'printableArea') {
//         obj.set({
//           selectable: false,
//           evented: false,
//           hasControls: false,
//           hasBorders: false,
//         });
//       }

//       if (obj.type === 'textbox' || obj.type === 'text' || obj.type === 'i-text') {
//         const textObj = obj as fabric.IText;
//         textObj.editable = true;
//         textObj.evented = true;

//         textObj.on('mousedown', (e) => {
//           if (e.e.detail === 2) {
//             textObj.enterEditing();
//             editor.canvas.setActiveObject(textObj);
//             editor.canvas.renderAll();
//           }
//         });

//         textObj.on('editing:entered', () => {
//           console.log('Entered editing mode for text:', textObj);
//         });

//         textObj.on('editing:exited', () => {
//           console.log('Exited editing mode for text:', textObj);
//         });

//         textObj.set({
//           lockMovementX: false,
//           lockMovementY: false,
//         });

//         const clipRects = getClippingRects();
//         if (clipRects.length > 0) {
//           textObj.set('clipPath', new fabric.Group(clipRects, {
//             absolutePositioned: true,
//           }));
//         }
//       }

//       if (obj.type === 'group') {
//         const group = obj as fabric.Group;

//         group.on('mousedown', () => handleGroupClick(group));

//         group.on('scaling', () => {
//           console.log('Group scaling event');
//         });

//         group.forEachObject((subObj: fabric.Object) => {
//           if (subObj.type === 'text' && (subObj as fabric.Text).text && ((subObj as fabric.Text).text as string).includes('click to select image')) {
//             attachImageUploadListener(subObj);
//           }
//         });
//       }
//     });

//     if (selectedColor.length > 0) {
//       editor.canvas.setBackgroundColor(selectedColor, () => { editor.canvas.renderAll(); });
//     }

//     editor.canvas.on('object:moving', (e) => {
//       const obj = e.target;
//       const clipRects = getClippingRects();
//       if (obj && clipRects.length > 0) {
//         obj.clipPath = new fabric.Group(clipRects, {
//           absolutePositioned: true,
//         });
//         editor.canvas.renderAll();
//       }

//     });

//     editor.canvas.on('object:scaling', (e) => {
//       const obj = e.target;
//       const clipRects = getClippingRects();
//       if (obj && clipRects.length > 0) {
//         obj.clipPath = new fabric.Group(clipRects, {
//           absolutePositioned: true,
//         });
//         editor.canvas.renderAll();
//       }
//     });

//     editor.canvas.on('selection:created', (e) => {
//       const selectedObject = e?.selected && e?.selected[0];
//       if (selectedObject && (selectedObject.type === 'textbox' || selectedObject.type === 'text' || selectedObject.type === 'i-text')) {
//         openTextEditDialog(selectedObject);
//       }
//     });
//     editor.canvas.on('selection:updated', (e) => {
//       const selectedObject = e?.selected && e?.selected[0];
//       if (selectedObject && (selectedObject.type === 'textbox' || selectedObject.type === 'text' || selectedObject.type === 'i-text')) {
//         openTextEditDialog(selectedObject);
//       }
//     });

//     editor.canvas.on('selection:cleared', () => {
//       setTextDialogOpen(false)
//       setTextOptions(pre => ({ ...pre, text: "Edit Your text Added" }))
//     });

//     editor.canvas.renderAll();
//   };

//   useEffect(() => {
//     if (editor) {
//       attachEventListeners();
//       const handleKey = (e: KeyboardEvent) => {
//         if (e.key === "Delete") {
//           const object = editor.canvas.getActiveObject();
//           if (object) editor.canvas.remove(object);
//         }
//       };

//       window.addEventListener("keyup", handleKey);

//       return () => {
//         window.removeEventListener("keyup", handleKey);
//       };
//     }
//   }, [editor]);


//   useEffect(() => {
//     const sideToLoadJson = canvasSides[sideSelected];
//     if (cartCanvasSides[sideSelected].canvasUrl) {
//       handleJSONImport(cartCanvasSides[sideSelected].canvasUrl)
//     }
//     else if (editor && editor.canvas && sideToLoadJson.canvasUrl) {
//       const fetchDesign = async () => {
//         try {
//           const response = await fetch(`${IMGURL}${sideToLoadJson.canvasUrl}`);
//           if (!response.ok) {
//             throw new Error('Network response was not ok');
//           }
//           const json = await response.json();
//           handleJSONImport(json);
//         } catch (error) {
//           console.error('Error fetching design JSON:', error);
//         }
//       };

//       fetchDesign();
//     }
//   }, [editor, canvasSides, loadNextJson]);


//   const handleJSONImport = (jsonData: any) => {
//     if (!editor || !editor.canvas) return;

//     try {
//       editor.canvas.clear();

//       editor.canvas.loadFromJSON(jsonData, async () => {
//         editor.canvas.forEachObject((obj: fabric.Object) => {
//           obj.set({
//             selectable: true,
//             hasControls: true,
//             hasBorders: true,
//           });

//           if ((obj as any).id === 'printableArea') {
//             obj.set({
//               selectable: false,
//               evented: false,
//               hasControls: false,
//               hasBorders: false,
//             });
//           }
//           obj.off("mousedown");
//           obj.off("scaling");
//         });

//         const areas = getPrintableAreas();
//         setPrintableAreas(areas);

//         attachEventListeners();
//         editor.canvas.renderAll();
//       });
//     } catch (error) {
//       console.error("Error parsing JSON:", error);
//     }
//   };

//   const attachImageUploadListener = (obj: fabric.Object) => {
//     const fileInput = document.createElement("input");
//     fileInput.type = "file";
//     fileInput.accept = "image/*";
//     fileInput.style.display = "none";
//     fileInput.onchange = (event) => handleImageUpload(event, obj);
//     document.body.appendChild(fileInput);

//     obj.on("mousedown", () => {
//       fileInput.click(); // Trigger file input click when object is clicked
//     });
//   };

//   const handleImageUpload = (event: Event, obj: fabric.Object) => {
//     if (!editor || !editor.canvas) return;
//     const input = event.target as HTMLInputElement;
//     if (input.files && input.files[0]) {
//       const file = input.files[0];
//       const reader = new FileReader();
//       reader.onload = (e) => {
//         const imgElement = new Image();
//         imgElement.src = e.target?.result as string;
//         imgElement.onload = () => {
//           const image = new fabric.Image(imgElement);
//           image.scaleToWidth(obj.width ?? 0);
//           image.scaleToHeight(obj.height ?? 0);
//           image.set({
//             left: obj.left,
//             top: obj.top,
//           });
//           editor.canvas.remove(obj);
//           editor.canvas.add(image);
//           editor.canvas.renderAll();
//         };
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   const addText = async () => {
//     if (!editor) return;
//     const area = printableAreas[0]?.getBoundingRect();

//     // if (!area) return;
//     const textProps: fabric.ITextboxOptions = {
//       left: area?.left ? area?.left + 1 : 0,
//       top: area?.top ? area?.top + 1 : 0,
//       width: 120,
//       fontSize: textOptions.fontSize,
//       fontFamily: textOptions.fontFamily,
//       fill: textOptions.textColor,
//       fontStyle: textOptions.fontStyle,
//       fontWeight: textOptions.fontWeight,
//       textAlign: textOptions.textAlign,
//     };

//     const text = new fabric.Textbox(textOptions.text, textProps);
//     editor.canvas.add(text);

//     text.on('mousedblclick', () => {
//       text.enterEditing();
//       text.selectAll();
//     });

//     // editor.canvas.setActiveObject(text);
//     editor.canvas.renderAll();
//   };
//   /*   const handleColorChange = (color: string) => {
//       if(!editor) return;
  
//         editor.canvas.setBackgroundColor(color, () => { editor.canvas.renderAll(); });
//         setSelectedColor(color) // Set the background color
  
//     }; */

//   const handleColorChange = (color: string) => {
//     if (!editor) return;
//     if (designTemplate.categoryId === 1) {
//       const confirmation = (activateSide.back || activateSide.sides) ? window.confirm(`Click on the print sides of bottom buttons to reflect the selected color.`) : true;
//       if (confirmation) {
//         editor.canvas.setBackgroundColor(color, () => {
//           editor.canvas.renderAll();
//         });
//         setSelectedColor(color);
//       }
//     }
//     editor.canvas.setBackgroundColor(color, () => {
//       editor.canvas.renderAll();
//     });
//     setSelectedColor(color);
//   };


//   const handleChangeSides = async (value: Side) => {
//     if (!editor) return;

//     const json = await JSON.stringify(editor.canvas.toJSON(['id', 'attachEventListeners']));
//     const base64Image = await editor.canvas.toDataURL();
//     //const base64Image = "";
//     await setCartCanvasSides((prev: any) => ({
//       ...prev,
//       [sideSelected]: { previewUrl: base64Image, canvasUrl: json },
//     }));

//     setSideSelected(value);

//     const selectedSide = cartCanvasSides[value];
//     if (selectedSide?.canvasUrl) {
//       // await editor.canvas.loadFromJSON(selectedSide.canvasUrl, editor.canvas.renderAll.bind(editor.canvas));
//       handleJSONImport(selectedSide.canvasUrl)
//     } else {
//       setLoadNextJson(pre => !pre)
//     }
//   };

//   const handleLastSide = async (value: Side) => {
//     if (!editor) return;

//     const json = await JSON.stringify(editor.canvas.toJSON(['id', 'attachEventListeners']));
//     const base64Image = await editor.canvas.toDataURL();
//     //const base64Image = "";
//     await setCartCanvasSides((prev: any) => ({
//       ...prev,
//       [sideSelected]: { previewUrl: base64Image, canvasUrl: json },
//     }));

//     setSideSelected(value);
//     handleJSONImport(json)
//     setLoadingPreview(true)
//     setTimeout(() => { setTriggerAddCart(true); setLoadingPreview(false) }, 4000)
//   };

//   const handleAddCartBefore = async () => {
//     await handleLastSide('front');
//     // setTimeout(()=>{ setLoading(true)},1000)
//   };

//   const handleAddCart = async () => {
//     const totalQty = designTemplate.categoryId === 1 ? sizesCount.L + sizesCount.M + sizesCount.S + sizesCount.XL + sizesCount.XXL : quantity
//     const priceINRUpdated = designTemplate.priceINR + (activateSide?.back ? 100 : 0) + (activateSide?.sides ? 100 : 0);
//     if (totalQty <= 0) { toast.error("Count need to be atleast one"); return; }
//     if (currentUser?.token) {
//       try {
//         const response = await AddItemCart({ customerId: currentUser.refId, productId: designTemplate.productId, qty: totalQty, orderDesign: JSON.stringify(cartCanvasSides), orderAttributes: JSON.stringify({ ...sizesCount, Color: selectedColor, activateSide }), readyToBuy: 1 });
//         if ('error' in response) {
//           console.error('error:', response.error);
//           dispatch(addProductToCart({ ...designTemplate, priceINR: priceINRUpdated, orderDesign: { ...cartCanvasSides }, totalQty, cartId: uuidv4(), orderAttributes: { ...sizesCount, Color: selectedColor, ...activateSide } }))
//           return;
//         }
//         const { success, result } = response?.data;

//         //console.log(success, result);

//         /*  if (success && result)navigate("/cart") */
//       } catch (error) {
//         console.error('Error adding to cart:', error);
//       }
//     } else {
//       dispatch(addProductToCart({ ...designTemplate, priceINR: priceINRUpdated, orderDesign: { ...cartCanvasSides }, totalQty, cartId: uuidv4(), orderAttributes: { ...sizesCount, Color: selectedColor, ...activateSide } }))
//     }
//     toast.success("Added to cart")
//   };
/*   useEffect(() => {
    if (editor && editor.canvas) {
      const screenWidth = window.outerWidth;
      const originalWidth = canvasWidth;
      const originalHeight = canvasHeight;

      if (screenWidth < originalWidth) {
        const newCanvasWidth = screenWidth - 12;
        const scaleRatio = newCanvasWidth / originalWidth;

        const newCanvasHeight = originalHeight * scaleRatio;
  
        editor.canvas.setWidth(newCanvasWidth - 4);
        editor.canvas.setHeight(newCanvasHeight - 4);
      
        editor.canvas.getObjects().forEach((obj) => {
          obj.scaleX = (obj.scaleX ?? 1) * scaleRatio;
          obj.scaleY = (obj.scaleY ?? 1) * scaleRatio;
          obj.left = (obj.left ?? 0) * scaleRatio;
          obj.top = (obj.top ?? 0) * scaleRatio;
  
          if (obj.type === 'textbox' || obj.type === 'text' || obj.type === 'i-text') {
            const textObj = obj as fabric.Textbox;
            textObj.fontSize = (textObj.fontSize ?? 12) * scaleRatio;
          }
  
          obj.setCoords();
        });
       
        const backgroundImage = editor.canvas.backgroundImage;
        
        if (backgroundImage && typeof backgroundImage !== 'string') {
          backgroundImage.scaleX = (backgroundImage.scaleX ?? 1) * scaleRatio;
          backgroundImage.scaleY = (backgroundImage.scaleY ?? 1) * scaleRatio;
        }
  
        editor.canvas.renderAll();
      }else{
        editor.canvas.setWidth(canvasWidth - 4);
        editor.canvas.setHeight(canvasHeight - 4);
        editor.canvas.renderAll();
      }
    }
  }, [editor, canvasWidth, canvasHeight]); */
  
//   useEffect(() => {
//     if (editor && editor.canvas) {
//       const screenWidth = window.innerWidth;
//       //const screenWidth = window.outerWidth;
//       const newCanvasWidth = screenWidth < canvasWidth ? screenWidth - 12 : canvasWidth;
//       editor.canvas.setWidth(newCanvasWidth - 4);
//       editor.canvas.setHeight(canvasHeight - 4);
//       editor.canvas.renderAll();
//     }
//   }, [editor, canvasWidth, canvasHeight]);

//   /*   const handleCanvasReady = (canvas:any) => {
//       onReady(canvas);
//       const screenWidth = window.innerWidth;
//       console.log(screenWidth);
//       const newCanvasWidth = screenWidth < canvasWidth ? screenWidth : canvasWidth;
//       canvas.setWidth(newCanvasWidth);
//       canvas.setHeight(canvasHeight);
//       canvas.renderAll();
//     }; */

//   const handleInputFocus = (textObj: fabric.Textbox) => {
//     if (!editor) return;
//     console.log(textFieldObjects);
//     editor.canvas.setActiveObject(textObj);
//     editor.canvas.renderAll();
//   };

//   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, textObj: any) => {
//     setTextOptions({ ...textOptions, text: e.target.value });
//     updateActiveText({ text: e.target.value });
//   };

//   const updateActiveText = (options: Partial<fabric.ITextboxOptions>) => {
//     if (!editor) return;
//     const activeObject = editor.canvas.getActiveObject() as  fabric.Textbox & { textField?: string };
//     if (activeObject && activeObject.type === 'textbox') {
//       setTextFieldObjects((prevObjects: any[]) =>
//         prevObjects.map((obj: any) => {
//             if (obj.textField === activeObject.textField) {
//                 return activeObject;
//             }
//             return obj;
//         }));
//         activeObject.set(options);
//         editor.canvas.renderAll();
//   }};
//   const handleUnSelect = () => {
//     if (!editor) return;
//     editor.canvas.discardActiveObject();
//     editor.canvas.renderAll();
//     setTextDialogOpen(false);
//     setTextOptions((prev: any) => ({ ...prev, text: "Edit Your text Added" }));
//   };

//   return (<div className='flex sm:flex-row flex-col gap-8 relative'>
//     {!triggerAddCart ? <div className='max-w-full relative'>
//       <div>
//         {textFieldObjects.map((textObj, index) => (
//           <div  key={index} className="flex items-center border-2 my-2 p-[2px] border-gray-400 rounded-md">
//           <span className="px-3 py-2 bg-blue-500 text-white"><IoText /></span>
//           <Input className="border-none  focus-visible:ring-0 focus-visible:ring-transparent "  
//           value={textObj.text}
//             onFocus={() => handleInputFocus(textObj)}
//             onBlur={()=>handleUnSelect()}
//             onChange={(e) => handleInputChange(e, textObj)} />
//         </div>
//         ))}
//       </div>
//       <div className='grid grid-cols-2'>
//         <Button type='button' variant={"outline"} onClick={addText} size={"sm"} className="bg-orange-600 text-white hover:bg-orange-800 px-4 py-2 rounded-md">
//           Add Text
//         </Button>
//         <Button variant={"outline"} className='bg-orange-600 text-white hover:bg-orange-700 px-4 py-2 rounded-md' size={"sm"} onClick={() => setSheetOpen(true)}>Upload Image</Button>
//       </div>
//       {textDialogOpen && <TextEditDialog setTextFieldObjects={setTextFieldObjects} setTextOptions={setTextOptions} textOptions={textOptions} editor={editor} setTextDialogOpen={setTextDialogOpen} />}
//       {(canvasHeight && canvasWidth) && <FabricJSCanvas className={`border-2 shadow-lg  border-gray-800`} onReady={onReady} />}
//       {/*  {(canvasHeight && canvasWidth) && <FabricJSCanvas className={`border-2 shadow-lg  border-gray-800 w-[${canvasWidth}px] h-[${canvasHeight}px]`} onReady={onReady} />} */}
//       {(designTemplate.categoryId === 1 && activateSide.front) && <div className="flex gap-3 my-2 justify-center">
//         {(activateSide.back || activateSide.sides) && <button onClick={() => { handleChangeSides('front') }} className={`text-sm p-1 px-2 rounded-md border-[1px] border-gray-500 ${sideSelected === "front" && "headermenu font-medium text-white"}`}>Front</button>}
//         {activateSide.back && <button onClick={() => { handleChangeSides('back') }} className={`text-sm p-1 px-2 rounded-md border-[1px] border-gray-500 ${sideSelected === "back" && "headermenu text-white font-medium"}`}>Back</button>}
//         {activateSide.sides && <button onClick={() => { handleChangeSides('left') }} className={`text-sm p-1 px-2 rounded-md border-[1px] border-gray-500 ${sideSelected === "left" && "headermenu font-medium text-white"}`}>Left</button>}
//         {activateSide.sides && <button onClick={() => { handleChangeSides('right') }} className={`text-sm p-1 px-2 rounded-md border-[1px] border-gray-500 ${sideSelected === "right" && "headermenu font-medium text-white"}`}>Right</button>}
//       </div>}
//     </div> : <PriveiwSides setTriggerAddCart={setTriggerAddCart} activateSide={activateSide} designTemplate={designTemplate} cartCanvasSides={cartCanvasSides} />}
//     <div className="flex flex-col gap-6 p-4 border w-full flex-grow  border-gray-300 rounded-lg shadow-lg">
//       {/*  {!triggerAddCart && (
//         <div className='flex flex-wrap gap-10 p-2 bg-gray-100 justify-between w-full rounded-lg'>
//           <p className="font-bold text-lg ">Add Field:</p>
//           <Button type='button' onClick={addText} size={"sm"} className="bg-orange-600 text-white hover:bg-orange-800 px-4 py-2 rounded-md">
//             Add Text
//           </Button>
//           <Button className='bg-orange-600 text-white hover:bg-orange-700 px-4 py-2 rounded-md' size={"sm"} onClick={() => setSheetOpen(true)}>Upload Image</Button>
//         </div>
//       )} */}
//       <Separator className='bg-gray-400 h-[3px]' />
//       <div className="flex flex-col gap-4">
//         {!triggerAddCart && (designTemplate.categoryId === 1 && <div className='flex gap-4'>
//           <p className='text-gray-600 font-semibold'>Print Sides:</p>
//           <div className="flex items-center space-x-2">
//             <Checkbox id="front" disabled={true} checked={activateSide.front}
//               onCheckedChange={async (value) => {
//                 setActivateSide(pre => ({ ...pre, back: Boolean(value) }));
//                 if (!value) {
//                   await handleChangeSides('front')
//                 }
//               }} />
//             <label
//               htmlFor="front"
//               className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
//             >
//               Front
//             </label>
//           </div>
//           <div className="flex items-center space-x-2">
//             <Checkbox id="back" checked={activateSide.back}
//               onCheckedChange={async (value) => {
//                 setActivateSide(pre => ({ ...pre, back: Boolean(value) }));
//                 if (!value) {
//                   await handleChangeSides('front')
// /*                     setCartCanvasSides(pre => ({ ...pre, back: { canvasUrl: "", previewUrl: "", side: false } }))
//  */                  }
//               }} />
//             <label
//               htmlFor="back"
//               className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
//             >
//               Back
//             </label>
//           </div>
//           <div className="flex items-center space-x-2">
//             <Checkbox id="sides" checked={activateSide.sides} onCheckedChange={async (value) => {
//               setActivateSide(pre => ({ ...pre, sides: Boolean(value) }));
//               if (!value) {
//                 await handleChangeSides('front')
//               }
//             }} />
//             <label
//               htmlFor="sides"
//               className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
//             >
//               Sides
//             </label>
//           </div>
//         </div>)}
//         {!triggerAddCart && (hexColors?.length > 0 && (
//           <div className='flex gap-3 flex-wrap items-center'>
//             <h4 className='font-medium text-md text-gray-700'>Color:</h4>
//             {hexColors?.map((color: string) => (
//               <Button
//                 key={color}
//                 value={color}
//                 className="rounded-full w-9 h-9 flex items-center justify-center shadow-md border border-gray-400"
//                 onClick={() => handleColorChange(color)}
//                 style={{ backgroundColor: color }}
//               />
//             ))}
//           </div>
//         ))}
//         <div className="flex flex-wrap gap-4 justify-between items-end">
//           {triggerAddCart && ((designTemplate?.categoryId === 1) ? (
//             <div className='flex flex-wrap gap-3  items-end'>
//               <div className="grid max-w-24 items-center gap-2">
//                 <Label htmlFor="s" className="font-semibold text-gray-700">S</Label>
//                 <Input type="number" min={0} className='focus-visible:ring-offset-0 focus-visible:ring-transparent border border-gray-400 rounded-md px-2 py-1' onChange={(e) => setSizesCount({ ...sizesCount, S: +e.target.value })} id="s" />
//               </div>
//               <div className="grid max-w-24 items-center gap-2">
//                 <Label htmlFor="M" className="font-semibold text-gray-700">M</Label>
//                 <Input type="number" min={0} className='focus-visible:ring-offset-0 focus-visible:ring-transparent border border-gray-400 rounded-md px-2 py-1' onChange={(e) => setSizesCount({ ...sizesCount, M: +e.target.value })} id="M" />
//               </div>
//               <div className="grid max-w-24 items-center gap-2">
//                 <Label htmlFor="L" className="font-semibold text-gray-700">L</Label>
//                 <Input type="number" min={0} className='focus-visible:ring-offset-0 focus-visible:ring-transparent border border-gray-400 rounded-md px-2 py-1' onChange={(e) => setSizesCount({ ...sizesCount, L: +e.target.value })} id="L" />
//               </div>
//               <div className="grid max-w-24 items-center gap-2">
//                 <Label htmlFor="XL" className="font-semibold text-gray-700">XL</Label>
//                 <Input type="number" min={0} className='focus-visible:ring-offset-0 focus-visible:ring-transparent border border-gray-400 rounded-md px-2 py-1' onChange={(e) => setSizesCount({ ...sizesCount, XL: +e.target.value })} id="XL" />
//               </div>
//               <div className="grid max-w-24 items-center gap-2">
//                 <Label htmlFor="2XL" className="font-semibold text-gray-700">2XL</Label>
//                 <Input type="number" min={0} className='focus-visible:ring-offset-0 focus-visible:ring-transparent  border border-gray-400 rounded-md px-2 py-1' onChange={(e) => setSizesCount({ ...sizesCount, XXL: +e.target.value })} id="2XL" />
//               </div>
//             </div>
//           ) :
//             <div className="flex border-[1px] border-gray-500 items-center gap-2 rounded-lg">
//               <Button variant="ghost" onClick={() => setQuantity((pre) => Math.max(1, pre - 1))} disabled={quantity === 1}>
//                 <FiMinus />
//               </Button>
//               <input
//                 type='number'
//                 min={1}
//                 className="w-20 border-none outline-none focus-visible:border-none focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0 text-center"
//                 value={quantity}
//                 onChange={(e) => setQuantity(+e.target.value)}
//               />
//               <Button variant="ghost" onClick={() => setQuantity((pre) => pre + 1)}>
//                 <FaPlus />
//               </Button>
//             </div>)}
//           {!triggerAddCart ? <Button size={"sm"} onClick={handleAddCartBefore} className='headermenu text-white hover:bg-blue-700 px-4 py-2 rounded-md justify-end mx-3'>Preview Design</Button> :
//             <Button size={"sm"} onClick={handleAddCart} className='headermenu text-white hover:bg-blue-700 px-4 py-2 rounded-md justify-end mx-3'>Add To Cart</Button>
//           }
//         </div>
//       </div>
//       <Separator className='bg-gray-400 h-[3px]' />
//       <div className="flex flex-col gap-2">
//         <div className="flex items-center justify-between gap-4">
//           {designTemplate?.productName && (
//             <p className='font-semibold text-md '>{designTemplate?.productName}</p>
//           )}
//           {designTemplate?.priceINR && (
//             <div className='flex gap-2'>
//               <p className='font-semibold text-md text-gray-600'>Price:</p>
//               <p className='mr-2 text-md font-semibold '>{Intl.NumberFormat("en-us", { style: "currency", currency: "INR" }).format(designTemplate?.priceINR + (activateSide.back ? 100 : 0) + (activateSide.sides ? 100 : 0))}</p>
//             </div>
//           )}
//         </div>
//         {designTemplate?.productDescription && (
//           <div>
//             <p className='font-semibold text-md text-gray-600'>Description:</p>
//             <div className='mx-4 text-md text-gray-800'>{parse(designTemplate?.productDescription)}</div>
//           </div>
//         )}
//       </div>
//       {modalOpen && <SelectImage editor={editor} setModalOpen={setModalOpen} groupSelect={groupSelect} />}
//       {sheetOpen && <SelectImagePrintable editor={editor} setSheetOpen={setSheetOpen} printableAreas={printableAreas} />}
//     </div>
//     {loadingPreview && <LoadingPreview />}
//   </div>
//   )
// };


// export function LoadingPreview() {
//   return (
//     <div className='absolute top-0 left-0 w-full h-screen bg-white opacity-50 shadow-lg z-50'>
//       <div className="flex justify-center items-center h-full">
//         <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-800"></div>
//       </div>
//     </div>
//   )
// }

// export const PriveiwSides = ({ cartCanvasSides, designTemplate, activateSide, setTriggerAddCart }: any) => {
//   const [sideSelected, setSelectedSide] = useState("front")
//   const [designPreview, setDesignPreview] = useState("")
//   const handleChangeSides = (side: string) => {
//     if (side === "left") {
//       setDesignPreview(designTemplate.previewLeftSideUrl)
//     }
//     if (side === "right") {
//       setDesignPreview(designTemplate.previewRightSideUrl)
//     }
//     if (side === "back") {
//       setDesignPreview(designTemplate.previewBackUrl)
//     }
//     setSelectedSide(side)
//   }
//   return <div className='md:w-[50%] max-w-full'>
//     <div className="flex gap-4 justify-between my-4 items-center">
//       <p className='font-semibold'>Preivew Image:</p>
//       <Button size={"sm"} variant={"link"} onClick={() => setTriggerAddCart(false)} className='font-semibold text-orange-500 hover:text-orange-700 '>Edit Design <MdEdit size={20} className='mx-1 ' /></Button>
//     </div>
//     <div className="flex gap-2 flex-col max-w-full ">
//       {cartCanvasSides?.front?.previewUrl && <img src={cartCanvasSides[sideSelected].previewUrl || `${IMGURL}${designPreview}`} alt="previewcart" className='w-full ' />}
//       {(designTemplate.categoryId === 1 && activateSide.front) && <div className="flex gap-3 my-2 justify-center">
//         {(activateSide?.back || activateSide?.sides) &&
//           <button onClick={() => { handleChangeSides('front') }} className={`text-sm p-1 px-2 rounded-md border-[1px] border-gray-500 ${sideSelected === "front" && "headermenu font-medium text-white"}`}>Front</button>}
//         {activateSide?.back && <button onClick={() => { handleChangeSides('back') }} className={`text-sm p-1 px-2 rounded-md border-[1px] border-gray-500 ${sideSelected === "back" && "headermenu text-white font-medium"}`}>Back</button>}
//         {activateSide?.sides && <button onClick={() => { handleChangeSides('left') }} className={`text-sm p-1 px-2 rounded-md border-[1px] border-gray-500 ${sideSelected === "left" && "headermenu font-medium text-white"}`}>Left</button>}
//         {activateSide?.sides && <button onClick={() => { handleChangeSides('right') }} className={`text-sm p-1 px-2 rounded-md border-[1px] border-gray-500 ${sideSelected === "right" && "headermenu font-medium text-white"}`}>Right</button>}
//       </div>}
//     </div>
//   </div>
// }

// const fontStyle = [{ id: 1, icon: <TbLetterN />, sty: 'normal' }, { id: 2, icon: <GoItalic />, sty: 'Italic' }]
// const fontWeight = [{ id: 1, icon: <TbLetterN />, wgt: 'normal' }, { id: 2, icon: <ImBold />, wgt: 'bold ' }]
// const textAlign = [
//   { id: 1, icon: <FaAlignLeft />, agn: 'left' },
//   { id: 2, icon: <FaAlignCenter />, agn: 'center' },
//   { id: 3, icon: <FaAlignRight />, agn: 'right' },
//   { id: 4, icon: <FaAlignJustify />, agn: 'justify' },
// ]
// export function TextEditDialog({ editor,setTextFieldObjects, setTextOptions, textOptions, setTextDialogOpen }: any) {

//   const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setTextOptions({ ...textOptions, text: e.target.value });
//     updateActiveText({ text: e.target.value });
//   };

//   const handleFontSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setTextOptions({ ...textOptions, fontSize: parseInt(e.target.value) });
//     updateActiveText({ fontSize: parseInt(e.target.value) });
//   };

//   const handleFontFamilyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
//     setTextOptions({ ...textOptions, fontFamily: e.target.value });
//     updateActiveText({ fontFamily: e.target.value });
//   };

//   const handleTextColorChange = (color: string) => {
//     setTextOptions({ ...textOptions, textColor: color });
//     updateActiveText({ fill: color });
//   };

//   const handleTextAlignmentChange = (alignment: string) => {
//     setTextOptions({ ...textOptions, textAlign: alignment });
//     updateActiveText({ textAlign: alignment });
//   };

//   const handleFontStyleChange = (style: 'normal' | 'italic') => {
//     setTextOptions({ ...textOptions, fontStyle: style });
//     updateActiveText({ fontStyle: style });
//   };

//   const handleFontWeightChange = (weight: 'normal' | 'bold' | number) => {
//     setTextOptions({ ...textOptions, fontWeight: weight });
//     updateActiveText({ fontWeight: weight });
//   };

//   const handleUnderlineChange = () => {
//     setTextOptions({ ...textOptions, underline: !textOptions.underline });
//     updateActiveText({ underline: !textOptions.underline });
//   };

//   const updateActiveText = (options: Partial<fabric.ITextboxOptions>) => {
//     if (!editor) return;
//     //const activeObject = editor.canvas.getActiveObject() as fabric.Textbox;
//     const activeObject = editor.canvas.getActiveObject() as  fabric.Textbox & { textField?: string };
//     if (activeObject && activeObject.type === 'textbox') {
//       setTextFieldObjects((prevObjects: any[]) =>
//         prevObjects.map((obj: any) => {
//             if (obj.textField === activeObject.textField) {
//                 return activeObject;
//             }
//             return obj;
//         })
//     );
//       activeObject.set(options);
//       editor.canvas.renderAll();
//     }
//   };

//   const handleUnSelect = () => {
//     editor.canvas.discardActiveObject();
//     editor.canvas.renderAll();
//     setTextDialogOpen(false);
//     setTextOptions((prev: any) => ({ ...prev, text: "Edit Your text Added" }));
//   };

//   return (
//     <div className={`absolute sm:right-[-425px] max-sm:bottom-[-350px] sm:top-0 sm:max-w-[425px] shadow-lg border-2 border-gray-600 z-10 bg-white p-2 rounded-lg`}>
//       <div className="flex justify-between gap-4 items-start">
//         <p className='pb-2 text-gray-800 font-semibold '>Edit Text:</p>
//         <Button onClick={handleUnSelect} variant={"link"} className='top-0 absolute right-0'><IoMdClose size={20} /></Button>
//       </div>
//       <div className="flex flex-wrap gap-4 items-center">
//         <Input type="text" className='w-full border-[1px] text-md font-medium border-gray-500 focus-visible:ring-transparent focus-visible:ring-0 ' value={textOptions.text} onChange={handleTextChange} />

//         <Label className='flex items-center gap-2'>
//           Font Size:
//           <Input type="range" min="10" max="50" className='w-28' value={textOptions.fontSize} onChange={handleFontSizeChange} />
//           <span className="font-semibold text-gray-700">{textOptions.fontSize}</span>
//         </Label>
//         <Label>
//           Font:
//           <select value={textOptions.fontFamily} onChange={handleFontFamilyChange} className='h-8 w-36 rounded-md border border-gray-400'>
//             {fontFamiliesDefault.map((family: string) => <option value={family} key={family}>{family}</option>)}
//           </select>
//         </Label>
//         <Label className='flex gap-2 items-center'>
//           Text Color:
//           <Input type="color" value={textOptions.textColor} onChange={(e) => handleTextColorChange(e.target.value)} className="w-[50px] h-9" />
//         </Label>
//         <Label className='flex gap-2 items-center'>
//           Font Style:
//           <div className='flex gap-2'>
//             {fontStyle.map(style => (
//               <Button key={style.id} variant={"link"} size={"sm"} onClick={() => handleFontStyleChange(style.sty as 'normal' | 'italic')}
//                 className={` rounded-md ${textOptions.fontStyle === style.sty && 'bg-blue-500 text-white'}`}>
//                 {style.icon}
//               </Button>
//             ))}
//             <Button variant={"link"} size={"sm"} className={`rounded-md ${textOptions.underline && 'bg-blue-500 text-white'}`}
//               onClick={handleUnderlineChange}><FaUnderline /></Button>
//           </div>
//         </Label>
//         <Label className='flex gap-2 items-center'>
//           Align Text:
//           <div>
//             {textAlign.map(alignment => (
//               <Button key={alignment.id} variant={"link"} onClick={() => handleTextAlignmentChange(alignment.agn)} className={` rounded-md ${textOptions.textAlign === alignment.agn && 'bg-blue-500 text-white'}`}>
//                 {alignment.icon}
//               </Button>
//             ))}
//           </div>
//         </Label>
//         <Label className='flex gap-2 items-center'>
//           Font Weight:
//           <div className='flex gap-2'>
//             {fontWeight.map(weight => (
//               <Button key={weight.id} variant={"link"} size={"sm"} onClick={() => handleFontWeightChange(weight.wgt as 'normal' | 'bold' | number)} className={`rounded-md ${textOptions.fontWeight === weight.wgt && 'bg-blue-500 text-white'}`}>
//                 {weight.icon}
//               </Button>
//             ))}
//             <Input
//               type="range"
//               min="100"
//               max="900"
//               step="100"
//               className='w-28'
//               value={typeof textOptions.fontWeight === 'number' ? textOptions.fontWeight : 400}
//               onChange={(e) => handleFontWeightChange(parseInt(e.target.value))}
//             />
//             <span className="font-semibold text-gray-700">
//               {typeof textOptions.fontWeight === 'number' ? textOptions.fontWeight : '400'}
//             </span>
//           </div>
//         </Label>
//       </div>
//     </div>
//   );
// }

// export default CanvasDesignCustomer;


//////////////////Mugs Design text options

//  <div className="flex flex-col gap-4 w-full p-2 rounded-lg shadow-md">
//         <p className="font-bold text-lg">Text Field:</p>
//         <div className="flex flex-wrap gap-4 items-center">
//           <Button
//             type="button"
//             onClick={addText}
//             size={"sm"}
//             className="bg-orange-600 hover:bg-orange-700 text-white px-3 py-1 rounded-lg"
//           >
//             Add Text
//           </Button>
//           <Label className="flex items-center gap-2">
//             Font Size:
//             <Input
//               type="range"
//               min="10"
//               max="50"
//               className="w-32"
//               value={textOptions.fontSize}
//               onChange={handleFontSizeChange}
//             />
//             {textOptions.fontSize}
//           </Label>
//           <Label className="flex items-center gap-2">
//             Font:
//             <select
//               value={textOptions.fontFamily}
//               onChange={handleFontFamilyChange}
//               className="h-8 w-32 rounded-lg border border-gray-300"
//             >
//               {fontFamiliesDefault.map((family: string) => (
//                 <option value={family} key={family}>
//                   {family}
//                 </option>
//               ))}
//             </select>
//           </Label>
//           <Label className="flex items-center gap-2">
//             Text Color:
//             <Input
//               type="color"
//               value={textOptions.textColor}
//               onChange={(e) => handleTextColorChange(e.target.value)}
//             />
//           </Label>
//           <Label className="flex items-center gap-2">
//             Font Style:
//             <select
//               value={textOptions.fontStyle}
//               onChange={handleFontStyleChange}
//               className="h-8 w-28 rounded-lg border border-gray-300"
//             >
//               <option value="normal">Normal</option>
//               <option value="italic">Italic</option>
//               <option value="oblique">Oblique</option>
//             </select>
//           </Label>
//           <Label className='flex gap-2 items-center'>
//                   Align Text:
//                   <select value={textOptions.textAlign} onChange={handleTextAlignmentChange} className='h-8 w-20 rounded-md border border-gray-400'>
//                     <option value="left">Left</option>
//                     <option value="center">Center</option>
//                     <option value="right">Right</option>
//                     <option value="justify">Justify</option>
//                   </select>
//                 </Label>
//           <Label className="flex items-center gap-2">
//             Font Weight:
//             <select
//               value={textOptions.fontWeight}
//               onChange={handleFontWeightChange}
//               className="h-8 w-28 rounded-lg border border-gray-300"
//             >
//               <option value="normal">Normal</option>
//               <option value="bold">Bold</option>
//               <option value="bolder">Bolder</option>
//               <option value="lighter">Lighter</option>
//             </select>
//           </Label>
//           <Label className="flex items-center gap-2">
//             <Input
//               type="checkbox"
//               checked={textOptions.underline}
//               onChange={handleUnderlineChange}
//             />
//             Underline
//           </Label>
//         </div>
//     </div> 