import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const helmetContext = {};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<StrictMode>
  <HelmetProvider context={helmetContext}>
  <Provider store={store}>
  <ToastContainer />
    <App /></Provider>
  </HelmetProvider>
  </StrictMode>)
