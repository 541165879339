
const TermsConditions = () => {
  return (
    <div className="bgcolorgold">
      <div className="wrapper flex flex-col gap-4">
        <div>
          <h5 className="h5-bold">TERMS AND CONDITIONS</h5>
        </div>
        <div>
          <p className="p-semibold-20">Introduction:</p>
          <p>
            These terms and conditions govern your use of this website and the purchase of products from PrintFerry ("we," "us," or "our").
            By accessing this website and/or placing an order for products, you agree to be bound by these terms and conditions.
          </p>
        </div>
        <div>
          <p className="p-semibold-20">Products:</p>
          <p>
            All products displayed on this website are subject to availability.
            We reserve the right to modify or discontinue any product at any time without prior notice.
            Product descriptions, images, and prices are provided for informational purposes and may be subject to change without notice.
          </p>
        </div>
        <div>
          <p className="p-semibold-20">Ordering:</p>
          <p>
            By placing an order on this website, you warrant that you are at least 18 years old and legally capable of entering into binding contracts.
            Orders are subject to acceptance and confirmation by us. We reserve the right to refuse or cancel any order for any reason, including but not limited to product availability, errors in pricing or product descriptions, or suspicion of fraudulent activity.
          </p>
        </div>
        <div>
          <p className="p-semibold-20">Payment:</p>
          <p>
            Payment for orders must be made in full at the time of purchase.
            We accept payment via RazorPay and other secure payment methods.
            All payments are processed securely through our payment gateway.
          </p>
        </div>
        <div>
          <p className="p-semibold-20">Shipping and Delivery:</p>
          <p>
            Shipping and delivery terms are governed by our shipping policy, which forms part of these terms and conditions.
            We are not responsible for delays or failures in delivery caused by events beyond our reasonable control.
          </p>
        </div>
        {/* <div>
          <p className="p-semibold-20">Returns and Refunds:</p>
          <p>
            Our returns and refunds policy is outlined in our return policy, which forms part of these terms and conditions.
            Returns are accepted within 2 days of receipt of the product, subject to certain conditions.
            Refunds will be issued in accordance with our refund policy.
          </p>
        </div> */}
        <div>
          <p className="p-semibold-20">Intellectual Property:</p>
          <p>
            All content, including text, images, logos, and trademarks, displayed on this website is the intellectual property of PrintFerry or its licensors and is protected by copyright laws.
            You may not reproduce, distribute, modify, or otherwise use any content from this website without our prior written consent.
          </p>
        </div>
        <div>
          <p className="p-semibold-20">Limitation of Liability:</p>
          <p>
            To the fullest extent permitted by law, we shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of this website or the purchase of products from us.
            Our total liability for any claims arising out of or related to these terms and conditions shall not exceed the total amount paid by you for the products ordered.
          </p>
        </div>
        <div>
          <p className="p-semibold-20">Governing Law:</p>
          <p>
            These terms and conditions shall be governed by and construed in accordance with the laws of [Your Jurisdiction].
            Any disputes arising out of or in connection with these terms and conditions shall be subject to the exclusive jurisdiction of the courts of [Your Jurisdiction].
          </p>
        </div>
        <div>
          <p className="p-semibold-20">Changes to Terms and Conditions:</p>
          <p>
            We reserve the right to modify or update these terms and conditions at any time without prior notice.
            Changes to these terms and conditions will be effective immediately upon posting on this website.
          </p>
        </div>
        <div>
          <p className="p-semibold-20">Contact Information:</p>
          <p>
            If you have any questions or concerns about these terms and conditions, please contact us at <strong>manager@printferry.com</strong> or <strong>+91-8888855555</strong>.
          </p>
        </div>
        <div>
          <p className="p-semibold-20">Acceptance of These Terms:</p>
          <p>
            By using or visiting the PrintFerry website, you acknowledge that you have read this agreement and agree to all its terms and conditions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
