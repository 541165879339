import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";

import { ChangeEvent, startTransition, useEffect, useState } from "react";
import {
  AlertDialogAction,
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../../../components/ui/alert-dialog";
import { Input } from "../../../ui/input";
import {
  useGetAllCategoryQuery,
  useGetAllColorsQuery,
  useGetSubcategoryQuery,
} from "../../../../redux/slice/CategoryApiSlice";
import {
  useDeleteCategoryAMutation,
  useDeleteSubCategoryAMutation,
  useAddCategoryAMutation,
  useAddSubcategoryAMutation,
} from "../../../../redux/slice/admin/ACategoryApiSlice";
import { Button } from "../../../../components/ui/button";
import { IoMdClose } from "react-icons/io";

/* type DropdownProps = {
  value: string;
  onChangeHandler?: () => void;
  type:string,
  catId?:number,
  onChangeCID?:any
}; */



const Dropdown = ({onChangeHandler,value,type,catId,onChangeCID,formType}: any) => {
  const [newCategory, setNewCategory] = useState<string>("");
  const { data: categories } = useGetAllCategoryQuery("categoriesList", {pollingInterval: 6000,refetchOnFocus: true,refetchOnMountOrArgChange: true,});
  const { data: subCategories/* , refetch: refetchSubCategories   */} = useGetSubcategoryQuery(catId);
  const [addNewCategory] = useAddCategoryAMutation();
  const [addNewSubCategory] = useAddSubcategoryAMutation();

  const handleCategory = () => {
    if (type === "categoryId") {
      addNewCategory({ categoryName: newCategory.toUpperCase() });
    } else {
      addNewSubCategory({ subcategoryName: newCategory.toUpperCase(), categoryId: catId });
    }
  };

  const changeHandler = (value: string) => {
    if (type === "categoryId") {
      onChangeHandler(Number(value));
      onChangeCID(Number(value));
    } else {
      onChangeHandler(Number(value));
    }
  };

  useEffect(()=>{
    if(subCategories?.result?.length&&type==="subCategoryId"&&formType!=="Edit"){
      onChangeHandler(subCategories?.result[0]?.id)}}
      ,[subCategories?.result,formType])
 
  const categoriesFix = type === "categoryId" ? categories?.result : subCategories?.result;

  return (
    <Select onValueChange={changeHandler} value={value}>
      <SelectTrigger className="select-field">
        <SelectValue placeholder="CategoryId" />
      </SelectTrigger>
      <SelectContent>
        {categoriesFix?.length > 0 &&
          categoriesFix?.map((category: any) => (
            <SelectItem
              key={category.id}
              value={category.id.toString()}
              className="select-item p-regular-14 flex justify-between gap-10 items-center"
            >
              {type === "categoryId"
                ? category.categoryName
                : category.subcategoryName}
            </SelectItem>
          ))}
        <AlertDialog>
          <AlertDialogTrigger className="p-medium-14 flex w-full rounded-sm py-3 pl-8 text-blue-500 hover:text-blue-350 focus:text-blue-600">
            {type === "categoryId" ? "Add New category" : "Add New Subcategory"}
          </AlertDialogTrigger>
          <AlertDialogContent className="bg-white">
            <AlertDialogHeader>
              <AlertDialogTitle> {type === "categoryId"
                  ? "Add New category"
                  : "Add New Subcategory"}</AlertDialogTitle>
              <AlertDialogDescription>
                <Input
                  type="text"
                  placeholder="category name"
                  className="input-field mt-3"
                  /* onChange={(e) =>{setNewCategory(e.target.value)}} */
                  onInput={(e: ChangeEvent<HTMLInputElement>) => setNewCategory(e.target.value)}
                />
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction
                onClick={() => startTransition(handleCategory)}
              >
                {type === "categoryId"
                  ? "Add New category"
                  : "Add New Subcategory"}
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
        {categoriesFix?.length > 0&& <DialogDemo categoriesFix={categoriesFix} type={type} /* refetchSubCategories={refetchSubCategories}  */ />}
      </SelectContent>
    </Select>
  );
};

export function DialogDemo({ categoriesFix, type/* ,refetchSubCategories */ }: any) {
  const [catId,setCatId]=useState<number>(0)
  const [deleteSubcategory/* ,{isSuccess:subCSuccess,data:subCData} */]=useDeleteSubCategoryAMutation()
  const [deleteCategory/* ,{isSuccess,data} */] = useDeleteCategoryAMutation();
  const [open,setOpen]=useState(false)
  const handleClose=()=>{
    setOpen(false)
  }
  // useEffect(()=>{
  //     /* refetchSubCategories() */
  //     if(isSuccess&&data.result){
  //       window.location.reload();
  //     }
  //     if(subCSuccess&&subCData.result){
  //       window.location.reload();
  //     }
  // },[isSuccess,data,subCSuccess,subCData/* ,refetchSubCategories */])

  const handleRemoveCart =  () => {
    if (type === "categoryId") {
     deleteCategory(catId);
    } else {
      deleteSubcategory(catId);
    }
    setOpen(false)
  };
  
  return (
    <Dialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }}}>
      <DialogTrigger className="p-medium-14 flex w-full rounded-sm py-3 pl-8 text-red-500 hover:text-red-350 focus:text-red-600">Delete</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Select the category to delete</DialogTitle>
          <DialogDescription>
            <AlertDialog>
             { categoriesFix?.map((category:any)=> <AlertDialogTrigger key={category.id} asChild>
                <Button variant="ghost" onClick={()=>setCatId(category.id)}>
                {type === "categoryId"
                ? category.categoryName
                : category.subcategoryName}
                </Button>
              </AlertDialogTrigger>)}
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                  <AlertDialogDescription>
                    This action cannot be undone. This will permanently remove
                    the category.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <Button onClick={() => startTransition(handleRemoveCart)}>Continue</Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
   );
}


export function ColorsSelect({onChangeHandler, type,value}: any) {
  const {data:allColors} =  useGetAllColorsQuery("colorsList", {pollingInterval: 6000,refetchOnFocus: true,refetchOnMountOrArgChange: true,})
  const [open,setOpen]=useState(false)
  const handleClose=()=>{
    setOpen(false)
  }
const handleColorChange=async(color:any)=>{
  if(type==="Update"){
    onChangeHandler(color)
  }else{
    onChangeHandler(color);
  }
  setOpen(false)
}
  return (
    <Dialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }}}>
      <DialogTrigger className="p-medium-14 flex w-full rounded-sm py-3 pl-8 text-red-500 hover:text-red-350 focus:text-red-600">Click Here To {type==="Create"?"Select The Color":"Change The Color"}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Select the color</DialogTitle>
          <DialogDescription>
            <div className="flex flex-wrap gap-2">
          {allColors?.result?.length>0&& allColors?.result.map((color:any) => (
          <Button
            key={color.id}
            value={color.id}
            className={`select-item rounded-full w-[40px] h-[40px] flex flex-row items-center justify-center`}
            onClick={()=>{handleColorChange(color)}}
            style={{ backgroundColor: color.colorCode }}
          >
          </Button>
          ))}</div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
   );
}


export default Dropdown;
