import { PaperColor, PaperContain, PaperTypeItem } from "@/react-app-env"
import { Button } from "../../../../../components/ui/button"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { IoMdAdd } from "react-icons/io"
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../../../../../components/ui/dialog"
import {
    AlertDialog,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "../../../../../components/ui/alert-dialog"
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "../../../../../components/ui/tooltip"
import { FaEdit } from "react-icons/fa"
import { MdDeleteOutline } from "react-icons/md"
import { Separator } from "../../../../../components/ui/separator"
import { Label } from "../../../../../components/ui/label"
import { Input } from "../../../../../components/ui/input"
import { toast } from "react-toastify"
import { v4 as uuidv4 } from 'uuid';
import { useUpdateProductAttributeMutation } from "../../../../../redux/slice/admin/AAttributeApiSlice"

interface SationaryPaperTypeProps {
    setCond: Dispatch<SetStateAction<boolean>>,
    id: number,
    paperSizesSet: any,
    paperQualitySet: any,
    paperTypeSet: any,
    paperSerialNoSet: any,
}
const SationaryPaperType = ({ setCond, id, paperSizesSet, paperQualitySet, paperTypeSet, paperSerialNoSet }: SationaryPaperTypeProps) => {
    const [variant, setVariant] = useState("")
    const [properties, setProperties] = useState({ pr: "", ePrice: 0 })
    const [updateProductAttribute] = useUpdateProductAttributeMutation()

    const handleSubmit = async () => {
        if (!properties.pr) {
            toast.error("Fill the mandatory fields")
            return;
        }
        let attributeValueNew;
        if (variant === "type") {
            const newProperties = { id: uuidv4(), type: properties.pr, ePrice: properties.ePrice, enabled: true, colors: [], contains: [] }
            attributeValueNew = { PaperSizes: paperSizesSet, PaperType: [...paperTypeSet, newProperties], SerialNo: paperSerialNoSet, PaperQuality: paperQualitySet }
        }
        await updateProductAttribute({ id, attributeName: 'Office Stationary Fixed', attributeValue: JSON.stringify(attributeValueNew) })
        setVariant("")
        setCond(p => !p)
        setProperties({ pr: "", ePrice: 0 })
        toast.success("Added/updated Changes")
    }

    return (<>
        {paperTypeSet?.length > 0 && <div className="w-full shadow-md bg-gray-200 p-3 rounded-md">
            <div className="flex justify-between gap-4 my-2">
                <p className="text-md font-semibold ">Paper Types Conditions:</p>
                <Button variant="default" onClick={() => setVariant("type")} >Add Paper Type</Button>
                {variant === "type" && <AddOffPropertyType setVariant={setVariant} type={variant} setProperties={setProperties} handleSubmit={handleSubmit} properties={properties} />}
            </div>
            <div className=" flex gap-4 flex-wrap">
                {paperTypeSet?.map((type: PaperTypeItem) => <div className="border flex flex-col gap-4 border-gray-400 bg-blue-200 p-2 py-3 rounded-lg" key={type.id}>
                    <div className="flex justify-between">
                        <div className="flex flex-col justify-between">
                            <p className="font-medium text-gray-500">Type: <span className="text-black">{type.type}</span></p>
                            <p className="font-medium text-gray-500">Extra Price: <span className="text-black">{type.ePrice}</span></p>
                        </div>
                        <EditDeleteButtonsType setCond={setCond} property={type} step={"EditType"} id={id} typeId={type.id} paperSizesSet={paperSizesSet} paperQualitySet={paperQualitySet} paperTypeSet={paperTypeSet} paperSerialNoSet={paperSerialNoSet} />
                    </div>
                    <Separator className="my-1" />
                    <PaperContains setCond={setCond} id={id} contains={type.contains} typeId={type.id} paperSizesSet={paperSizesSet} paperQualitySet={paperQualitySet} paperTypeSet={paperTypeSet} paperSerialNoSet={paperSerialNoSet} />
                    <Separator className="my-1" />
                    <PaperColors setCond={setCond} id={id} colors={type.colors} typeId={type.id} paperSizesSet={paperSizesSet} paperQualitySet={paperQualitySet} paperTypeSet={paperTypeSet} paperSerialNoSet={paperSerialNoSet} />
                </div>)}
            </div>
        </div>}

    </>)
}

interface PaperContainsProps extends SationaryPaperTypeProps {
    contains: PaperContain[],
    typeId: string
}
const PaperContains = ({ contains, typeId, setCond, id, paperSizesSet, paperQualitySet, paperTypeSet, paperSerialNoSet }: PaperContainsProps) => {
    const [variant, setVariant] = useState("")
    const [properties, setProperties] = useState({ pr: "", ePrice: 0 })
    const [updateProductAttribute] = useUpdateProductAttributeMutation()

    const handleSubmit = async () => {
        if (!properties.pr) {
            toast.error("Fill the mandatory fields")
            return;
        }
        let attributeValueNew;
        if (variant === "contains") {
            const editType = paperTypeSet.find((paper: any) => paper.id === typeId);
            const newProperties = { id: uuidv4(), contain: properties.pr, ePrice: properties.ePrice, enabled: true };
            const updatedType = { ...editType, contains: [...editType.contains, newProperties] };
            attributeValueNew = {
                PaperSizes: paperSizesSet,
                PaperType: paperTypeSet.map((paper: any) => paper.id === typeId ? updatedType : paper),
                SerialNo: paperSerialNoSet,
                PaperQuality: paperQualitySet
            };
        }
        await updateProductAttribute({ id, attributeName: 'Office Stationary Fixed', attributeValue: JSON.stringify(attributeValueNew) })
        setVariant("")
        setCond(p => !p)
        setProperties({ pr: "", ePrice: 0 })
        toast.success("Added/updated Changes")
    }

    return <>
        <div className="flex justify-between gap-2">
            <p className="text-md font-semibold ">Paper Contains:</p>
            <TooltipProvider delayDuration={100}>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Button className="p-1 rounded-full h-10 w-10 bg-gray-400" onClick={() => setVariant("contains")} ><IoMdAdd size={20} /></Button>
                    </TooltipTrigger>
                    <TooltipContent>
                        <p>Add to New Paper Contains</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
            {variant === "contains" && <AddOffPropertyType setVariant={setVariant} type={variant} setProperties={setProperties} handleSubmit={handleSubmit} properties={properties} />}
        </div>
        <div className=" flex gap-4 flex-wrap">
            {contains?.map((type: PaperContain) => <div className="border flex flex-col gap-4 border-gray-400 bg-blue-200 p-2 py-3 rounded-lg" key={type.id}>
                <div className="flex flex-col justify-between">
                    <p className="font-medium text-gray-500">Type: <span className="text-black">{type.contain}</span></p>
                    <p className="font-medium text-gray-500">Extra Price: <span className="text-black">{type.ePrice}</span></p>
                </div>
                <EditDeleteButtons step={"EditContains"} size={type} setCond={setCond} id={id} typeId={typeId} paperSizesSet={paperSizesSet} paperQualitySet={paperQualitySet} paperTypeSet={paperTypeSet} paperSerialNoSet={paperSerialNoSet} />
            </div>)}
        </div>
    </>
}

interface PaperColorsProps extends SationaryPaperTypeProps {
    colors: PaperColor[],
    typeId: string
}
const PaperColors = ({ colors, typeId, setCond, id, paperSizesSet, paperQualitySet, paperTypeSet, paperSerialNoSet }: PaperColorsProps) => {
    const [variant, setVariant] = useState("")
    const [properties, setProperties] = useState({ pr: "", ePrice: 0 })
    const [updateProductAttribute] = useUpdateProductAttributeMutation()

    const handleSubmit = async () => {
        if (!properties.pr) {
            toast.error("Fill the mandatory fields")
            return;
        }
        let attributeValueNew;
        if (variant === "colors") {
            const editType = paperTypeSet.find((paper: any) => paper.id === typeId);
            const newProperties = { id: uuidv4(), color: properties.pr, enabled: true };
            const updatedType = { ...editType, colors: [...editType.colors, newProperties] };
            attributeValueNew = {
                PaperSizes: paperSizesSet,
                PaperType: paperTypeSet.map((paper: any) => paper.id === typeId ? updatedType : paper),
                SerialNo: paperSerialNoSet,
                PaperQuality: paperQualitySet
            };
        }
        await updateProductAttribute({ id, attributeName: 'Office Stationary Fixed', attributeValue: JSON.stringify(attributeValueNew) })
        setVariant("")
        setCond(p => !p)
        setProperties({ pr: "", ePrice: 0 })
        toast.success("Added/updated Changes")
    }
    return <>
        <div className="flex justify-between items-center gap-4">
            <p className="text-md font-semibold ">Paper colors:</p>
            <TooltipProvider delayDuration={100}>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Button className="p-1 rounded-full h-10 w-10 bg-gray-400 " onClick={() => setVariant("colors")} ><IoMdAdd size={20} /></Button>
                    </TooltipTrigger>
                    <TooltipContent>
                        <p>Add to New Paper Color</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
            {variant === "colors" && <AddOffPropertyType setVariant={setVariant} type={variant} setProperties={setProperties} handleSubmit={handleSubmit} properties={properties} />}

        </div>
        <div className=" flex gap-4 flex-wrap">
            {colors?.map((type: PaperColor) => <div className="border flex flex-col gap-4 border-gray-400 bg-blue-200 p-2 py-3 rounded-lg" key={type.id}>
                <div className="flex flex-col justify-between">
                    <p className="font-medium text-gray-500">Paper Color: <span className="text-black">{type.color}</span></p>
                </div>
                <EditDeleteButtons step={"EditColors"} size={type} setCond={setCond} id={id} typeId={typeId} paperSizesSet={paperSizesSet} paperQualitySet={paperQualitySet} paperTypeSet={paperTypeSet} paperSerialNoSet={paperSerialNoSet} />
            </div>)}
        </div>
    </>
}
export default SationaryPaperType


const EditDeleteButtons = ({ setCond, step, size, typeId, id, paperSizesSet, paperQualitySet, paperTypeSet, paperSerialNoSet }: any) => {
    const [editVariant, setEditVariant] = useState("")
    const [deleteVariant, setDeleteVariant] = useState("")

    return (<div className="flex justify-between bg-gray-200 rounded-lg">
        {editVariant === step && <EditOffPropertyType typeId={typeId} setCond={setCond} id={id} setEditVariant={setEditVariant} editVariant={editVariant} property={size} paperSizesSet={paperSizesSet} paperTypeSet={paperTypeSet} paperQualitySet={paperQualitySet} paperSerialNoSet={paperSerialNoSet} />}
        {/*    {deleteVariant === step && <AlertDialogDelete setCond={setCond} id={id} setDeleteVariant={setDeleteVariant} type={deleteVariant} property={size} paperSizesSet={paperSizesSet} paperQualitySet={paperQualitySet} paperTypeSet={paperTypeSet} paperSerialNoSet={paperSerialNoSet} />}
 */}           {deleteVariant === step && <AlertDialogDelete setCond={setCond} id={id} typeId={typeId} setDeleteVariant={setDeleteVariant} type={deleteVariant} property={size} paperSizesSet={paperSizesSet} paperQualitySet={paperQualitySet} paperTypeSet={paperTypeSet} paperSerialNoSet={paperSerialNoSet} />}

  <Button onClick={() => setEditVariant(step)} variant={"ghost"}><FaEdit size={20} color="blue" /></Button>
        <Button onClick={() => setDeleteVariant(step)} variant={"ghost"}><MdDeleteOutline size={20} color="red" /></Button>
    </div>)
}

const EditDeleteButtonsType = ({
    step,
    setCond,
    id,
    typeId,
    property,
    paperSizesSet,
    paperQualitySet,
    paperTypeSet,
    paperSerialNoSet
}: any) => {
    const [editVariant, setEditVariant] = useState("")
    const [deleteVariant, setDeleteVariant] = useState("")

    return (<div className="flex fle-col bg-gray-200 rounded-lg">
        {editVariant === step && <EditOffPropertyType property={property} typeId={typeId} setCond={setCond} id={id} setEditVariant={setEditVariant} editVariant={editVariant} paperSizesSet={paperSizesSet} paperTypeSet={paperTypeSet} paperQualitySet={paperQualitySet} paperSerialNoSet={paperSerialNoSet} />}
        {deleteVariant === step && <AlertDialogDelete setCond={setCond} id={id} typeId={typeId} setDeleteVariant={setDeleteVariant} type={deleteVariant} property={property} paperSizesSet={paperSizesSet} paperQualitySet={paperQualitySet} paperTypeSet={paperTypeSet} paperSerialNoSet={paperSerialNoSet} />}
        <Button onClick={() => setEditVariant(step)} variant={"ghost"}><FaEdit size={20} color="blue" /></Button>
        <Button onClick={() => setDeleteVariant(step)} variant={"ghost"}><MdDeleteOutline size={20} color="red" /></Button>
    </div>)
}

const AddOffPropertyType = ({ type, setProperties, properties, handleSubmit, setVariant }: { type: string, setVariant: any, setProperties: any, properties: any, handleSubmit: () => void }) => {

    const [open, setOpen] = useState(true)
    const handleClose = () => {
        setOpen(false)
        setVariant("")
        setProperties({ pr: "", ePrice: 0 })
    }
    return (<Dialog open={open} onOpenChange={() => {
        if (!open) {
            setOpen(true)
        } else {
            handleClose()
        }
    }}>
        {/* <DialogTrigger asChild>
        <Button variant="default">Add New {type}</Button>
    </DialogTrigger> */}
        <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
                <DialogTitle>Add New {type}</DialogTitle>
            </DialogHeader>
            <Separator />
            <div className="grid gap-4 py-4">
                <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="name" className="text-right">
                        {type} Name:*
                    </Label>
                    <Input id="name" required value={properties.pr} onChange={(e) => setProperties((pre: any) => ({ ...pre, pr: e.target.value }))} className="col-span-3 input-field" />
                </div>
                {type !== "colors" && <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="price" className="text-right">
                        Extra Price:*
                    </Label>
                    <Input id="price" required value={properties.ePrice} onChange={(e) => setProperties((pre: any) => ({ ...pre, ePrice: +e.target.value }))} className="col-span-3 input-field" />
                </div>}
            </div>
            <DialogFooter className="w-full flex gap-4">
                <Button type="button" onClick={handleSubmit} className="bg-green-700">Save</Button>
                <Button type="button" onClick={handleClose}>cancel</Button>
            </DialogFooter>
        </DialogContent>
    </Dialog>)
}

interface EditOffPropertyTypeProps extends SationaryPaperTypeProps {
    /*   colors: PaperColor[],*/
    editVariant: string,
    property?: any,
    setEditVariant: any
    typeId: string,
}
const EditOffPropertyType = ({ property, editVariant, setEditVariant, typeId, setCond, id, paperSizesSet, paperQualitySet, paperTypeSet, paperSerialNoSet }: EditOffPropertyTypeProps) => {
    const [properties, setProperties] = useState({ pr: "", ePrice: 0 })
    const [updateProductAttribute] = useUpdateProductAttributeMutation()
    const [open, setOpen] = useState(true)


    useEffect(() => {
        if (editVariant === "EditColors") {
            setProperties({ pr: property.color, ePrice: property.ePrice })
        } else if (editVariant === "EditType") {
            setProperties({ pr: property.type, ePrice: property.ePrice })
        } else if (editVariant === "EditContains") {
            setProperties({ pr: property.contain, ePrice: property.ePrice })
        }

    }, [property, editVariant])

    const handleClose = () => {
        setOpen(false)
        setEditVariant("")
        setProperties({ pr: "", ePrice: 0 })
    }


    const handleSubmit = async () => {
        if (!properties.pr) {
            toast.error("Fill the mandatory fields")
            return;
        }
        let attributeValueNew;
        if (editVariant === "EditColors") {
            const editType = paperTypeSet.find((paper: any) => paper.id === typeId);
            const existingType = paperTypeSet.filter((paper: any) => paper.id !== typeId);
            const newProperties = { id: uuidv4(), color: properties.pr, enabled: true };
            const updatedColors = editType.colors.filter((color: any) => color.id !== property.id);
            updatedColors.push(newProperties);
            const updatedType = { ...editType, colors: updatedColors };
            attributeValueNew = {
                PaperSizes: paperSizesSet,
                PaperType: [...existingType, updatedType],
                SerialNo: paperSerialNoSet,
                PaperQuality: paperQualitySet
            };
        }
        if (editVariant === "EditType") {
            const editType = paperTypeSet.find((paper: any) => paper.id === typeId);
            const oldTypes = paperTypeSet.filter((paper: any) => paper.id !== typeId);
            const newProperties = { ...editType, ePrice: properties.ePrice, type: properties.pr };
            attributeValueNew = {
                PaperSizes: paperSizesSet,
                PaperType: [...oldTypes, newProperties],
                SerialNo: paperSerialNoSet,
                PaperQuality: paperQualitySet
            };
        }
        if (editVariant === "EditContains") {
            const editType = paperTypeSet.find((paper: any) => paper.id === typeId);
            const newProperties = { id: uuidv4(), contain: properties.pr, ePrice: properties.ePrice, enabled: true };
            const updatedContains = editType.contains.filter((contain: any) => contain.id !== property.id);
            updatedContains.push(newProperties);
            const updatedType = { ...editType, contains: updatedContains };
            const existingType = paperTypeSet.filter((paper: any) => paper.id !== typeId);

            attributeValueNew = {
                PaperSizes: paperSizesSet,
                PaperType: [...existingType, updatedType],
                SerialNo: paperSerialNoSet,
                PaperQuality: paperQualitySet
            };
        }

        await updateProductAttribute({ id, attributeName: 'Office Stationary Fixed', attributeValue: JSON.stringify(attributeValueNew) })
        setEditVariant("")
        setCond(p => !p)
        setProperties({ pr: "", ePrice: 0 })
        toast.success("Added/updated Changes")
    }

    return (<Dialog open={open} onOpenChange={() => {
        if (!open) {
            setOpen(true)
        } else {
            handleClose()
        }
    }}>
        {/* <DialogTrigger asChild>
        <Button variant="default">Add New {type}</Button>
    </DialogTrigger> */}
        <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
                <DialogTitle>Add New {editVariant}</DialogTitle>
            </DialogHeader>
            <Separator />
            <div className="grid gap-4 py-4">
                <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="name" className="text-right">
                        {editVariant} Name:*
                    </Label>
                    <Input id="name" required value={properties.pr} onChange={(e) => setProperties((pre: any) => ({ ...pre, pr: e.target.value }))} className="col-span-3 input-field" />
                </div>
                {editVariant !== "EditColors" && <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="price" className="text-right">
                        Extra Price:*
                    </Label>
                    <Input id="price" required value={properties.ePrice} onChange={(e) => setProperties((pre: any) => ({ ...pre, ePrice: +e.target.value }))} className="col-span-3 input-field" />
                </div>}
            </div>
            <DialogFooter className="w-full flex gap-4">
                <Button type="button" onClick={handleSubmit} className="bg-green-700">Save</Button>
                <Button type="button" onClick={handleClose}>cancel</Button>
            </DialogFooter>
        </DialogContent>
    </Dialog>)
}

export function AlertDialogDelete({ property, type, setDeleteVariant, setCond, id, typeId, paperSizesSet, paperQualitySet, paperTypeSet, paperSerialNoSet }: any) {
    const [open, setOpen] = useState(true)
    const [updateProductAttribute] = useUpdateProductAttributeMutation()

    const handleClose = () => {
        setOpen(false)
        setDeleteVariant("")
    }
    const handleSubmit = async () => {
        let attributeValueNew;
        if (type === "EditColors") {
            const editType = paperTypeSet.find((paper: any) => paper.id === typeId);
            const existingType = paperTypeSet.filter((paper: any) => paper.id !== typeId);
            const updatedColors = editType.colors.filter((color: any) => color.id !== property.id);
            const updatedType = { ...editType, colors: updatedColors };
            attributeValueNew = {
                PaperSizes: paperSizesSet,
                PaperType: [...existingType,updatedType],
                SerialNo: paperSerialNoSet,
                PaperQuality: paperQualitySet
            };
        }
        if (type === "EditType") {
            const oldTypes = paperTypeSet.filter((paper: any) => paper.id !== typeId);
            attributeValueNew = {
                PaperSizes: paperSizesSet,
                PaperType:[...oldTypes] ,
                SerialNo: paperSerialNoSet,
                PaperQuality: paperQualitySet
            };
        }
        if (type === "EditContains") {
            const editType = paperTypeSet.find((paper: any) => paper.id === typeId);
            const updatedContains = editType.contains.filter((contain: any) => contain.id !== property.id);
            const updatedType = { ...editType, contains: updatedContains };
            const existingType = paperTypeSet.filter((paper: any) => paper.id !== typeId);

            attributeValueNew = {
                PaperSizes: paperSizesSet,
                PaperType:[...existingType,updatedType],
                SerialNo: paperSerialNoSet,
                PaperQuality: paperQualitySet
            };
        }
        await updateProductAttribute({id, attributeName: 'Office Stationary Fixed', attributeValue: JSON.stringify(attributeValueNew) })
        setDeleteVariant("")
        setCond((p:any) => !p)
        toast.success("Deleted Sucessfully and Changes done")
       
    }

    return (
        <AlertDialog open={open} onOpenChange={() => {
            if (!open) {
                setOpen(true)
            } else {
                handleClose()
            }
        }}>
            {/* <AlertDialogTrigger asChild>
                <Button variant="outline">Show Dialog</Button>
            </AlertDialogTrigger> */}
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                    <AlertDialogDescription>
                        This action cannot be undone. This will permanently delete {type==="EditType"?"All the data related to this type of Contains and Colors also":"data related to Colors/Contains"}.
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <Button className="bg-red-700" onClick={handleSubmit} >Delete</Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}
