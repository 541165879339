import {  ChangeEvent, useEffect, useRef, useState } from 'react';
import { fabric } from 'fabric';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import { Button } from '../../../../../components/ui/button';
import { fontFamiliesDefault } from '../../../../../constants';
import { Label } from '../../../../../components/ui/label';
import { Input } from '../../../../../components/ui/input';
import { Sheet, SheetContent } from '../../../../../components/ui/sheet';
import { Card } from '../../../../../components/ui/card';
import { useGetLogodesignsMutation } from '../../../../../redux/slice/admin/ALogoApiSlice';
import { IMGURL } from '../../../../../constants/appConfig';

const shapes = {
  heart: 'M 300 50 C 300 25 250 25 250 100 C 250 25 200 25 200 100 C 200 175 250 225 300 300 C 350 225 400 175 400 100 C 400 25 350 25 350 50 Z',
    circle: 'M 100 50 A 50 50 0 1 1 50 100 A 50 50 0 1 1 100 50 Z',
  square: 'M 50 50 L 150 50 L 150 150 L 50 150 Z',
};

/* const addShapeToCanvas = (editor, shapeType) => {
  const shapePath = shapes[shapeType];
  if (!shapePath) return;

  const shape = new fabric.Path(shapePath, {
    left: 50,
    top: 50,
    fill: 'transparent',
    stroke: 'black',
    strokeWidth: 2,
  });

  editor.canvas.add(shape);
  editor.canvas.renderAll();
}; */
/* 
const applyClipping = (editor, shapeType) => {
  const shapePath = shapes[shapeType];
  if (!shapePath) return;

  const clipPath = new fabric.Path(shapePath, {
    left: 50,
    top: 50,
    scaleX: 2,
    scaleY: 2,
  });

  editor.canvas.getObjects().forEach(obj => {
    obj.clipPath = clipPath;
  });
  editor.canvas.renderAll();
}; */

const CreateTemplate1 = () => {
  const { editor, onReady } = useFabricJSEditor();
  const textAreaEl = useRef<HTMLTextAreaElement>(null);
  const [textOptions, setTextOptions] = useState({
    fontSize: 20,
    fontFamily: 'Arial',
    textColor: '#000000',
    fontStyle: 'normal' as 'normal' | 'italic' | 'oblique' | undefined,
    fontWeight: 'normal' as 'normal' | 'bold' | 'bolder' | 'lighter' | number | undefined,
    underline: false
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [groupSelect, setGroupSelect] = useState({});

  const getPrintableArea = () => {
    if (!editor || !editor.canvas) return null;
    const objects = editor.canvas.getObjects();
    const printableArea = objects.find(obj => (obj as any).id === 'printableArea');
    return printableArea as fabric.Rect;
  };

  function applyClipping() {
console.log(editor?.canvas.getObjects());
       
      const clipPath = new fabric.Path('M 272 178 C 272 134 220 134 200 160 C 180 134 128 134 128 178 C 128 222 200 278 200 278 C 200 278 272 222 272 178 Z', {
    left: 50,
    top: 50,
    absolutePositioned: true,
  });
      /* const clipRect = new fabric.Rect({
        left: dime.left,
        top: dime.top,
        width: dime.width,
        height: dime.height,
        absolutePositioned: true,
      }); */
      return clipPath;
   
  }
  
  // Function to attach event listeners to text objects
  /* const attachEventListeners = () => {
      if (!editor || !editor.canvas) return;
  
      editor.canvas.forEachObject((obj: fabric.Object) => {
          // Make sure all objects are selectable and have controls
          obj.set({
              selectable: true,
              hasControls: true,
              hasBorders: true,
          });
  
          if ((obj as any).id === 'printableArea') {
            obj.set({
              selectable: false,
              evented: false,
              hasControls: false,
              hasBorders: false,
            });
          }

          if (obj.type === "textbox" || obj.type === "text" || obj.type === "i-text") {
              const textObj = obj as fabric.IText;
              textObj.editable = true;
              textObj.evented = true;
  
              // Enable editing on double click
              textObj.on("mousedown", (e) => {
                  if (e.e.detail === 2) {  // Double click
                      textObj.enterEditing();
                      editor.canvas.setActiveObject(textObj);
                      editor.canvas.renderAll();
                  }
              });
  
              // Log editing events
              textObj.on("editing:entered", () => {
                  console.log("Entered editing mode for text:", textObj);
              });
  
              textObj.on("editing:exited", () => {
                  console.log("Exited editing mode for text:", textObj);
              });
  
              // Allow movement
              textObj.set({
                  lockMovementX: false,
                  lockMovementY: false,
              });
          }
  
          // Attach image upload listener for group objects
          if (obj.type === "group") {
              const group = obj as fabric.Group;
              console.log(group);
              
              group.on("mousedown", () => {
                  const imageInput = document.createElement('input');
                  imageInput.type = 'file';
                  imageInput.accept = 'image/*';
                  imageInput.style.display = 'none';
                  imageInput.addEventListener('change', function (e) {
                      const file = (e.target as HTMLInputElement).files?.[0];
                      const reader = new FileReader();
  
                      reader.onload = function (event) {
                          const imgObj = new Image();
                          imgObj.src = event.target?.result as string;
  
                          imgObj.onload = function () {
                              if (editor.canvas && imgObj.width && imgObj.height && group) {
                                  const widthFix = (group?.scaleX && group?.width) ? group?.width * group?.scaleX : group?.width!;
                                  const heightFix = (group?.scaleY && group?.height) ? group?.height * group?.scaleY : group?.height!;
                                  console.log(group.left);
                                  
                                  const img = new fabric.Image(imgObj);
                                  img.set({
                                      left: group.left,
                                      top: group.top,
                                      scaleX: widthFix / imgObj.width,
                                      scaleY: heightFix / imgObj.height,
                                  });
  
                                  editor.canvas.remove(group);
                                  editor.canvas.add(img);
                                  editor.canvas.renderAll();
                              }
                          };
                      };
  
                      if (file) {
                          reader.readAsDataURL(file);
                      }
                  });
  
                  imageInput.click();
              });
  
              group.on("scaling", () => {
                  console.log("Group scaling event");
              });
  
              group.forEachObject((subObj: fabric.Object) => {
                  if (subObj.type === "text" && (subObj as fabric.Text).text && ((subObj as fabric.Text).text as string).includes("click to select image")) {
                      attachImageUploadListener(subObj);
                  }
              });
          }
          
     });
  
      editor.canvas.on('object:moving', (e) => {
          const obj = e.target;
          console.log('Object moving:', obj);
      });
  
      editor.canvas.on('object:scaling', (e) => {
          const obj = e.target;
          console.log('Object scaling:', obj);
      });
  
      editor.canvas.on('object:selected', (e) => {
          const obj = e.target;
          console.log('Object selected:', obj);
      });
  
      editor.canvas.renderAll();
  }; 
   */
  
  const handleGroupClick = (group: fabric.Group) => {
    setGroupSelect(group);
    setModalOpen(true);
  };

  const attachEventListeners = () => {
      if (!editor || !editor.canvas) return;
  
      editor.canvas.forEachObject((obj: fabric.Object) => {
          // Make sure all objects are selectable and have controls
          obj.set({
              selectable: true,
              hasControls: true,
              hasBorders: true,
          });
  
          if ((obj as any).id === 'printableArea') {
            obj.set({
              selectable: false,
              evented: false,
              hasControls: false,
              hasBorders: false,
            });
          }

          if (obj.type === "textbox" || obj.type === "text" || obj.type === "i-text") {
              const textObj = obj as fabric.IText;
              textObj.editable = true;
              textObj.evented = true;
  
              // Enable editing on double click
              textObj.on("mousedown", (e) => {
                  if (e.e.detail === 2) {  // Double click
                      textObj.enterEditing();
                      editor.canvas.setActiveObject(textObj);
                      editor.canvas.renderAll();
                  }
              });
  
              // Log editing events
              textObj.on("editing:entered", () => {
                  console.log("Entered editing mode for text:", textObj);
              });
  
              textObj.on("editing:exited", () => {
                  console.log("Exited editing mode for text:", textObj);
              });
  
              // Allow movement
              textObj.set({
                  lockMovementX: false,
                  lockMovementY: false,
              });
          }
  
          // Attach image upload listener for group objects
          if (obj.type === 'group') {
            const group = obj as fabric.Group;
      
            group.on('mousedown', () => handleGroupClick(group));
      
            group.on('scaling', () => {
              console.log('Group scaling event');
            });
      
            group.forEachObject((subObj: fabric.Object) => {
              if (subObj.type === 'text' && (subObj as fabric.Text).text && ((subObj as fabric.Text).text as string).includes('click to select image')) {
                attachImageUploadListener(subObj);
              }
            });
          }
        });

       /*  editor.canvas.on('object:moving', (e) => {
          const obj = e.target;
          const clipRect = applyClipping();
          
          if (obj && clipRect) {
            obj.clipPath = clipRect;
            editor.canvas.renderAll();
          }
        });
    
        editor.canvas.on('object:scaling', (e) => {
          const obj = e.target;
          const clipRect = applyClipping();
    
          if (obj && clipRect) {
            obj.clipPath = clipRect;
            editor.canvas.renderAll();
          }
        }); */
        editor.canvas.on('object:moving', (e) => {
          const obj = e.target;
          console.log('Object moving:', obj);
        });
      
        editor.canvas.on('object:scaling', (e) => {
          const obj = e.target;
          console.log('Object scaling:', obj);
        });
      
        editor.canvas.on('object:selected', (e) => {
          const obj = e.target;
          console.log('Object selected:', obj);
        });
        editor.canvas.renderAll();
      };
  
  useEffect(() => {
      if (editor) {
          attachEventListeners();
      }
  }, [editor]);
  
/*   const handleJSONImport = () => {
      if (!editor || !editor.canvas || !textAreaEl.current) return;
  
      try {
          const json = JSON.parse(textAreaEl.current.value);
  
          editor.canvas.clear();
        

          editor.canvas.loadFromJSON(json, () => {
            console.log(json);
            
              editor.canvas.forEachObject((obj: fabric.Object) => {
                  obj.set({
                      selectable: true,
                      hasControls: true,
                      hasBorders: true,
                  });
                  if ((obj as any).id === 'printableArea') {
                    obj.set({
                      selectable: false,
                      evented: false,
                      hasControls: false,
                      hasBorders: false,
                    });
                  }
                  obj.off("mousedown");
                  obj.off("scaling");
              });
  
              attachEventListeners();
              editor.canvas.renderAll();
          });
      } catch (error) {
          console.error("Error parsing JSON:", error);
      }
  }; */
  const handleJSONImport = () => {
    if (!editor || !editor.canvas || !textAreaEl.current) return;
  
    try {
      const json = JSON.parse(textAreaEl.current.value);
      
      editor.canvas.clear();
      
      editor.canvas.loadFromJSON(json, () => {
        console.log(json);
  
        // Ensure the canvas background image is adjusted
        const backgroundImage = editor.canvas.backgroundImage;
        if (backgroundImage && backgroundImage instanceof fabric.Image) {
          const canvasWidth = editor.canvas.getWidth();
          const canvasHeight = editor.canvas.getHeight();
          
          // Calculate scaling factors
          /* const scaleX = canvasWidth / (backgroundImage.width || 1);
          const scaleY = canvasHeight / (backgroundImage.height || 1); */
          
          // Apply scaling
          backgroundImage.set({
           /*  scaleX: scaleX,
            scaleY: scaleY, */
            width: canvasWidth,
            height: canvasHeight
          });
        }
        
        // Adjust each object according to canvas dimensions
        editor.canvas.forEachObject((obj: fabric.Object) => {
          obj.set({
            selectable: true,
            hasControls: true,
            hasBorders: true,
          });
          if ((obj as any).id === 'printableArea') {
            obj.set({
              selectable: false,
              evented: false,
              hasControls: false,
              hasBorders: false,
            });
          }
          obj.off("mousedown");
          obj.off("scaling");
          
       /*     // Calculate new scale factors based on the canvas dimensions
        const canvasWidth = editor.canvas.getWidth();
        const canvasHeight = editor.canvas.getHeight();
        const objWidth = obj.width ?? obj.getScaledWidth();
        const objHeight = obj.height ?? obj.getScaledHeight();

        // Apply scaling to fit the object to the canvas
        const scaleX = canvasWidth / objWidth;
        const scaleY = canvasHeight / objHeight;

        // Apply scaling and set the new dimensions
        obj.set({
          scaleX: scaleX,
          scaleY: scaleY,
          width: objWidth * scaleX,
          height: objHeight * scaleY,
        });*/
      }); 
  
        attachEventListeners();
        editor.canvas.renderAll();
      });
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  };
  
  
  const attachImageUploadListener = (obj: fabric.Object) => {
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.accept = "image/*";
      fileInput.style.display = "none";
      fileInput.onchange = (event) => handleImageUpload(event, obj);
      document.body.appendChild(fileInput);
  
      obj.on("mousedown", () => {
          fileInput.click(); // Trigger file input click when object is clicked
      });
  };
  
  const handleImageUpload = (event: Event, obj: fabric.Object) => {
      if (!editor || !editor.canvas) return;
      const input = event.target as HTMLInputElement;
      if (input.files && input.files[0]) {
          const file = input.files[0];
          const reader = new FileReader();
          reader.onload = (e) => {
              const dataUrl = e.target?.result as string;
              fabric.Image.fromURL(dataUrl, (img) => {
                  img.set({
                      left: obj.left || 0,
                      top: obj.top || 0,
                      scaleX: obj.scaleX || 1,
                      scaleY: obj.scaleY || 1,
                      angle: obj.angle || 0,
                      opacity: obj.opacity || 1,
                      selectable: true,
                      hasControls: true,
                      hasBorders: true,
                  });
  
                  editor.canvas.remove(obj); // Remove the original text object
                  editor.canvas.add(img); // Add the uploaded image to the canvas
                  editor.canvas.renderAll(); // Render the canvas to reflect changes
              });
          };
          reader.readAsDataURL(file);
      }
  };

  const handleImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && editor && editor.canvas) {
      const printableArea = await getPrintableArea();
      Array.from(files).forEach(file => {
        const reader = new FileReader();
        reader.onload = () => {
          fabric.Image.fromURL(reader.result as string, (oImg: any) => {
            oImg.scaleToWidth(100);
            oImg.scaleToHeight(100);
            if (printableArea) {
              oImg.set({
                left: printableArea.left || 0,
                top: printableArea.top || 0
              });
            } else {
              oImg.set({
                left: 20,
                top: 20
              });
            }
            editor.canvas.add(oImg);
          });
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const addText = async() => {
    if (!editor) return;
    const printableArea = await getPrintableArea();
    const textProps: fabric.ITextboxOptions = {
      left: 20,
      top: 20,
      width: 150,
      fontSize: textOptions.fontSize,
      fontFamily: textOptions.fontFamily,
      fill: textOptions.textColor,
      fontStyle: textOptions.fontStyle,
      fontWeight: textOptions.fontWeight,
    };
    if (printableArea) {
      textProps.left = printableArea.left? printableArea.left+1 : 0;
      textProps.top = printableArea.top? printableArea.top+1 : 0;
    }

    const text = new fabric.Textbox('Double click to edit', textProps);
    editor.canvas.add(text);

    text.on('mousedblclick', () => {
      text.enterEditing();
      text.selectAll();
    });

    editor.canvas.setActiveObject(text);
    editor.canvas.renderAll();
  };

  const addHeartPath = () => {
    if (!editor) return;

    // Better heart path definition main below heart
   // const heartPath = new fabric.Path('M 272 178 C 272 134 220 134 200 160 C 180 134 128 134 128 178 C 128 222 200 278 200 278 C 200 278 272 222 272 178 Z', {
    const heartPath = new fabric.Path('M 50,150 Q 75,130 100,150 Q 125,170 150,150 Q 170,130 150,100 Q 130,75 150,50 Q 170,25 150,0 Q 125,-20 100,0 Q 75,20 50,0 Q 25,-20 0,0 Q -20,25 0,50 Q 20,75 0,100 Q -20,130 0,150 Q 25,170 50,150 Z', {
      top: 50,
      left: 50,
      fill: 'white',
      stroke: 'black',
      strokeWidth: 1,
      selectable: true,
      hasControls: true,  // Enable controls for resizing
      hasBorders: true,
      lockScalingFlip: true,
    });

    /* const ellipse = new fabric.Circle({
      left: 100,
      top: 100,
      fill: 'red',
      stroke: 'green',
      strokeWidth: 3,
  }); */
/*   const circle = new fabric.Circle({
    left: 100,        // X position
    top: 100,         // Y position
    radius: 50,       // Radius of the circle
    fill: 'blue',     // Fill color
    stroke: 'black',  // Stroke color
    strokeWidth: 2,   // Stroke width
    selectable: true,  // Allow selection
}); */
    editor.canvas.add(heartPath);
    editor.canvas.renderAll();
  };

  const handleFontSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextOptions({ ...textOptions, fontSize: parseInt(e.target.value) });
    updateActiveText({ fontSize: parseInt(e.target.value) });
  };

  const handleFontFamilyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTextOptions({ ...textOptions, fontFamily: e.target.value });
    updateActiveText({ fontFamily: e.target.value });
  };

  const handleTextColorChange = (color: string) => {
    setTextOptions({ ...textOptions, textColor: color });
    updateActiveText({ fill: color });
  };

  const handleFontStyleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const fontStyle = e.target.value as 'normal' | 'italic' | 'oblique';
    setTextOptions({ ...textOptions, fontStyle });
    updateActiveText({ fontStyle });
  };

  const handleFontWeightChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const fontWeight = e.target.value as 'normal' | 'bold' | 'bolder' | 'lighter' | number;
    setTextOptions({ ...textOptions, fontWeight });
    updateActiveText({ fontWeight });
  };

  const handleUnderlineChange = () => {
    setTextOptions({ ...textOptions, underline: !textOptions.underline });
    updateActiveText({ underline: !textOptions.underline });
  };

  const updateActiveText = (options: Partial<fabric.ITextboxOptions>) => {
    if (!editor) return;
    const activeObject = editor.canvas.getActiveObject() as fabric.Textbox;
    if (activeObject && activeObject.type === 'textbox') {
      activeObject.set(options);
      editor.canvas.renderAll();
    }
  };
  
  //To resize the image after upload and not able to edit text
  // useEffect(() => {
  //   if (editor) {
  //     attachEventListeners();
  //   }
  // }, [editor]);

  // const handleJSONImport = () => {
  //   if (!editor || !editor.canvas || !textAreaEl.current) return;

  //   try {
  //     const json = JSON.parse(textAreaEl.current.value);

  //     editor.canvas.clear();

  //     editor.canvas.loadFromJSON(json, () => {
  //       editor.canvas.forEachObject((obj: fabric.Object) => {
  //         obj.set({
  //           selectable: true,
  //           hasControls: true,
  //           hasBorders: true,
  //         });
  //         obj.off("mousedown");
  //         obj.off("scaling");
  //       });

  //       attachEventListeners();
  //       editor.canvas.renderAll();
  //     });
  //   } catch (error) {
  //     console.error("Error parsing JSON:", error);
  //   }
  // };

  // const attachImageUploadListener = (obj: fabric.Object) => {
  //   const fileInput = document.createElement("input");
  //   fileInput.type = "file";
  //   fileInput.accept = "image/*";
  //   fileInput.style.display = "none";
  //   fileInput.onchange = (event) => handleImageUpload(event, obj);
  //   document.body.appendChild(fileInput);
  
  //   obj.on("mousedown", () => {
  //     fileInput.click(); // Trigger file input click when object is clicked
  //   });
  // };
  
  // const handleImageUpload = (event: Event, obj: fabric.Object) => {
  //   if (!editor || !editor.canvas) return;
  //   const input = event.target as HTMLInputElement;
  //   if (input.files && input.files[0]) {
  //     const file = input.files[0];
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       const dataUrl = e.target?.result as string;
  //       fabric.Image.fromURL(dataUrl, (img) => {
  //         img.set({
  //           left: obj.left || 0,
  //           top: obj.top || 0,
  //           scaleX: obj.scaleX || 1,
  //           scaleY: obj.scaleY || 1,
  //           angle: obj.angle || 0,
  //           opacity: obj.opacity || 1,
  //           selectable: true,
  //           hasControls: true,
  //           hasBorders: true,
  //         });
  
  //         editor.canvas.remove(obj); // Remove the original text object
  //         editor.canvas.add(img); // Add the uploaded image to the canvas
  //         editor.canvas.renderAll(); // Render the canvas to reflect changes
  //       });
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };
  
  // const attachEventListeners = () => {
  //   if (!editor || !editor.canvas) return;

  //   editor.canvas.forEachObject((obj: fabric.Object) => {
  //     obj.set({
  //       selectable: true,
  //       hasControls: true,
  //       hasBorders: true,
  //     });

  //     obj.on("mousedown", () => {
  //       console.log("Object mousedown event");
  //     });

  //     obj.on("scaling", () => {
  //       console.log("Object scaling event");
  //     });

    
  //       if (obj.type === "text" && (obj as fabric.Text).text && ((obj as fabric.Text).text as string).includes("click to select image")) {
  //           attachImageUploadListener(obj);
  //       }

  //     if (obj.type === "group") {
  //       const group = obj as fabric.Group;
  //       console.log(group);
  //       group.on("mousedown", () => {
  //           const imageInput = document.createElement('input');
  //           imageInput.type = 'file';
  //           imageInput.accept = 'image/*';
  //           imageInput.style.display = 'none';
  //           imageInput.addEventListener('change', function (e) {
  //             const file = (e.target as HTMLInputElement).files?.[0];
  //             const reader = new FileReader();
    
  //             reader.onload = function (event) {
  //               const imgObj = new Image();
  //               imgObj.src = event.target?.result as string;
    
  //               imgObj.onload = function () {
  //                 if (editor.canvas && imgObj.width && imgObj.height && group) {
  //                   const widthFix = (group?.scaleX && group?.width) ? group?.width*group?.scaleX : group?.width!;
  //                   const heightFix =(group?.scaleY && group?.height) ? group?.height*group?.scaleY : group?.height!;
                    
  //                   const img = new fabric.Image(imgObj);
  //                   img.set({
  //                     left: group.left,
  //                     top: group.top,
  //                     scaleX: widthFix/ imgObj.width,
  //                     scaleY: heightFix / imgObj.height,
  //                   });
  //                  /*  img.set({
  //                     left: group.left,
  //                     top: group.top,
  //                     scaleX: group.width! / imgObj.width,
  //                     scaleY: group.height! / imgObj.height,
  //                   }); */
    
  //                   editor.canvas.remove(group);
  //                   editor.canvas.add(img);
  //                   editor.canvas.renderAll();
  //                 }
  //               };
  //             };
    
  //             if (file) {
  //               reader.readAsDataURL(file);
  //             }
  //           });
    
  //           imageInput.click();
  //       });
  //       group.on("scaling", () => {
  //         console.log("Group scaling event");
  //       });
  //       group.forEachObject((subObj: fabric.Object) => {
  //           if (subObj.type === "text" && (subObj as fabric.Text).text && ((subObj as fabric.Text).text as string).includes("click to select image")) {
  //               attachImageUploadListener(subObj);
  //           }
  //       });
  //     }
  //   });
  // }; 

  return (
    <div className='wrapper flex gap-10'>
      <FabricJSCanvas className="border-2 border-gray-800 w-[450px] h-[600px]" onReady={onReady} />
      <div className="flex flex-col w-[300px] gap-4">
      <textarea ref={textAreaEl} className='w-full h-20 border-2 border-gray-400'></textarea>
      <Button onClick={handleJSONImport} className='w-40'>Import JSON</Button>
     <div className='flex flex-col gap-3 w-full my-2'>
          <p className="font-bold text-lg">Text Field:</p>
          <div className="flex flex-wrap gap-6 items-center">
            <Button type='button' onClick={addText} size={"sm"} className="bg-blue-500 hover:bg-blue-700">
              Add Text
            </Button>
            <Label className='flex  items-center'>
              Font Size:
              <Input type="range" min="10" max="50" className='w-32' value={textOptions.fontSize} onChange={handleFontSizeChange} />
              {textOptions.fontSize}
            </Label>
            <Label>
              Font:
              <select value={textOptions.fontFamily} onChange={handleFontFamilyChange} className='h-8 w-32 rounded-lg'>
                {fontFamiliesDefault.map(family => <option value={family} key={family}>{family}</option>)}
              </select>
            </Label>
            <Label className='flex gap-1 items-center'>
              Text Color:
              <Input type="color" value={textOptions.textColor} onChange={(e) => handleTextColorChange(e.target.value)} />
            </Label>
            <Label className='flex gap-2 items-center'>
              Font Style:
              {/*  <Button size={"sm"} onClick={()=>{setTextOptions(pre=>({...pre,fontStyle:"normal"}));updateActiveText({ fontStyle:"normal" });}} className={`${textOptions.fontStyle === "normal"?"bg-black":"bg-gray-500"}`} >Normal</Button>
              <Button size={"sm"} onClick={()=>{setTextOptions(pre=>({...pre,fontStyle:"italic"}));updateActiveText({ fontStyle:"italic" });}}  className={`${textOptions.fontStyle === "italic"?"bg-black":"bg-gray-500"}`}  >Italic</Button>
              */} <select value={textOptions.fontStyle} onChange={handleFontStyleChange} className='h-8 w-28 rounded-lg'>
                <option value="normal">Normal</option>
                <option value="italic">Italic</option>
                <option value="oblique">Oblique</option>
              </select>
            </Label>
            <Label>
              Font Weight:
              <select value={textOptions.fontWeight} onChange={handleFontWeightChange} className='h-8 w-28 rounded-lg'>
                <option value="normal">Normal</option>
                <option value="bold">Bold</option>
                <option value="bolder">Bolder</option>
                <option value="lighter">Lighter</option>
              </select>
            </Label>
            <Label className='flex items-center gap-2'>
              <Input type="checkbox" checked={textOptions.underline} onChange={handleUnderlineChange} />
              Underline
            </Label>
            {/* <Button onClick={addUnderlineToText} size={"sm"} className="bg-blue-500 hover:bg-blue-700">
              Toggle Underline
            </Button> */}
          </div>
      </div>
      <div>
        <Button onClick={addHeartPath}>Heart Image</Button>
            <Label htmlFor="picture">Upload Custom Image</Label>
            <Input id="picture" type="file" placeholder='Select own image' onChange={handleImageChange} />
           {modalOpen&& <SelectImageLogo editor={editor} setModalOpen={setModalOpen} groupSelect={groupSelect} setGroupSelect={setGroupSelect}/>}
       </div>
      </div>
    </div>
  );
};

export const SelectImageLogo = ({ editor,setModalOpen,groupSelect ,setGroupSelect}: any) => {

  const [getLogoDesigns, {data: logos} ] = useGetLogodesignsMutation()
  const [open, setOpen] = useState(true)
  const handleClose = () => {
    setOpen(false)
    setModalOpen(false)
  }
  const handleImageUpload = (url: string) => {
    
    setModalOpen(false);
    if (!editor || !editor.canvas || !groupSelect) return;
  
    const imgObj = new Image();
    imgObj.src = url;
  
    imgObj.onload = () => {
      if (imgObj.width && imgObj.height) {
        fabric.Image.fromURL(url, (img) => {
          const widthFix = (groupSelect.scaleX && groupSelect.width) ? groupSelect.width * groupSelect.scaleX : groupSelect.width!;
          const heightFix = (groupSelect.scaleY && groupSelect.height) ? groupSelect.height * groupSelect.scaleY : groupSelect.height!;
  
          img.set({
            left: groupSelect.left,
            top: groupSelect.top,
            scaleX: widthFix / imgObj.width,
            scaleY: heightFix / imgObj.height,
            selectable: true,
            hasControls: true,
            hasBorders: true,
          });
  
          editor.canvas.remove(groupSelect);
          editor.canvas.add(img);
          editor.canvas.renderAll();
        });
      } else {
        console.error("Image dimensions are not defined.");
      }
    };
  
    imgObj.onerror = () => {
      console.error("Failed to load image.");
    };
  };
  
  
  const handleLocalImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    setModalOpen(false);
    if (files && editor && editor.canvas && groupSelect) {
      const reader = new FileReader();
      reader.onload = () => {
        fabric.Image.fromURL(reader.result as string, (img) => {
          if (img.width && img.height) {
            const widthFix = (groupSelect.scaleX && groupSelect.width) ? groupSelect.width * groupSelect.scaleX : groupSelect.width!;
            const heightFix = (groupSelect.scaleY && groupSelect.height) ? groupSelect.height * groupSelect.scaleY : groupSelect.height!;
  
            img.set({
              left: groupSelect.left,
              top: groupSelect.top,
              scaleX: widthFix / img.width,
              scaleY: heightFix / img.height,
              selectable: false,
              hasControls: true,
              hasBorders: true,
            });
  
            editor.canvas.remove(groupSelect);
            editor.canvas.add(img);
            editor.canvas.renderAll();
          } else {
            console.error("Image dimensions are not defined.");
          }
        });
      };
      reader.readAsDataURL(files[0]);
    }
  };
  

  useEffect(()=>{getLogoDesigns({})},[])

  return <Sheet open={open} onOpenChange={() => {
    if (!open) {
      setOpen(true);
    } else {
      handleClose();
    }
  }}>
    {/* <SheetTrigger asChild >
      <Button className='headermenu w-[150px]' size="sm">Select From Gallery</Button>
    </SheetTrigger> */}
    <SheetContent side="left" className="overflow-y-scroll max-h-screen w-[350px] " >
    <div>
            <Label htmlFor="local-image" className='headermenu'>Upload Your Local Image</Label>
            <Input id="local-image" type="file" onChange={handleLocalImageChange} className='hidden' />
          </div>
      <div className='flex gap-3 flex-wrap my-3'>
        {logos?.result?.length > 0 && logos?.result?.map((logo: any) => <Card key={logo.id} className="w-[100px]" onClick={() => { setOpen(false); handleImageUpload(`${IMGURL}${logo.designUrl}`) }}>
          <img src={`${IMGURL}${logo.designUrl}`} alt="template" className="w-full h-[80px]" />
        </Card>)}
      </div>
    </SheetContent>
  </Sheet>
}
export default CreateTemplate1;

// export default CreateTemplate1;
// const CreateTemplate1 = () => {
//   const { editor, onReady } = useFabricJSEditor();
//   const textAreaEl = useRef<HTMLTextAreaElement>(null);

//   useEffect(() => {
//     if (editor) {
//       attachEventListeners();
//     }
//   }, [editor]);

//   const handleJSONImport = () => {
//     if (!editor || !editor.canvas || !textAreaEl.current) return;

//     try {
//       const json = JSON.parse(textAreaEl.current.value);

//       editor.canvas.clear();

//       editor.canvas.loadFromJSON(json, () => {
//         editor.canvas.forEachObject((obj: fabric.Object) => {
//           obj.set({
//             selectable: true,
//             hasControls: true,
//             hasBorders: true,
//           });
//           obj.off("mousedown");
//           obj.off("scaling");
//         });

//         attachEventListeners();
//         editor.canvas.renderAll();
//       });
//     } catch (error) {
//       console.error("Error parsing JSON:", error);
//     }
//   };

//   const attachImageUploadListener = (obj: fabric.Object) => {
//     const fileInput = document.createElement("input");
//     fileInput.type = "file";
//     fileInput.accept = "image/*";
//     fileInput.style.display = "none";
//     fileInput.onchange = (event) => handleImageUpload(event, obj);
//     document.body.appendChild(fileInput);
  
//     obj.on("mousedown", () => {
//       fileInput.click(); // Trigger file input click when object is clicked
//     });
//   };
  
//   const handleImageUpload = (event: Event, obj: fabric.Object) => {
//     if (!editor || !editor.canvas) return;
//     const input = event.target as HTMLInputElement;
//     if (input.files && input.files[0]) {
//       const file = input.files[0];
//       const reader = new FileReader();
//       reader.onload = (e) => {
//         const dataUrl = e.target?.result as string;
//         fabric.Image.fromURL(dataUrl, (img) => {
//           img.set({
//             left: obj.left || 0,
//             top: obj.top || 0,
//             scaleX: obj.scaleX || 1,
//             scaleY: obj.scaleY || 1,
//             angle: obj.angle || 0,
//             opacity: obj.opacity || 1,
//             selectable: true,
//             hasControls: true,
//             hasBorders: true,
//           });
  
//           editor.canvas.remove(obj); // Remove the original text object
//           editor.canvas.add(img); // Add the uploaded image to the canvas
//           editor.canvas.renderAll(); // Render the canvas to reflect changes
//         });
//       };
//       reader.readAsDataURL(file);
//     }
//   };
  
//   const attachEventListeners = () => {
//     if (!editor || !editor.canvas) return;

//     editor.canvas.forEachObject((obj: fabric.Object) => {
//       obj.set({
//         selectable: true,
//         hasControls: true,
//         hasBorders: true,
//       });

//       obj.on("mousedown", () => {
//         console.log("Object mousedown event");
//       });

//       obj.on("scaling", () => {
//         console.log("Object scaling event");
//       });

//       /* if (obj.type === "text" && (obj as fabric.Text).text && (obj as fabric.Text).text.includes("click to select image")) {
//         attachImageUploadListener(obj);
//       } */
//         if (obj.type === "text" && (obj as fabric.Text).text && ((obj as fabric.Text).text as string).includes("click to select image")) {
//             attachImageUploadListener(obj);
//         }

//       if (obj.type === "group") {
//         const group = obj as fabric.Group;
//         group.on("mousedown", () => {
//           console.log("Group mousedown event");
//         });

//         group.on("scaling", () => {
//           console.log("Group scaling event");
//         });

//      /*    group.forEachObject((subObj: fabric.Object) => {
//           if (subObj.type === "text" && (subObj as fabric.Text).text && (subObj as fabric.Text).text.includes("click to select image")) {
//             attachImageUploadListener(subObj);
//           }
//         }); */
//         group.forEachObject((subObj: fabric.Object) => {
//             if (subObj.type === "text" && (subObj as fabric.Text).text && ((subObj as fabric.Text).text as string).includes("click to select image")) {
//                 attachImageUploadListener(subObj);
//             }
//         });
//       }
//     });
//   };

//   return (
//     <div>
//       <textarea ref={textAreaEl}></textarea>
//       <button onClick={handleJSONImport}>Import JSON</button>
//       <FabricJSCanvas className="border-2 border-gray-800 w-[450px] h-[600px]" onReady={onReady} />
//     </div>
//   );
// };

// export default CreateTemplate1;


/*  const CreateTemplate1 = () => {
  const { editor, onReady } = useFabricJSEditor(); // Assuming this hook provides editor instance
  const textAreaEl = useRef<HTMLTextAreaElement>(null);
  const [imageFiles, setImageFiles] = useState<{ [key: string]: File }>({});

  const handleJSONImport = () => {
    if (!editor || !textAreaEl.current) return;

    try {
      const json = JSON.parse(textAreaEl.current.value);

      // Clear canvas before loading new content
      editor.canvas.clear();

      // Load objects from JSON
      editor.canvas.loadFromJSON(json, () => {
        // Ensure all objects are selectable after loading JSON
        editor.canvas.forEachObject(obj => {
          obj.set({ selectable: true });
          obj.off('mousedown'); // Remove existing mousedown listeners
          obj.off('scaling'); // Remove existing scaling listeners
        });

        // Attach file inputs for image objects
        json.objects.forEach((obj: any, index: number) => {
          if (obj.type === 'image' && !obj.src.startsWith('http')) {
            const fileInput = document.createElement('input');
            fileInput.type = 'file';
            fileInput.accept = 'image/*';
            fileInput.style.display = 'none';
            fileInput.onchange = (event) => handleImageUpload(event, obj, index);
            document.body.appendChild(fileInput);
            fileInput.click();
          }
        });

        // Reattach event listeners after loading JSON
        attachEventListeners();
        editor.canvas.renderAll();
      });
    } catch (error) {
      console.error('Error parsing JSON:', error);
    }
  };

  const handleImageUpload = (event: Event, obj: any, index: number) => {
    if (!editor ) return;
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const dataUrl = e.target?.result as string;
        fabric.Image.fromURL(dataUrl, (img) => {
          img.set({
            left: obj.left || 0,
            top: obj.top || 0,
            scaleX: obj.scaleX || 1,
            scaleY: obj.scaleY || 1,
            angle: obj.angle || 0,
            opacity: obj.opacity || 1,
            selectable: true // Ensure image is selectable
          });

          const item = editor.canvas.item(index) as unknown as fabric.Object;
          editor.canvas.remove(item);
          editor.canvas.insertAt(img, index, false);
          editor.canvas.renderAll();
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const attachEventListeners = () => {
    if (!editor) return;

    // Example: Add event listener to group
    editor.canvas.forEachObject(obj => {
      if (obj.type === 'group') {
        obj.on('mousedown', () => {
          console.log('Group mousedown event');
        });

        obj.on('scaling', () => {
          console.log('Group scaling event');
        });
      }
    });
  };

  return (
    <div className="flex flex-col items-center mt-10">
      <FabricJSCanvas className="border-2 border-gray-800 w-[450px] h-[600px]" onReady={onReady} />
      <div className="flex justify-center flex-wrap mt-4 space-x-4">
        <button onClick={handleJSONImport} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">
          Load JSON
        </button>
        <textarea
          ref={textAreaEl}
          className="w-full h-16 border border-gray-500 resize-none"
          placeholder="Paste JSON here"
        />
      </div>
    </div>
  );
};

export default CreateTemplate1;
 */


/* const CreateTemplate1 = () => {
  const textAreaEl = useRef<HTMLTextAreaElement>(null);
  const { editor,onReady } = useFabricJSEditor(); // Assuming this hook provides editor instance

  const handleJSONImport = () => {
    if (!editor || !textAreaEl.current) return;

    try {
      const json = JSON.parse(textAreaEl.current.value);
      editor.canvas.loadFromJSON(json, () => {
        // Ensure all objects are selectable after loading JSON
        editor.canvas.forEachObject(obj => {
          obj.set({ selectable: true });
        });

        editor.canvas.renderAll();
      });
    } catch (error) {
      console.error('Error parsing JSON:', error);
    }
  };

  return (
    <div className="flex flex-col items-center mt-10">
      <FabricJSCanvas className="border-2 border-gray-800 w-[450px] h-[600px]" onReady={onReady} />
      <div className="w-1024">
        <button onClick={handleJSONImport} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">
          Load JSON
        </button>
        <textarea
          ref={textAreaEl}
          className="w-full h-16 border border-gray-500 resize-none"
          placeholder="Paste JSON here"
        />
      </div>
    </div>
  );
};

export default CreateTemplate1;
 */

/* import React, { useState } from 'react';
import { fabric } from 'fabric';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import { fontFamiliesDefault } from '../../../../../constants';


const CreateTemplate = () => {
  const { editor, onReady } = useFabricJSEditor();
  const [textOptions, setTextOptions] = useState({
    fontSize: 20,
    fontFamily: 'Arial',
    textColor: '#000000',
    fontStyle: 'normal' as 'normal' | 'italic' | 'oblique' | undefined,
    fontWeight: 'normal' as 'normal' | 'bold' | 'bolder' | 'lighter' | number | undefined,
  });


  const addText = () => {
    if (!editor) return;

    const textProps: fabric.ITextboxOptions = {
      left: 20,
      top: 20,
      width: 200,
      fontSize: textOptions.fontSize,
      fontFamily: textOptions.fontFamily,
      fill: textOptions.textColor,
      fontStyle: textOptions.fontStyle,
      fontWeight: textOptions.fontWeight,
    };
    const text = new fabric.Textbox('Double click to edit', textProps);
    editor.canvas.add(text);

    // Add event listener for double-click to edit
    text.on('mousedblclick', () => {
      text.enterEditing();
      text.selectAll();
    });

    editor.canvas.setActiveObject(text);
    editor.canvas.renderAll();
  };

  const deleteText = () => {
    if (editor && editor.canvas) {
      const activeObject = editor.canvas.getActiveObject();
      if (activeObject) {
        editor.canvas.remove(activeObject);
      }
    }
  };

  const handleFontSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextOptions({ ...textOptions, fontSize: parseInt(e.target.value) });
    updateActiveText({ fontSize: parseInt(e.target.value) });
  };

  const handleFontFamilyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTextOptions({ ...textOptions, fontFamily: e.target.value });
    updateActiveText({ fontFamily: e.target.value });
  };

  const handleTextColorChange = (color: string) => {
    setTextOptions({ ...textOptions, textColor: color });
    updateActiveText({ fill: color });
  };

  const handleFontStyleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const fontStyle = e.target.value as 'normal' | 'italic' | 'oblique';
    setTextOptions({ ...textOptions, fontStyle });
    updateActiveText({ fontStyle });
  };

  const handleFontWeightChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const fontWeight = e.target.value as 'normal' | 'bold' | 'bolder' | 'lighter' | number;
    setTextOptions({ ...textOptions, fontWeight });
    updateActiveText({ fontWeight });
  };

  const updateActiveText = (options: Partial<fabric.ITextboxOptions>) => {
    if (!editor) return;
    const activeObject = editor.canvas.getActiveObject() as fabric.Textbox;
    if (activeObject && activeObject.type === 'textbox') {
      activeObject.set(options);
      editor.canvas.renderAll();
    }
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file || !editor) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      const imgElement = new Image();
      imgElement.src = event.target?.result as string;
      imgElement.onload = () => {
        const imgInstance = new fabric.Image(imgElement, {
          left: 0,
          top: 0,
          scaleX: editor.canvas.width! / imgElement.width,
          scaleY: editor.canvas.height! / imgElement.height,
          selectable: false,
        });

        editor.canvas.setBackgroundImage(imgInstance, editor.canvas.renderAll.bind(editor.canvas));
      };
    };
    reader.readAsDataURL(file);
  };

  const deleteBackgroundImage = () => {
    if (editor && editor.canvas) {
      editor.canvas.backgroundImage = undefined; // Clear the background image
      editor.canvas.renderAll();
    }
  };

  return (
    <div className="flex flex-row mt-10 items-center">
      <div className="">
        <FabricJSCanvas className="border-2 border-gray-800 w-[450px] h-[600px]" onReady={onReady} />
      </div>
      <div className="flex justify-center  flex-wrap mt-4 space-x-4">
        <button onClick={addText} className="bg-blue-500 text-white rounded hover:bg-blue-700">
          Add Text
        </button>
        <button onClick={deleteText} className="bg-red-500 text-white rounded hover:bg-red-700">
          Delete Text
        </button>
        <label>
          Font Size:
          <input type="range" min="10" max="50" value={textOptions.fontSize} onChange={handleFontSizeChange} />
          {textOptions.fontSize}
        </label>
        <label>
          Font Family:
          <select value={textOptions.fontFamily} onChange={handleFontFamilyChange}>
          {fontFamiliesDefault.map((family:any) => <option value={family} key={family}>{family}</option>)}
          </select>
        </label>
        <label>
          Text Color:
          <input type="color" value={textOptions.textColor} onChange={(e) => handleTextColorChange(e.target.value)} />
        </label>
        <label>
          Font Style:
          <select value={textOptions.fontStyle} onChange={handleFontStyleChange}>
            <option value="normal">Normal</option>
            <option value="italic">Italic</option>
            <option value="oblique">Oblique</option>
          </select>
        </label>
        <label>
          Font Weight:
          <select value={textOptions.fontWeight} onChange={handleFontWeightChange}>
            <option value="normal">Normal</option>
            <option value="bold">Bold</option>
            <option value="bolder">Bolder</option>
            <option value="lighter">Lighter</option>
          </select>
        </label>
        <div>
        <label htmlFor='image'>select Background Image</label>
        <input id='image' type="file" accept="image/*" onChange={handleImageUpload} className=" hidden px-2 py-2 bg-green-500 text-white rounded hover:bg-green-700" />
        </div>
        <button onClick={deleteBackgroundImage} className="bg-yellow-500 text-white rounded hover:bg-yellow-700">
          Delete Background
        </button>
      </div>
    </div>
  );
};

export default CreateTemplate;
 */

/* import React, { useState, useCallback } from 'react';
import Editor from './Editor';
import TextOptions from './TextOptions';
import { fabric } from 'fabric';

const CreateTemplate = () => {
  const [textOptions, setTextOptions] = useState({
    fontSize: 20,
    fontFamily: 'Arial',
    textColor: '#000000',
    fontStyle: 'normal' as 'normal' | 'italic' | 'oblique' | undefined,
    fontWeight: 'normal' as 'normal' | 'bold' | 'bolder' | 'lighter' | number | undefined,
  });

  const handleFontSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFontSize = parseInt(e.target.value);
    setTextOptions((prevOptions) => ({ ...prevOptions, fontSize: newFontSize }));
  };

  const handleFontFamilyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newFontFamily = e.target.value;
    setTextOptions((prevOptions) => ({ ...prevOptions, fontFamily: newFontFamily }));
  };

  const handleTextColorChange = (color: string) => {
    setTextOptions((prevOptions) => ({ ...prevOptions, textColor: color })); 
  };

  const handleFontStyleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newFontStyle = e.target.value as 'normal' | 'italic' | 'oblique';
    setTextOptions((prevOptions) => ({ ...prevOptions, fontStyle: newFontStyle }));
  };

  const handleFontWeightChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newFontWeight = e.target.value as 'normal' | 'bold' | 'bolder' | 'lighter' | number;
    setTextOptions((prevOptions) => ({ ...prevOptions, fontWeight: newFontWeight }));
  };

  const updateActiveText = useCallback((editor: any, options: Partial<fabric.ITextboxOptions>) => {
    const canvas = editor?.canvas;
    if (canvas) {
      const activeObject = canvas.getActiveObject() as fabric.Textbox;
      if (activeObject && activeObject.type === 'textbox') {
        activeObject.set(options);
        canvas.renderAll();
      }
    }
  }, []);



  return (
    <div className="flex flex-col mt-10 items-center">
      <Editor textOptions={textOptions} updateActiveText={updateActiveText} />
      <TextOptions
        textOptions={textOptions}
        onFontSizeChange={handleFontSizeChange}
        onFontFamilyChange={handleFontFamilyChange}
        onTextColorChange={handleTextColorChange}
        onFontStyleChange={handleFontStyleChange}
        onFontWeightChange={handleFontWeightChange}
      />
    </div>
  );
};

export default CreateTemplate; */