import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { Button } from "../../components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog"
import { Label } from "../../components/ui/label"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select"
import { useGetAllCategoryQuery, useGetSubcategoryQuery } from "../../redux/slice/CategoryApiSlice"
import { toast } from "react-toastify"
import { Input } from "../../components/ui/input"
import { useSearchParams } from "react-router-dom"
import { FilterByCategoryTemplate, FilterBySubCategoryTemplate } from "./Templates"
import { useAddTransparentImageAMutation, useDeleteTransparentImageAMutation, useGetTransparentImagesAMutation, useUpdateTransparentImageAMutation } from "../../redux/slice/admin/ALogoApiSlice"
import { IMGURL } from "../../constants/appConfig"
import { FaEdit } from "react-icons/fa"
import { MdDelete } from "react-icons/md"

const TransparentImages = () => {
  const [getTransparentImages, { data: transparentImages }] = useGetTransparentImagesAMutation()

  const [searchParams]=useSearchParams()
  const catId=searchParams.get("catId")||1;
  const subcatId=searchParams.get("subCatId")||"All";
  const [condEdDe,setCondEdDe]=useState<boolean>(false)

 useEffect(() => {
    if(subcatId==="All"){
      getTransparentImages({ categoryId:catId,subcategoryId:0})
    }else if(catId && subcatId){
      getTransparentImages({ subcategoryId:subcatId, categoryId:catId })
    }
  }, [catId,subcatId,getTransparentImages,condEdDe]) 



  return (
    <div className="wrapper-dashboard">
      <div className="flex justify-between gap-4 flex-wrap">
        <h5 className="text-xl font-bold">Transperant Images</h5>
        <div className="flex gap-6 flex-wrap">
        <FilterByCategoryTemplate catId={catId.toString()} />
        <FilterBySubCategoryTemplate catId={catId.toString()} subCatId={subcatId}/>
        </div>
        <AddImageToCategory setCondEdDe={setCondEdDe}/>
      </div>
      <div className="my-4">
        {/* <p className="font-medium">Attribute Name: {attributeName}</p> */}
        <div className="flex gap-2 items-center flex-wrap my-4">
       {transparentImages?.result?.length > 0 ?
        transparentImages?.result?.map((Image: any) => <div key={Image.id} className="flex flex-col relative gap-4 shadow-lg rounded-lg border border-gray-500">
          <img src={`${IMGURL}${Image.imageUrl}`} alt={"new"} className="w-56 h-56 bg-red-500 rounded-lg"/>
          <div className="absolute flex gap-1 bottom-0 right-0 bg-gray-400 rounded-lg">
         <EditTranImage  Image={Image} setCondEdDe={setCondEdDe}/>
           <DeleteTranImage  id={Image.id} setCondEdDe={setCondEdDe}/></div>
           <p className="text-sm p-1 font-semibold">SubCategoryId: {Image.subcategoryId}</p>
          </div>
          ):
        
        <p className="text-xl font-bold">Selected Filters There Is No Transperent Images</p>}
        </div>
      </div>
    </div>
  )
}


const AddImageToCategory = ({ setCondEdDe }: { setCondEdDe:Dispatch<SetStateAction<boolean>> }) => {
  const [categoryId, setCategoryId] = useState<number>(1)
  const [subcategoryId, setSubcategoryId] = useState<number>(0)
  const [transperantImg, setTransperantImg] = useState<string>("")
  const [addTransparentImage] = useAddTransparentImageAMutation()
  const [open, setOpen] = useState(false)

  const handleSubmit = async () => {
    if(!transperantImg){
      toast.error("Select the Transperant Image")
    }
    await addTransparentImage({ categoryId,subcategoryId,  imageUrl: transperantImg })
    setOpen(false)
    setTransperantImg("")
    setCondEdDe(p=>!p)
  }

  const handleClose = () => {
    setOpen(false)
    setTransperantImg("")
  }

  return (
    <Dialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      <DialogTrigger asChild>
        <Button variant="default" className="headermenu">Add Transperent</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Add Transperent Images</DialogTitle>
          <p className="text-sm my-1 text-red-500"></p>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="flex items-center gap-4">
            <Label htmlFor="Category" className="text-right">
              Category: T Shirt
            </Label>
            {/* <FilterByCategory onChangeHandler={setCategoryId} categoryId={categoryId} /> */}
          </div>
          <div className="flex items-center gap-4">
            <Label htmlFor="subCategory" className="text-right">
              SubCategory:
            </Label>
            <FilterBySubCategory onChangeHandler={setSubcategoryId} subcategoryId={subcategoryId} categoryId={categoryId} />
          </div>
          <div className="">
            <div className="flex items-center space-x-2">
            <Label htmlFor="template" className="text-right">
                Choose Template/Logo
              </Label>
              <Input id="template" type="file" className="col-span-3 input-field" accept="image/*, application/pdf"
                        onChange={(event) => {
                          const file = event.target.files && event.target.files[0];
                          if (file) {
                            const reader = new FileReader();
                            reader.onload = () => {
                              const base64String = reader.result as string;
                              setTransperantImg(base64String);
                            };
                            reader.readAsDataURL(file); // Convert the selected file to Base64
                          }
                        }}/>
            </div>
            {transperantImg&& <img src={transperantImg} alt="preview" className="w-32 h-32 bg-gray-500"/>}
          </div>

        </div>
        <DialogFooter>
          <Button onClick={handleSubmit}>Add Sides</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>)
}

export function DeleteTranImage({ id,setCondEdDe }: { id: number,setCondEdDe:Dispatch<SetStateAction<boolean>> }) {
  const [open, setOpen] = useState(false)
  const [deleteTransparentImage] = useDeleteTransparentImageAMutation()
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <AlertDialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      <AlertDialogTrigger asChild>
        <Button variant={"ghost"} ><MdDelete size={"20"} color="red"  /></Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete Transperant related to Category/SubCategory.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={() => {deleteTransparentImage(id); setCondEdDe(p=>!p)}} className="bg-red-500" >Delete</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

const EditTranImage = ({ Image,setCondEdDe }: {Image:any,setCondEdDe:Dispatch<SetStateAction<boolean>>}) => {
  const [categoryId, setCategoryId] = useState<number>(1)
  const [subcategoryId, setSubcategoryId] = useState<number>(1)
  const [transperantImg, setTransperantImg] = useState<string>("")
  const [updateTransparentImage] = useUpdateTransparentImageAMutation()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setCategoryId(Image.categoryId)
    setSubcategoryId(Image.subcategoryId)
  }, [Image])

  const handleSubmit = async () => {
    await updateTransparentImage({ id:Image?.id, categoryId,subcategoryId,  imageUrl:transperantImg.length>0?transperantImg: Image.imageUrl,isImageurlChanged:transperantImg.length>0&&true })
    setOpen(false)
    setCondEdDe(p=>!p)
  }

 
  const handleClose = () => {
    setOpen(false)
  }


  return (
    <Dialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      <DialogTrigger asChild>
        <Button variant="ghost" ><FaEdit size={"20"} /></Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Edit Sides To SubCategory</DialogTitle>
          <p className="text-sm my-1 text-red-500"></p>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="flex items-center gap-4">
            <Label htmlFor="Category" className="text-right">
              Category:T shirt
            </Label>
           {/*  <FilterByCategory onChangeHandler={setCategoryId} categoryId={categoryId} /> */}
          </div>
          <div className="flex items-center gap-4">
            <Label htmlFor="subCategory" className="text-right">
              SubCategory:
            </Label>
            <FilterBySubCategory onChangeHandler={setSubcategoryId} subcategoryId={subcategoryId} categoryId={categoryId} />
          </div>
          <div className="">
            <div className="flex items-center space-x-2">
            <Label htmlFor="template" className="text-right">
                Choose Trasperant Image
              </Label>
              <Input id="template" type="file" className="col-span-3 input-field" accept="image/*, application/pdf"
                        onChange={(event) => {
                          const file = event.target.files && event.target.files[0];
                          if (file) {
                            const reader = new FileReader();
                            reader.onload = () => {
                              const base64String = reader.result as string;
                              setTransperantImg(base64String);
                            };
                            reader.readAsDataURL(file); // Convert the selected file to Base64
                          }
                        }}/>
            </div>
            {transperantImg? <img src={transperantImg} alt="preview" className="w-32 h-32 bg-gray-500"/>:<img src={`${IMGURL}${Image.imageUrl}`} alt="preview" className="w-32 h-32 bg-gray-500"/>}
          </div>

        </div>
        <DialogFooter>
          <Button onClick={handleSubmit}>Edit Sides</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>)
}

export const FilterByCategory = ({ onChangeHandler, categoryId }: any) => {
  const { data: categories } = useGetAllCategoryQuery("categoriesList", { pollingInterval: 60000, refetchOnFocus: true, refetchOnMountOrArgChange: true })

  const handleValueChange = (selectedValue: string) => {
    onChangeHandler(selectedValue);
  };

  return (<div className="flex gap-1 items-center">
    <Select onValueChange={handleValueChange} value={categoryId.toString()} >
      <SelectTrigger className="bg-blue-50 text-md font-semibold px-0">
        <SelectValue placeholder="T Shirts" />
      </SelectTrigger>
      <SelectContent className='border-gray-500 bg-gray-100  hover:text-black '>
        {categories?.result?.map((category: any) => (
          <SelectItem
            key={category.id}
            value={category.id.toString()}
            className="select-item p-regular-14"
          >
            {category.categoryName}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  </div>);
};
export const FilterBySubCategory = ({ onChangeHandler, subcategoryId, categoryId }: any) => {
  const { data: subCategories } = useGetSubcategoryQuery(categoryId, { pollingInterval: 60000, refetchOnFocus: true, refetchOnMountOrArgChange: true })

  const handleValueChange = (selectedValue: string) => {
    onChangeHandler(Number(selectedValue));
  };

  return (<div className="flex gap-1 items-center">
    <Select onValueChange={handleValueChange} value={subcategoryId.toString()} >
      <SelectTrigger className="bg-blue-50 text-md font-semibold px-0">
        <SelectValue placeholder="All" />
      </SelectTrigger>
      <SelectContent className='border-none bg-gray-100  hover:text-black '>
        <SelectItem
          key={"All"}
          value={"0"}
          className="select-item p-regular-14"
        >
          All
        </SelectItem>
        {subCategories?.result?.map((subCategory: any) => (
          <SelectItem
            key={subCategory.id}
            value={subCategory.id.toString()}
            className="select-item p-regular-14"
          >
            {subCategory.subcategoryName}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  </div>);
};


export default TransparentImages