import PopularProducts, { CustomSection, DecorativeProducts,  MobileAccessories,  OfficeStationary } from "../components/shared/Home/PopularProducts";
import CarouselCom  from "../components/shared/Home/Carousel";
import { Separator } from "../components/ui/separator";

const ImageCom = ()=>{
  return <div className="wrapper" style={{height:"450px"}}>
  <img src="/assets/p1.jpg" alt="print" className="w-full h-full"/>
      </div>
}
const Home = () => {
  return (
    <>
    <CarouselCom />
    <PopularProducts/>
    <Separator className="max-w-6xl mx-auto"/>
    <DecorativeProducts/>
    <CustomSection/>
    <MobileAccessories/>
    <Separator className="max-w-6xl mx-auto my-2"/>
    <OfficeStationary/>
    </>
  )
}

export default Home