import { Textarea } from "../../../components/ui/textarea"
import { Label } from "../../../components/ui/label"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../components/ui/select"
import { useGetProductAttributesByAttNameMutation } from "../../../redux/slice/admin/AAttributeApiSlice"
import { useEffect, useState } from "react"
import { PaperQualityT, PaperSizeT, PaperTypeItem, serailNoTypeT } from "@/react-app-env"
//import { serialNotype } from "../../../constants"

const SelectOfficeSationary = ({setOfficeAttribute,officeAttribute, catId, subcatId, setAllOptionsOfficeSet,setExtraAmount,setAllOptionsOfficeSetCheck }: any) => {
    const [productAttByAttName, { data: productAttributes }] = useGetProductAttributesByAttNameMutation()
    const [productAttByAttNameSerialNo, { data: productAttributesSerialNo }] = useGetProductAttributesByAttNameMutation()
    let attributeName = "Office Stationary"
    const [selectedType, setSelectedType] = useState<PaperTypeItem>();
    const [containsId, setContainsId] = useState<string>("0");
    const [colorId, setColorId] = useState<string>("0");
    const [typeId, setTypeId] = useState<string>("0");
    const [pSize, setPSize] = useState<string>("0");
    const [pQua, setPQua] = useState<string>("0");
    const [sNo, setSNo] = useState<number>(0);
    const [paperSizesSet, setPaperSizesSet] = useState<PaperSizeT[]>([]);
    const [paperQualitySet, setPaperQualitySet] = useState<PaperQualityT[]>([]);
    const [paperTypeSet, setPaperTypeSet] = useState<PaperTypeItem[]>([]);
    const [paperSerialNoSet, setPaperSerialNoSet] = useState<serailNoTypeT[]>([]);
    const [AttValueObj, setAttValueObj] = useState(null);
    const [eTotalPrice, setETotalPrice] = useState<number>(0);
    const [count, setCount] = useState<number>(0);
    const [selectOffice, setSelectOffice] = useState({ type: false, typeSet: false, qua: false, quaSet: false, size: false, sizeSet: false })
   
    

    useEffect(() => {
        if ((selectOffice.type === selectOffice.typeSet) && (selectOffice.qua === selectOffice.quaSet) && (selectOffice.size === selectOffice.sizeSet)&&(count>0)) {
            setAllOptionsOfficeSet(true)
            setExtraAmount(eTotalPrice)
        }
    }, [count, selectOffice, setAllOptionsOfficeSet,setExtraAmount,eTotalPrice])

    useEffect(() => {
        if ((selectOffice.type) || (selectOffice.qua) || (selectOffice.size)) {
            setAllOptionsOfficeSetCheck(true)
        }
    }, [selectOffice, setAllOptionsOfficeSetCheck])

    useEffect(() => {
        productAttByAttName({ catId, subcatId, attributeName })
    }, [productAttByAttName, attributeName, catId, subcatId])

    useEffect(() => {
        const AttValue = productAttributes?.result[0]?.attributeValue
        const AttValueObj = AttValue ? JSON.parse(AttValue) : null;
        setAttValueObj(AttValueObj)
        const paperSizesSet = AttValueObj ? AttValueObj["paperSizes"] : null;
        setPaperSizesSet(paperSizesSet)
        const paperQualitySet = AttValueObj ? AttValueObj["paperQua"] : null;
        setPaperQualitySet(paperQualitySet)
        const paperTypeSet = AttValueObj ? AttValueObj["paperType"] : null;
        setPaperTypeSet(paperTypeSet) 
    }, [productAttributes])

    useEffect(() => {
        if(paperTypeSet?.length > 0){
            productAttByAttNameSerialNo({ catId: 11, subcatId: 0, attributeName:'Office Stationary Fixed' })
        }
    }, [paperTypeSet, productAttByAttNameSerialNo])

    useEffect(() => {
        const AttValue = productAttributesSerialNo?.result[0]?.attributeValue
        const AttValueObj = AttValue ? JSON.parse(AttValue) : null;
        const paperSizesSet = AttValueObj ? AttValueObj["SerialNo"] : null;
        setPaperSerialNoSet(paperSizesSet) 
    }, [productAttributesSerialNo])



    /*     const calculatePrice = () => {
            let price = 0;
            // Add logic to calculate the price based on selected options
            if (pSize !== 0) price += paperSizesSet.find((size: any) => size.id === pSize)?.ePrice || 0;
            if (pQua !== 0) price += paperQualitySet.find((quality: any) => quality.id === pQua)?.ePrice || 0;
            if (typeId !== 0) price += selectedType?.ePrice || 0;
            if (containsId !== 0) price += selectedType?.contains.find((contain: any) => contain.cid === containsId)?.ePrice || 0;
            setTotalPrice(price);
        } */

    useEffect(() => {
        let price = 0;
        if (pSize !== "0") price += paperSizesSet?.find((size: any) => size.id === pSize)?.ePrice || 0;
        if (pQua !== "0") price += paperQualitySet?.find((quality: any) => quality.id === pQua)?.ePrice || 0;
        if (typeId !== "0") price += selectedType?.ePrice || 0;
        if (containsId !== "0") price += selectedType?.contains.find((contain: any) => contain.id === containsId)?.ePrice || 0;
        setETotalPrice(price);
    }, [pSize, pQua, typeId, containsId, selectedType, paperSizesSet, paperQualitySet]);

    useEffect(() => {
        if (paperTypeSet?.length > 0) {
            if (containsId !== "0" && colorId !== "0" && typeId !== "0" && sNo !== 0) {
                setSelectOffice(pre => ({ ...pre, "typeSet": true }))
                const paperData=paperTypeSet.filter(pt=>pt.id===typeId)
                const containsData=paperData[0]?.contains.find(cd=>cd.id===containsId)
                const colorsData=paperData[0]?.colors.find(cd=>cd.id===colorId)
                const selectOp={type:{paperType:paperData[0].type,id:paperData[0].id,enabled:paperData[0].enabled,ePrice:paperData[0].ePrice},
                               PaperConstains:containsData,paperColors:colorsData }
               setOfficeAttribute((pre:any)=>({...pre,paperType:{...selectOp}}))
            }else if(containsId === "0" || colorId === "0" || typeId === "0" || sNo === 0){
                setSelectOffice(pre => ({ ...pre, "typeSet": false }))
                setOfficeAttribute((pre:any)=>({...pre,paperType:{}}))
            }
            setSelectOffice(pre => ({ ...pre, "type": true }))
            setCount(pre=>pre+1)
        }
    }, [colorId, containsId, sNo, typeId, paperTypeSet, setOfficeAttribute])


    useEffect(() => {
        if (paperQualitySet?.length > 0) {
            if (pQua !=="0") {
                setSelectOffice(pre => ({ ...pre, "quaSet": true }))
                const paperData=paperQualitySet.filter(pt=>pt.id===pQua)
                setOfficeAttribute((pre:any)=>({...pre,paperQuality:{...paperData[0]}}))
            }else if (pQua ==="0") {
                setSelectOffice(pre => ({ ...pre, "quaSet": false }))
                setOfficeAttribute((pre:any)=>({...pre,paperQuality:{}}))
            }
            setSelectOffice(pre => ({ ...pre, "qua": true }))
            setCount(pre=>pre+1)
        }
    }, [pQua, paperQualitySet, setOfficeAttribute])

    useEffect(() => {
        if (paperSizesSet?.length > 0) {
            if (pSize !== "0") {
                setSelectOffice(pre => ({ ...pre, "sizeSet": true }))
                const paperData=paperSizesSet.filter(pt=>pt.id===pSize)
                setOfficeAttribute((pre:any)=>({...pre,paperSize:{...paperData[0]}}))
            }else if (pSize === "0") {
                setSelectOffice(pre => ({ ...pre, "sizeSet": false }))
                setOfficeAttribute((pre:any)=>({...pre,paperSize:{}}))
            }
            setSelectOffice(pre => ({ ...pre, "size": true }))
            setCount(pre=>pre+1)
        }
    }, [pSize, paperSizesSet, setOfficeAttribute])

    const handleTypeChange = (value: any) => {
        const numericValue = Number(value);
        setTypeId(value)
        const attR = paperTypeSet.filter((item: PaperTypeItem) => item.id === value)
        setSelectedType(attR[0])
        if (numericValue === 0) {
            setColorId("0"); setContainsId("0")
            setAllOptionsOfficeSet(false)
        }
    }

    const handleContainChange = (value: any) => {
        const numericValue = Number(value);
        setContainsId(value)
        if (numericValue !== 0) {
            const attR = paperTypeSet.filter((item: PaperTypeItem) =>
                item.contains.some((contain: any) => contain.id === value)
            );
            setSelectedType(attR[0])
        }
        else if (typeId === "0" && colorId === "0" && numericValue === 0) {
            const attR = paperTypeSet.filter((item: PaperTypeItem) =>
                item.colors.some((contain: any) => contain.id === numericValue));
            setSelectedType(attR[0])
            setAllOptionsOfficeSet(false)
        }
        /*   calculatePrice() */
    }

    const handleColorChange = (value: any) => {
        const numericValue = Number(value);
        setColorId(value)
        if (numericValue !== 0) {
            const attR = paperTypeSet.filter((item: PaperTypeItem) =>
                item.colors.some((contain: any) => contain.id === value));
            setSelectedType(attR[0])
        }
        else if (typeId === "0" && containsId === "0" && numericValue === 0) {
            const attR = paperTypeSet.filter((item: PaperTypeItem) =>
                item.colors.some((contain: any) => contain.id === numericValue));
            setSelectedType(attR[0])
            setAllOptionsOfficeSet(false)
        }
        /* calculatePrice() */
    }

    const handleSizeChange = (value: any) => {
        const numericValue = Number(value);
        setPSize(value)
        /*   calculatePrice() */
        if(numericValue===0){
            setAllOptionsOfficeSet(false)
        }
    }

    const handleQualityChange = (value: any) => {
        const numericValue = Number(value);
        setPQua(value)
        if(numericValue===0){
            setAllOptionsOfficeSet(false)
        }
    }

    const handleNumberingChange = (value: any) => {
        const numericValue = Number(value);
        setSNo(value)
        const numbering = paperSerialNoSet.filter(nu=>nu.id===value)
        setOfficeAttribute((pre:any)=>({...pre,SerailNo:{...numbering[0]}}))
        /*   calculatePrice() */
        if(numericValue===0){
            setAllOptionsOfficeSet(false)
        }
    }



    return (
        <div className=" w-full">
            <p>Select Options:</p>
            {AttValueObj && <div className="grid md:gap-4 gap-2 md:grid-cols-2 grid-cols-1 my-4 w-full">
                {paperSizesSet?.length > 0 && <div className="flex gap-3 items-center">
                    <Label className="font-bold md:min-w-28">Paper Size:*</Label>
                    <Select value={pSize?.toString()} onValueChange={handleSizeChange}>
                        <SelectTrigger className="bg-blue-50 text-md font-semibold px-3 w-full ">
                            <SelectValue placeholder="----Select----" />
                        </SelectTrigger>
                        <SelectContent className='border-gray-500 bg-gray-50  hover:text-black'>
                            <SelectItem key={0} value={"0"}>---Select---</SelectItem>
                            {paperSizesSet?.map((sizes: any) => (
                                <SelectItem
                                    key={sizes.id}
                                    value={sizes?.id?.toString()}
                                    className="select-item p-regular-14"
                                >
                                    {sizes.paper}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select></div>}
                {paperQualitySet?.length > 0 && <div className="flex gap-3 items-center">
                    <Label className="font-bold md:min-w-28">Paper Quality:*</Label>
                    <Select value={pQua?.toString()} onValueChange={handleQualityChange}>
                        <SelectTrigger className="bg-blue-50 text-md font-semibold px-3 w-full">
                            <SelectValue placeholder="----Select----" />
                        </SelectTrigger>
                        <SelectContent className='border-gray-500 bg-gray-50  hover:text-black '>
                            <SelectItem key={0} value={"0"}>---Select---</SelectItem>
                            {paperQualitySet?.map((quality: any) => (
                                <SelectItem
                                    key={quality?.id}
                                    value={quality?.id?.toString()}
                                    className="select-item p-regular-14 font-semibold"
                                >
                                    {quality.quality}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select></div>}
                {(selectedType) ? <div className="flex gap-3 items-center">
                    <Label className="font-bold md:min-w-28">Paper Type:*</Label><Select onValueChange={handleTypeChange} value={typeId?.toString()}>
                        <SelectTrigger className="bg-blue-50 text-md font-semibold px-3 w-full ">
                            <SelectValue placeholder="---Select---" />
                        </SelectTrigger>
                        <SelectContent className='border-gray-500 bg-gray-50  hover:text-black '>
                            <SelectItem key={0} value={"0"}>---Select---</SelectItem>
                            <SelectItem
                                key={selectedType.id}
                                value={selectedType?.id?.toString()}
                                className="select-item p-regular-14"
                            >
                                {selectedType.type}
                            </SelectItem>
                        </SelectContent>
                    </Select></div> : (paperTypeSet?.length > 0 && <div className="flex gap-3 items-center">
                        <Label className="font-bold md:min-w-28">Paper Type:*</Label><Select onValueChange={handleTypeChange} value={typeId?.toString()}>
                            <SelectTrigger className="bg-blue-50 text-md font-semibold px-3 w-full ">
                                <SelectValue placeholder="---Select---" />
                            </SelectTrigger>
                            <SelectContent className='border-gray-500 bg-gray-50  hover:text-black '>
                                <SelectItem key={0} value={"0"}>---Select---</SelectItem>
                                {paperTypeSet?.filter((typeP: any) => typeP.enabled)?.map((typeP: any) => (
                                    <SelectItem
                                        key={typeP.id}
                                        value={typeP?.id?.toString()}
                                        className="select-item p-regular-14"
                                    >
                                        {typeP.type}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select></div>)}
                {selectedType ? (
                    <><div className="flex gap-3 items-center">
                        <Label className="font-bold md:min-w-28">Paper Contains:*</Label>
                        <Select onValueChange={handleContainChange} value={containsId?.toString()}>
                            <SelectTrigger className="bg-blue-50 text-md font-semibold px-3 w-full">
                                <SelectValue placeholder="----Select----" />
                            </SelectTrigger>
                            <SelectContent className='border-gray-500 bg-gray-50 hover:text-black'>
                                <SelectItem key={0} value={"0"}>
                                    ---Select---
                                </SelectItem>
                                {selectedType?.contains?.filter((contain: any) => contain.enabled)?.map((contain: any) => (
                                    <SelectItem key={contain.id} value={contain.id?.toString()}>
                                        {contain.contain}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select></div>
                        <div className="flex gap-3 items-center">
                            <Label className="font-bold md:min-w-28">Paper Colors:*</Label>
                            <Select onValueChange={handleColorChange} value={colorId?.toString()}>
                                <SelectTrigger className="bg-blue-50 text-md font-semibold px-3 w-full">
                                    <SelectValue placeholder="----Select Color----" />
                                </SelectTrigger>
                                <SelectContent className='border-gray-500 bg-gray-50 hover:text-black'>
                                    <SelectItem key={0} value={"0"}>
                                        ---Select---
                                    </SelectItem>
                                    {selectedType?.colors?.filter((color: any) => color.enabled)?.map((color: any) => (
                                        <SelectItem key={color.id} value={color?.id?.toString()}>
                                            {color.color}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select></div>
                    </>
                ) : (
                    <>
                        {paperTypeSet?.length > 0 && <div className="flex gap-3 items-center">
                            <Label className="font-bold md:min-w-28">Paper Contains:*</Label><Select onValueChange={handleContainChange} value={containsId?.toString()}>
                                <SelectTrigger className="bg-blue-50 text-md font-semibold px-3 w-full">
                                    <SelectValue placeholder="----Select Contains----" />
                                </SelectTrigger>
                                <SelectContent className='border-gray-500 bg-gray-50 hover:text-black'>
                                    <SelectItem key={0} value={"0"}>
                                        ---Select---
                                    </SelectItem>
                                    {paperTypeSet?.filter((typeP: any) => typeP.enabled)?.flatMap((type: any) => type.contains).filter((contain: any) => contain?.enabled).map((contain: any) => (
                                        <SelectItem key={contain.id} value={contain?.id?.toString()}>
                                            {contain.contain}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select></div>}

                        {paperTypeSet?.length > 0 && <div className="flex gap-3 items-center">
                            <Label className="font-bold md:min-w-28">Paper Colors:*</Label><Select onValueChange={handleColorChange} value={colorId?.toString()}>
                                <SelectTrigger className="bg-blue-50 text-md font-semibold px-3 w-full">
                                    <SelectValue placeholder="----Select Color----" />
                                </SelectTrigger>
                                <SelectContent className='border-gray-500 bg-gray-50 hover:text-black'>
                                    <SelectItem key={0} value={"0"}>---Select---</SelectItem>
                                    {paperTypeSet?.filter((typeP: any) => typeP.enabled)?.flatMap((type: any) => type.colors).filter((contain: any) => contain?.enabled).map((color: any) => (
                                        <SelectItem key={color.id} value={color?.id?.toString()}>
                                            {color.color}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select></div>}
                    </>
                )}
                {paperTypeSet?.length > 0 && <div className="flex gap-3 items-center">
                    <Label className="font-bold md:min-w-28">Serial No:*</Label><Select onValueChange={handleNumberingChange} value={sNo?.toString()}>
                        <SelectTrigger className="bg-blue-50 text-md font-semibold px-3 w-full ">
                            <SelectValue placeholder="---Select---" />
                        </SelectTrigger>
                        <SelectContent className='border-gray-500 bg-gray-50  hover:text-black '>
                            <SelectItem key={0} value={"0"}>---Select---</SelectItem>
                            {paperSerialNoSet?.length > 0 && paperSerialNoSet?.map((item: any) => <SelectItem
                                key={item.id}
                                value={item?.id?.toString()}
                                className="select-item p-regular-14"
                            >
                                {item.type}
                            </SelectItem>)}
                        </SelectContent>
                    </Select></div>}
                {paperTypeSet?.length > 0 && <div className="md:col-span-2">
                    <Label className="font-bold">Instructions To Printer:</Label>

                    <Textarea className="textarea" value={officeAttribute.instructions} onChange={e=>setOfficeAttribute((pre:any)=>({...pre,instructions:e.target.value}))}/>
                </div>}
            </div>}
        </div>
    )

}
export default SelectOfficeSationary