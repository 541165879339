
import { Button } from "../../components/ui/button";
import { PaperQualityT, PaperSizeT, PaperTypeItem, serailNoTypeT } from "@/react-app-env";
import { useEffect, useState } from "react";
import { useGetProductAttributesByAttNameMutation, useSaveProductAttributeSidesMutation, useUpdateProductAttributeMutation } from "../../redux/slice/admin/AAttributeApiSlice";
// import { PaperQuality, PaperSizes, PaperTypeFixed } from "../../constants";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Label } from "../../components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select"
import { Card } from "../../components/ui/card";
import { Switch } from "../../components/ui/switch";
import { IoMdClose } from "react-icons/io";
import { v4 as uuidv4 } from 'uuid';
import { FilterBySubCategory } from "./StationaryAttributes";
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../../components/ui/dialog"
import {
    AlertDialog,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "../../components/ui/alert-dialog"
import { Input } from "../../components/ui/input"
import { Separator } from "../../components/ui/separator";
import { FaEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import SationaryPaperType from "../../components/shared/admin/components/OfficeSationary/Sationary";

const AddAttributeOffice = () => {

    return (
        <div className="max-w-full md:mx-20 md:my-10 border-2 border-gray-300 md:p-4">
            <div className="flex justify-between gap-4">
                <h5 className="h5-bold mb-4">Add Fields Stationary</h5>
            </div>
            <AddOfficeToCategory /* type="Create" */ />
        </div>
    )
}


const AddOfficeToCategory = () => {
    //const [categoryId, setCategoryId] = useState<number>(11)
    const categoryId = 11;
    const [subcategoryId, setSubcategoryId] = useState<number>(25)
    const [saveProductAttribute] = useSaveProductAttributeSidesMutation()
    const [paperSIzeEn, setPaperSIzeEn] = useState<boolean>(false)
    const [paperSizes, setPaperSizes] = useState<PaperSizeT[]>([])
    const [paperQuaEn, setPaperQuaEn] = useState<boolean>(false)
    const [paperQua, setPaperQua] = useState<PaperQualityT[]>([])
    const [paperTypeEn, setPaperTypeEn] = useState<boolean>(false)
    const [paperType, setPaperType] = useState<PaperTypeItem[]>([])
    const navigate = useNavigate()
    const [productAttByAttName, { data: productAttributes }] = useGetProductAttributesByAttNameMutation()
    const attributeName = 'Office Stationary Fixed'
    const [paperSizesSet, setPaperSizesSet] = useState([])
    const [paperQualitySet, setPaperQualitySet] = useState([])
    const [paperTypeSet, setpaperTypeSet] = useState([])



    useEffect(() => {
        productAttByAttName({ catId: 11, subcatId: 0, attributeName })
    }, [productAttByAttName, attributeName])

    useEffect(() => {
        const AttValue = productAttributes?.result[0]?.attributeValue
        const AttValueObj = AttValue ? JSON.parse(AttValue) : null;
        const paperSizesSet = AttValueObj ? AttValueObj["PaperSizes"] : null;
        setPaperSizesSet(paperSizesSet)
        const paperQualitySet = AttValueObj ? AttValueObj["PaperQuality"] : null;
        setPaperQualitySet(paperQualitySet)
        const paperTypeSet = AttValueObj ? AttValueObj["PaperType"] : null;
        setpaperTypeSet(paperTypeSet)
        setPaperType(paperTypeSet)
    }, [productAttributes])

    const handleSubmit = async () => {
        if (!paperSIzeEn && !paperQuaEn && !paperTypeEn) {
            toast.error("Select atleast one property")
        }
        const valuesAtt = JSON.stringify({
            ...(paperSIzeEn ? { paperSizes } : {}),
            ...(paperQuaEn ? { paperQua } : {}),
            ...(paperTypeEn ? { paperType } : {})
        });
        await saveProductAttribute({ categoryId, subcategoryId, attributeName: "Office Stationary", attributeValue: valuesAtt })
        navigate(`/admin/dashboard/attributes/stationary?catId=${categoryId}&subCatId=${subcategoryId}`)
    }

    const handleChangeSizes = (selectedValue: any) => {
        setPaperSizes(prev => {
            const exists = prev.find(val => val.id === selectedValue.id);
            if (exists) {
                return prev.map(val =>
                    val.id === selectedValue.id ? selectedValue : val
                );
            } else {
                return [...prev, selectedValue];
            }
        });
    };

    const handleChangeQuality = (selectedValue: any) => {
        setPaperQua(prev => {
            const exists = prev.find(val => val.id === selectedValue.id);
            if (exists) {
                return prev.map(val =>
                    val.id === selectedValue.id ? selectedValue : val
                );
            } else {
                return [...prev, selectedValue];
            }
        });
    };

    const handlePropertyToggle = (id: string, property: keyof PaperTypeItem) => {
        setPaperType(prevState =>
            prevState.map(item =>
                item.id === id
                    ? {
                        ...item, "enabled": !item.enabled
                        /*  [property]: Array.isArray(item[property]) // Type Guard
                           ? (item[property] as Array<any>).map((subItem: any) => ({ ...subItem, enabled: !subItem.enabled }))
                           : item.type === '' ? 'Default Value' : '' // Assume it's a string property */
                    }
                    : item
            )
        );
    };

    const handleSubPropertyToggle = (id: string, property: keyof PaperTypeItem, subPropertyId: number) => {
        setPaperType(prevState =>
            prevState.map(item =>
                item.id === id && Array.isArray(item[property]) // Type Guard
                    ? {
                        ...item,
                        [property]: (item[property] as Array<any>).map((subItem: any) =>
                            subItem.id === subPropertyId || subItem.id === subPropertyId
                                ? { ...subItem, enabled: !subItem.enabled }
                                : subItem
                        )
                    }
                    : item
            )
        );
    };

    return (<>
        <div className=" py-4">

            <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-4">
                <Label htmlFor="Category" className="font-bold text-lg" >
                    Category: Office Stationary
                </Label>
                <div className="flex items-center gap-4 w-full">
                    <Label htmlFor="subCategory">
                        SubCategory:
                    </Label>
                    <FilterBySubCategory onChangeHandler={setSubcategoryId} subcategoryId={subcategoryId} categoryId={categoryId} />
                </div>

            </div>

            <div className="grid md:grid-cols-2  grid-cols-1 items-center gap-6 my-4">
                <Card className="w-full p-1 py-2 border-gray-500">
                    <div className="flex justify-between">
                        <div className="flex items-center space-x-2">
                            <Switch id="size" checked={paperSIzeEn}
                                onCheckedChange={(value) => setPaperSIzeEn(value)} />
                            <Label htmlFor="size">PAPER SIZE:</Label>
                        </div>
                        <div className="bg-gray-500">

                        </div>
                    </div>
                    {paperSIzeEn && <div className="p-2 space-y-2">
                        {paperSizes?.length > 0 && <div className="flex gap-2 flex-wrap">
                            {paperSizes.map(pap => <p key={pap.id} style={{ fontSize: "12px" }} className="p-2 shadow-md bg-gray-200 rounded-full px-3 flex items-center gap-1 text-sm">{pap.paper}
                                <button onClick={() => setPaperSizes(pre => ([...pre.filter(pr => pr.id !== pap.id)]))}><IoMdClose /></button>
                            </p>)}
                        </div>}
                        <Select onValueChange={handleChangeSizes}   >
                            <SelectTrigger className="bg-blue-50 text-md font-semibold px-0  w-full">
                                <SelectValue placeholder="----Select----" />
                            </SelectTrigger>
                            <SelectContent className='border-gray-500 bg-gray-100  hover:text-black '>
                                {paperSizesSet?.map((sizes: any) => (
                                    <SelectItem
                                        key={sizes.id}
                                        value={sizes}
                                        className="select-item p-regular-14"
                                    >
                                        {sizes.paper}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>}
                </Card>
                <Card className="w-full p-1 py-2 border-gray-500">
                    <div className="flex items-center space-x-2">
                        <Switch id="size" checked={paperQuaEn}
                            onCheckedChange={(value) => setPaperQuaEn(value)} />
                        <Label htmlFor="size">PAPER QUALITY:</Label>
                    </div>
                    {paperQuaEn && <div className="p-2 space-y-2">
                        {paperQua.length > 0 && <div className="flex gap-2 flex-wrap">
                            {paperQua.map(pap => <p key={pap.id} style={{ fontSize: "12px" }} className="p-2 shadow-md bg-gray-200 rounded-full px-3 flex items-center gap-1 text-sm">{pap.quality}
                                <button onClick={() => setPaperQua(pre => ([...pre.filter(pr => pr.id !== pap.id)]))}><IoMdClose /></button>
                            </p>)}
                        </div>}
                        <Select onValueChange={handleChangeQuality}   >
                            <SelectTrigger className="bg-blue-50 text-md h-12 font-semibold p-2  w-full">
                                <SelectValue placeholder="----Select Quality----" />
                            </SelectTrigger>
                            <SelectContent className='border-gray-500 bg-gray-100  hover:text-black '>
                                {paperQualitySet?.map((qual: any) => (
                                    <SelectItem
                                        key={qual.id}
                                        value={qual}
                                        className="select-item p-regular-14"
                                    >
                                        {qual.quality}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>}
                </Card>
                <Card className="w-full p-1 py-2 col-span-2 border-gray-500">
                    <div className="flex items-center space-x-2">
                        <Switch id="size" checked={paperTypeEn}
                            onCheckedChange={(value) => setPaperTypeEn(value)} />
                        <Label htmlFor="size">PAPER CONDITIONS:</Label>
                    </div>
                    {paperTypeEn && <div className="p-2 space-y-2">
                        {paperTypeSet?.length > 0 && <div className="flex gap-2 flex-wrap">
                            {paperType?.map((item: PaperTypeItem) => (
                                <div key={item.id} style={{ marginBottom: '10px', border: '1px solid #ccc', padding: '10px' }}>
                                    <div>
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={item.enabled}
                                                onChange={() => handlePropertyToggle(item.id, 'type')}
                                            />
                                            Type: {item.type}
                                        </label>
                                    </div>

                                    <div>
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={item.contains.some((c: any) => c.enabled)}
                                                onChange={() => handlePropertyToggle(item.id, 'contains')}
                                            />
                                            Contains:
                                            {item.contains.map((c: any) => (
                                                <div key={c.id}>
                                                    <input
                                                        type="checkbox"
                                                        checked={c.enabled}
                                                        onChange={() => handleSubPropertyToggle(item.id, 'contains', c.id)}
                                                    />
                                                    {c.contain}
                                                </div>
                                            ))}
                                        </label>
                                    </div>

                                    <div>
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={item.colors.some((c: any) => c.enabled)}
                                                onChange={() => handlePropertyToggle(item.id, 'colors')}
                                            />
                                            Colors:
                                            {item.colors.map((c: any) => (
                                                <div key={c.id}>
                                                    <input
                                                        type="checkbox"
                                                        checked={c.enabled}
                                                        onChange={() => handleSubPropertyToggle(item.id, 'colors', c.id)}
                                                    />
                                                    {c.color}
                                                </div>
                                            ))}
                                        </label>
                                    </div>
                                </div>
                            ))}
                        </div>}
                    </div>}
                </Card>
            </div>

        </div>
        <div className="flex justify-between gap-4">
            <Button onClick={handleSubmit} className="headermenu">Add Stationary Fields</Button>
            <Button onClick={() => navigate(-1)}>Cancel</Button>
        </div>

    </>
    )
}


export function AddNew() {
    const [productAttByAttName, { data: productAttributes }] = useGetProductAttributesByAttNameMutation()
    const attributeName = 'Office Stationary Fixed'
    const [paperSizesSet, setPaperSizesSet] = useState([])
    const [paperQualitySet, setPaperQualitySet] = useState([])
    const [paperTypeSet, setPaperTypeSet] = useState([])
    const [paperSerialNoSet, setPaperSerialNoSet] = useState([])
    const [properties, setProperties] = useState({ pr: "", ePrice: 0 })
    const [variant, setVariant] = useState("")
    const [cond, setCond] = useState(false)
    const [updateProductAttribute] = useUpdateProductAttributeMutation()


    useEffect(() => {
        productAttByAttName({ catId: 11, subcatId: 0, attributeName })
    }, [productAttByAttName, attributeName, cond])

    useEffect(() => {
        const AttValue = productAttributes?.result[0]?.attributeValue
        const AttValueObj = AttValue ? JSON.parse(AttValue) : null;
        const paperSizesSet = AttValueObj ? AttValueObj["PaperSizes"] : null;
        setPaperSizesSet(paperSizesSet)
        const paperQualitySet = AttValueObj ? AttValueObj["PaperQuality"] : null;
        setPaperQualitySet(paperQualitySet)
        const paperTypeSet = AttValueObj ? AttValueObj["PaperType"] : null;
        setPaperTypeSet(paperTypeSet)
        const paperSerialNoSet = AttValueObj ? AttValueObj["SerialNo"] : null;
        setPaperSerialNoSet(paperSerialNoSet)
    }, [productAttributes])

    const handleSubmit = async () => {
        if (!properties.pr) {
            toast.error("Fill the mandatory fields")
            return;
        }
        let attributeValueNew;
        if (variant === "Sizes") {
            const newProperties = { id: uuidv4(), paper: properties.pr, ePrice: properties.ePrice }
            attributeValueNew = { PaperSizes: [...paperSizesSet, newProperties], PaperType: paperTypeSet, SerialNo: paperSerialNoSet, PaperQuality: paperQualitySet }
        } else if (variant === "Quality") {
            const newProperties = { id: uuidv4(), quality: properties.pr, ePrice: properties.ePrice }
            attributeValueNew = { PaperSizes: paperSizesSet, PaperType: paperTypeSet, SerialNo: paperSerialNoSet, PaperQuality: [...paperQualitySet, newProperties] }
        } else if (variant === "SerialNo") {
            const newProperties = { id: uuidv4(), type: properties.pr }
            attributeValueNew = { PaperSizes: paperSizesSet, PaperType: paperTypeSet, SerialNo: [...paperSerialNoSet, newProperties], PaperQuality: paperQualitySet }
        }
        await updateProductAttribute({ id: productAttributes?.result[0]?.id, attributeName: attributeName, attributeValue: JSON.stringify(attributeValueNew) })
        setVariant("")
        setCond(p => !p)
        setProperties({ pr: "", ePrice: 0 })
        toast.success("Added/updated Changes")

    }


    return (<>
        <Separator className="my-2" />
        {paperSizesSet?.length > 0 && <div className="w-full shadow-md bg-gray-200 p-3 rounded-md">
            <div className="flex justify-between gap-4 my-2">
                <p className="text-md font-semibold">Paper Sizes Available:</p>
                <Button onClick={() => setVariant("Sizes")} variant="default" >Add New Sizes</Button>
                {variant === "Sizes" && <AddOffProperty setVariant={setVariant} type={variant} setProperties={setProperties} handleSubmit={handleSubmit} properties={properties} />}
            </div>
            <div className=" flex gap-4 flex-wrap">
                {paperSizesSet?.map((size: PaperSizeT) => <div className="border flex gap-4 border-gray-400 bg-blue-200 p-2 py-3 rounded-lg" key={size.id}>
                    <div className="flex flex-col justify-between">
                        <p className="font-medium text-gray-500">Size: <span className="text-black">{size.paper}</span></p>
                        <p className="font-medium  text-gray-500">Extra Price: <span className="text-black">{size.ePrice}</span></p>
                    </div>
                    <EditDeleteButtons setCond={setCond} id={productAttributes?.result[0]?.id} key={size.id} size={size} step={"EditSizes"} paperSizesSet={paperSizesSet} paperQualitySet={paperQualitySet} paperTypeSet={paperTypeSet} paperSerialNoSet={paperSerialNoSet} />
                </div>)}
            </div>
        </div>}
        <Separator className="my-2" />
        {paperQualitySet?.length > 0 && <div className="w-full shadow-md bg-gray-200 p-3 rounded-md">
            <div className="flex justify-between gap-4 my-2">
                <p className="text-md font-semibold">Quality Available:</p>
                <Button variant="default" onClick={() => setVariant("Quality")} >Add New Quality</Button>
                {variant === "Quality" && <AddOffProperty setVariant={setVariant} type={variant} setProperties={setProperties} handleSubmit={handleSubmit} properties={properties} />}
            </div>
            <div className=" flex gap-4 flex-wrap">
                {paperQualitySet?.map((size: PaperQualityT) => <div className="border flex gap-4 border-gray-400 bg-blue-200 p-2 py-3 rounded-lg" key={size.id}>
                    <div className="flex flex-col justify-between">
                        <p className="font-medium text-gray-500">Size: <span className="text-black">{size.quality}</span></p>
                        <p className="font-medium  text-gray-500">Extra Price: <span className="text-black">{size.ePrice}</span></p>
                    </div>
                    <EditDeleteButtons setCond={setCond} id={productAttributes?.result[0]?.id} key={size.id} size={size} step={"EditQuality"} paperSizesSet={paperSizesSet} paperQualitySet={paperQualitySet} paperTypeSet={paperTypeSet} paperSerialNoSet={paperSerialNoSet} />
                </div>)}
            </div>
        </div>}

        <Separator className="my-2" />
        {paperSerialNoSet?.length > 0 && <div className="w-full shadow-md bg-gray-200 p-3 rounded-md">
            <div className="flex justify-between gap-4 my-2">
                <p className="text-md font-semibold ">Serail No Type Available:</p>
                <Button variant="default" onClick={() => setVariant("SerialNo")} >Add New SerialNo</Button>
                {variant === "SerialNo" && <AddOffProperty setVariant={setVariant} type={variant} setProperties={setProperties} handleSubmit={handleSubmit} properties={properties} />}
            </div>
            <div className=" flex gap-4 flex-wrap">
                {paperSerialNoSet?.map((size: serailNoTypeT) => <div className="border flex gap-4 border-gray-400 bg-blue-200 p-2 py-3 rounded-lg" key={size.id}>
                    <div className="flex flex-col justify-between">
                        <p className="font-medium text-gray-500">Serail No: <span className="text-black">{size.type}</span></p>
                    </div>
                    <EditDeleteButtons setCond={setCond} id={productAttributes?.result[0]?.id} key={size.id} size={size} step={"EditSerialNo"} paperSizesSet={paperSizesSet} paperQualitySet={paperQualitySet} paperTypeSet={paperTypeSet} paperSerialNoSet={paperSerialNoSet} />
                </div>)}
            </div>
        </div>}
        <Separator className="my-2" />
        <SationaryPaperType setCond={setCond} id={productAttributes?.result[0]?.id} paperSizesSet={paperSizesSet} paperQualitySet={paperQualitySet} paperTypeSet={paperTypeSet} paperSerialNoSet={paperSerialNoSet} />
    </>);
}

const AddOffProperty = ({ type, setProperties, properties, handleSubmit, setVariant }: { type: string, setVariant: any, setProperties: any, properties: any, handleSubmit: () => void }) => {

    const [open, setOpen] = useState(true)
    const handleClose = () => {
        setOpen(false)
        setVariant("")
        setProperties({ pr: "", ePrice: 0 })
    }
    return (<Dialog open={open} onOpenChange={() => {
        if (!open) {
            setOpen(true)
        } else {
            handleClose()
        }
    }}>
        {/* <DialogTrigger asChild>
        <Button variant="default">Add New {type}</Button>
    </DialogTrigger> */}
        <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
                <DialogTitle>Add New {type}</DialogTitle>
            </DialogHeader>
            <Separator />
            <div className="grid gap-4 py-4">
                <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="name" className="text-right">
                        {type} Name:*
                    </Label>
                    <Input id="name" required value={properties.pr} onChange={(e) => setProperties((pre: any) => ({ ...pre, pr: e.target.value }))} className="col-span-3 input-field" />
                </div>
                {type !== "SerialNo" && <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="price" className="text-right">
                        Extra Price:*
                    </Label>
                    <Input id="price" required value={properties.ePrice} onChange={(e) => setProperties((pre: any) => ({ ...pre, ePrice: +e.target.value }))} className="col-span-3 input-field" />
                </div>}
            </div>
            <DialogFooter className="w-full flex gap-4">
                <Button type="button" onClick={handleSubmit} className="bg-green-700">Save</Button>
                <Button type="button" onClick={handleClose}>cancel</Button>
            </DialogFooter>
        </DialogContent>
    </Dialog>)
}


type EditButtonProps = {
    size: any,
    setCond: any,
    id: number,
    step: string,
    paperSizesSet: any,
    paperQualitySet: any,
    paperTypeSet: any,
    paperSerialNoSet: any,
}
const EditDeleteButtons: React.FC<EditButtonProps> = ({
    size,
    step,
    setCond,
    id,
    paperSizesSet,
    paperQualitySet,
    paperTypeSet,
    paperSerialNoSet
}) => {
    const [editVariant, setEditVariant] = useState("")
    const [deleteVariant, setDeleteVariant] = useState("")

    return (<div className="flex flex-col gap-1 bg-gray-200 rounded-lg">
        {editVariant === step && <EditOffProperty setCond={setCond} id={id} setEditVariant={setEditVariant} type={editVariant} property={size} paperSizesSet={paperSizesSet} paperQualitySet={paperQualitySet} paperTypeSet={paperTypeSet} paperSerialNoSet={paperSerialNoSet} />}
        {deleteVariant === step && <AlertDialogDelete setCond={setCond} id={id} setDeleteVariant={setDeleteVariant} type={deleteVariant} property={size} paperSizesSet={paperSizesSet} paperQualitySet={paperQualitySet} paperTypeSet={paperTypeSet} paperSerialNoSet={paperSerialNoSet} />}
        <Button onClick={() => setEditVariant(step)} variant={"ghost"}><FaEdit size={20} color="blue" /></Button>
        <Button onClick={() => setDeleteVariant(step)} variant={"ghost"}><MdDeleteOutline size={20} color="red" /></Button>
    </div>)
}


type editProps = {
    type: string,
    setEditVariant: any,
    property: any,
    setCond: any,
    id: number,
    paperSizesSet: any,
    paperQualitySet: any,
    paperTypeSet: any,
    paperSerialNoSet: any,
}
const EditOffProperty = ({ type, setCond, id, setEditVariant, property, paperSizesSet, paperQualitySet, paperTypeSet, paperSerialNoSet }: editProps) => {
    const [properties, setProperties] = useState({ pr: "", ePrice: 0 })
    const [updateProductAttribute] = useUpdateProductAttributeMutation()
    const [open, setOpen] = useState(true)
    //  console.log(type, property);

    useEffect(() => {
        if (type === "EditSerialNo") {
            setProperties({ pr: property.type, ePrice: property.ePrice })
        } else if (type === "EditQuality") {
            setProperties({ pr: property.quality, ePrice: property.ePrice })
        } else if (type === "EditSizes") {
            setProperties({ pr: property.paper, ePrice: property.ePrice })
        }

    }, [property, type])

    const handleClose = () => {
        setOpen(false)
        setEditVariant("")
        setProperties({ pr: "", ePrice: 0 })
    }

    const handleSubmit = async () => {
        if (!properties.pr) {
            toast.error("Fill the mandatory fields")
            return;
        }
        let attributeValueNew;
        if (type === "EditSizes") {
            const oldProperties = JSON.parse(JSON.stringify(paperSizesSet.filter((paper: any) => paper.id !== property.id)))
            const newProperties = { id: uuidv4(), paper: properties.pr, ePrice: properties.ePrice }
            attributeValueNew = { PaperSizes: [...oldProperties, newProperties], PaperType: paperTypeSet, SerialNo: paperSerialNoSet, PaperQuality: paperQualitySet }
        } else if (type === "EditQuality") {
            const oldProperties = JSON.parse(JSON.stringify(paperQualitySet.filter((paper: any) => paper.id !== property.id)))
            const newProperties = { id: uuidv4(), quality: properties.pr, ePrice: properties.ePrice }
            attributeValueNew = { PaperSizes: paperSizesSet, PaperType: paperTypeSet, SerialNo: paperSerialNoSet, PaperQuality: [...oldProperties, newProperties] }
        } else if (type === "EditSerialNo") {
            const oldProperties = JSON.parse(JSON.stringify(paperSerialNoSet.filter((paper: any) => paper.id !== property.id)))
            const newProperties = { id: uuidv4(), type: properties.pr }
            attributeValueNew = { PaperSizes: paperSizesSet, PaperType: paperTypeSet, SerialNo: [...oldProperties, newProperties], PaperQuality: paperQualitySet }
        }

        await updateProductAttribute({ id, attributeName: 'Office Stationary Fixed', attributeValue: JSON.stringify(attributeValueNew) })
        setEditVariant("")
        setCond((p: boolean) => !p)
        setProperties({ pr: "", ePrice: 0 })
        toast.success("Added/updated Changes")

    }


    return (<Dialog open={open} onOpenChange={() => {
        if (!open) {
            setOpen(true)
        } else {
            handleClose()
        }
    }}>
        {/* <DialogTrigger asChild>
        <Button variant="default">Add New {type}</Button>
    </DialogTrigger> */}
        <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
                <DialogTitle>Edit Fields {type}</DialogTitle>
            </DialogHeader>
            <Separator />
            <div className="grid gap-4 py-4">
                <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="name" className="text-right">
                        {type} Name:*
                    </Label>
                    <Input id="name" required value={properties.pr} onChange={(e) => setProperties((pre: any) => ({ ...pre, pr: e.target.value }))} className="col-span-3 input-field" />
                </div>
                {type !== "EditSerialNo" && <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="price" className="text-right">
                        Extra Price:*
                    </Label>
                    <Input id="price" required value={properties.ePrice} onChange={(e) => setProperties((pre: any) => ({ ...pre, ePrice: +e.target.value }))} className="col-span-3 input-field" />
                </div>}
            </div>
            <DialogFooter className="w-full flex gap-4">
                <Button type="button" onClick={handleSubmit} className="bg-green-700">Save</Button>
                <Button type="button" onClick={handleClose}>cancel</Button>
            </DialogFooter>
        </DialogContent>
    </Dialog>)
}

type DeleteProps = {
    type: string,
    setDeleteVariant: any,
    property: any,
    setCond: any,
    id: number,
    paperSizesSet: any,
    paperQualitySet: any,
    paperTypeSet: any,
    paperSerialNoSet: any,
}
export function AlertDialogDelete({ type, setCond, id, setDeleteVariant, property, paperSizesSet, paperQualitySet, paperTypeSet, paperSerialNoSet }: DeleteProps) {
    const [open, setOpen] = useState(true)
    const [updateProductAttribute] = useUpdateProductAttributeMutation()

    const handleClose = () => {
        setOpen(false)
        setDeleteVariant("")
    }
    const handleSubmit = async () => {
        let attributeValueNew;
        if (type === "EditSizes") {
            const oldProperties = JSON.parse(JSON.stringify(paperSizesSet.filter((paper: any) => paper.id !== property.id)))
            attributeValueNew = { PaperSizes: [...oldProperties], PaperType: paperTypeSet, SerialNo: paperSerialNoSet, PaperQuality: paperQualitySet }
        } else if (type === "EditQuality") {
            const oldProperties = JSON.parse(JSON.stringify(paperQualitySet.filter((paper: any) => paper.id !== property.id)))
            attributeValueNew = { PaperSizes: paperSizesSet, PaperType: paperTypeSet, SerialNo: paperSerialNoSet, PaperQuality: [...oldProperties] }
        } else if (type === "EditSerialNo") {
            const oldProperties = JSON.parse(JSON.stringify(paperSerialNoSet.filter((paper: any) => paper.id !== property.id)))
            attributeValueNew = { PaperSizes: paperSizesSet, PaperType: paperTypeSet, SerialNo: [...oldProperties], PaperQuality: paperQualitySet }
        }
        await updateProductAttribute({ id, attributeName: 'Office Stationary Fixed', attributeValue: JSON.stringify(attributeValueNew) })
        setDeleteVariant("")
        setCond((p: boolean) => !p)
        toast.success("Delete Changes Successfully")
        setOpen(false)
    }
    return (
        <AlertDialog open={open} onOpenChange={() => {
            if (!open) {
                setOpen(true)
            } else {
                handleClose()
            }
        }}>
           {/*  <AlertDialogTrigger asChild>
                <Button variant="outline">Show Dialog</Button>
            </AlertDialogTrigger> */}
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                    <AlertDialogDescription>
                        This action cannot be undone. This will permanently delete data related to Particular stationary.
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <Button className="bg-red-700" onClick={handleSubmit} >Delete</Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default AddAttributeOffice