import { APIURL } from '../constants/appConfig';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


const baseQuery = fetchBaseQuery({
    baseUrl: APIURL,
    prepareHeaders: (headers, { getState }) => {
      const userDetailsString = localStorage.getItem("pfuser");
      const userDetails = userDetailsString ? JSON.parse(userDetailsString) : null;
  
      if (userDetails?.token) {
        headers.set("authorization", `Bearer ${userDetails.token}`);
      } 
      return headers;
    }
  });
  

/* const baseQueryWithReauth = async (args, api, extraOptions) => {
    // console.log(args) // request url, method, body
    // console.log(api) // signal, dispatch, getState()
    // console.log(extraOptions) //custom like {shout: true}

    let result = await baseQuery(args, api, extraOptions)

    // If you want, handle other status codes, too
    if (result?.error?.status === 403) {
        console.log('sending refresh token')

        // send refresh token to get new access token 
        const refreshResult = await baseQuery('/auth/refresh', api, extraOptions)

        if (refreshResult?.data) {

            // store the new token 
            api.dispatch(setCredentials({ ...refreshResult.data }))

            // retry original query with new access token
            result = await baseQuery(args, api, extraOptions)
        } else {

            if (refreshResult?.error?.status === 403) {
                refreshResult.error.data.message = "Your login has expired."
            }
            return refreshResult
        }
    }

    return result
} */

export const apiSlice = createApi({
    baseQuery,
    tagTypes: ["Logo",'Transparent','Cart','Printarea','Banner', 'User','Category','Product','Templates','SubCategory','Images','Address','Order','Attribute',"Razorpay","Dashboard"],
    endpoints: builder => ({})
})