import React, { ChangeEvent, useState } from 'react';
import { fabric } from 'fabric';

import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "../../../../../components/ui/select"
import { Label } from '../../../../../components/ui/label';
import { Input } from '../../../../../components/ui/input';
import { Button } from '../../../../../components/ui/button';
import { useGetAllPrintareaShapesQuery } from '../../../../../redux/slice/admin/APrintAreaApiSlice';
import { TPrintareaShapes } from '@/react-app-env';



const shapes: Record<ShapeType, string> = {
    heart: 'M 272 178 C 272 134 220 134 200 160 C 180 134 128 134 128 178 C 128 222 200 278 200 278 C 200 278 272 222 272 178 Z',
    circle: 'M 50,50 m -50,0 a 50,50 0 1,0 100,0 a 50,50 0 1,0 -100,0', // Corrected circle path
    square: 'M 50 50 L 150 50 L 150 150 L 50 150 Z',
    hexagon: 'M 50 25 L 100 50 L 100 100 L 50 125 L 0 100 L 0 50 Z',
    star: 'M 100,10 L 120,60 L 180,60 L 130,90 L 150,140 L 100,110 L 50,140 L 70,90 L 20,60 L 80,60 Z',
    flower: 'M 50,150 Q 75,130 100,150 Q 125,170 150,150 Q 170,130 150,100 Q 130,75 150,50 Q 170,25 150,0 Q 125,-20 100,0 Q 75,20 50,0 Q 25,-20 0,0 Q -20,25 0,50 Q 20,75 0,100 Q -20,130 0,150 Q 25,170 50,150 Z',
    oval: 'M 100,50 a 50,25 0 1,0 100,0 a 50,25 0 1,0 -100,0',
    customShape: 'M 100,50 C 120,70 140,90 160,70 C 180,50 160,30 140,50 C 120,70 100,90 80,70 C 60,50 80,30 100,50 Z', // Custom shape resembling the uploaded image wrong
};

/* const shape = {
    heart: 'M 272 168 C 264 152 244 140 220 140 C 196 140 176 152 168 168 C 160 184 152 192 140 192 C 128 192 120 184 112 168 C 104 152 84 140 60 140 C 36 140 16 152 8 168 C 0 184 0 208 0 240 C 0 288 56 336 112 384 C 168 336 224 288 224 240 C 224 208 224 184 216 168 Z',
    circle: 'M 50 50 A 50 50 0 1 1 100 100 A 50 50 0 1 1 50 50 Z',
    square: 'M 50 50 L 150 50 L 150 150 L 50 150 Z',
    hexagon: 'M 50 25 L 100 50 L 100 100 L 50 125 L 0 100 L 0 50 Z',
    star: 'M 100,10 L 120,60 L 180,60 L 130,90 L 150,140 L 100,110 L 50,140 L 70,90 L 20,60 L 80,60 Z',
    flower: 'M 50 25 Q 25 50 50 75 Q 75 50 50 25 Q 25 0 50 -25 Q 75 0 50 25 Z M 100 50 Q 75 75 100 100 Q 125 75 100 50 Q 75 25 100 0 Q 125 25 100 50 Z M 150 75 Q 125 100 150 125 Q 175 100 150 75 Q 125 50 150 25 Q 175 50 150 75 Z',
}; */

type ShapeType = 'heart' | 'circle' | 'square' | 'hexagon' | 'star' | 'flower' | 'oval' | 'customShape';

interface ShapeOptions {
    stroke: string;
    strokeWidth:number;
    fill:string;
    opacity:number
  }

const PrintableAreaShapes = ({ editor }: any) => {
    const [selectedShape, setSelectedShape] = useState<string>('M 50,50 m -50,0 a 50,50 0 1,0 100,0 a 50,50 0 1,0 -100,0');
    const [shapeOptions,setShapeOptions] =useState<ShapeOptions>({stroke:"#000000",strokeWidth:.5,fill: "rgba(255, 255, 255, 0)",opacity:1})
    const { data: printshapes ,isLoading,isError,isSuccess} = useGetAllPrintareaShapesQuery({})

 

    const handleShapeChange = (value: string) => {
        setSelectedShape(value);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { id, value, type } = event.target;
        setShapeOptions(prev => ({
          ...prev,
          [id]: type === 'range' ? parseFloat(value) : value
        }));
        handleUpdateSelectedShape();
      };

    const handleUpdateSelectedShape=()=>{
        if(!editor) return;
        const activeObject = editor.canvas.getActiveObject() ;
        if (activeObject && activeObject?.id === 'printableArea') {
            activeObject.set({
                stroke: shapeOptions.stroke,
                strokeWidth: shapeOptions.strokeWidth,
                fill: shapeOptions.fill,
                opacity: shapeOptions.opacity
              });
            editor.canvas.renderAll();
          }
    }

    const handleAddShape = () => {
        if (!editor) return;

        const shapePath = new fabric.Path(selectedShape, {
            top: 50,
            left: 50,
            fill: shapeOptions.fill,
            stroke: shapeOptions.stroke,
            strokeWidth: shapeOptions.strokeWidth,
            opacity: shapeOptions.opacity,
            selectable: true,
        });
        (shapePath as any).id = 'printableArea';
        editor.canvas.add(shapePath);
        editor.canvas.renderAll();
    };

    return (
        <div className=' my-1 flex flex-wrap gap-3'>

            <Select value={selectedShape} onValueChange={handleShapeChange}>
                <SelectTrigger className="w-[180px] bg-gray-100" >
                    <SelectValue placeholder="Heart" />
                </SelectTrigger>
                <SelectContent>
                    <SelectGroup>
                      {printshapes?.result?.map((area:TPrintareaShapes)=> <SelectItem key={area.id} value={area.dimensions}>{area.shapeName}</SelectItem>)}
                       {/*  <SelectItem value="heart">Heart</SelectItem>
                        <SelectItem value="circle">Circle</SelectItem>
                        <SelectItem value="square">Square</SelectItem>
                        <SelectItem value="hexagon">Hexagon</SelectItem>
                        <SelectItem value="star">Star</SelectItem>
                        <SelectItem value="oval">Oval</SelectItem>
                        <SelectItem value="customShape">customShape</SelectItem> */}
                    </SelectGroup>
                </SelectContent>
            </Select>
            <Button onClick={handleAddShape} type='button' className='headermenu'>Add Shape</Button>
              <Label className='flex items-center'>
                Border color:
                <Input type="color" id='stroke' className='w-16' value={shapeOptions.stroke} onChange={handleChange} />
              </Label>
              <Label className='flex items-center'>
               Fill color:
                <Input type="color" className='w-16' id='fill' value={shapeOptions.fill} onChange={handleChange} />
              </Label> 
              <Label className='flex items-center'>
                Border Size:
                <Input className='w-28' type="range" min={0} step={.1} max={5} id='strokeWidth' value={shapeOptions.strokeWidth} onChange={handleChange} />
                <p className='mx-2'>{shapeOptions.strokeWidth}</p>
              </Label>
           
              <Label className='flex items-center'>
                Opacity:
                <Input className='w-28' type="range" min={0} step={.1} max={5} id='opacity' value={shapeOptions.opacity} onChange={handleChange} />
                <p className='mx-2'>{shapeOptions.opacity}</p>
              </Label>
      

           
        </div>

    );
};

export default PrintableAreaShapes
