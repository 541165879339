import {  Link, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { SlashIcon } from "@radix-ui/react-icons"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../components/ui/breadcrumb"
import { TemplateT } from '@/react-app-env'
import { Card } from '../components/ui/card'
import { IMGURL } from '../constants/appConfig'
import { useGetProductsByDesignMutation } from '../redux/slice/ProductsApiSlice'
import { FaChevronRight } from 'react-icons/fa'


const TemplatesBySubCategory = () => {
  const { subCatId } = useParams();
  const [getProductDesigns,{data:productDesigns,isLoading,isError,isSuccess}]=useGetProductsByDesignMutation()

  useEffect(()=>{
    getProductDesigns({subcatId:subCatId})
  },[getProductDesigns,subCatId])


  let content
  if (isLoading) content= <section className="bgcolorgold" ><p className="p-4">Loading...</p></section>;
  if (isError) content = <p>{productDesigns?.result?.message}</p>
  if (!productDesigns?.result?.length){
    content= <section className="bgcolorgold" >
    <p className="p-4 text-center text-lg font-semibold">
      No Templates Available Under This SubCategory 
    </p></section>}
  if (isSuccess&& productDesigns?.result?.length>0){
    content=   <div className="wrapper flex flex-wrap md:gap-4 gap-4 my-2">
  {productDesigns?.result?.map((product: any) => (
    <Card key={product.id} className="w-[270px] bg-white rounded-lg shadow-lg overflow-hidden border border-gray-500 transition-transform transform hover:scale-105">
      <Link to={`/productdesign/${product.id}`} className="w-full h-full flex flex-col">
        <img
          src={`${IMGURL}${product.imageUrl}`}
          alt="template"
          className="w-full h-[250px] object-fill "
        />  
        <div className=''>
        {/*  <p className='text-center font-semibold text-md line-clamp-1 py-1'>{product.productName}</p>
         <p className='text-center font-semibold text-md line-clamp-1'>{new Intl.NumberFormat('en-IN',{currency:"INR",style:"currency"}).format(product.priceINR)}</p> */}
         <p className=' py-4  px-3 font-semibold text-xl rounded-b-lg w-auto text-white headermenu flex items-center justify-between' >Customize Design <FaChevronRight /></p>
        </div>
        
      </Link>
    </Card>
  ))}

  </div>
  }
  return (<section className="bgcolorgold" >
    <div>
    {content}
    </div>
    </section>);
};


export function BreadcrumbWithCustomSeparator({data}:{data: TemplateT}) {
  return (
    <Breadcrumb className='h5-bold  sm:m-2 md:my-4 md:px-72'>
      <BreadcrumbList>
        <BreadcrumbItem>
          {/* <BreadcrumbLink href="/">Home</BreadcrumbLink> */}
          <Link to="/">Home</Link>
        </BreadcrumbItem>
        <BreadcrumbSeparator>
          <SlashIcon />
        </BreadcrumbSeparator>
        <BreadcrumbItem>
          <BreadcrumbPage className='font-bold text-lg'>{data.subcategoryId}</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  )
}
export default TemplatesBySubCategory;

