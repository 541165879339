import { Button } from "../../../../components/ui/button";
import { Form, FormField, FormItem, FormLabel, FormMessage } from "../../../../components/ui/form";
import { Input } from "../../../../components/ui/input";
import { useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const formSchema = z.object({
  images: z.string(),
});

const UploadImages = ({ id }: { id: string }) => {
  const [imagesBase64, setImagesBase64] = useState<string[]>([]);
  const navigate = useNavigate();
//  const[uploadImages] = useAddProductImagesAMutation()
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      images: "",
    },
  });

  const onSubmit =async (values: z.infer<typeof formSchema>) => {
  /* await Promise.all(
      imagesBase64.map(async (item: any) => {
        const response = await uploadImages({ productId:id,image:item});
        if ('error' in response) {
          console.error('error:', response.error);
          return;
        }
        console.log('Images are added to product', response?.data);
      })
    );
  navigate("/admin/dashboard/products") */
  
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const newImagesBase64: string[] = [];
      const readAsDataURL = (file: File) => {
        return new Promise<string>((resolve) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve(reader.result as string);
          };
          reader.readAsDataURL(file);
        });
      };
      for (let i = 0; i < Math.min(5 - imagesBase64.length, files.length); i++) {
        const base64String = await readAsDataURL(files[i]!);
        newImagesBase64.push(base64String);
      }
      setImagesBase64((prev) => [...prev, ...newImagesBase64]);
    }
  };
  

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <div className="flex flex-col gap-2">
          <FormField
            control={form.control}
            name="images"
            render={({ field }) => (
              <FormItem className="w-[500px]">
                <FormLabel>Choose Multiple Images for the Product (Max:5 Images)</FormLabel>
                <Input
                  type="file"
                  accept="image/*, application/pdf"
                  multiple
                  className="input-field"
                  onChange={handleFileChange}
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex flex-wrap gap-4">
            {imagesBase64.length > 0 &&
              imagesBase64.map((image: any, index: number) => (
                <img key={index} src={image} alt={`uploadedimage-${index}`} width="70" height="70" />
              ))}
          </div>
        </div>

        <div className="flex md:justify-between items-center md:flex-row flex-col">
          <Button type="submit" size="lg" className="button col-span-1 headermenu">
            Upload Images
          </Button>
          <Button onClick={() => navigate(-1)} size="lg" className="button col-span-1 bg-red-400">
            Cancel
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default UploadImages;
