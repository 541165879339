import { apiSlice } from "../../../redux/apiSlice";


export const AproductsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        saveProductTemplate: builder.mutation({
            query: initialProdData => ({
                url: '/admin/saveProductTemplate',
                method: 'POST',
                body: {
                    ...initialProdData,
                }
            }),
            invalidatesTags: [
                { type: 'Templates', id: "LIST" }
            ]
        }),
        copyProductTemplate: builder.mutation({
            query: initialProdData => ({
                url: '/admin/copyProductTemplate',
                method: 'POST',
                body: {
                    ...initialProdData,
                }
            }),
            invalidatesTags: [
                { type: 'Templates', id: "LIST" }
            ]
        }),
        updateProductTemplate: builder.mutation({
            query: initialProdData => ({
                url: '/admin/updateProductTemplate',
                method: 'POST',
                body: {
                    ...initialProdData,
                }
            }),
            invalidatesTags: [
                { type: 'Templates', id: "LIST" }
            ]
        }),
        getProductTemplate: builder.mutation({
            query: initialProdData => ({
                url: '/common/getProductTemplate',
                method: 'POST',
                body: {
                    ...initialProdData,
                }
            }),
            invalidatesTags: [
                { type: 'Templates', id: "LIST" }
            ]
        }),
        getProductTemplateById: builder.query({
            query: id => ({
                url: `/common/getProductTemplateById/${id}`,
            }),
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                  return [
                    { type: 'Templates', id: 'LIST' },
                    ...result.ids.map((id:any) => ({ type: 'Templates', id })),
                  ];
                } else return [{ type: 'Templates', id: 'LIST' }];
              },
        }),
        deleteCategoryTemplateA: builder.mutation({
            query: ( id ) => ({
                url: `/admin/deleteProductTemplate/${id}`,
                method: 'DELETE',
                }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Templates', id: arg.id }
            ]
        }),
    }),
})

export const {
    useSaveProductTemplateMutation,
    useUpdateProductTemplateMutation,
    useDeleteCategoryTemplateAMutation,
    useGetProductTemplateByIdQuery,
    useGetProductTemplateMutation,
    useCopyProductTemplateMutation,
} = AproductsApiSlice

