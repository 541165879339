import { useGetAdminDashboardDataQuery } from "../../redux/slice/admin/ADashboardApiSlice";
import { Bar, BarChart, XAxis } from "recharts"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/ui/card"
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../components/ui/chart"
import { Pie, PieChart } from "recharts"


export default function HomeAdmin() {
  const { data: getDashboardData } = useGetAdminDashboardDataQuery('dashboardsList', { pollingInterval: 6000, refetchOnFocus: true, refetchOnMountOrArgChange: true })
  const result = getDashboardData?.result


  const data = [
    {
      id: 1,
      title: "Total products",
      name: "totalProducts",
      count: 5,
      image:
        "/assets/dashboard/products.png",
    },
    {
      id: 2,
      title: "Total coustmers",
      name: "totalCustomers",
      count: 5,
      image:
        "/assets/dashboard/customers.png",
    },
    {
      id: 3,
      title: "Total Orders",
      name: "totalOrders",
      image:
        "/assets/dashboard/totalOrders.jpg",
    },
    {
      id: 4,
      title: "Active Orders",
      name: "activeOrders",
      image:
        "/assets/dashboard/activeOrders.png",
    },
    {
      id: 5,
      title: "Cancelled Orders",
      name: "cancelledOrders",
      image:
        "/assets/dashboard/cancelledOrders.png",
    },
    {
      id: 6,
      title: "Delivered Orders",
      name: "deliveredOrders",
      image:
        "/assets/dashboard/deliveredOrders.png",
    },
  ];
  /* const status = [
    {id:1,
      title: "Total Orders",
      name:"totalOrders",
      image:
        "https://6valley.6amtech.com/public/assets/back-end/img/pending.png",
    },
    {id:2,
      title: "Active Orders",
      name:"activeOrders",
      image:
        "https://6valley.6amtech.com/public/assets/back-end/img/confirmed.png",
    },
    {id:3,
      title: "Cancelled Orders",
      name:"cancelledOrders",
      image:
        "https://6valley.6amtech.com/public/assets/back-end/img/packaging.png",
    },
    {id:4,
      title: "Delivered Orders",
      name:"deliveredOrders",
      image:
        "https://6valley.6amtech.com/public/assets/back-end/img/out-of-delivery.png",
    },
  ]; */
  return (
    <div className="wrapper-dashboard bg-gray-50">
      <div>
        <h5 className='h5-bold'>Dashboard</h5>
        <span className="text-gray-500 ">Welcome to Dashboard.</span>
      </div>
      <div className="my-6 py-4 shadow-lg border-2 flex-col flex gap-8 border-gray-200 bg-gray-100">
        <div className="mx-2 p-4 flex flex-wrap gap-6 justify-between">
          {data?.map((x) => {
            return (
              <Card key={x.id} className="w-[320px] h-[130px] shadow-lg p-3 bg-purple-50">
                <div className="flex justify-between">
                  <p className="text-orange-600 font-medium text-md">{x.title}</p>
                  <img src={x?.image} alt="img" width={70} height={70} />
                </div>
                <div className="text-orange-600 font-medium text-md">
                  {result && result[x.name!]}
                </div>
              </Card>
            );
          })}
        </div>
        {/* <div className="mx-2 p-4 flex flex-wrap gap-2 justify-between">
              {status?.map((x) => {
                return (
                  <Card key={x.id} className="w-[260px] p-3 py-5 border-none bg-gray-200 hover:shadow-xl ">
                      <div className="flex justify-between items-center">
                        <div className="flex gap-3">
                        <img src={x?.image} alt="img"  width={30} height={30}/>
                       <p className="text-blue-800 font-medium text-md">{x.title}</p>
                        </div>
                        <p className="text-blue-800 font-medium text-md">{result&&result[x.name]}</p>
                      </div>
                  </Card>
                );
              })}
       </div> */}
       <div className="flex md:flex-row flex-col gap-4 p-6 w-full">
       <ChartCom/>
       <ChartCom2/>
       </div>
      
      </div>
    </div>
  );
}



const chartData = [
  { month: "January", delivered: 50, cancelled: 10 },
  { month: "February", delivered: 90, cancelled: 6 },
  { month: "March", delivered: 60, cancelled: 15 },
  { month: "April", delivered: 30, cancelled: 19 },
  { month: "May", delivered: 80, cancelled: 9 },
  { month: "June", delivered: 100, cancelled: 8 },
]

const chartConfig = {
  activities: {
    label: "Orders",
  },
  delivered: {
    label: "delivered",
    color: "hsl(var(--chart-1))",
  },
  cancelled: {
    label: "cancelled",
    color: "hsl(var(--chart-2))",
  },
} satisfies ChartConfig

export function ChartCom() {
  return (
    <Card className="flex flex-col p-3 md:w-[50%] w-full">
      <CardHeader className="">
      <p className="font-semibold">Total Orders Last 6 Months</p>
      <CardDescription>Delivered - Cancelled Orders</CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig}>
          <BarChart accessibilityLayer data={chartData}>
            <XAxis
              dataKey="month"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              tickFormatter={(value) => value.slice(0, 3)}
            />
            <Bar
              dataKey="delivered"
              stackId="a"
              fill="var(--color-delivered)"
              radius={[0, 0, 4, 4]}
            />
            <Bar
              dataKey="cancelled"
              stackId="a"
              fill="var(--color-cancelled)"
              radius={[4, 4, 0, 0]}
            />
            <ChartTooltip
              content={
                <ChartTooltipContent labelKey="Orders" indicator="line" />
              }
              cursor={false}
              defaultIndex={1}
            />
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
   )
}


const chartData1 = [
  { browser: "chrome", visitors: 275, fill: "var(--color-chrome)" },
  { browser: "safari", visitors: 200, fill: "var(--color-safari)" },
  { browser: "firefox", visitors: 187, fill: "var(--color-firefox)" },
  { browser: "edge", visitors: 173, fill: "var(--color-edge)" },
  { browser: "other", visitors: 90, fill: "var(--color-other)" },
]

const chartConfig1 = {
  visitors: {
    label: "Visitors",
  },
  chrome: {
    label: "Chrome",
    color: "hsl(var(--chart-1))",
  },
  safari: {
    label: "Safari",
    color: "hsl(var(--chart-2))",
  },
  firefox: {
    label: "Firefox",
    color: "hsl(var(--chart-3))",
  },
  edge: {
    label: "Edge",
    color: "hsl(var(--chart-4))",
  },
  other: {
    label: "Other",
    color: "hsl(var(--chart-5))",
  },
} satisfies ChartConfig

export function ChartCom2() {
  return (
    <Card className="flex flex-col md:w-[50%] w-full">
      <CardHeader className="items-center pb-0">
        <p className="font-semibold">Top Products Sale Previous Month</p>
      </CardHeader>
      <CardContent className="flex-1 pb-0">
        <ChartContainer
          config={chartConfig1}
          className="mx-auto aspect-square max-h-[250px]"
        >
          <PieChart>
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <Pie
              data={chartData1}
              dataKey="visitors"
              nameKey="browser"
              stroke="0"
            />
          </PieChart>
        </ChartContainer>
      </CardContent>
   
    </Card>
  )
}
