import parse from 'html-react-parser';
import { Button } from "../../../ui/button"
import 'react-toastify/dist/ReactToastify.css';
import {  IMGURL } from "../../../../constants/appConfig"
import { ProductComTemplateT, ProductWithImages } from "@/react-app-env"
import { useNavigate } from "react-router-dom"

const ViewProduct = ({saree}:{saree:ProductComTemplateT}) => {
    const navigate=useNavigate()
  return (
    <div className="grid grid-cols-1 gap-5 md:grid-cols-2 md:gap-6  wrapper-weaves">
      <div className="flex md:flex-row flex-col  gap-3 ">
        <img
          src={`${IMGURL}${saree.sampleImageUrl}`}
          alt={saree.productName}
          width={350}
          height={450}
          className="rounded-md bg-cover w-[85%] h-full"
        /></div>
      <div className="flex flex-col gap-3">
       <div className="flex gap-1">
          <h6 className="text-md font-semibold">ProductName:</h6>
          <p>{saree.productName}</p>
        </div>
        <div className="flex gap-4 md:flex-row flex-col">
        <div className="flex gap-1">
          <h6 className="text-md font-semibold">Category:</h6>
          <p>{saree.categoryName}</p>
        </div>
        <div className="flex gap-1">
          <h6 className="text-md font-semibold">Category:</h6>
          <p>{saree.subcategoryName}</p>
        </div>
       
        </div>
        {/* <div>
          <h5 className="text-lg font-semibold">Product Description Short:</h5>
          <p>{saree.descriptionShort}</p>
        </div> */}
        <div>
          <h6 className="text-md font-semibold">Product Description Long:</h6>
          <div>{parse(saree?.productDescription)}</div>
        </div>
        <div className=" flex lg:flex-row flex-col gap-6">
        <div className="flex gap-1">
          <h6 className="text-md font-semibold">PriceINR:</h6>
          {/* <p className="flex items-center"><MdCurrencyRupee size={18} />{saree.priceINR}</p> */}
          <p className="flex items-center">{ new Intl.NumberFormat('en-US', { style: 'currency', currency: "INR" }).format(saree.priceINR)}</p>
        </div>
        </div>
        <div className="flex justify-between">
        <Button onClick={()=>navigate(`/admin/dashboard/edit/${saree.productId}`)} className="mt-8">Edit Product</Button>
        <Button onClick={()=>navigate(-1)} className="mt-8">Back to Products</Button>
        </div>
      </div>
      </div>
  )
}

export default ViewProduct