import { cartData } from "@/react-app-env"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"


type cartType={
  cartItems:cartData[],
}

const initialState:cartType = {
    cartItems: []
}

export const cartSlice = createSlice({
  name: 'cartSaree',
  initialState,
  reducers: {
    addProductToCart: (state,action:PayloadAction<cartData>) => {
      const prd = action.payload
      state.cartItems.push({...prd})
    },
    removeSareesFromCart: (state, action: PayloadAction<string>) => {
        const itemIdToRemove = action.payload;
        state.cartItems = state.cartItems.filter((item) => item.cartId !== itemIdToRemove);
    },
  /*   removeSareeFromCart: (state, action: PayloadAction<cartData>) => {
        const saree = action.payload
        const existingCartItem = state.cartItems.filter(item=>item.id===saree.id)
        if(existingCartItem[0].totalQty>1){
            existingCartItem[0].totalQty -=1
            existingCartItem[0].priceINR -=saree.priceINR
        }
        state.cartItems = state.cartItems.filter((item) => item.id !== saree.id);
    }, */
  },
})

export const { addProductToCart,removeSareesFromCart,/* removeSareeFromCart */ } = cartSlice.actions


export default cartSlice.reducer