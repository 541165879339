import { privacyConstants } from "../../constants/index";

const Privacy = () => {
  return (
    <div className="bgcolorgold">
      <div className="wrapper flex flex-col gap-6">
        <h5 className="h5-bold">Privacy Policy for PrintFerry</h5>
        <div className="flex flex-col gap-2">
          <p className="p-semibold-20">Last updated:</p>
          <p className="p-regular-16">
            Thank you for choosing PrintFerry. We value your privacy and are committed to safeguarding your personal information. If you have any questions or concerns about this policy or our practices regarding your personal data, please reach out to us at support@printferry.com.
          </p>
          <p className="p-regular-16">
            By accessing our website or placing an order, you agree to the terms outlined in this privacy policy. This policy applies to all data collected through our website and during any other interactions you may have with PrintFerry.
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-1">
            <p className="p-semibold-20">1. Information We Collect:</p>
            <div className="wrapper-weaves flex flex-col gap-1">
              <p>
                <strong>• Personal Information: </strong>When you create an account, place an order, or contact us, we collect personal information such as your name, email address, shipping address, and payment details.
              </p>
              <p>
                <strong>• Device Information:</strong> We automatically gather information about the device you use to access our website, such as your web browser, IP address, time zone, and certain cookies installed on your device.
              </p>
              <p>
                <strong>• Order Information:</strong> We collect details related to your order, including the products you’ve selected, payment information, and shipping details, to process and fulfill your orders.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <p className="p-semibold-20">2. How We Use Your Information:</p>
            <div className="wrapper-weaves flex flex-col gap-1">
              <p>
                <strong>• Processing and Fulfilling Orders:</strong> We use your personal information to process and fulfill your orders, send order confirmations, tracking updates, and invoices.
              </p>
              <p>
                <strong>• Communication: </strong> We may contact you to inform you about your order status, special offers, or new products. You can opt out of these communications at any time.
              </p>
              <p>
                <strong>• Service Improvement: </strong> We analyze information about your device and interaction with our site to improve our services, ensuring a better user experience.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <p className="p-semibold-20">3. Sharing Your Information:</p>
            <div className="wrapper-weaves flex flex-col gap-1">
              <p>
                <strong>• Third-Party Service Providers: </strong> We may share your information with third-party service providers who assist us in processing payments, shipping orders, or providing customer support.
              </p>
              <p>
                <strong>• Legal Compliance: </strong> We may disclose your information if required by law or if we believe that such action is necessary to comply with legal obligations, protect our rights, or ensure the safety of our users.
              </p>
              <p>
                <strong>• Business Transfers: </strong> In the event of a merger, acquisition, or asset sale, your personal information may be transferred to the new owner as part of the business transfer.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <p className="p-semibold-20">4. Data Security:</p>
            <div className="wrapper-weaves flex flex-col gap-1">
              <p>
                <strong>• Security Measures: </strong> We implement appropriate technical and organizational measures to protect your personal data from unauthorized access, loss, or misuse. However, no method of transmission over the internet is completely secure, and we cannot guarantee absolute security.
              </p>
              <p>
                <strong>• Data Retention: </strong> We retain your personal information only as long as necessary to fulfill the purposes outlined in this privacy policy, unless a longer retention period is required by law.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <p className="p-semibold-20">5. Your Rights:</p>
            <div className="wrapper-weaves flex flex-col gap-1">
              <p>
                <strong>• Access and Correction: </strong> You have the right to access the personal information we hold about you and to request corrections or updates to ensure its accuracy.
              </p>
              <p>
                <strong>• Opting Out: </strong> You can opt out of receiving marketing communications from us by following the instructions provided in those communications.
              </p>
              <p>
                <strong>• Deletion: </strong> You may request the deletion of your personal information by contacting us, subject to certain legal obligations.
              </p>
            </div>
          </div>
          <p className="p-regular-16 mt-4">
            Thank you for choosing <strong>PrintFerry</strong>. Your privacy is important to us.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
