import { useEffect, useState } from "react";
import { Button } from "../../../components/ui/button";
import { useGetCustomerQuery } from "../../../redux/slice/UserApiSlice";
import ShippingInfo from "./ShippingInfo";
import Payment from "./Payment";
import { useCreateOrderMutation } from "../../../redux/slice/admin/ACreateOrderApiSlice";
import { useGetShoppingCartByCustomerQuery } from "../../../redux/slice/CartApiSlice";
import { RazorpaySuccessResponse, cartProduct } from "@/react-app-env";
import {
  useCreateOrderRazorPayMutation,
  useRazorPayValidateDataMutation,
} from "../../../redux/slice/RazoryPayApiSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const CHECKOUT_STEPS = [
  {
    name: "Shipping Info",
    component: ({ user }: any) => <ShippingInfo user={user} />,
  },
  {
    name: "Payment",
    component: () => <Payment />,
  },
];

const CheckoutStepper = () => {
  const currentUser = JSON.parse(localStorage.getItem("pfuser")!);
  const { data, refetch } = useGetCustomerQuery(currentUser?.refId);
  const user = data?.result;
  const [currentStep, setCurrentstep] = useState<number>(1);
  const [totalPrice, setTotalprice] = useState();
  const [taxAmount, setTaxAmount] = useState<number>(0);
  const [FinalPrice, setFinalPrice] = useState();
  const [addressId, setAddressId] = useState();
  const [createOrder, { data: orderData, isSuccess: orderSuccess }] =useCreateOrderMutation();
  const [createOrderRazorPay] = useCreateOrderRazorPayMutation();
  const [RazorPayValidateData] = useRazorPayValidateDataMutation();
  const { data: cartItemsAPI } = useGetShoppingCartByCustomerQuery(currentUser?.refId);
  const navigate=useNavigate()
  const [razorpayRes, setRazorpayRes] = useState<RazorpaySuccessResponse>();
  //console.log({ orderData, orderSuccess, FinalPrice, razorpayRes });
  
    useEffect(()=>{
      if(orderData?.success&&orderSuccess){
      navigate(`/myorders`)
      toast.success("Your order got placed successfully")
      }else if(!orderData?.success&&orderSuccess){
        toast.error("If amount got deducted from your account and order not placed Successfully. Please connect to Customer Support",{autoClose: 5000})
        navigate(`/return-policy`)
      }
    },[orderData,orderSuccess,navigate])
  
  useEffect(()=>{
    async function fetchData() {
    if (currentStep === 2 && user && addressId && totalPrice && FinalPrice &&  razorpayRes?.msg && razorpayRes?.paymentId) {
      await Promise.all(cartItemsAPI?.result?.map(async (item: any,idx:number) => {
        //const attributes = JSON.parse(item?.orderAttributes)
       // const price = item.priceINR +(attributes?.activateSide?.back?100:0)+(attributes?.activateSide?.sides?100:0)
       const price =item?.attributesPrice? item.priceINR + item?.attributesPrice: item.priceINR

        if(item.readyToBuy){
            let totalAmount =(price) * item.quantity
            let productId = item.productId
            let quantity = item.quantity
            let orderTotal=totalAmount+50
        await createOrder({customerId: user.id,addressId,shoppingCartId:item.cartId,totalAmount,taxAmount:50,orderTotal,productId,quantity,transactionPaymentId: razorpayRes?.paymentId,transactionNo: razorpayRes?.razorpay_order_id});
      }
      }));
    }
  }
  fetchData(); 
  },[currentStep, createOrder, user, addressId, totalPrice, taxAmount, FinalPrice,cartItemsAPI,razorpayRes])


    async function handleProceedToPay() {
      
      if (FinalPrice) {
        const amount = FinalPrice*100
       
        const response = await createOrderRazorPay({amount,currency:"INR"});
    
        
        if ("data" in response) {
          const order = response.data;
          var options = {
            key: "rzp_test_XzqZYz0SXAGhOs", // Enter the Key ID generated from the Dashboard
            amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency:"INR",
            name: "PRINTFERRY", //your business name
            description: "Test Transaction",
            order_id: order.id,
            handler: async function (response: any) {
              const body = {
                ...response,
              };
              const validateRes = await RazorPayValidateData({ ...body });
              //console.log(validateRes);
              
              if ("data" in validateRes) {
                const jsonRes = validateRes.data; 
                setRazorpayRes(jsonRes);
                setCurrentstep((p) => p + 1);
              }
            },
            prefill: {
              name: "vila",
              email: "vila@example.com",
              contact: "9000000000",
            },
            notes: {
              address: "Razorpay Corporate Office",
            },
            theme: {
              color: "#3399cc",
            },
          };

          var rzp1 = new window.Razorpay(options);
          
          rzp1.on("payment.failed", function (response: any) {
            alert('Payment was cancelled');
            navigate('/cart');
           /*  alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id); 
               alert(response.error.code);
            alert(response.error.description);*/
          });
          
          rzp1.open();
          /* e.preventDefault(); */
        }
      }
     // setCurrentstep((p) => p + 1)
}

//useEffect(()=>{if(FinalPrice){navigate("/cart")}},[FinalPrice,navigate])

  return (<div className="bgcolorgold">
    <div className="wrapper">
      <div className="flex justify-between items-center wrapper">
        {currentStep === 1 &&
          CHECKOUT_STEPS.map((step, idx) => {
            return (
              <div
                key={step.name}
                className={`flex justify-center flex-col items-center`}
              >
                <div
                  className={`${
                    currentStep > idx + 1 && `bg-green-500`
                  } bg-gray-400 ${
                    currentStep === idx + 1 && `bg-blue-500`
                  } flex justify-center rounded-full w-[30px] h-[30px] p-1`}
                >
                  {idx + 1}
                </div>
                <div>{step.name}</div>
              </div>
            );
          })}
      </div>
      {user && currentStep === 1 && (
        <ShippingInfo
          user={user}
          reftechUser={refetch}
          setTotalprice={setTotalprice}
          setAddressId={setAddressId}
          setTaxAmount={setTaxAmount}
          setOrderTotal={setFinalPrice}
        />
      )}
      {/* {(user && currentStep === 2 &&orderSuccess)&& <Payment />} */}
      <div className=" flex justify-end my-4">
        {currentStep === 1 && (
          <Button
            onClick={handleProceedToPay}
            className="w-[120px] headermenu"
            disabled={(!addressId||!FinalPrice)}
          >
            Proceed To Pay
          </Button>
        )}
      </div>
    </div>
    </div>);
};

export default CheckoutStepper;
