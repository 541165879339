import { Route,RouterProvider,createBrowserRouter,createRoutesFromElements, } from "react-router-dom";
import Home from "./Pages/Home";
import ErrorPage from "./Pages/Error";
import SIgnUp from "./Pages/SignUp";
import SignIn from "./Pages/Signin";
import PageNavigation from "./Pages/PageNavigation";
import Cart from "./Pages/Cart";
import RequireAuth, { RequireAuthAdmin } from "./components/shared/auth/RequireAuth";
import CustomerDetails from "./Pages/CustomerDetails";
import DashboardLayout from "./Pages/admin/DashboardLayout";
import HomeAdmin from "./Pages/admin/Home";
import ProductsAdmin from "./Pages/admin/Products";
import EditProduct from "./Pages/admin/EditProduct";
import NewProduct from "./Pages/admin/AddProduct";
import ViewProductPage from "./Pages/admin/ViewProductPage";
import UploadImagesPage from "./Pages/admin/UploadImagesPage";
import Checkout from "./Pages/Checkout";
import OrdersAdmin from "./Pages/admin/Orders";
import MyOrders from "./Pages/MyOrders";
import CancelledOrders from "./Pages/admin/CancelledOrders";
import ClosedOrders from "./Pages/admin/ClosedOrders";
import Privacy from "./Pages/footerpages/Privacy";
import Return from "./Pages/footerpages/Return";
import About from "./Pages/footerpages/About";
import TermsConditions from "./Pages/footerpages/TermsReturn";
import ShippingPolicy from "./Pages/footerpages/ShippingPolicy";
import Customers from "./Pages/admin/Customers";
import Payment from "./components/shared/checkout/Payment";
import FAQ, { ContactUs } from "./Pages/footerpages/FAQ";
import ForgotPwd from "./Pages/ForgotPwd";
import { ScrollTop } from "./components/shared/RootLayout/ScrollToTop";
import TemplatesAdmin from "./Pages/admin/Templates";
import ProductByCategory from "./Pages/ProductByCategory";
import ColorByCategory from "./Pages/admin/Color";
import CreateTemplate1 from "./components/shared/admin/components/canvas/CC";
import AddTemplate from "./Pages/admin/AddTemplate";
import EditTemplate from "./Pages/admin/EditTemplate";
import LogoCustomImages from "./Pages/admin/LogoCustomImages";
import FabricCanvasFixPostion from "./components/shared/admin/components/canvas/CanvasFixedPosition";
import TemplatesBySubCategory from "./Pages/TemplatesBySubCategories";
import DesignProduct from "./Pages/DesignProduct";
import CateoriesPageAdmin from "./Pages/admin/Cateories";
import DesigMug from "./Pages/DesignMug";
import AllCategoryProducts from "./Pages/AllCategoryProducts";
import PrintSides from "./Pages/admin/PrintSides";
import PrintareaShapes from "./Pages/admin/PrintareaShapes";
import TransparentImages from "./Pages/admin/TransparentImages";
import StationaryAttributes from "./Pages/admin/StationaryAttributes";
import PriceBySizes from "./Pages/admin/PriceBySizes";
import AddAttributeOffice from "./Pages/admin/AddAttributeOffice";
import EditAttributeOffice from "./Pages/admin/EditAttributeOffice";
import BannerImages from "./Pages/admin/BannerImages";
import AboutUs from "./Pages/footerpages/About";
import OrderTrack from "./Pages/footerpages/OrderTrack";

const Rout = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/"  element={<PageNavigation />} errorElement={<ErrorPage />}>
      <Route element={<ScrollTop />}>
        <Route index element={<Home />} />
        <Route path="category/:catId" element={<ProductByCategory />} />
        <Route path="allcategories" element={<AllCategoryProducts />} />
        <Route path="subcategory/:subCatId" element={<TemplatesBySubCategory />} />
        <Route path="productdesign/:id" element={<DesignProduct />} />
        <Route path="mugdesign/:id" element={<DesigMug />} />
        <Route path="template1" element={<CreateTemplate1 />} />
        <Route path="fixposition" element={<FabricCanvasFixPostion />} />
        <Route path="privacy-policy" element={<Privacy />} />
        <Route path="return-policy" element={<Return />} />
        <Route path="terms-conditions" element={<TermsConditions />} />
        <Route path="shipping-policy" element={<ShippingPolicy />} />
        <Route path="help-faq" element={<FAQ />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="track-order" element={<OrderTrack />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="signup" element={<SIgnUp />} />
        <Route path="signin" element={<SignIn />} />
        <Route path="forgotpwd" element={<ForgotPwd />} />
        <Route path="cart" element={<Cart />} />
        <Route element={<RequireAuth/>}>
        <Route path="userdetails" element={<CustomerDetails/>}/>
        <Route path="checkout" element={<Checkout/>}/>
        <Route path="myorders" element={<MyOrders/>}/>
        <Route path="orderres/:id" element={<Payment/>}/>
        </Route>
      </Route>
      </Route>
      <Route element={<RequireAuthAdmin/>}>
      <Route path="admin" element={<DashboardLayout/>}>
          <Route index element={<HomeAdmin/>}/>
          <Route path="dashboard/products" element={<ProductsAdmin/>}/>
          <Route path="dashboard/templates" element={<TemplatesAdmin/>}/>
          <Route path="dashboard/logo" element={<LogoCustomImages/>}/>
          <Route path="dashboard/attributes/colors" element={<ColorByCategory/>}/>
          <Route path="dashboard/attributes/sides" element={<PrintSides/>}/>
          <Route path="dashboard/attributes/sizes" element={<PriceBySizes/>}/>
          <Route path="dashboard/area" element={<PrintareaShapes/>}/>
          <Route path="dashboard/custom-images" element={<TransparentImages/>}/>
          <Route path="dashboard/banner-images" element={<BannerImages/>}/>
          <Route path="dashboard/attributes/stationary" element={<StationaryAttributes/>}/>
          <Route path="dashboard/attributes/stationary/new" element={<AddAttributeOffice/>}/>
          <Route path="dashboard/attributes/stationary/:id" element={<EditAttributeOffice/>}/>
          <Route path="dashboard/orders/active" element={<OrdersAdmin/>}/>
          <Route path="dashboard/customers" element={<Customers/>}/>
          <Route path="dashboard/orders/cancelled" element={<CancelledOrders/>}/>
          <Route path="dashboard/orders/closed" element={<ClosedOrders/>}/>
          <Route path="dashboard/newproduct" element={<NewProduct/>}/>
          <Route path="dashboard/edit/:id" element={<EditProduct/>}/>
          <Route path="dashboard/newtemplate" element={<AddTemplate/>}/>
          <Route path="dashboard/edittemplate/:id" element={<EditTemplate/>}/>
          <Route path="dashboard/uploadimages/:id" element={<UploadImagesPage/>}/>
          <Route path="dashboard/view/:id" element={<ViewProductPage/>}/>
          <Route path="dashboard/categories" element={<CateoriesPageAdmin/>}/>
      </Route>
      </Route>
    </Route>
  )
);



const App = () => {
  return <RouterProvider router={Rout} />;
}; 


export default App;
