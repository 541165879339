import { useParams } from "react-router-dom"
import TemplateForm from "../../components/shared/admin/components/canvas/TemplateForm"
import { useGetProductTemplateByIdQuery } from "../../redux/slice/admin/ATemplateApiSlice"

const EditTemplate = () => {
    const {id}=useParams()
    const {data}=useGetProductTemplateByIdQuery(id)
    
    return (
        <div className="max-w-full md:mx-20 md:my-10 border-2 border-gray-300 md:p-4">
          <h5 className="h5-bold mb-4">Edit Template</h5>
      {data?.result&& <TemplateForm type="Edit" data={data?.result}/>}
    </div>
    )
}

export default EditTemplate