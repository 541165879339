import { Link, useNavigate, useParams } from 'react-router-dom'
import { useGetAllCategoryQuery, useGetSubcategoryQuery } from '../redux/slice/CategoryApiSlice'
import { SlashIcon } from "@radix-ui/react-icons"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../components/ui/breadcrumb"
import { ProductDataT, TSubCategory } from '@/react-app-env'
import { Card } from '../components/ui/card'
import { IMGURL } from '../constants/appConfig'
import {  useEffect } from 'react'

const ProductByCategory = () => {
  const { catId } = useParams();
  const { data: subCategories, isLoading, isSuccess, isError, } = useGetSubcategoryQuery(catId)
  const navigate = useNavigate()
  useEffect(() => {
    if (catId && (parseInt(catId) === 0)) {
      navigate("/allcategories")
    }
  }, [catId, navigate])


  let content
  if (isLoading) content = <section className="bgcolorgold" ><p className="p-4">Loading...</p></section>;
  if (isError) content = <p>{subCategories?.result?.message}</p>
  if (!subCategories?.result?.length) {
    content = <section className="bgcolorgold" >
      <p className="p-4 text-center text-lg font-semibold">
        No SubCategories Available Under This Category
      </p></section>
  }
  if (isSuccess && subCategories?.result?.length > 0) {
    content = <div className="wrapper grid md:grid-cols-2 grid-cols-1 gap-3">
       {Number(catId)===2&&<div className="flex flex-col max-md:hidden gap-4">
    <img src={"/assets/bc/mug.png"} alt='mug' className='h-full object-cover'/>
    <img src={"/assets/bc/mug1.png"} alt='mug1' className='h-full object-cover'/>
    </div>}
    {Number(catId)===21&&<div className="flex flex-col max-md:hidden gap-4">
    <img src={"/assets/bc/key1.jpg"} alt='key1' className='h-full object-cover'/>
    <img src={"/assets/bc/key.jpg"} alt='key' className='h-full object-cover'/>
    </div>}
       {Number(catId)===11&&<div className="flex flex-col max-md:hidden gap-4">
    <img src={"/assets/bc/office.jpg"} alt='office' className='h-full object-cover'/>
    <img src={"/assets/bc/office1.jpg"} alt='office1' className='h-full object-cover'/>
    </div>}
    <div className="grid md:grid-cols-2 max-md:grid-cols-3 max-sm:grid-cols-1  gap-3">
      {subCategories?.result?.map((subCategory: TSubCategory) => <Card key={subCategory.id} className="relative w-full   border-2 flex flex-col items-center border-gray-500">
        <Link to={`/subcategory/${subCategory.id}`} className='w-full '>
          <img src={`${IMGURL}${subCategory.imageUrl}`} alt={subCategory.subcategoryName} className='w-full max-sm:h-[350px] h-[280px]  rounded-t-md object-cover' />
{/*           <p className='absolute bottom-4 text-white headermenu  p-2 px-3 rounded-lg text-sm font-semibold'>{subCategory.subcategoryName}</p> */}  
          <p className='bottom-4  text-white headermenu h-16 flex items-center justify-center  p-2 px-3 rounded-b-md text-xl font-semibold'>{subCategory.subcategoryName}</p>
      </Link></Card>)}
    </div>
    {Number(catId)===1&&<div className="flex flex-col max-md:hidden gap-4">
    <img src={"/assets/bc/shirt.jpeg"} alt='tshirt' className='h-full object-cover'/>
    <img src={"/assets/bc/shirt1.png"} alt='tshirt' className='h-full object-cover'/>
    </div>}
    {Number(catId)===4&&<div className="flex flex-col max-md:hidden gap-4">
    <img src={"/assets/bc/cushion.jpg"} alt='cushion' className='h-full object-cover'/>
    </div>}
    {Number(catId)===22&&<div className="flex flex-col max-md:hidden gap-4">
    <img src={"/assets/bc/mouse.jpg"} alt='mouse' className='h-full object-cover'/>
    </div>}
    {Number(catId)===15&&<div className="flex flex-col max-md:hidden gap-4">
    <img src={"/assets/bc/fridge2.jpg"} alt='fridge2' className='h-full object-cover'/>
    <img src={"/assets/bc/fridge.jpg"} alt='fridge' className='h-full object-cover'/>
    <img src={"/assets/bc/fridge1.jpg"} alt='fridge1' className='h-full object-cover'/>
    </div>}
    </div>
  }
  return (<section className="bgcolorgold" >
    <div>
      {content}
    </div>
  </section>);
};



export function BreadcrumbWithCustomSeparator({ data }: { data: TSubCategory }) {
  return (
    <Breadcrumb className='h5-bold  sm:m-2 md:my-4 md:px-72'>
      <BreadcrumbList>
        <BreadcrumbItem>
          {/* <BreadcrumbLink href="/">Home</BreadcrumbLink> */}
          <Link to="/">Home</Link>
        </BreadcrumbItem>
        <BreadcrumbSeparator>
          <SlashIcon />
        </BreadcrumbSeparator>
        <BreadcrumbItem>
          <BreadcrumbPage className='font-bold text-lg'>{data.subcategoryName}</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  )
}
export default ProductByCategory;

