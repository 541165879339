import { useDispatch } from "react-redux";
import { Button } from "../../../components/ui/button";
import { IMGURL } from "../../../constants/appConfig";
import { ProductOrderCart, TCartItems, cartData, cartProduct } from "@/react-app-env";
import { MdDelete } from "react-icons/md";
import {
    AlertDialog,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
  } from "../../../components/ui/alert-dialog"
import {  removeSareesFromCart } from "../../../redux/features/cartSlice";
import { useAddItemCartMutation, useDeleteItemCartMutation, useUpdateShoppingCartItemMutation } from "../../../redux/slice/CartApiSlice";


const CartDetails = ({ saree,type }: { saree: cartData,type?:"Cart"  }) => {
  const frontPreview = saree.orderDesign.front.previewUrl ;

  
  return (
    <>
      <div className="flex justify-between flex-col md:flex-row md:px-4 items-center gap-2 max-md:border max-md:p-2 border-gray-50">
        <div className="flex gap-4">
         {frontPreview&& <img
            src={frontPreview}
            alt={saree.productName}
            className="rounded-md  bg-cover w-[150px] h-[120px] "
          />}
          <div className="flex flex-col gap-2 w-[150px]">
          <p  className="text-md font-semibold">{saree.productName}</p>
          </div>
        </div>
        <div>
          <p>Qty:{saree.totalQty}</p>
        </div>
        <div>
         <AlertDialogDemo id={saree.cartId!} type={type}/>
        </div>
        <div>
          <p className="flex text-sm font-semibold items-center gap-1">
            { new Intl.NumberFormat('en-US', { style: 'currency', currency: "INR" }).format(saree.priceINR)}
          </p>
        </div>
      </div>
    </>
  );
};
export const CartDetailsAPI = ({ product,type,setState }: { product: ProductOrderCart,type?:"WishList"|"Cart",setState?:any }) => {
  const currentUser = JSON.parse(localStorage.getItem("pfuser")!);
  const [updateItemCart] = useUpdateShoppingCartItemMutation()
  const handleCheckboxChange = async () => {
    const response = await updateItemCart({ id: product.cartId, readyToBuy: product.readyToBuy===0?1:0 });
    if ('error' in response) {
      console.error('error:', response.error);
      return;
    }

  };
 const attributes = JSON.parse(product?.orderAttributes)
//   const price:number = product.priceINR +(attributes?.activateSide?.back?100:0)+(attributes?.activateSide?.sides?100:0)

   const price:number =product?.attributesPrice? product.priceINR + product?.attributesPrice: product.priceINR
   
  return (
    <>
      <div className=" flex justify-between flex-col md:flex-row md:px-4 items-center gap-2 max-md:border max-md:p-2 border-gray-50">
        <div className="flex gap-4">
        {!type&&<input type="checkbox" checked={Boolean(product.readyToBuy)} onChange={handleCheckboxChange} className="bg-black w-4 h-4"/>}
          <img
            src={`${IMGURL}${product.previewFrontUrl}`}
            alt={product.productName}
            className="rounded-md bg-cover w-[150px] h-[120px] "
          />
          <div className="flex flex-col gap-1 w-[200px]">
            <p className="text-md font-semibold">{product.productName}</p>
        {product.categoryId === 1 && <div className="text-sm flex gap-1"><span className="text-gray-600">Print Sides: </span><p>front, {attributes?.activateSide?.back&&"back, "}{attributes?.activateSide?.sides&&"sleeves"}</p></div>}
          {attributes?.Color &&<div className="text-sm flex gap-1 items-center"><span className="text-gray-600">Color:</span><p className={`w-5 h-5 rounded-full`} style={{backgroundColor:attributes?.Color}}/></div>}
           <div className="text-sm"><span className="text-gray-600">Qty:</span> {product.quantity}</div>
          </div>
        </div>
        <div>
         <AlertDialogDemo id={product.cartId} type={type} setState={setState}/>
        </div>
        <div>
          <p className="flex text-sm font-semibold items-center gap-1">
         { new Intl.NumberFormat('en-US', { style: 'currency', currency: "INR" }).format(price)}
          </p>
        </div>
      </div>
    </>
  );
};


export function AlertDialogDemo({id,type,setState}:{id:string|number,type?:string,setState?:any}) {
  const currentUser=JSON.parse(localStorage.getItem("pfuser")!);
  const [deleteItemCart]=useDeleteItemCartMutation()
    const dispatch=useDispatch()
   const handleRemoveCart =async()=>{
    if(currentUser?.token){
       await deleteItemCart(id)
    }else{
     dispatch(removeSareesFromCart(id.toString()))
    }
   }
    return (
      <AlertDialog>
        <AlertDialogTrigger asChild>
       <Button variant="ghost" >Remove<MdDelete  size={20} /></Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently remove the  item from cart.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <Button onClick={handleRemoveCart}>Continue</Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    )
  }


  export default CartDetails;