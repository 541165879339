import { NavLink } from "react-router-dom";
import NavigationLinks from "./NavigationLinks";
import HeaderIcons from "./HeaderIcons";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import MobileNav from "../RootLayout/MobileNav";
import { NavLinksH } from "../../../constants";


const Header = () => {
  const { auth } = useSelector((state: RootState) => state.authCon)
  return (
    <header className="text-white text-xl headersticky top-0 z-50">
      <nav className="w-full ">
        <div className="w-full bg-white flex justify-center">
          <div className="w-full text-black md:max-w-6xl flex flex-row gap-8 justify-between items-center">
            <MobileNav />
            <div className="flex gap-4 items-center max-md:hidden ">
              <NavLink to="/contact-us" className="text-sm font-semibold">
                Contact Us
              </NavLink>
              <NavLink to="/track-order" className="text-sm font-semibold">
                Track Order
              </NavLink>
            </div>
            <div>
              <NavLink to="/">
                <img alt="logo" src="/assets/logo/printferry_logo.png" className="h-16 my-1" />
              </NavLink>
            </div>
            <div className="flex gap-4 justify-end">
              <HeaderIcons />
            </div>
          </div>
        </div>
        <div className="w-full headermenu">
          <div className="w-full wrapper flex-wrap gap-3 md:flex-row flex-col justify-between md:flex hidden ">
            {NavLinksH.map(nav => <NavLink key={nav.id} to={`/category/${nav.id}`} className="hover:text-blue-400 text-md">{nav.categoryName}</NavLink>
            )}
          </div>
        </div>
      </nav>
    </header>

  );
};

export default Header;


