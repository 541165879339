import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { Button } from "../../components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog"
import { Label } from "../../components/ui/label"
import { useDeleteProductAttributeMutation, useGetProductAttributesByAttNameMutation, useGetProductAttributesQuery, useSaveProductAttributeSidesMutation, useUpdateProductAttributeMutation } from "../../redux/slice/admin/AAttributeApiSlice"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select"
import { useGetAllCategoryQuery, useGetSubcategoryQuery } from "../../redux/slice/CategoryApiSlice"
import { Checkbox } from "../../components/ui/checkbox"
import { toast } from "react-toastify"
import { Input } from "../../components/ui/input"
import { MdDeleteOutline } from "react-icons/md"
import { IoMdClose } from "react-icons/io"
import { useNavigate, useSearchParams } from "react-router-dom"
import { FilterByCategoryTemplate, FilterBySubCategoryTemplate } from "./Templates"
import { ProductAttribute } from "@/react-app-env"

const PriceBySizes = () => {
  const [productAttByAttName, { data: productAttributes }] = useGetProductAttributesByAttNameMutation()
  let attributeName = "Sizes Price"
  const [searchParams] = useSearchParams()
  const catId = searchParams.get("catId") || 1;
  const subcatId = searchParams.get("subCatId") || "All";
  const [condEdDe, setCondEdDe] = useState<boolean>(false)
  const [editSides, setEditSides] = useState<boolean>(false)
  const [deleteSides, setDeleteSides] = useState<boolean>(false)

  useEffect(() => {
    if (subcatId === "All") {
      productAttByAttName({ catId, attributeName, subcatId: 0 })
    } else {
      productAttByAttName({ catId, subcatId, attributeName })
    }
  }, [productAttByAttName, attributeName, catId, subcatId, condEdDe])

  const sizesFixed = productAttributes?.result[0];
  const attributeValueSizes = sizesFixed && JSON.parse(sizesFixed?.attributeValue)


  return (
    <div className="wrapper-dashboard">
      <div className="flex justify-between gap-4 flex-wrap">
        <h5 className="text-xl font-bold">Sizes By Price</h5>
        <div className="flex gap-6 flex-wrap">
          <FilterByCategoryTemplate catId={catId.toString()} />
          <FilterBySubCategoryTemplate catId={catId.toString()} subCatId={subcatId} />
        </div>
        <AddSidesToCategory />
      </div>
      <div className="my-4 p-4shadow-md">
        <p className="font-semibold text-lg text-gray-700">Attribute Name: {attributeName}</p>
         <div className="flex gap-4 items-center flex-wrap my-4">
          {sizesFixed ? (
            <div className="flex gap-6 flex-wrap bg-white p-4 rounded-md shadow-inner">
             <p className="font-semibold">Sizes By Extra Price:</p>
             <div className="flex gap-3">
              <div className="font-semibold">S:<span className="mx-2">{ new Intl.NumberFormat('en-US', { style: 'currency', currency: "INR" }).format(attributeValueSizes.S)}</span></div>
              <div className="font-semibold">M:<span className="mx-2">{ new Intl.NumberFormat('en-US', { style: 'currency', currency: "INR" }).format(attributeValueSizes.M)}</span></div>
              <div className="font-semibold">L:<span className="mx-2">{ new Intl.NumberFormat('en-US', { style: 'currency', currency: "INR" }).format(attributeValueSizes.L)}</span></div>
              <div className="font-semibold">XL:<span className="mx-2">{ new Intl.NumberFormat('en-US', { style: 'currency', currency: "INR" }).format(attributeValueSizes.XL)}</span></div>
              <div className="font-semibold">XXL:<span className="mx-2">{ new Intl.NumberFormat('en-US', { style: 'currency', currency: "INR" }).format(attributeValueSizes.XXL)}</span></div>
             </div>
              <div className="flex gap-4">
              <Button onClick={()=>setEditSides(true)} variant="default" className="headermenu">Edit Sizes By Price</Button>
              <Button onClick={()=>setDeleteSides(true)}  className="bg-red-800" >Delete Sizes By Price</Button>
              </div>
             {editSides&& <EditSides side={sizesFixed} setCondEdDe={setCondEdDe} setEditSides={setEditSides} />}
             {deleteSides&& <DeleteSides id={sizesFixed.id} setCondEdDe={setCondEdDe} setDeleteSides={setDeleteSides}/>}
            </div>
          ) : (
            <p className="text-lg font-semibold text-gray-800 p-3 rounded-md shadow-sm">
              Selected Filters: There Is No Sizes By Price
            </p>
          )}
        </div>
      </div>

    </div>
  )
}


const AddSidesToCategory = () => {
  //const [categoryId, setCategoryId] = useState<number>(1)
  const categoryId=1
  const [subcategoryId, setSubcategoryId] = useState<number>(0)
  const [sizesPrice, setSizesPrice] = useState({ S: 0, M: 0, L: 0, XL: 0, XXL: 0 })
  const [saveProductAttribute] = useSaveProductAttributeSidesMutation()
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const handleSubmit = async () => {
   /*  if (!sides.back && !sides.sides) {
      toast.error("Add This Property only when other then the front side")
    } */
    await saveProductAttribute({ categoryId, subcategoryId, attributeName: "Sizes Price", attributeValue: JSON.stringify(sizesPrice) })
    setOpen(false)
    setSizesPrice({ S: 0, M: 0, L: 0, XL: 0, XXL: 0 })
    navigate(`/admin/dashboard/attributes/sizes?catId=${categoryId}&subCatId=${subcategoryId===0?"All":subcategoryId}`)
  }

  const handleClose = () => {
    setOpen(false)
    setSizesPrice({ S: 0, M: 0, L: 0, XL: 0, XXL: 0 })
  }

  return (
    <Dialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      <DialogTrigger asChild>
        <Button variant="default" className="headermenu">Add Sizes Price</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Add Sizes By Price</DialogTitle>
          <p className="text-sm my-1 text-red-500"></p>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="flex items-center gap-4">
            <Label htmlFor="Category" className="text-right">
              Category: TShirt
            </Label>
          {/*   <FilterByCategory onChangeHandler={setCategoryId} categoryId={categoryId} /> */}
          </div>
          <div className="flex items-center gap-4">
            <Label htmlFor="subCategory" className="text-right">
              SubCategory:
            </Label>
            <FilterBySubCategory onChangeHandler={setSubcategoryId} subcategoryId={subcategoryId} categoryId={categoryId} />
          </div>
          <div className="flex items-center gap-4">
            <Label htmlFor="color" className="text-right">
              Add Extra Prices Depending On Sizes:
            </Label>
            </div>
            <div className="flex items-center gap-4 flex-wrap">
            <div className="grid max-w-24 items-center gap-2">
                <Label htmlFor="s" className="font-semibold text-gray-700">S</Label>
                <Input value={sizesPrice.S} className='focus-visible:ring-offset-0 focus-visible:ring-transparent border border-gray-400 rounded-md px-2 py-1' onChange={(e) => setSizesPrice({ ...sizesPrice, S: +e.target.value })} id="s" />
              </div>
              <div className="grid max-w-24 items-center gap-2">
                <Label htmlFor="M" className="font-semibold text-gray-700">M</Label>
                <Input value={sizesPrice.M} className='focus-visible:ring-offset-0 focus-visible:ring-transparent border border-gray-400 rounded-md px-2 py-1' onChange={(e) => setSizesPrice({ ...sizesPrice, M: +e.target.value })} id="M" />
              </div>
              <div className="grid max-w-24 items-center gap-2">
                <Label htmlFor="L" className="font-semibold text-gray-700">L</Label>
                <Input value={sizesPrice.L} className='focus-visible:ring-offset-0 focus-visible:ring-transparent border border-gray-400 rounded-md px-2 py-1' onChange={(e) => setSizesPrice({ ...sizesPrice, L: +e.target.value })} id="L" />
              </div>
              <div className="grid max-w-24 items-center gap-2">
                <Label htmlFor="XL" className="font-semibold text-gray-700">XL</Label>
                <Input value={sizesPrice.XL} className='focus-visible:ring-offset-0 focus-visible:ring-transparent border border-gray-400 rounded-md px-2 py-1' onChange={(e) => setSizesPrice({ ...sizesPrice, XL: +e.target.value })} id="XL" />
              </div>
              <div className="grid max-w-24 items-center gap-2">
                <Label htmlFor="2XL" className="font-semibold text-gray-700">2XL</Label>
                <Input value={sizesPrice.XXL} className='focus-visible:ring-offset-0 focus-visible:ring-transparent  border border-gray-400 rounded-md px-2 py-1' onChange={(e) => setSizesPrice({ ...sizesPrice, XXL: +e.target.value })} id="2XL" />
              </div>
            </div>
        </div>
        <DialogFooter>
          <Button onClick={handleSubmit}>Add Sizes Price</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>)
}

export function DeleteSides({ id, setCondEdDe ,setDeleteSides}: { id: number, setCondEdDe: Dispatch<SetStateAction<boolean>>,setDeleteSides:Dispatch<SetStateAction<boolean>> }) {
  const [open, setOpen] = useState(true)
  const [deleteProductAttribute] = useDeleteProductAttributeMutation()
  const handleClose = () => {
    setOpen(false)
    setDeleteSides(false)
  }
  return (
    <AlertDialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      {/* <AlertDialogTrigger asChild>
        <Button className="bg-red-800" >Delete Sides</Button>
      </AlertDialogTrigger> */}
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete Sides related to Category/SubCategory.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={() => { deleteProductAttribute(id); setCondEdDe(p => !p);setDeleteSides(false) }} className="bg-red-500" >Delete</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

const EditSides = ({ side, setCondEdDe,setEditSides }: { side: ProductAttribute, setCondEdDe: Dispatch<SetStateAction<boolean>>,setEditSides:Dispatch<SetStateAction<boolean>> }) => {
  const [categoryId, setCategoryId] = useState<number>(1)
  const [subcategoryId, setSubcategoryId] = useState<number>(0)
  const [sizesPrice, setSizesPrice] = useState({ S: 0, M: 0, L: 0, XL: 0, XXL: 0 })
  const [updateProductAttribute] = useUpdateProductAttributeMutation()
  const [open, setOpen] = useState(true)

  useEffect(() => {
    setCategoryId(side.categoryId)
    setSubcategoryId(side.subcategoryId)
    setSizesPrice(JSON.parse(side.attributeValue))
  }, [side])

  const handleSubmit = async () => {
    await updateProductAttribute({ id: side?.id, categoryId, subcategoryId, attributeName: "Sizes Price", attributeValue: JSON.stringify(sizesPrice) })
    setOpen(false)
    setEditSides(false)
    setCondEdDe(p => !p)
  }


  const handleClose = () => {
    setOpen(false)
    setEditSides(false)
  }


  return (
    <Dialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      {/* <DialogTrigger asChild>
        <Button variant="default" className="headermenu">Edit Sides</Button>
      </DialogTrigger> */}
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Edit Sizes By Price</DialogTitle>
          <p className="text-sm my-1 text-red-500"></p>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="flex items-center gap-4">
            <Label htmlFor="Category" className="text-right">
              Category: TShirt
            </Label>
          {/*   <FilterByCategory onChangeHandler={setCategoryId} categoryId={categoryId} /> */}
          </div>
          <div className="flex items-center gap-4">
            <Label htmlFor="subCategory" className="text-right">
              SubCategory:
            </Label>
            <FilterBySubCategory onChangeHandler={setSubcategoryId} subcategoryId={subcategoryId} categoryId={categoryId} />
          </div>
          <div className="flex items-center gap-4">
            <Label htmlFor="color" className="text-right">
              Edit Extra Prices Depending On Sizes:
            </Label>
            </div>
            <div className="flex items-center gap-3 flex-wrap">
            <div className="grid max-w-24 items-center gap-2">
                <Label htmlFor="s" className="font-semibold text-gray-700">S</Label>
                <Input value={sizesPrice.S} className='focus-visible:ring-offset-0 focus-visible:ring-transparent border border-gray-400 rounded-md px-2 py-1' onChange={(e) => setSizesPrice({ ...sizesPrice, S: +e.target.value })} id="s" />
              </div>
              <div className="grid max-w-24 items-center gap-2">
                <Label htmlFor="M" className="font-semibold text-gray-700">M</Label>
                <Input value={sizesPrice.M} className='focus-visible:ring-offset-0 focus-visible:ring-transparent border border-gray-400 rounded-md px-2 py-1' onChange={(e) => setSizesPrice({ ...sizesPrice, M: +e.target.value })} id="M" />
              </div>
              <div className="grid max-w-24 items-center gap-2">
                <Label htmlFor="L" className="font-semibold text-gray-700">L</Label>
                <Input value={sizesPrice.L} className='focus-visible:ring-offset-0 focus-visible:ring-transparent border border-gray-400 rounded-md px-2 py-1' onChange={(e) => setSizesPrice({ ...sizesPrice, L: +e.target.value })} id="L" />
              </div>
              <div className="grid max-w-24 items-center gap-2">
                <Label htmlFor="XL" className="font-semibold text-gray-700">XL</Label>
                <Input value={sizesPrice.XL} className='focus-visible:ring-offset-0 focus-visible:ring-transparent border border-gray-400 rounded-md px-2 py-1' onChange={(e) => setSizesPrice({ ...sizesPrice, XL: +e.target.value })} id="XL" />
              </div>
              <div className="grid max-w-24 items-center gap-2">
                <Label htmlFor="2XL" className="font-semibold text-gray-700">2XL</Label>
                <Input value={sizesPrice.XXL} className='focus-visible:ring-offset-0 focus-visible:ring-transparent  border border-gray-400 rounded-md px-2 py-1' onChange={(e) => setSizesPrice({ ...sizesPrice, XXL: +e.target.value })} id="2XL" />
              </div>
            </div>
        </div>
        <DialogFooter>
          <Button onClick={handleSubmit}>Edit Sizes By Price</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>)
}

export const FilterByCategory = ({ onChangeHandler, categoryId }: any) => {
  const { data: categories } = useGetAllCategoryQuery("categoriesList", { pollingInterval: 60000, refetchOnFocus: true, refetchOnMountOrArgChange: true })

  const handleValueChange = (selectedValue: string) => {
    onChangeHandler(selectedValue);
  };

  return (<div className="flex gap-1 items-center">
    <Select onValueChange={handleValueChange} value={categoryId.toString()} >
      <SelectTrigger className="bg-blue-50 text-md font-semibold px-0">
        <SelectValue placeholder="T Shirts" />
      </SelectTrigger>
      <SelectContent className='border-gray-500 bg-gray-100  hover:text-black '>
        {categories?.result?.map((category: any) => (
          <SelectItem
            key={category.id}
            value={category.id.toString()}
            className="select-item p-regular-14"
          >
            {category.categoryName}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  </div>);
};
export const FilterBySubCategory = ({ onChangeHandler, subcategoryId, categoryId }: any) => {
  const { data: subCategories } = useGetSubcategoryQuery(categoryId, { pollingInterval: 60000, refetchOnFocus: true, refetchOnMountOrArgChange: true })

  const handleValueChange = (selectedValue: string) => {
    onChangeHandler(Number(selectedValue));
  };

  return (<div className="flex gap-1 items-center">
    <Select onValueChange={handleValueChange} value={subcategoryId.toString()} >
      <SelectTrigger className="bg-blue-50 text-md font-semibold px-0">
        <SelectValue placeholder="All" />
      </SelectTrigger>
      <SelectContent className='border-none bg-gray-100  hover:text-black '>
        <SelectItem
          key={"All"}
          value={"0"}
          className="select-item p-regular-14"
        >
          All
        </SelectItem>
        {subCategories?.result?.map((subCategory: any) => (
          <SelectItem
            key={subCategory.id}
            value={subCategory.id.toString()}
            className="select-item p-regular-14"
          >
            {subCategory.subcategoryName}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  </div>);
};


export default PriceBySizes