
import { useParams } from "react-router-dom";
import { useGetSubcategoryByIdQuery } from "../redux/slice/CategoryApiSlice";
import MugDesignCanvas from "../components/shared/canvas/MugDesignCanvas";
import { useGetProductByIdQuery } from "../redux/slice/ProductsApiSlice";
import { ProductComTemplateT } from "@/react-app-env";

const DesigMug = () => {
    const { id } = useParams();
    const {data:designTemplate,isLoading,isError,isSuccess}=useGetProductByIdQuery(id)

    let content
    if (isLoading) content= (
        <div className="flex justify-center items-center h-full">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
        </div>
    );
    if (isError) content = <p>{designTemplate?.result?.message}</p>
    if (!designTemplate?.result){
      content= <section className="bgcolorgold" >
      <p className="p-4 text-center text-lg font-semibold">
        No Templates Available Under This SubCategory 
      </p></section>}
    if (isSuccess&& designTemplate?.result){
      content=   <div className="wrapper flex flex-wrap gap-8">
        <FetchSubcategoryDetails designTemplate={designTemplate.result}/>
    </div>
    }
    return (<section className="bgcolorgold" >
      <div>
      {content}
      </div>
      </section>);
}

const FetchSubcategoryDetails = ({designTemplate}:{designTemplate:ProductComTemplateT})=>{
    const { data: subCategoryDetails,isLoading ,isSuccess} = useGetSubcategoryByIdQuery(designTemplate?.subcategoryId)

    let content
    if (isLoading) content= (
        <div className="flex justify-center items-center h-full">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
        </div>
    );
    if (isSuccess&& subCategoryDetails?.result){
        content=   <div className="flex flex-wrap ">
         <MugDesignCanvas  designTemplate={designTemplate} canvasHeight={subCategoryDetails?.result?.canvasHeight} canvasWidth={subCategoryDetails?.result?.canvasWidth} />
      </div>
      }
    return <>{content}</>
}

export default DesigMug