import { Link } from "react-router-dom"
import { Card } from "../../../components/ui/card"
import { NavLinksH } from "../../../constants"

const popular=[
  {id:1,
    img:"/assets/ds/k2.png",
    name:"T Shirts",
    category:1
  },
  {id:2,
    img:"/assets/ds/k1.png",
    name:"Mugs",
    category:2
  },
  {id:3,
    img:"/assets/ds/k3.png",
    name:"DrinkWare",
    category:3
  },
  {id:4,
    img:"/assets/ds/k4.png",
    name:"Business Cards",
    category:11
  }
]
const mobile=[
  {id:1,
    img:"/assets/mobilecases/ps.webp",
    name:"Pop Socket",
    subCategoryId:69
  },
  {id:2,
    img:"/assets/mobilecases/ad.jpg",
    name:"Andriod Mobile Covers",
    subCategoryId:21
  },
  {id:3,
    img:"/assets/mobilecases/ip.jpg",
    name:"IPhone Mobile Covers",
    subCategoryId:20
  },
]

const officeStationary=[
  {id:1,
    img:"/assets/officestationary/vs.jpg",
    name:"Visiting Cards",
    subCategoryId:25
  },
  {id:2,
    img:"/assets/officestationary/lh.jpeg",
    name:"Letterheads",
    subCategoryId:26
  },
  {id:3,
    img:"/assets/officestationary/en.jpeg",
    name:"Envelope",
    subCategoryId:27
  },
  {id:4,
    img:"/assets/officestationary/bb.png",
    name:"Bill Book",
    subCategoryId:28
  },
  {id:5,
    img:"/assets/officestationary/pd.jpg",
    name:"Pads",
    subCategoryId:29
  },
  {id:6,
    img:"/assets/officestationary/cd.jpg",
    name:"Cards",
    subCategoryId:30
  }
]

const PopularProducts = () => {
  return (<div className="wrapper">
    <h5 className="font-bold text-2xl my-4 text-center text-blue-500">POPULAR PRODUCTS</h5>
     <div className="  grid sm:grid-cols-4 max-md:grid-cols-2 max-sm:grid-cols-1 gap-4">
     {popular.map(item=> <div className="w-full  border-gray-400 " key={item.id}>
      <Link to={`/category/${item.category}`}>
        <img src={item.img} alt={item.name} className="w-full lg:h-[280px] rounded-lg shadow-lg border-2  border-gray-500" />
        <p className="flex justify-center items-center h-[50px] font-semibold "> {item.name}</p></Link>
      </div>)}
    </div>
  </div>
  )
}

export const DecorativeProducts = () => {
  return (<div className="wrapper ">
    <h5 className="font-bold text-2xl my-4 text-center text-orange-500">HOME DECORATIVE</h5>
     <div className="grid md:grid-cols-2 grid-cols-1 gap-1">
      <div>
        <Link to={`/subcategory/31`}>
          <img src='/assets/images/homedecorative/walldecoration.jpg' alt="" className="w-full h-[485px] rounded-md border-2 border-gray-600" /></Link>
      </div>
      <div>
        <div className="grid grid-cols-2 gap-1">
          <div>
            <Link to={`/subcategory/44`}>
            <img src='/assets/images/homedecorative/clocks.jpg' alt="" className="h-[240px] w-full rounded-md border-2 border-gray-600" /></Link>
          </div>
          <div>
          <Link to={`/subcategory/32`}>
            <img src='/assets/images/homedecorative/photoframe.jpg' alt="" className="h-[240px] w-full rounded-md border-2 border-gray-600" /></Link>
          </div>
          <div>
            <Link to={`/category/4`}>
            <img src='/assets/images/homedecorative/cushions.jpg' alt="" className="h-[240px] w-full rounded-md border-2 border-gray-600" /></Link>
          </div>
          <div>
            <Link to={`/subcategory/66`}>
            <img src='/assets/images/homedecorative/stones.jpg' alt="" className="h-[240px] w-full rounded-md border-2 border-gray-600" /></Link>
          </div>
        </div>
        
      </div>
    </div>
  </div>
   
  )
}

export const MobileAccessories = () => {
  return (
    <div className="wrapper py-8 ">
      <h5 className="font-bold text-2xl my-4 text-center  text-red-500">MOBILE ACCESSORIES</h5>
    <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-5">
      {mobile.map(item => (
        <Card key={item.id} className="w-full h-[400px] border border-gray-500 shadow-lg hover:shadow-2xl transition-shadow duration-300 rounded-lg overflow-hidden">
          <Link to={`/subcategory/${item.subCategoryId}`} className="block h-full">
            <div className="overflow-hidden rounded-t-lg">
              <img src={item.img} alt={item.name} className="w-full h-[300px] object-cover transform hover:scale-110 transition-transform duration-300" />
            </div>
            <div className="flex justify-center items-center h-[100px] p-4 bg-white rounded-b-lg">
              <p className="text-center text-lg font-semibold text-blue-700 hover:text-blue-900 transition-colors duration-300">{item.name}</p>
            </div>
          </Link>
        </Card>
      ))}
    </div>
  </div>
);
};
export const CustomSection = () => {
  return (
    <div className="headermenu py-8 my-4">
    <div className="grid md:grid-cols-2 grid-cols-1 gap-2 wrapper  ">

     {/*    <div className="grid grid-cols-2 gap-1">
          <div>
            <Link to={`/category/15`}>
            <img src='/assets/images/customsection/fm.jpg' alt="" className="h-[250px] w-full rounded-md border-2 border-gray-600" /></Link>
          </div>
          <div>
          <Link to={`/category/17`}>
            <img src='/assets/images/customsection/cs.jpg' alt="" className="h-[250px] w-full rounded-md border-2 border-gray-600" /></Link>
          </div>
          
        </div>
        
 */}
    <div>
        <Link to={`/subcategory/18`}>
          <img src='/assets/images/customsection/tb.jpeg' alt="" className="w-full h-[250px] shadow-lg rounded-md border-2 border-gray-600" /></Link>
      </div>
    <div>
        <Link to={`/category/1`}>
          <img src='/assets/images/customsection/hts.jpg' alt="" className="w-full h-[250px] shadow-lg rounded-md border-2 border-gray-600" /></Link>
      </div>
      
    </div>
  </div>
);
};

export const OfficeStationary = () => {
  return (
    <div className="max-w-5xl mx-auto py-2">
      <h5 className="font-bold text-2xl my-6 text-center  text-blue-500">OFFICE STATIONARY</h5>
      <div className="grid md:grid-cols-3 grid-cols-1 sm:grid-cols-2 gap-5 max-sm:px-4">
        {officeStationary.map(item => (
          <Card key={item.id} className="w-full  border-gray-800 border-2 shadow-lg hover:shadow-xl transition-shadow duration-300">
            <Link to={`/subcategory/${item.subCategoryId}`}>
              <div className="overflow-hidden rounded-t-md">
                <img src={item.img} alt={item.name} className="w-full md:h-[280px] h-[300px] rounded-t-md transform hover:scale-105 transition-transform duration-300" />
              </div>
              <p className="flex justify-center items-center h-[65px] font-semibold text-lg text-gray-700 hover:text-gray-900 transition-colors duration-300">
                {item.name}
              </p>
            </Link>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default PopularProducts