const AboutUs = () => {
  return (
    <div className="bg-light-gray">
      <div className="wrapper flex flex-col gap-6 p-8">
        <h2 className="text-3xl font-bold  text-primary">
          About PrintFerry
        </h2>
        <div className="flex flex-col gap-4  text-gray-800">
          <p>
            Welcome to <strong>PrintFerry</strong>, your trusted partner in personalized printing solutions. At PrintFerry, we believe that every idea deserves to be seen, shared, and celebrated. Whether you're looking to create custom prints for your business, special events, or personal projects, we are here to bring your vision to life with quality, precision, and care.
          </p>
          <p>
            Founded with a passion for design and a commitment to excellence, PrintFerry has been serving customers across various industries, helping them turn their creative ideas into tangible, high-quality products. Our journey began with a simple goal: to provide top-notch printing services that combine the latest technology with unparalleled customer service.
          </p>
          <p>
            Over the years, we have grown from a small local print shop into a full-fledged online printing platform, catering to the diverse needs of our clients. From business cards to banners, flyers to brochures, and everything in between, our extensive range of products and services is designed to meet the unique demands of each project.
          </p>
          <h3 className="text-2xl font-semibold text-primary">
            Why Choose PrintFerry?
          </h3>
          <ul className="list-disc pl-6">
            <li>
              <strong>Quality You Can Trust:</strong> We use the latest printing technology and premium materials to ensure that every product meets the highest standards of quality.
            </li>
            <li>
              <strong>Custom Solutions:</strong> Our team of experienced designers and print specialists work closely with you to create custom prints that reflect your brand or personal style.
            </li>
            <li>
              <strong>Fast Turnaround:</strong> We understand the importance of deadlines. That's why we offer fast and reliable turnaround times, ensuring that your prints are ready when you need them.
            </li>
            <li>
              <strong>Competitive Pricing:</strong> We believe that high-quality printing should be accessible to everyone. Our competitive pricing structure ensures that you get the best value for your investment.
            </li>
            <li>
              <strong>Exceptional Customer Service:</strong> Our dedicated customer support team is always here to assist you with any questions or concerns, from the initial design to the final delivery.
            </li>
          </ul>
          <p>
            At PrintFerry, we are more than just a printing service. We are a team of passionate professionals who are committed to helping you make a lasting impression. Whether you're a small business owner, a marketing professional, or an individual with a creative idea, we are here to support your printing needs every step of the way.
          </p>
          <p>
            Thank you for choosing <strong>PrintFerry</strong>. We look forward to working with you and bringing your ideas to life.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

