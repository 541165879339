
import {  useNavigate, useParams } from "react-router-dom";
import { useGetSubcategoryByIdQuery } from "../redux/slice/CategoryApiSlice";
import { useEffect } from "react";
import CanvasDesignCustomer from "../components/shared/canvas/CanvasDesignCustomer";
import { useGetProductByIdQuery } from "../redux/slice/ProductsApiSlice";
import { ProductComTemplateT } from "@/react-app-env";
import { useGetProductAttributesByCatSubCatMutation } from "../redux/slice/admin/AAttributeApiSlice";


const DesignProduct = () => {
    const { id } = useParams();
    const {data:designTemplate,isLoading,isError,isSuccess}=useGetProductByIdQuery(id)
    const navigate=useNavigate()


    useEffect(()=>{
        if(designTemplate?.result?.categoryId===2){
   navigate(`/mugdesign/${designTemplate?.result?.productId}`)
        }
    },[designTemplate,navigate])

    let content
    if (isLoading) content= (
        <div className="flex justify-center items-center h-full">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
        </div>
    );
    if (isError) content = <p>{designTemplate?.result?.message}</p>
    if (!designTemplate?.result){
      content= <section className="bgcolorgold" >
      <p className="p-4 text-center text-lg font-semibold">
        No Templates Available Under This ID 
      </p></section>}
    if (isSuccess&& designTemplate?.result){
      content=   <div className="wrapper flex flex-wrap gap-8 font-display">
        <FetchSubcategoryDetails designTemplate={designTemplate.result} />
    </div>
    }
    return (<section className="bgcolorgold" >
      <div>
      {content}
      </div>
      </section>);
}

const FetchSubcategoryDetails = ({designTemplate}:{designTemplate:ProductComTemplateT})=>{

    const { data: subCategoryDetails,isLoading ,isSuccess} = useGetSubcategoryByIdQuery(designTemplate.subcategoryId)
    const [getProductAtt,{data:fixedSidesAdmin}] =useGetProductAttributesByCatSubCatMutation()

      const sidesFixedByAdmin = fixedSidesAdmin?.result.length>0&&  JSON.parse(fixedSidesAdmin?.result[0].attributeValue)
     
   useEffect(()=>{
     getProductAtt({catId:designTemplate.categoryId,subcatId:designTemplate.subcategoryId,attributeName:"Sides"})
   },[getProductAtt,designTemplate.categoryId,designTemplate.subcategoryId])

    let content
    if (isLoading) content= (
        <div className="flex justify-center items-center h-full">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
        </div>
    );
    if (isSuccess&& subCategoryDetails?.result){
        content=   <div className=" flex flex-wrap gap-8  mx-auto w-full font-display">
         {sidesFixedByAdmin?<CanvasDesignCustomer sidesFixedByAdmin={sidesFixedByAdmin} designTemplate={designTemplate} canvasHeight={subCategoryDetails?.result?.canvasHeight} canvasWidth={subCategoryDetails?.result?.canvasWidth} />:
         <CanvasDesignCustomer designTemplate={designTemplate} canvasHeight={subCategoryDetails?.result?.canvasHeight} canvasWidth={subCategoryDetails?.result?.canvasWidth} />}
      </div>
      }
    return <>{content}</>
}

export default DesignProduct