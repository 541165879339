import { apiSlice } from "../../apiSlice";

export const APrintAreaApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        savePrintareaShapes: builder.mutation({
            query: initialProdData => ({
                url: '/admin/savePrintareaShapes',
                method: 'POST',
                body: {
                    ...initialProdData,
                }
            }),
            invalidatesTags: [
                { type: 'Printarea', id: "LIST" }
            ]
        }),
        getAllPrintareaShapes: builder.query({
            query: () => ({
                url: '/admin/getAllPrintareaShapes',
            }),
            providesTags: (result, error, arg) => {
                console.log(result);
                if (result?.ids) {
                  return [
                    { type: 'Printarea', id: 'LIST' },
                    ...result.ids.map((id:any) => ({ type: 'Printarea', id })),
                  ];
                } else return [{ type: 'Printarea', id: 'LIST' }];
              },
        }),
        deletePrintareaShapes: builder.mutation({
            query: ( id ) => ({
                url: `/admin/deletePrintareaShapes/${id}`,
                method: 'DELETE',
                }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Printarea', id: arg.id }
            ]
        }),
    }),
})

export const {
    useDeletePrintareaShapesMutation,
    useGetAllPrintareaShapesQuery,
    useSavePrintareaShapesMutation
} = APrintAreaApiSlice

