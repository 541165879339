import { companyDeatils } from "../../../constants"
import { Separator } from "../../../components/ui/separator"
import { FaFacebookSquare, FaInstagram, FaWhatsapp } from "react-icons/fa"
import { MdEmail } from "react-icons/md"
import { Link } from "react-router-dom"


const Footer = () => {
  const phoneNumber = '8888855555';

  return (
    <footer className="border-gray-500 border-t-2 mt-4  xl:px-2 bg-gray-200">
      <div className="wrapper">
        <div className="flex justify-between gap-10">
          <div className="flex flex-col gap-2">
            <h3 className="font-bold text-blue-500">Company</h3>
            <div className="flex gap-1 flex-col">
              {companyDeatils.map(com=><Link to={com.route} key={com.id}>{com.name}</Link>)}
            </div>
          </div>
          <div className="flex flex-col  gap-3">
            <h3 className="font-bold text-blue-500">Connect On</h3>
            <div className="flex gap-6">
              <Link to={"https://www.instagram.com/"} target="_blank"><FaInstagram color="red" size={28} /></Link>
              <Link to={"https://www.facebook.com/"} target="_blank"><FaFacebookSquare color="blue" size={28} /> </Link>
            </div>
          </div>
          <div className="flex flex-col  gap-3">
            <h3 className="font-bold  text-blue-500">Support</h3>
            <div className="flex gap-6">
              <Link to={"mailto:printferry@gmail.com"} target="_blank"><MdEmail size={28} /></Link>
              <Link to={`https://wa.me/${phoneNumber}`} target="_blank"><FaWhatsapp color="green" size={28} /> </Link>
            </div>
          </div>
        </div>
        <Separator className="my-3 bg-white " />
        <div className="flex flex-col gap-3 items-center">
          <p className="font-bold text-xl">Secure Payments</p>
          <div className="flex gap-6 flex-wrap justify-center ">
            <img src="/assets/payments/paypal.png" alt="paypal" className="h-[40px]" />
            <img src="/assets/payments/Razorpay.png" alt="Razorpay" className="h-[40px]" />
            <img src="/assets/payments/mastercard.png" alt="MasterCard" className="h-[40px]" />
            <img src="/assets/payments/rupay.png" alt="Rupay" className="h-[40px]" />
            <img src="/assets/payments/upi.png" alt="UPI" className="h-[40px]" />
          </div>
        </div>
        <Separator className="my-3 bg-white " />
        <div className=" lg:justify-center items-center  flex  gap-10">
          <p>@copyright 2024 All Rights reserved.</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer