
import { Row } from "@tanstack/react-table";
import jsPDF from 'jspdf';
import { Button } from "../../../../ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../ui/select";
import { OrderT} from "@/react-app-env";
import { useNavigate } from "react-router-dom";
import { useDeleteProductAMutation } from "../../../../../redux/slice/admin/AProductsApiSlice";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../../../ui/alert-dialog";
import { RiDeleteBin6Line } from "react-icons/ri";
import {  useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import {  useUpdateOrderCourierInfoMutation, useUpdateOrderStatusAMutation } from "../../../../../redux/slice/admin/ACreateOrderApiSlice";
import { cancelledStatuses, orderStatuses } from "../../../../../constants/index";
import { Input } from "../../../../ui/input";
import { IoMdDownload } from "react-icons/io";
import { IMGURL } from "../../../../../constants/appConfig";
import { CgYinyang } from "react-icons/cg";

interface DataTableRowActionsProps<TData> {
  row: Row<OrderT>;
  type?:string
}

export function DataTableRowActionsOrder<TData>({
  row,type
}: DataTableRowActionsProps<TData>) {
  const order = row.original;
  const [updateorderStatus, { data, isSuccess }] = useUpdateOrderStatusAMutation();
  const [orderStatus, setOrderStatus] = useState<string>(order.orderStatus);
  const [open,setOpen]=useState(false)
  const handleClose=()=>{
    setOpen(false)
  }
  const status= type==="cancelled"?cancelledStatuses:orderStatuses
  
  const handleUpdateOrder = async () => {
    await updateorderStatus({ordStatus:orderStatus, ordId: order.id });
    setOpen(false)
  };
  const changeHandler = (value: string) => {
    setOrderStatus(value);
  };
  return (
    <AlertDialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }}}>
      <AlertDialogTrigger asChild>
        <Button variant="ghost" className="text-sm font-normal">
          {order.orderStatus} <FaRegEdit size={16} className="pl-1" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Change Order Status</AlertDialogTitle>
          <AlertDialogDescription>
            <Select value={orderStatus} onValueChange={changeHandler}>
              <SelectTrigger className="w-[60%] bg-white ring-1">
                <SelectValue placeholder="Theme" />
              </SelectTrigger>
              <SelectContent className="bg-gray-100">
                {status?.map((status) => (
                  <SelectItem key={status.id} value={status.name}>
                    {status.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button onClick={handleUpdateOrder}>Update Status</Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
export function DataTableRowActionsCourier<TData>({
  row,
}: DataTableRowActionsProps<TData>) {
  const order = row.original;
  const [updateCourierStatus] = useUpdateOrderCourierInfoMutation();
  const [cName, setCName] = useState<string>("DTDC Express Limited");
  const [cBillNo, setCBillNo] = useState<string>("");
  const [open,setOpen]=useState(false)
  const handleClose=()=>{
    setOpen(false)
  }
  
  
  const handleUpdateCourierInfo = async () => {
   // console.log({courierName:cName, orderId: order.id,billNo:cBillNo});
    await updateCourierStatus({courierName:cName, orderId: order.id,billNo:cBillNo});
    setOpen(false)
  };
/*   const changeHandler = (value: string) => {
    setCName(value);
  }; */
  return (
    <AlertDialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }}}>
      <AlertDialogTrigger asChild>
        <Button variant="ghost" className="text-sm font-normal">
          {order.courierName}{", "}{order.CourierBillNo} <FaRegEdit size={16} className="pl-1" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Change/Enter Courier Info</AlertDialogTitle>
          <AlertDialogDescription className="flex flex-col gap-2">
            {/* <Select value={cName} onValueChange={changeHandler}>
              <SelectTrigger className="w-[60%] bg-white ring-1">
                <SelectValue placeholder="Courier Service" />
              </SelectTrigger>
              <SelectContent className="bg-gray-100">
                {indianCourierServices?.map((status) => (
                  <SelectItem key={status.id} value={status.name}>
                    {status.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select> */}
            <Input className="text-black" value={cName} onChange={(event) => setCName(event.target.value)} placeholder="Courier Service" />
            <Input className="text-black" value={cBillNo} onChange={(event) => setCBillNo(event.target.value)} placeholder="Courier Bill No" />
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button onClick={handleUpdateCourierInfo}>Update/Add Courier Info</Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export function AlertDialogDemo({ id }: { id: number }) {
  const [deleteProductA, { isSuccess, data }] = useDeleteProductAMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess && data?.success) {
      /*  navigate("/admin/dashboard/products") */
      window.location.reload();
    }
  }, [isSuccess, navigate, data]);

  const handleRemoveProduct = async () => {
    await deleteProductA(id);
  };
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="ghost" className="pl-0">
          Delete <RiDeleteBin6Line size={18} />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently remove the
            Product from app.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button onClick={handleRemoveProduct}>Continue</Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

const generatePDF = async (order: OrderT) => {
  const doc = new jsPDF();
  const attributes = JSON.parse(order.orderAttributes);


  // Set title
  doc.setFontSize(20);
  doc.text('Order Details', 10, 10);

    // Initial Y position for the text
    let currentY = 20;

    const addTextToPDF = (text: string, x: number, y: number, maxWidth: number, lineSpacing: number = 5) => {
      const lines = doc.splitTextToSize(text, maxWidth);
      const lineHeight = doc.getTextDimensions(lines[0]).h + lineSpacing; // Calculate line height including spacing
  
      // Check if there's enough space on the current page
      if (y + lines.length * lineHeight > doc.internal.pageSize.height) {
          doc.addPage(); // Add a new page
          y = 20; // Reset y position for the new page
      }
  
      // Add the text lines to the PDF
      doc.text(lines, x, y + 5); // Adjust the initial y position
      return y + lines.length * lineHeight; // Return the new y position
  };

  // Add order details with word wrapping
  doc.setFontSize(12);
  currentY = addTextToPDF(`Order ID: ${order.id}`, 10, currentY, 180);
  currentY = addTextToPDF(`Order Number: ${order.orderNumber}`, 10, currentY, 180);
  currentY = addTextToPDF(`Order Date: ${order.orderDate}`, 10, currentY, 180);
  //currentY = addTextToPDF(`Customer ID: ${order.customerId}`, 10, currentY, 180);
  currentY = addTextToPDF(`Delivery Address: ${order.deliveryAddress}`, 10, currentY, 180);
  currentY = addTextToPDF(`Mobile: ${order.mobile}`, 10, currentY, 180);
  currentY = addTextToPDF(`Product Name: ${order.productName}`, 10, currentY, 180);
 // currentY = addTextToPDF(`Product ID: ${order.productId}`, 10, currentY, 180);
 // currentY = addTextToPDF(`Template ID: ${order.templateId}`, 10, currentY, 180);
  currentY = addTextToPDF(`Price: ${order.price}`, 10, currentY, 180);
  currentY = addTextToPDF(`Quantity: ${order.quantity}`, 10, currentY, 180);
  currentY = addTextToPDF(`Total Amount: ${order.totalAmount}`, 10, currentY, 180);
  currentY = addTextToPDF(`Tax Amount: ${order.taxAmount}`, 10, currentY, 180);
  currentY = addTextToPDF(`Order Total: ${order.orderTotal}`, 10, currentY, 180);
  currentY = addTextToPDF(`Transaction No: ${order.transactionNo}`, 10, currentY, 180);
  currentY = addTextToPDF(`Transaction Payment ID: ${order.transactionPaymentId}`, 10, currentY, 180);
  currentY = addTextToPDF(`Courier Name: ${order.courierName}`, 10, currentY, 180);
  currentY = addTextToPDF(`Courier Bill No: ${order.CourierBillNo}`, 10, currentY, 180);
  currentY = addTextToPDF(`Order Status: ${order.orderStatus}`, 10, currentY, 180);


  // Add attributes?
  currentY = addTextToPDF(`Attributes:`, 10, currentY, 180);
  if (attributes?.S) currentY = addTextToPDF(`Size - S: ${attributes?.S}`, 10, currentY, 180);
  if (attributes?.M) currentY = addTextToPDF(`Size - M: ${attributes?.M}`, 10, currentY, 180);
  if (attributes?.L) currentY = addTextToPDF(`Size - L: ${attributes?.L}`, 10, currentY, 180);
  if (attributes?.XL) currentY = addTextToPDF(`Size - XL: ${attributes?.XL}`, 10, currentY, 180);
  if (attributes?.XXL) currentY = addTextToPDF(`Size - XXL: ${attributes?.XXL}`, 10, currentY, 180);
  if (attributes?.Color) currentY = addTextToPDF(`Color: ${attributes?.Color}`, 10, currentY, 180);

  if (attributes?.activateSide) {
    currentY = addTextToPDF(`Activate Side:`, 10, currentY, 180);
    currentY = addTextToPDF(`Front: ${attributes?.activateSide.front}`, 20, currentY, 180);
    currentY = addTextToPDF(`Back: ${attributes?.activateSide.back}`, 20, currentY, 180);
    currentY = addTextToPDF(`Sides: ${attributes?.activateSide.sides}`, 20, currentY, 180);
  }
  if (attributes?.paperSize?.id) {
    currentY = addTextToPDF(`Paper Size:`, 10, currentY, 180);
    currentY = addTextToPDF(`Paper: ${attributes?.paperSize.paper}`, 20, currentY, 180);
    currentY = addTextToPDF(`Extra Price: ${attributes?.paperSize.ePrice}`, 20, currentY, 180);
   // currentY = addTextToPDF(`id: ${attributes?.paperSize.id}`, 20, currentY, 180);
  }
  if (attributes?.paperQuality?.id) {
    currentY = addTextToPDF(`Paper Quality:`, 10, currentY, 180);
    currentY = addTextToPDF(`Quality: ${attributes?.paperQuality.quality}`, 20, currentY, 180);
    currentY = addTextToPDF(`Extra Price: ${attributes?.paperQuality.ePrice}`, 20, currentY, 180);
   // currentY = addTextToPDF(`id: ${attributes?.paperQuality.id}`, 20, currentY, 180);
  }

  if (attributes?.paperType?.type?.id) {
    currentY = addTextToPDF(`Paper Type:`, 10, currentY, 180);
    currentY = addTextToPDF(`PaperType: ${attributes?.paperType.type.paperType}`, 20, currentY, 180);
    currentY = addTextToPDF(`Extra Price: ${attributes?.paperType.type.ePrice}`, 20, currentY, 180);
   // currentY = addTextToPDF(`id: ${attributes?.paperType.type.id}`, 20, currentY, 180);
  }

  if (attributes?.paperType?.PaperConstains?.id) {
    currentY = addTextToPDF(`Paper Contain:`, 10, currentY, 180);
    currentY = addTextToPDF(`Contain: ${attributes?.paperType.PaperConstains.contain}`, 20, currentY, 180);
    currentY = addTextToPDF(`Extra Price: ${attributes?.paperType.PaperConstains.ePrice}`, 20, currentY, 180);
    //currentY = addTextToPDF(`id: ${attributes?.paperType.PaperConstains.id}`, 20, currentY, 180);
  }
  if (attributes?.paperType?.paperColors) {
    currentY = addTextToPDF(`Paper Colors:`, 10, currentY, 180);
    currentY = addTextToPDF(`Color: ${attributes?.paperType.paperColors.color}`, 20, currentY, 180);
 //   currentY = addTextToPDF(`id: ${attributes?.paperType.paperColors.id}`, 20, currentY, 180);
  }
  
  if (attributes?.SerailNo?.id) {
    currentY = addTextToPDF(`Serial Numbers:`, 10, currentY, 180);
    currentY = addTextToPDF(`Serial No: ${attributes?.SerailNo.type}`, 20, currentY, 180);
   // currentY = addTextToPDF(`id: ${attributes?.SerailNo.id}`, 20, currentY, 180);
  }

  if (attributes?.instructions) {
    currentY = addTextToPDF(`Paper Instructions:`, 10, currentY, 180);
    currentY = addTextToPDF(`Instructions: ${attributes?.instructions}`, 20, currentY, 180);
  }


currentY += 20;


const addImageToPDF = async (imageUrl: string, imageName: string) => {
  const img = new Image();
  img.src = imageUrl;

  await new Promise((resolve) => {
      img.onload = () => {
          const pageWidth = doc.internal.pageSize.getWidth();
          const margin = 20; // Adjust margin as needed
          const maxWidth = pageWidth * 0.8 - margin * 2; // 80% of the page width
          const scale = maxWidth / img.width;
          const imgWidth = img.width * scale;
          const imgHeight = img.height * scale;
          doc.addPage(); 
          doc.text(imageName, margin, 20); // Add image name above the image
          doc.addImage(img, 'PNG', margin, 30, imgWidth, imgHeight);
          resolve(null);
      };
  });
};

// const addImageToPDF = async (imageUrl: string, imageName: string) => {
//   const img = new Image();
//   img.src = imageUrl;

//   await new Promise((resolve) => {
//     img.onload = () => {
//       const pageWidth = doc.internal.pageSize.getWidth();
//       const margin = 20; 
//       const maxWidth = pageWidth * 0.8 - margin * 2; 
//       const scale = maxWidth / img.width;
//       const imgWidth = img.width * scale;
//       const imgHeight = img.height * scale;

//       const imgX = margin;
//       const imgY = 30;

//       doc.addPage();
//       doc.text(imageName, margin, 20); 
     
//       doc.setDrawColor(0, 0, 0); 
//       doc.rect(imgX , imgY , imgWidth, imgHeight ); // Draw rectangle for border

//       // Add the image
//       doc.addImage(img, 'PNG', imgX, imgY, imgWidth, imgHeight);
//       resolve(null);
//     };
//   });
// };

// Add images, each on a new page
await addImageToPDF(`${IMGURL}${order.orderImageUrl}`, 'Main Image');

if (order.orderImageBackUrl) {
  await addImageToPDF(`${IMGURL}${order.orderImageBackUrl}`, 'Back Image');
}

if (order.orderImageLeftUrl) {
  await addImageToPDF(`${IMGURL}${order.orderImageLeftUrl}`, 'Left Image');
}

if (order.orderImageRightUrl) {
  await addImageToPDF(`${IMGURL}${order.orderImageRightUrl}`, 'Right Image');
}

  // Download the PDF
  doc.save(`order_${order.id}.pdf`);
};


export function DataTableRowActionsOrderDownload<TData>({
  row,type
}: DataTableRowActionsProps<TData>) {
  const order = row.original;
 
  const handleDownload = async () => {
    await generatePDF(order);
   //console.log(order);
   
  };

  return <Button onClick={handleDownload}><IoMdDownload />
</Button>
}

/* const generatePDF = async (order: OrderT) => {
  const doc = new jsPDF();
  const attributes? = JSON.parse(order.orderAttributes?)
  // Set title
  doc.setFontSize(20);
  doc.text('Order Details', 10, 10);

  // Set order details
  doc.setFontSize(12);
  doc.text(`Order ID: ${order.id}`, 10, 20);
  doc.text(`Courier Name: ${order.courierName}`, 10, 30);
  doc.text(`Courier Bill No: ${order.CourierBillNo}`, 10, 40);
  // Add more order details as needed

  // Function to add images to the PDF
  const addImageToPDF = async (imageUrl: string, x: number, y: number) => {
    const img = new Image();
    img.src = `${IMGURL}${imageUrl}`;
    await new Promise((resolve) => {
      img.onload = () => {
        doc.addImage(img, 'PNG', x, y, 50, 50); // Adjust the size as needed
        resolve(null);
      };
    });
  };


  // Add front images
  await addImageToPDF(order.orderImageUrl, 10, 50);

  // Conditionally add other images
  if (order.orderImageBackUrl) {
    await addImageToPDF(order.orderImageBackUrl, 70, 50);
  }
  if (order.orderImageLeftUrl) {
    await addImageToPDF(order.orderImageLeftUrl, 10, 110);
  }
  if (order.orderImageRightUrl) {
    await addImageToPDF(order.orderImageRightUrl, 70, 110);
  }

  // Download the PDF
  doc.save(`order_${order.id}.pdf`);
}; */
