/* import clsx from 'clsx';
import { NavLink, useLocation } from 'react-router-dom';
import { IoMdHome } from 'react-icons/io';
import { HiOutlineDocumentDuplicate } from 'react-icons/hi';
import { HiOutlineUserGroup } from 'react-icons/hi2';
import { FaCartArrowDown } from "react-icons/fa";

const links = [
  { name: 'DashBoard', path: '/admin', icon: IoMdHome },
  { name: 'Orders',path: '/admin/dashboard/orders', icon: FaCartArrowDown },
  { name: 'Products', path: '/admin/dashboard/products', icon: HiOutlineDocumentDuplicate },
  { name: 'Customers', path: '/admin/dashboard/customers', icon: HiOutlineUserGroup },
];

 export default function NavLinks() {
  const { pathname } = useLocation();

  return (
    <>
      {links.map((link) => {
        const LinkIcon = link.icon;
        return (
          <NavLink
            key={link.name}
            to={link.path}
            className={clsx(
              'flex h-[48px] grow items-center justify-center gap-2 rounded-md headermenu p-3 text-sm font-medium hover:bg-sky-100 hover:text-blue-600 md:flex-none md:justify-start md:p-2 md:px-3',
              { 'bg-red-100 text-red-600': pathname === link.path }
            )}
          >
            <LinkIcon size={20} />
            <p className="hidden md:block">{link.name}</p>
          </NavLink>
        );
      })}
    </>
  );
} */

import { useState } from 'react';
import clsx from 'clsx';
import { NavLink, useLocation } from 'react-router-dom';
import { IoMdHome } from 'react-icons/io';
import { HiOutlineDocumentDuplicate } from 'react-icons/hi';
import { HiOutlineUserGroup } from 'react-icons/hi2';
import { CgTemplate } from "react-icons/cg";
import { FaCartArrowDown } from "react-icons/fa";
import { Button } from '../../../../components/ui/button';
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa6";
import { IoColorPaletteOutline, IoShapesSharp } from "react-icons/io5";
import { MdAttractions, MdOutlineCategory, MdOutlineDesignServices } from 'react-icons/md';
import { RxTransparencyGrid } from "react-icons/rx";
import { ImImages } from 'react-icons/im';

const links = [
  { name: 'DashBoard', path: '/admin', icon: IoMdHome },
  { name: 'Orders', path: '/admin/dashboard/orders', icon: FaCartArrowDown, subRoutes: [
    { path: '/admin/dashboard/orders/active', name: 'Active Orders' },
    { path: '/admin/dashboard/orders/cancelled', name: 'Cancelled Orders' },
    { path: '/admin/dashboard/orders/closed', name: 'Closed Orders' },
  ] },
  { name: 'Products', path: '/admin/dashboard/products', icon: HiOutlineDocumentDuplicate },
  { name: 'Template Design', path: '/admin/dashboard/templates?catId=1', icon: CgTemplate },
  { name: 'Logo/Custom Images', path: '/admin/dashboard/logo', icon: MdOutlineDesignServices  },
  { name: 'Attributes', path: '/admin/dashboard/attributes', icon: MdAttractions , subRoutes: [
    { path: '/admin/dashboard/attributes/colors', name: 'Colors' },
    { path: '/admin/dashboard/attributes/sides', name: 'Print Sides' },
    { path: '/admin/dashboard/attributes/stationary', name: 'Stationary' },
    { path: '/admin/dashboard/attributes/sizes', name: 'sizes' },
  ] },
  { name: 'Printarea Shapes', path: '/admin/dashboard/area', icon: IoShapesSharp  },
  { name: 'Customers', path: '/admin/dashboard/customers', icon: HiOutlineUserGroup },
  { name: 'Categories', path: '/admin/dashboard/categories', icon: MdOutlineCategory }, 
  { name: 'Banner Images', path: '/admin/dashboard/banner-images', icon: ImImages   },
  { name: 'Trasparent Images', path: '/admin/dashboard/custom-images', icon: RxTransparencyGrid },
];

const NavLinks = () => {
  const { pathname } = useLocation();
  const [showSubRoutes, setShowSubRoutes] = useState("");
  const handleOrdersClick = (name:string) => {
    setShowSubRoutes(pre=>pre===name?"":name);
  };

  return (
    <>
      {links.map((link) => {
        const LinkIcon = link.icon;
        let NavLinks
        if(link?.subRoutes?.length!>0){
          const dropdownOrder=<Button key={link.name} onClick={()=>handleOrdersClick(link.name)}className='flex justify-between w-full p-3 headermenu hover:bg-sky-100  hover:text-blue-600 md:p-2 md:px-3'><span className='flex gap-2'><LinkIcon size={20} />{link.name}</span>{showSubRoutes?<FaChevronUp  size={18}/>:<FaChevronDown size={18} />} </Button>
          const subRoutes=link?.subRoutes?.map(sublink=><SubRoutesNavigation sublink={sublink}   key={sublink.name} />)
          NavLinks=<div key={link.name}>
          {dropdownOrder}
          {showSubRoutes===link.name&&subRoutes}
          </div>
        }else{
         NavLinks=<NavLink
            key={link.name}
            to={link.path}
            className={clsx(
              'flex h-[48px] grow items-center justify-center gap-2 rounded-md headermenu p-3 text-sm font-medium hover:bg-sky-100 hover:text-blue-600 md:flex-none md:justify-start md:p-2 md:px-3',
              { 'bg-red-100 text-red-600': pathname === link.path }
            )}
          >
            <LinkIcon size={20} />
            <p className="hidden md:block">{link.name}</p>
          </NavLink>
        }
        return NavLinks
      })}
    </>
  );
};



const SubRoutesNavigation=({sublink}:any)=>{
  const { pathname } = useLocation();
  return <NavLink
  key={sublink.name}
  to={sublink.path}
  className={clsx(
    'flex h-[48px] grow items-center justify-center gap-2 rounded-md headermenu p-3 text-sm font-medium hover:bg-sky-100 hover:text-blue-600 md:flex-none md:justify-start md:p-2 md:px-8',
    { 'bg-red-100 text-red-600': pathname === sublink.path }
  )}
>
  {/* <LinkIcon size={20} /> */}
  <p className="hidden md:block">{sublink.name}</p>
</NavLink>
}
export default NavLinks;
