import { apiSlice } from "../../../redux/apiSlice";


export const AcategoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addCategoryA: builder.mutation({
        query: initialProdData => ({
            url: '/admin/addCategory',
            method: 'POST',
            body: { ...initialProdData}
        }),
        invalidatesTags: [
            { type: 'Category', id: "LIST" }
        ]
    }),
    updateCategoryA: builder.mutation({
        query: initialProdData => ({
            url: '/admin/updateCategory',
            method: 'POST',
            body: { ...initialProdData}
        }),
        invalidatesTags: [
            { type: 'Category', id: "LIST" }
        ]
    }),
    addSubcategoryA: builder.mutation({
        query: initialProdData => ({
            url: '/admin/addSubcategory',
            method: 'POST',
            body: { ...initialProdData}
        }),
        invalidatesTags: [
            { type: 'SubCategory', id: "LIST" }
        ]
    }),
    updateSubcategoryA: builder.mutation({
        query: initialProdData => ({
            url: '/admin/updateSubcategory',
            method: 'POST',
            body: { ...initialProdData}
        }),
        invalidatesTags: [
            { type: 'SubCategory', id: "LIST" }
        ]
    }),
    deleteCategoryA: builder.mutation({
        query: ( id ) => ({
            url: `/admin/deleteCategory/${id}`,
            method: 'DELETE',
            }),
        invalidatesTags: (result, error, arg) => [
            { type: 'Category', id: arg.id }
        ]
    }),
    deleteSubCategoryA: builder.mutation({
        query: ( id ) => ({
            url: `/admin/deleteSubcategory/${id}`,
            method: 'DELETE',
            }),
        invalidatesTags: (result, error, arg) => [
            { type: 'SubCategory', id: arg.id }
        ]
    }),
  }),
});


export const {
    useAddCategoryAMutation,
    useDeleteCategoryAMutation,
    useAddSubcategoryAMutation,
    useUpdateCategoryAMutation,
    useDeleteSubCategoryAMutation,
    useUpdateSubcategoryAMutation,
} = AcategoryApiSlice

