import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { Button } from "../../components/ui/button";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog"
import { useCopyProductTemplateMutation, useDeleteCategoryTemplateAMutation, useGetProductTemplateMutation } from "../../redux/slice/admin/ATemplateApiSlice";
import { Card } from "../../components/ui/card";
import { IMGURL } from "../../constants/appConfig";
import { TemplateT } from "@/react-app-env";
import { useGetAllCategoryQuery, useGetSubcategoryQuery } from "../../redux/slice/CategoryApiSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MdDelete, MdEdit } from "react-icons/md";
import { FaRegCopy } from "react-icons/fa";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../components/ui/tooltip";
import { toast } from "react-toastify";

const TemplatesAdmin = () => {
  const [searchParams] = useSearchParams();
  const catId = searchParams.get("catId") || 1;
  const subCatId = searchParams.get("subCatId") || "All";
  const [getTemplates, { data: templates }] = useGetProductTemplateMutation()
  const [copyTemplateById] = useCopyProductTemplateMutation()
  const [condEdDe, setCondEdDe] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (subCatId === "All") {
      getTemplates({ categoryId: catId })
    } else {
      getTemplates({ categoryId: catId, subcategoryId: subCatId })
    }
  }, [catId, getTemplates, subCatId, condEdDe])

  const handleCopyTemplate = async (id: number) => {
   const response = await copyTemplateById({ id })
   console.log(response);
   if( "data" in response){
   if(response.data.success){
    setCondEdDe(p=>!p)
    toast.success("Copied Template Successfully")
   }
    
   }
   
  }

  return (
    <div className="wrapper-dashboard bg-gray-50">
      <div className="flex justify-between flex-wrap gap-3">
        <h5 className="h5-bold">Templates</h5>
        <div className="flex gap-4">
          <FilterByCategoryTemplate catId={catId.toString()} />
          <FilterBySubCategoryTemplate catId={catId.toString()} subCatId={subCatId.toString()} />
        </div>
        <Button className="headermenu" onClick={() => navigate("/admin/dashboard/newtemplate")}>Add New Template</Button>
      </div>
      <div className="flex flex-wrap gap-6 my-4">
        {templates?.result?.length > 0 && templates?.result?.map((template: TemplateT) => <Card key={template.id} className="max-w-[300px] relative">
          <img src={`${IMGURL}${template.templatePreviewUrl}`} alt="template" className="w-full min-h-[100px] max-h-[300px]" />
          <div className="flex gap-3 justify-between bg-gray-200 border-t-2 border-gray-600">
            <TooltipProvider delayDuration={100}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button onClick={() => handleCopyTemplate(template.id)} variant="ghost"><FaRegCopy size={20} color="blue" /></Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Copy Template</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <TooltipProvider delayDuration={100}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button onClick={() => navigate(`/admin/dashboard/edittemplate/${template.id}`)} variant="ghost"><MdEdit size={20} color="blue" /></Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Edit Template</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <DeleteTemplate id={template.id} setCondEdDe={setCondEdDe} />
          </div>
        </Card>)}
        {templates?.result?.length <= 0 && <h2 className="my-3 font-bold text-lg">Selected Filters There Is No Templates</h2> }
      </div>
    </div>
  )
}



export function DeleteTemplate({ id, setCondEdDe }: { id: number, setCondEdDe: Dispatch<SetStateAction<boolean>> }) {
  const [open, setOpen] = useState(false)
  const [deleteTemplate, { data }] = useDeleteCategoryTemplateAMutation()
  const handleClose = () => {
    setOpen(false)
  }
  const handleDelete = async () => {
    await deleteTemplate(id)
    setCondEdDe((pre: boolean) => !pre)
  }
  return (
    <AlertDialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      <AlertDialogTrigger asChild>
        <Button className="" variant="ghost">{/* Delete Template */}<MdDelete size={20} color="red" /></Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete the template.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={() => { setOpen(false) }}>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={handleDelete} className="bg-red-500">Continue</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export const FilterByCategoryTemplate = ({ catId }: { catId?: string }) => {
  const { data: categories } = useGetAllCategoryQuery("categoriesList", { pollingInterval: 60000, refetchOnFocus: true, refetchOnMountOrArgChange: true })
  const [searchParams, setSearchParams] = useSearchParams();
  const handleValueChange = (selectedValue: string) => {
    setSearchParams({ "catId": selectedValue, "subCatId": "All" });
  };

  return (<div className="flex gap-1 items-center">
    <p className="font-bold">Category:</p>
    <Select onValueChange={handleValueChange} value={catId} >
      <SelectTrigger className="bg-blue-50 text-md font-semibold px-0">

        <SelectValue placeholder="T Shirts" />
      </SelectTrigger>
      <SelectContent className='border-gray-500 bg-gray-100  hover:text-black '>
        {categories?.result?.map((category: any) => (
          <SelectItem
            key={category.id}
            value={category.id.toString()}
            className="select-item p-regular-14"
          >
            {category.categoryName}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  </div>);
};

export const FilterBySubCategoryTemplate = ({ catId, subCatId }: { catId?: string, subCatId?: string }) => {
  const { data: subCategories } = useGetSubcategoryQuery(catId, { pollingInterval: 60000, refetchOnFocus: true, refetchOnMountOrArgChange: true })
  const [searchParams, setSearchParams] = useSearchParams();
  const ScatId = searchParams.get("catId") || 1;

  const handleValueChange = (selectedValue: string) => {
    setSearchParams({ "catId": ScatId.toString(), "subCatId": selectedValue });
  };

  return (<div className="flex gap-1 items-center">
    <p className="font-bold">SubCategory:</p>
    <Select onValueChange={handleValueChange} value={subCatId} >
      <SelectTrigger className="bg-blue-50 text-md font-semibold px-0">
        <SelectValue placeholder="All" />
      </SelectTrigger>
      <SelectContent className='border-none bg-gray-100  hover:text-black '>
        <SelectItem
          key={"All"}
          value={"All"}
          className="select-item p-regular-14"
        >
          All
        </SelectItem>
        {subCategories?.result?.map((subCategory: any) => (
          <SelectItem
            key={subCategory.id}
            value={subCategory.id.toString()}
            className="select-item p-regular-14"
          >
            {subCategory.subcategoryName}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  </div>);
};

export default TemplatesAdmin
