import { useDeletePrintareaShapesMutation, useGetAllPrintareaShapesQuery, useSavePrintareaShapesMutation } from '../../redux/slice/admin/APrintAreaApiSlice'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
  } from "../../components/ui/dialog"
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from '../../components/ui/button'
import { Label } from '../../components/ui/label'
import { Input } from '../../components/ui/input'
import { Textarea } from '../../components/ui/textarea'
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
  } from "../../components/ui/alert-dialog"
import { MdDeleteOutline } from 'react-icons/md'
import { TPrintareaShapes } from '@/react-app-env'
import { FaEdit } from 'react-icons/fa'
const PrintareaShapes = () => {
    const { data: printshapes ,isLoading,isError,isSuccess} = useGetAllPrintareaShapesQuery({})

 
 let content
 if (isLoading) content= (
    <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
    </div>
);
 if (isError) content = <p>{printshapes?.result?.message}</p>
 if (!printshapes?.result?.length){
   content=<div className="wrapper-dashboard">
   <div className="flex justify-between gap-4 flex-wrap">
     <h5 className="text-xl font-bold">PrintArea Shapes</h5>
     <div className="flex gap-6 flex-wrap">
    
     </div>
    <AddSidesToCategory/>
   </div>
   <p className="p-4 text-center text-lg font-semibold">
     No PrintShapes Available Under This Category 
   </p></div>}
 if (isSuccess&& printshapes?.result?.length>0){
   content=   <div className="wrapper-dashboard">
   <div className="flex justify-between gap-4 flex-wrap">
     <h5 className="text-xl font-bold">PrintArea Shapes</h5>
     <div className="flex gap-6 flex-wrap">
    
     </div>
    <AddSidesToCategory/>
   </div>
   <div className="my-4">
     <div className="flex gap-2 items-center flex-wrap my-4">
     {printshapes?.result?.map((area:TPrintareaShapes)=><div key={area.id} className='shadow-lg rounded-md border border-gray-400 p-2 relative'>
        <p className='font-semibold'>{area.shapeName}</p>
        <svg xmlns="http://www.w3.org/2000/svg" className='m-2' width="100" height="100" viewBox="0 0 300 300" preserveAspectRatio="xMidYMid meet">
                <path d={area.dimensions} fill="none" stroke="black" strokeWidth="6" />
        </svg>    
        <div className="flex justify-between rounded-lg gap-2 bg-gray-200">
            <EditArea area={area}/>
            <DeleteArea id={area.id}/>
        </div>
     </div>)}
     </div>
   </div>
 </div>
 }
 
    return <div>
    {content}
    </div>
}

const AddSidesToCategory = () => {
    const [shapeName, setShapeName] = useState<string>("")
    const [dimensions, setDimensions] = useState<string>("")
    const [saveProductAttribute] = useSavePrintareaShapesMutation()
    const [open, setOpen] = useState(false)
  
    const handleSubmit = async () => {
      if(!shapeName && !dimensions){
        toast.error("Add Shape and dimensions fields")
      }
      await saveProductAttribute({ shapeName, dimensions })
      setOpen(false)
      setShapeName("")
      setDimensions("")
    }
  
    const handleClose = () => {
      setOpen(false)
      setShapeName("")
      setDimensions("")
    }
  
    return (
      <Dialog open={open} onOpenChange={() => {
        if (!open) {
          setOpen(true);
        } else {
          handleClose();
        }
      }}>
        <DialogTrigger asChild>
          <Button variant="default" className="headermenu">Add New PrintArea Shape</Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Add Printarea shape</DialogTitle>
            <p className="text-sm my-1 text-red-500"></p>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="flex items-center gap-4">
              <Label htmlFor="shapeName" className="text-right">
                ShapeName:
              </Label>
              <Input onChange={e=>setShapeName(e.target.value)} value={shapeName} placeholder='Shape Name' className='input-field' id='shapeName' />
            </div>
            <div className="flex items-center gap-4">
              <Label htmlFor="dimensions" className="text-right">
                Dimensions:
              </Label>
              <Textarea onChange={e=>setDimensions(e.target.value)} value={dimensions} placeholder='SVG Path' className='textarea' />
            </div>
          {dimensions&& <svg xmlns="http://www.w3.org/2000/svg" >
    <path d={dimensions} fill="none"  stroke="black" />
   </svg>}

          </div>
          <DialogFooter>
            <Button onClick={handleSubmit}>Add Area</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>)
  }
  
  export function DeleteArea({ id }: { id: number }) {
    const [open, setOpen] = useState(false)
    const [deleteProductAttribute] = useDeletePrintareaShapesMutation()
    const handleClose = () => {
      setOpen(false)
    }
    return (
      <AlertDialog open={open} onOpenChange={() => {
        if (!open) {
          setOpen(true);
        } else {
          handleClose();
        }
      }}>
        <AlertDialogTrigger asChild>
          <Button className="" variant="ghost"><MdDeleteOutline  size={20} color='red' /></Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete  Printarea Shape.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={() => deleteProductAttribute(id)} className="bg-red-500" >Delete</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    )
  }
  
  const EditArea = ({area }: { area:TPrintareaShapes}) => {
    const [shapeName, setShapeName] = useState<string>("")
    const [dimensions, setDimensions] = useState<string>("")
    const [saveProductAttribute] = useSavePrintareaShapesMutation()
    const [open, setOpen] = useState(false)
  
    useEffect(()=>{
        setShapeName(area.shapeName)
        setDimensions(area.dimensions)
    },[area])
    const handleSubmit = async () => {
      if(!shapeName && !dimensions){
        toast.error("Add Shape and dimensions fields")
      }
      await saveProductAttribute({id:area.id, shapeName, dimensions })
      setOpen(false)
      setShapeName("")
      setDimensions("")
    }
  
    const handleClose = () => {
      setOpen(false)
    }
    return (
      <Dialog open={open} onOpenChange={() => {
        if (!open) {
          setOpen(true);
        } else {
          handleClose();
        }
      }}>
        <DialogTrigger asChild>
          <Button variant="ghost" className=""><FaEdit  size={"20"}  /></Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Edit PrintArea</DialogTitle>
            <p className="text-sm my-1 text-red-500"></p>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="flex items-center gap-4">
              <Label htmlFor="shapeName" className="text-right">
                ShapeName:
              </Label>
              <Input onChange={e=>setShapeName(e.target.value)} value={shapeName} placeholder='Shape Name' className='input-field' id='shapeName' />
            </div>
            <div className="flex items-center gap-4">
              <Label htmlFor="dimensions" className="text-right">
                Dimensions:
              </Label>
              <Textarea onChange={e=>setDimensions(e.target.value)} value={dimensions} placeholder='SVG Path' className='textarea' />
            </div>
          {dimensions&& <svg xmlns="http://www.w3.org/2000/svg" className='min-w-100 min-h-100'>
    <path d={dimensions} fill="none"  stroke="black" />
   </svg>}

          </div>
          <DialogFooter>
            <Button onClick={handleSubmit}>Add Sides</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>)
  }
  
export default PrintareaShapes