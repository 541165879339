import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { Button } from "../../components/ui/button"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../components/ui/alert-dialog"
import { Label } from "../../components/ui/label"
import { useDeleteProductAttributeMutation, useGetProductAttributesByAttNameMutation } from "../../redux/slice/admin/AAttributeApiSlice"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select"
import { useGetAllCategoryQuery, useGetSubcategoryQuery } from "../../redux/slice/CategoryApiSlice"
import { useNavigate, useSearchParams } from "react-router-dom"
import { FilterByCategoryTemplate } from "./Templates"
import {  PaperTypeItem } from "@/react-app-env"
import { AddNew } from "./AddAttributeOffice"
//import { PaperQuality, PaperSizes, PaperTypeFixed } from "../../constants"

const StationaryAttributes = () => {
  const [productAttByAttName, { data: productAttributes }] = useGetProductAttributesByAttNameMutation()
  let attributeName = "Office Stationary"
  const [searchParams] = useSearchParams()
  const catId = searchParams.get("catId") || 11;
  const subcatId = searchParams.get("subCatId") || "All";
  const [condEdDe, setCondEdDe] = useState<boolean>(false)
  const [deleteCon, setDeleteCon] = useState<boolean>(false)
  const [selectedType, setSelectedType] = useState<PaperTypeItem>();
  const [containsId, setContainsId] = useState<string>("0");
  const [colorId, setColorId] = useState<string>("0");
  const [typeId, setTypeId] = useState<string>("0");
  const [pSize, setPSize] = useState<string>("0");
  const [pQua, setPQua] = useState<string>("0");
  const navigate =useNavigate()

  useEffect(() => {
    if (subcatId === "All") {
      productAttByAttName({ catId, attributeName, subcatId: 0 })
    } else {
      productAttByAttName({ catId, subcatId, attributeName })
    }
  }, [productAttByAttName, attributeName, catId, subcatId, condEdDe])


  const AttValue = productAttributes?.result[0]?.attributeValue
  const AttValueObj = AttValue ? JSON.parse(AttValue) : null;
  const paperSizesSet = AttValueObj ? AttValueObj["paperSizes"] : null;
  const paperQualitySet = AttValueObj ? AttValueObj["paperQua"] : null;
  const paperTypeSet = AttValueObj ? AttValueObj["paperType"] : null;


  // console.log(paperTypeSet.flatMap((type: any) => type.contains));
  const handleTypeChange = (value: any) => {
    const numericValue = Number(value);
    setTypeId(value)
    const attR = paperTypeSet.filter((item: PaperTypeItem) => item.id === value)
    setSelectedType(attR[0])
    if (numericValue === 0) {
      setColorId(value); setContainsId(value)
    }
  }

  const handleContainChange = (value: any) => {
    const numericValue = Number(value);
    setContainsId(value)
    if (numericValue !== 0) {
      const attR = paperTypeSet.filter((item: PaperTypeItem) =>
        item.contains.some((contain: any) => contain.id === value)
      );
      setSelectedType(attR[0])
    }
    else if (typeId === "0" && colorId === "0" && numericValue === 0) {
      const attR = paperTypeSet.filter((item: PaperTypeItem) =>
        item.colors.some((contain: any) => contain.id === numericValue));
      setSelectedType(attR[0])
    }
  }

  const handleColorChange = (value: any) => {
    const numericValue = Number(value);
    setColorId(value)
    if (numericValue !== 0) {
      const attR = paperTypeSet.filter((item: PaperTypeItem) =>
        item.colors.some((contain: any) => contain.id === value));
      setSelectedType(attR[0])
    }
    else if (typeId === "0" && containsId === "0" && numericValue === 0) {
      const attR = paperTypeSet.filter((item: PaperTypeItem) =>
        item.colors.some((contain: any) => contain.id === numericValue));
      setSelectedType(attR[0])
    }

  }

  const handleQuality = (value: any) => {
   // const numericValue = Number(value);
    setPQua(value)
  }
  const handleSize = (value: any) => {
   // const numericValue = Number(value);
    setPSize(value)
  }


  return (
    <div className="wrapper-dashboard">
      <div className="flex justify-between gap-4 flex-wrap">
        <h5 className="text-xl font-bold">Office Stationary</h5>
        <div className="flex gap-6 flex-wrap">
          <FilterByCategoryTemplate catId={catId?.toString()} />
          <FilterBySubCategoryAttributes catId={catId?.toString()} subCatId={subcatId} />
        </div>
        <Button  className="headermenu" onClick={()=>navigate(`/admin/dashboard/attributes/stationary/new`)} >Add Fields Stationary</Button>
      </div>
      <div className="my-4">
        <p className="font-medium">Attribute Name: {attributeName}</p>
        {(catId==="11" || catId===11)&&(subcatId==="0"|| subcatId==="All") ? <AddNew/>:<>
        {AttValueObj ? <div className="flex gap-2 flex-col my-4">
          {paperSizesSet?.length > 0 && <div className="flex gap-3 items-center">
            <Label>Paper Size:</Label>
            <Select value={pSize?.toString()} onValueChange={handleSize}>
              <SelectTrigger className="bg-blue-50 text-md font-semibold px-0  ">
                <SelectValue placeholder="----Select----" />
              </SelectTrigger>
              <SelectContent className='border-gray-500 bg-gray-100  hover:text-black '>
                <SelectItem key={0} value={"0"}>---Select---</SelectItem>
                {paperSizesSet?.map((sizes: any) => (
                  <SelectItem
                    key={sizes.id}
                    value={sizes?.id?.toString()}
                    className="select-item p-regular-14"
                  >
                    {sizes.paper}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select></div>}
          {paperQualitySet?.length > 0 && <div className="flex gap-3 items-center">
            <Label>Paper Quality:</Label>
            <Select value={pQua?.toString()} onValueChange={handleQuality}>
              <SelectTrigger className="bg-blue-50 text-md font-semibold px-0 ">
                <SelectValue placeholder="----Select----" />
              </SelectTrigger>
              <SelectContent className='border-gray-500 bg-gray-100  hover:text-black '>
                <SelectItem key={0} value={"0"}>---Select---</SelectItem>
                {paperQualitySet?.map((quality: any) => (
                  <SelectItem
                    key={quality.id}
                    value={quality?.id?.toString()}
                    className="select-item p-regular-14"
                  >
                    {quality.quality}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select></div>}
          {(selectedType) ? <div className="flex gap-3 items-center">
            <Label>Paper Type:</Label><Select onValueChange={handleTypeChange} value={typeId?.toString()}>
              <SelectTrigger className="bg-blue-50 text-md font-semibold px-0 ">
                <SelectValue placeholder="---Select---" />
              </SelectTrigger>
              <SelectContent className='border-gray-500 bg-gray-100  hover:text-black '>
                <SelectItem key={0} value={"0"}>---Select---</SelectItem>
                <SelectItem
                  key={selectedType.id}
                  value={selectedType?.id?.toString()}
                  className="select-item p-regular-14"
                >
                  {selectedType.type}
                </SelectItem>
              </SelectContent>
            </Select></div> : (paperTypeSet?.length > 0 && <div className="flex gap-3 items-center">
              <Label>Paper Type:</Label><Select onValueChange={handleTypeChange} value={typeId?.toString()}>
                <SelectTrigger className="bg-blue-50 text-md font-semibold px-0 ">
                  <SelectValue placeholder="---Select---" />
                </SelectTrigger>
                <SelectContent className='border-gray-500 bg-gray-100  hover:text-black '>
                  <SelectItem key={0} value={"0"}>---Select---</SelectItem>
                  {paperTypeSet?.filter((typeP: any) => typeP.enabled)?.map((typeP: any) => (
                    <SelectItem
                      key={typeP.id}
                      value={typeP?.id?.toString()}
                      className="select-item p-regular-14"
                    >
                      {typeP.type}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select></div>)}
          {selectedType ? (
            <><div className="flex gap-3 items-center">
              <Label>Paper Contains:</Label>
              <Select onValueChange={handleContainChange} value={containsId?.toString()}>
                <SelectTrigger className="bg-blue-50 text-md font-semibold px-0">
                  <SelectValue placeholder="----Select Contains----" />
                </SelectTrigger>
                <SelectContent className='border-gray-500 bg-gray-100 hover:text-black'>
                  <SelectItem key={0} value={"0"}>
                    ---Select Contains---
                  </SelectItem>
                  {selectedType?.contains?.filter((contain: any) =>contain.enabled)?.map((contain: any) => (
                    <SelectItem key={contain.id} value={contain?.id?.toString()}>
                      {contain.contain}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select></div>
              <div className="flex gap-3 items-center">
                <Label>Paper Colors:</Label>
                <Select onValueChange={handleColorChange} value={colorId?.toString()}>
                  <SelectTrigger className="bg-blue-50 text-md font-semibold px-0">
                    <SelectValue placeholder="----Select Color----" />
                  </SelectTrigger>
                  <SelectContent className='border-gray-500 bg-gray-100 hover:text-black'>
                    <SelectItem key={0} value={"0"}>
                      ---Select Contains---
                    </SelectItem>
                    {selectedType?.colors?.filter((color: any) =>color.enabled)?.map((color: any) => (
                      <SelectItem key={color.id} value={color?.id?.toString()}>
                        {color.color}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select></div>
            </>
          ) : (
            <>
              {paperTypeSet?.length > 0 && <div className="flex gap-3 items-center">
                <Label>Paper Contains:</Label><Select onValueChange={handleContainChange} value={containsId?.toString()}>
                  <SelectTrigger className="bg-blue-50 text-md font-semibold px-0">
                    <SelectValue placeholder="----Select Contains----" />
                  </SelectTrigger>
                  <SelectContent className='border-gray-500 bg-gray-100 hover:text-black'>
                    <SelectItem key={0} value={"0"}>
                      ---Select Contains---
                    </SelectItem>
                    {paperTypeSet?.filter((typeP: any) => typeP.enabled)?.flatMap((type: any) => type.contains).filter((contain: any) => contain?.enabled).map((contain: any) => (
                      <SelectItem key={contain.id} value={contain?.id?.toString()}>
                        {contain.contain}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select></div>}

              {paperTypeSet?.length > 0 && <div className="flex gap-3 items-center">
                <Label>Paper Colors:</Label><Select onValueChange={handleColorChange} value={colorId?.toString()}>
                  <SelectTrigger className="bg-blue-50 text-md font-semibold px-0">
                    <SelectValue placeholder="----Select Color----" />
                  </SelectTrigger>
                  <SelectContent className='border-gray-500 bg-gray-100 hover:text-black'>
                    <SelectItem key={0} value={"0"}>---Select Color---</SelectItem>
                    {paperTypeSet?.filter((typeP: any) => typeP.enabled)?.flatMap((type: any) => type.colors).filter((contain: any) => contain?.enabled).map((color: any) => (
                      <SelectItem key={color.id} value={color?.id?.toString()}>
                        {color.color}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select></div>}
            </>
          )}
        </div> : <p className="text-xl font-bold my-5">Selected Filters There Is No Stationary Properties</p>}
        {AttValueObj && <div className="space-x-4">
          <Button variant="default" className="headermenu" onClick={() => navigate(`/admin/dashboard/attributes/stationary/${productAttributes?.result[0]?.id}`)}>Edit Fields Stationary</Button>
          <Button className="bg-red-800" onClick={() => setDeleteCon(true)} >Delete Staionary</Button>
         {deleteCon && <DeleteOffice id={productAttributes?.result[0].id} setCondEdDe={setCondEdDe} setDeleteCon={setDeleteCon} />}
        </div>}
        </>}
      </div>
    </div>
  )
}


export function DeleteOffice({ id, setCondEdDe, setDeleteCon }: { id: number, setCondEdDe: Dispatch<SetStateAction<boolean>>, setDeleteCon: Dispatch<SetStateAction<boolean>> }) {
  const [open, setOpen] = useState(true)
  const [deleteProductAttribute] = useDeleteProductAttributeMutation()
  const handleClose = () => {
    setOpen(false)
    setDeleteCon(false)
  }
  return (
    <AlertDialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      {/*  <AlertDialogTrigger asChild>
        <Button className="bg-red-800" >Delete Staionary</Button>
      </AlertDialogTrigger> */}
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete Office stationary  related to Category/SubCategory.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={() => { deleteProductAttribute(id); setCondEdDe(p => !p);setDeleteCon(false) }} className="bg-red-500" >Delete</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}


export const FilterByCategory = ({ onChangeHandler, categoryId }: any) => {
  const { data: categories } = useGetAllCategoryQuery("categoriesList", { pollingInterval: 60000, refetchOnFocus: true, refetchOnMountOrArgChange: true })

  const handleValueChange = (selectedValue: string) => {
    onChangeHandler(selectedValue);
  };

  return (<div className="flex gap-1 items-center">
    <Select onValueChange={handleValueChange} value={categoryId?.toString()} >
      <SelectTrigger className="bg-blue-50 text-md font-semibold px-0">
        <SelectValue placeholder="T Shirts" />
      </SelectTrigger>
      <SelectContent className='border-gray-500 bg-gray-100  hover:text-black '>
        {categories?.result?.map((category: any) => (
          <SelectItem
            key={category.id}
            value={category.id?.toString()}
            className="select-item p-regular-14"
          >
            {category.categoryName}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  </div>);
};
export const FilterBySubCategory = ({ onChangeHandler, subcategoryId, categoryId }: any) => {
  const { data: subCategories } = useGetSubcategoryQuery(categoryId, { pollingInterval: 60000, refetchOnFocus: true, refetchOnMountOrArgChange: true })

  const handleValueChange = (selectedValue: string) => {
    onChangeHandler(Number(selectedValue));
  };

  return (<div className="flex gap-1 items-center">
    <Select onValueChange={handleValueChange} value={subcategoryId?.toString()} >
      <SelectTrigger className="bg-blue-50 text-md font-semibold px-0">
        <SelectValue placeholder="All" />
      </SelectTrigger>
      <SelectContent className='border-none bg-gray-100  hover:text-black '>
        {/*  <SelectItem
          key={"All"}
          value={"0"}
          className="select-item p-regular-14"
        >
          All
        </SelectItem> */}
        {subCategories?.result?.map((subCategory: any) => (
          <SelectItem
            key={subCategory.id}
            value={subCategory?.id?.toString()}
            className="select-item p-regular-14"
          >
            {subCategory.subcategoryName}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  </div>);
};

export const FilterBySubCategoryAttributes = ({ catId, subCatId }: { catId?: string, subCatId?: string }) => {
  const { data: subCategories } = useGetSubcategoryQuery(catId, { pollingInterval: 60000, refetchOnFocus: true, refetchOnMountOrArgChange: true })
  const [searchParams, setSearchParams] = useSearchParams();
  const ScatId = searchParams.get("catId") || 11;

  const handleValueChange = (selectedValue: string) => {
    setSearchParams({ "catId": ScatId?.toString(), "subCatId": selectedValue });
  };

  return (<div className="flex gap-1 items-center">
    <p className="font-bold">SubCategory:</p>
    <Select onValueChange={handleValueChange} value={subCatId} >
      <SelectTrigger className="bg-blue-50 text-md font-semibold px-0">
        <SelectValue placeholder="All" />
      </SelectTrigger>
      <SelectContent className='border-none bg-gray-100  hover:text-black '>
        <SelectItem
          key={"All"}
          value={"All"}
          className="select-item p-regular-14"
        >
          All
        </SelectItem>
        {subCategories?.result?.map((subCategory: any) => (
          <SelectItem
            key={subCategory.id}
            value={subCategory?.id?.toString()}
            className="select-item p-regular-14"
          >
            {subCategory.subcategoryName}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  </div>);
};
export default StationaryAttributes