import { useNavigate } from 'react-router-dom'
import { Button } from '../../components/ui/button'
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
  } from "../../components/ui/alert-dialog"
import {
    Pagination,
    PaginationContent,
    PaginationEllipsis,
    PaginationItem,
    PaginationLink,
    PaginationNext,
    PaginationPrevious,
  } from "../../components/ui/pagination"
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
  } from "../../components/ui/dialog"
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Label } from '../../components/ui/label'
import { Input } from '../../components/ui/input'
import { useAddLogodesignMutation, useDeleteLogodesignMutation, useGetLogodesignsMutation, useUpdateLogodesignMutation } from '../../redux/slice/admin/ALogoApiSlice'
import { Card } from '../../components/ui/card'
import { toast } from 'react-toastify'
import { TLogoDesign } from '@/react-app-env'
import { IMGURL } from '../../constants/appConfig'
import { MdDelete, MdEdit } from 'react-icons/md'

const LogoCustomImages = () => {
    const [getLogoDesigns,{data:designs,}] =useGetLogodesignsMutation()
    const pageSize=20;
    const [pageNumber,setPageNumber]=useState(1)
    const [condEdDe,setCondEdDe]=useState<boolean>(false)
    useEffect(()=>{
        getLogoDesigns({pageNumber,pageSize})
    },[getLogoDesigns,pageSize,pageNumber,condEdDe])

   return( <div className="wrapper-dashboard bg-gray-50">
    <div className="flex justify-between gap-3">
    <h5 className="h5-bold">Logo/Custom Images</h5>
   
   <AddLogoImages setCondEdDe={setCondEdDe}/>
    </div>
    <div className="flex flex-wrap gap-6 my-4 mb-6">
     {designs?.result?.length>0&& designs?.result?.map((logo:TLogoDesign)=><Card key={logo.id}className="max-w-[150px] relative ">
      <img src={`${IMGURL}${logo.designUrl}`} alt="template" className="w-full h-[200px] rounded-t-lg object-fill"/>
      <div className="flex justify-between bg-slate-300 rounded-b-lg ">
        <DeleteLogoDesign id={logo.id} setCondEdDe={setCondEdDe}/> 
       <EditLogoImages setCondEdDe={setCondEdDe} data={logo}/>
       </div>
       </Card>)}
    </div>
    {designs?.result?.length>0&&<div>
        <PaginationButtons pageSize={pageSize} currentPage={pageNumber} onPageChange={setPageNumber} dataCount={designs?.dataCount}/>
    </div>}
    </div>)
}

interface AddLogoImagesProps {
    setCondEdDe: Dispatch<SetStateAction<boolean>>;
}
export function AddLogoImages({setCondEdDe}:AddLogoImagesProps) {
    const [templatePreview,setTemplatePreview]=useState<string>("")
    const [addLogodesign]=useAddLogodesignMutation()
  
    
  const handleSubmit =async()=>{
  await addLogodesign({templatePreview})
  setTemplatePreview("")
  setOpen(false)
  toast.success("New Logo design added successfully")
  setCondEdDe((pre:boolean)=>!pre)
  };
  
  const [open,setOpen]=useState(false)
    const handleClose=()=>{
      setOpen(false)
    }
  
    return (
      <Dialog open={open} onOpenChange={() => {
        if (!open) {
          setOpen(true);
        } else {
          handleClose();
        }}}>
        <DialogTrigger asChild>
          <Button className="headermenu">Add Logo/Custom Image</Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px] md:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Add Logo/Custom Image</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="template" className="text-right">
                Choose Template/Logo
              </Label>
              <Input id="template" type="file" className="col-span-3 input-field" accept="image/*, application/pdf"
                        onChange={(event) => {
                          const file = event.target.files && event.target.files[0];
                          if (file) {
                            const reader = new FileReader();
                            reader.onload = () => {
                              const base64String = reader.result as string;
                              setTemplatePreview(base64String);
                            };
                            reader.readAsDataURL(file); // Convert the selected file to Base64
                          }
                        }}/>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
             {templatePreview&& <img src={templatePreview} alt="baseimg"/>}
            </div>
          </div>
          <DialogFooter>
            <Button onClick={handleSubmit}>Save Logo</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    )
  }
  

interface EditLogoImagesProps {
    setCondEdDe: Dispatch<SetStateAction<boolean>>;
    data: TLogoDesign; // Update this type based on the actual type of data
}
export function EditLogoImages({setCondEdDe,data}:EditLogoImagesProps) {
    const [templatePreview,setTemplatePreview]=useState<string>("")
    const [updateLogodesign]=useUpdateLogodesignMutation()
  
    
  const handleSubmit =async()=>{
  await updateLogodesign({templatePreview,designId:data?.id})
  setTemplatePreview("")
  setOpen(false)
  toast.success("Logo design Edited successfully")
  setCondEdDe((pre:boolean)=>!pre)
  };
  
  const [open,setOpen]=useState(false)
    const handleClose=()=>{
      setOpen(false)
    }
  
    return (
      <Dialog open={open} onOpenChange={() => {
        if (!open) {
          setOpen(true);
        } else {
          handleClose();
        }}}>
        <DialogTrigger asChild>
        <Button  /* className="absolute top-0 right-0 rounded-full p-3 bg-gray-200" */ variant="ghost"><MdEdit  size={20} color="blue" /></Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px] md:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Edit Logo/Custom Image</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="template" className="text-right">
                Choose Template/Logo
              </Label>
              <Input id="template" type="file" className="col-span-3 input-field" accept="image/*, application/pdf"
                        onChange={(event) => {
                          const file = event.target.files && event.target.files[0];
                          if (file) {
                            const reader = new FileReader();
                            reader.onload = () => {
                              const base64String = reader.result as string;
                              setTemplatePreview(base64String);
                            };
                            reader.readAsDataURL(file); // Convert the selected file to Base64
                          }
                        }}/>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
             {templatePreview? <img src={templatePreview} alt="baseimg"/>:<img src={`${IMGURL}${data.designUrl}`} alt="baseimg"/>}
            </div>
          </div>
          <DialogFooter>
            <Button onClick={handleSubmit}>Save Logo</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    )
  }

  export function DeleteLogoDesign({id,setCondEdDe}:{id:number,setCondEdDe:Dispatch<SetStateAction<boolean>>}) {
    const [open,setOpen]=useState(false)
    const [deleteLogodesign]=useDeleteLogodesignMutation()
    const handleClose=()=>{
      setOpen(false)
    }
    const handleDelete=async()=>{
     await deleteLogodesign(id)
     setCondEdDe((pre:boolean)=>!pre)
    }
    return (
      <AlertDialog open={open} onOpenChange={() => {
        if (!open) {
          setOpen(true);
        } else {
          handleClose();
        }}}>
        <AlertDialogTrigger asChild>
          <Button /* className=" absolute bottom-0 right-0 rounded-full p-3 bg-gray-200" */ variant="ghost">{/* Delete Template */}<MdDelete size={20} color="red" /></Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete the Logo.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={()=>{setOpen(false)}}>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleDelete} className="bg-red-500">Continue</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    )
  }

  interface PaginationButtonsProps {
    currentPage: number;
    pageSize: number;
    onPageChange: (page: number) => void;
    dataCount: number;
  }
  
  
  export function PaginationButtons({
    currentPage,
    pageSize,
    onPageChange,
    dataCount,
  }: PaginationButtonsProps) {
    const totalPages = Math.ceil(dataCount / pageSize);
  
    const generatePages = () => {
      const pages: (number | string)[] = [];
      if (totalPages <= 5) {
        for (let i = 1; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        pages.push(1);
        if (currentPage > 3) pages.push('...');
        if (currentPage > 2) pages.push(currentPage - 1);
        if (currentPage > 1 && currentPage < totalPages) pages.push(currentPage);
        if (currentPage < totalPages - 1) pages.push(currentPage + 1);
        if (currentPage < totalPages - 2) pages.push('...');
        pages.push(totalPages);
      }
      return pages;
    };
  
    const pages = generatePages();
  
    return (
      <Pagination>
        <PaginationContent>
          <PaginationItem>
            <PaginationPrevious
              href="#"
              onClick={currentPage > 1 ? () => onPageChange(currentPage - 1) : undefined}
              aria-disabled={currentPage === 1}
              style={{ pointerEvents: currentPage === 1 ? 'none' : 'auto', opacity: currentPage === 1 ? 0.5 : 1 }}
            />
          </PaginationItem>
          {pages.map((page, index) => (
            <PaginationItem key={index}>
              {page === '...' ? (
                <PaginationEllipsis />
              ) : (
                <PaginationLink
                  href="#"
                  onClick={() => onPageChange(page as number)}
                  isActive={currentPage === page}
                >
                  {String(page)}
                </PaginationLink>
              )}
            </PaginationItem>
          ))}
          <PaginationItem>
            <PaginationNext
              href="#"
              onClick={currentPage < totalPages ? () => onPageChange(currentPage + 1) : undefined}
              aria-disabled={currentPage === totalPages}
              style={{ pointerEvents: currentPage === totalPages ? 'none' : 'auto', opacity: currentPage === totalPages ? 0.5 : 1 }}
            />
          </PaginationItem>
        </PaginationContent>
      </Pagination>
    );
  }
  

export default LogoCustomImages