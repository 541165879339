import { ColumnDef } from "@tanstack/react-table"
import { DataTableColumnHeader } from "./data-table-column-header"
import { DataTableRowActions } from "./data-table-row-actions"
import { ProductDataT } from "@/react-app-env"
import { IMGURL } from "../../../../../constants/appConfig"
import parse from 'html-react-parser';


export const columns: ColumnDef<ProductDataT>[] = [

  {
    accessorKey: "id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Id" />
    ),
    cell: ({ row }) => {
      const product = row.original
    return <div className="w-[80px]">{product.id}</div>
  },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "templateId",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="TemplateId" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },
  },
  {
    accessorKey: "categoryName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Category" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },
  },
  {
    accessorKey: "subcategoryName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Subcategory" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },
  },
  {
    accessorKey: "productName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="ProductName" />
    ),
    cell: ({ row }) => (
      <div className="capitalize w-[200px]  line-clamp-2 max-h-[3.6em]">{row.getValue("productName")}</div>
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },
  },
  {
    accessorKey: "productDescription",
    header:  ({ column }) => (
      <DataTableColumnHeader column={column} title="Description" />
    ),
    cell: ({ row }) => (
      <div className="capitalize w-[250px]  overflow-hidden overflow-ellipsis whitespace-pre-line leading-[1.2em] max-h-[3.6em]">{parse(row.getValue("productDescription"))}</div>
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },
  },
  {
    accessorKey: "priceINR",
    header: () => <div className="text-right">PriceINR</div>,
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue("priceINR"));
      const formatted = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "INR",
      }).format(amount);
      return <div className="text-right font-medium ">{formatted}</div>;
    },
  },
  {
    id: "actions",
    cell: ({ row }) => <DataTableRowActions row={row} />,
  },
]
